<template>
  <v-container fluid id="tract-settings">
    <v-row dense>
      <v-col cols="12">
        <material-card
          color="primary"
          full-width
          :title="$t('tractSettings')"
          :text="$t('tractSettingsDescription')"
        >
          <v-tabs v-model="tab" touchless>
            <v-tab>
              <span>{{ $t('tractTypes') }}</span>
            </v-tab>

            <v-tab>
              <span>{{ $t('harvestTypes') }}</span>
            </v-tab>

            <v-tab>
              <span>{{ $t('loggingMethods') }}</span>
            </v-tab>
          </v-tabs>

          <v-window v-model="tab">
            <v-window-item>
              <TractTypeSettings/>
            </v-window-item>

            <v-window-item>
              <HarvestTypeSettings/>
            </v-window-item>

            <v-window-item>
              <LoggingMethodSettings/>
            </v-window-item>

          </v-window>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TractSettings',

  components: {
    TractTypeSettings: () => import('./tract-type/TractTypeSettings.vue'),
    HarvestTypeSettings: () => import('./harvest-type/HarvestTypeSettings.vue'),
    LoggingMethodSettings: () => import('./logging-method/LoggingMethodSettings.vue')
  },

  data: () => ({
    tab: 0
  })
}
</script>
