import i18n from '@/i18n'
import { DataTableColumn, ColumnSet } from '@/model/DataTableColumn.js'
import { SETTING_KEYS } from '@/utils/UserSettings.js'
import { tonStringFromPounds } from '@/utils/NumericMutations'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { CorrectionType } from '@/utils/Enumerations.js'
import { localeCompareAlphanumeric } from '../utils/base'
import IncludeHeadersForWidget from '../components/dashboard/InteractiveWidgetHeaderHelper'
import { sortLandowners } from '../utils/SortHelpers'

export default {
  ticketCRUDHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('hashSign'),
        value: 'ticketNumber',
        align: 'left',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 28,
        sortable: true,
        text: i18n.t('source'),
        value: 'origin',
        align: 'center',
        hidable: true,
        group: 'misc'
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('trailerId'),
        value: 'trailerIdentifier',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('tract'),
        value: 'tract',
        hidable: false
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('account'),
        value: 'account',
        group: 'contract'
      }),
      new DataTableColumn({
        id: 22,
        sortable: true,
        text: i18n.t('landOwner'),
        value: 'landownerBundle',
        sort: sortLandowners,
        group: 'misc'
      }),
      new DataTableColumn({
        id: 23,
        sortable: true,
        text: i18n.t('logger'),
        value: 'logger',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 24,
        sortable: true,
        text: i18n.t('hauler'),
        value: 'hauler',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 25,
        sortable: true,
        text: i18n.t('supplier'),
        value: 'supplier',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 26,
        sortable: true,
        text: i18n.t('driver'),
        value: 'driver',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 27,
        sortable: true,
        text: i18n.t('consultingForester'),
        value: 'consultingForester',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination',
        group: 'contract'
      }),
      new DataTableColumn({
        id: 21,
        sortable: true,
        text: i18n.t('deck'),
        value: 'deckName',
        group: 'load'
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('setting'),
        value: 'setting',
        group: 'contract'
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('product'),
        value: 'product',
        group: 'load'
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('pieces'),
        value: 'pieces',
        align: 'right',
        group: 'load'
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('averageLength'),
        value: 'averageLength',
        align: 'right',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 12,
        sortable: true,
        text: i18n.t('plf'),
        value: 'plf',
        align: 'right',
        group: 'load'
      }),
      new DataTableColumn({
        id: 20,
        sortable: true,
        text: i18n.t('sed'),
        value: 'averageSED',
        align: 'right',
        group: 'load'
      }),
      new DataTableColumn({
        id: 13,
        sortable: true,
        text: i18n.t('grossTons'),
        value: 'grossWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 14,
        sortable: true,
        text: i18n.t('defectTons'),
        value: 'defectWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 15,
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 19,
        sortable: true,
        text: i18n.t('createdAt'),
        value: 'loadCreatedAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load'
      }),
      new DataTableColumn({
        id: 16,
        sortable: true,
        text: i18n.t('weighedOut'),
        value: 'weighedOutAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load'
      }),
      new DataTableColumn({
        id: 17,
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 18,
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        hidable: false
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'TicketGridHeaders',
      userSettingKey: SETTING_KEYS.TICKET_GRID_HIDDEN_COLUMNS
    })
  },

  bpTicketCRUDHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('hashSign'),
        value: 'ticketNumber',
        align: 'left',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 18,
        sortable: true,
        text: i18n.t('source'),
        value: 'origin',
        align: 'center',
        hidable: true,
        group: 'misc'
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('trailerId'),
        value: 'trailerIdentifier',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('fromAccount'),
        value: 'fromAccount',
        hidable: false
      }),
      // TODO: IH - restore column with TSBP
      // new DataTableColumn({
      //   id: 17,
      //   sortable: true,
      //   text: i18n.t('tract'),
      //   value: 'tract',
      //   hidable: false

      // }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('account'),
        value: 'account',
        group: 'contract'
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination',
        group: 'contract'
      }),
      new DataTableColumn({
        id: 21,
        sortable: true,
        text: i18n.t('deck'),
        value: 'deckName',
        group: 'load'
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('product'),
        value: 'product',
        group: 'load'
      }),
      new DataTableColumn({
        id: 16,
        sortable: true,
        text: i18n.t('departureTons'),
        value: 'departureWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('grossTons'),
        value: 'grossWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('defectTons'),
        value: 'defectWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 15,
        sortable: true,
        text: i18n.t('createdAt'),
        value: 'loadCreatedAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load'
      }),
      new DataTableColumn({
        id: 12,
        sortable: true,
        text: i18n.t('weighedOut'),
        value: 'weighedOutAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load'
      }),
      new DataTableColumn({
        id: 13,
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 14,
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        hidable: false
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'ByproductTicketGridHeaders',
      userSettingKey: SETTING_KEYS.BP_TICKET_GRID_HIDDEN_COLUMNS
    })
  },

  transferColumns () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('hashSign'),
        value: 'ticketNumber',
        align: 'left',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 17,
        sortable: true,
        text: i18n.t('source'),
        value: 'origin',
        align: 'center',
        hidable: true,
        group: 'misc'
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('trailerId'),
        value: 'trailerIdentifier',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('fromAccount'),
        value: 'fromAccount',
        hidable: false
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('account'),
        value: 'account',
        group: 'contract'
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination',
        group: 'contract'
      }),
      new DataTableColumn({
        id: 22,
        sortable: true,
        text: i18n.t('fromDeck'),
        value: 'fromDeckName',
        group: 'load',
        hidable: true
      }),
      new DataTableColumn({
        id: 21,
        sortable: true,
        text: i18n.t('deck'),
        value: 'deckName',
        group: 'load',
        hidable: true
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('product'),
        value: 'product',
        group: 'load'
      }),
      new DataTableColumn({
        id: 16,
        sortable: true,
        text: i18n.t('departureTons'),
        value: 'departureWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('grossTons'),
        value: 'grossWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('defectTons'),
        value: 'defectWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 15,
        sortable: true,
        text: i18n.t('createdAt'),
        value: 'loadCreatedAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load'
      }),
      new DataTableColumn({
        id: 12,
        sortable: true,
        text: i18n.t('weighedOut'),
        value: 'weighedOutAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load'
      }),
      new DataTableColumn({
        id: 13,
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 14,
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        hidable: false
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'TransferTicketGridHeaders',
      userSettingKey: SETTING_KEYS.TRANSFER_TICKET_GRID_HIDDEN_COLUMNS
    })
  },

  logYardSaleColumns () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('hashSign'),
        value: 'ticketNumber',
        align: 'left',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 17,
        sortable: true,
        text: i18n.t('source'),
        value: 'origin',
        align: 'center',
        hidable: true,
        group: 'misc'
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('trailerId'),
        value: 'trailerIdentifier',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc'
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('fromAccount'),
        value: 'fromAccount',
        hidable: false
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('account'),
        value: 'account',
        group: 'contract'
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination',
        group: 'contract'
      }),
      new DataTableColumn({
        id: 22,
        sortable: true,
        text: i18n.t('fromDeck'),
        value: 'fromDeckName',
        group: 'load',
        hidable: true
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('product'),
        value: 'product',
        group: 'load'
      }),
      new DataTableColumn({
        id: 16,
        sortable: true,
        text: i18n.t('departureTons'),
        value: 'departureWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('grossTons'),
        value: 'grossWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('defectTons'),
        value: 'defectWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight'
      }),
      new DataTableColumn({
        id: 15,
        sortable: true,
        text: i18n.t('createdAt'),
        value: 'loadCreatedAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load'
      }),
      new DataTableColumn({
        id: 12,
        sortable: true,
        text: i18n.t('weighedOut'),
        value: 'weighedOutAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load'
      }),
      new DataTableColumn({
        id: 13,
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 14,
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        hidable: false
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'LogYardSaleTicketGridHeaders',
      userSettingKey: SETTING_KEYS.LYS_TICKET_GRID_HIDDEN_COLUMNS
    })
  },

  ticketConsumptionHeaders () {
    const columns = [
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('hashSign'),
        value: 'ticketNumber',
        align: 'left',
        hidable: false,
        width: '*'
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        align: 'center',
        width: 'auto'
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        align: 'center',
        width: 'auto'
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('tract'),
        value: 'tract',
        width: 'auto'
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('account'),
        value: 'account',
        width: 'auto'
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination',
        width: 'auto'
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('product'),
        value: 'product',
        width: 'auto'
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'right',
        hidable: false,
        format: tonStringFromPounds,
        width: '*'
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('pieces'),
        value: 'pieces',
        align: 'right',
        width: '*'
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('mbf'),
        value: 'mbf',
        align: 'right',
        hidable: false,
        width: '*'
      }),
      new DataTableColumn({
        id: 13,
        sortable: true,
        text: i18n.t('rpi'),
        value: 'rpi',
        align: 'right',
        width: '*'
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('weighedOut'),
        value: 'weighedOutAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        width: '*'
      }),
      new DataTableColumn({
        id: 12,
        sortable: true,
        text: i18n.t('deckAssignedAt'),
        value: 'deckAssignedAt',
        align: 'center',
        hidable: false,
        format: (x) => utcToLocalDate(x, 'L - LT'),
        width: '*'
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'TicketConsumptionHeaders',
      userSettingKey: SETTING_KEYS.CONSUME_GRID_HIDDEN_COLUMNS
    })
  },

  consumedTicketHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('hashSign'),
        value: 'ticketNumber',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('tract'),
        value: 'tract'
      },
      {
        sortable: true,
        text: i18n.t('account'),
        value: 'account'
      },
      {
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination'
      },
      {
        sortable: true,
        text: i18n.t('product'),
        value: 'product'
      },
      {
        sortable: true,
        text: i18n.t('rpi'),
        value: 'rpi'
      },
      {
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('pieces'),
        value: 'pieces',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('weighedOut'),
        value: 'weighedOutAt',
        align: 'center'
      }
    ]
  },

  // Used by both the Unpost Tickets dialog and Export Batch dialog.
  ticketUnpostingHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('ticketNumber'),
        value: 'ticketNumber',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('tract'),
        value: 'tract'
      },
      {
        sortable: true,
        text: i18n.t('account'),
        value: 'account'
      },
      {
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination'
      },
      {
        sortable: true,
        text: i18n.t('setting'),
        value: 'setting'
      },
      {
        sortable: true,
        text: i18n.t('product'),
        value: 'product'
      },
      {
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'right',
        format: tonStringFromPounds
      },
      {
        sortable: true,
        text: i18n.t('pieces'),
        value: 'pieces',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('weighedOutAt'),
        value: 'weighedOutAt',
        align: 'center',
        format: (value) => utcToLocalDate(value, 'MM/DD/YYYY - LT')
      }
    ]
  },

  ticketPostingHeaders () {
    return this.ticketUnpostingHeaders().concat({
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    })
  },

  // Used by both the Unpost Tickets dialog and Export Batch dialog.
  ticketByproductUnpostingHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('ticketNumber'),
        value: 'ticketNumber',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('account'),
        value: 'account'
      },
      {
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination'
      },
      // TODO: IH - Add tract with TSBP
      // {
      //   sortable: true,
      //   text: i18n.t('tract'),
      //   value: 'tract'
      // },
      {
        sortable: true,
        text: i18n.t('fromAccount'),
        value: 'fromAccount'
      },
      {
        sortable: true,
        text: i18n.t('product'),
        value: 'product'
      },
      {
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'center',
        format: tonStringFromPounds
      },
      {
        sortable: true,
        text: i18n.t('weighedOutAt'),
        value: 'weighedOutAt',
        align: 'center',
        format: (value) => utcToLocalDate(value, 'MM/DD/YYYY - LT')
      }
    ]
  },

  ticketByproductPostingHeaders  () {
    return this.ticketByproductUnpostingHeaders().concat({
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    })
  },

  ticketNoCrudHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('hashSign'),
        value: 'ticketNumber',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('tract'),
        value: 'tract'
      },
      {
        sortable: true,
        text: i18n.t('account'),
        value: 'account'
      },
      {
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination'
      },
      {
        sortable: true,
        text: i18n.t('product'),
        value: 'product'
      },
      {
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('pieces'),
        value: 'pieces',
        align: 'right'
      },
      {
        sortable: true,
        text: i18n.t('weighedOut'),
        value: 'weighedOutAt',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center'
      }
    ]
  },

  ticketCorrectionHeaders () {
    return [
      {
        sortable: true,
        text: '',
        value: 'exportStatus',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('type'),
        value: 'type',
        format: (value) => CorrectionType.forInt(value).name
      },
      {
        text: i18n.t('notes'),
        value: 'notes'
      },
      {
        sortable: true,
        text: i18n.t('createdBy'),
        value: 'createdBy',
        align: 'center'
      },
      {
        sortable: true,
        text: i18n.t('createdAt'),
        value: 'createdAt',
        align: 'center',
        format: (value) => utcToLocalDate(value, 'MM/DD/YYYY - LT')
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  },

  importTicketLogCountHeaders () {
    return [
      {
        sortable: false,
        text: i18n.t('ticketNumber'),
        value: 'ticketNumber'
      },
      {
        sortable: false,
        text: i18n.t('length'),
        value: 'length',
        format: ft => `${ft}'`
      },
      {
        sortable: false,
        text: i18n.t('count'),
        value: 'count'
      }
    ]
  },

  ticketHeadersForInteractiveWidget (widget) {
    const include = new IncludeHeadersForWidget(widget)
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: true,
        text: i18n.t('hashSign'),
        value: 'ticketNumber',
        align: 'left',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('status'),
        value: 'status',
        align: 'center',
        hidable: false,
        visible: include.Status
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc',
        visible: include.Ext
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc',
        visible: include.Ext
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('trailerId'),
        value: 'trailerIdentifier',
        sort: localeCompareAlphanumeric,
        align: 'center',
        group: 'misc',
        visible: false
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('tract'),
        value: 'tract',
        group: include.Tract ? 'contract' : undefined,
        hidable: false,
        visible: include.Tract
      }),
      new DataTableColumn({
        id: 28,
        sortable: true,
        text: i18n.t('tractType'),
        value: 'tractType',
        hidable: false,
        visible: include.TractType
      }),
      new DataTableColumn({
        id: 29,
        sortable: true,
        text: i18n.t('fromAccount'),
        value: 'fromAccount',
        group: include.FromAccount ? 'contract' : undefined,
        hidable: false,
        visible: include.FromAccount
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('account'),
        value: 'account',
        group: 'contract',
        visible: include.Account
      }),
      new DataTableColumn({
        id: 22,
        sortable: true,
        text: i18n.t('landOwner'),
        value: 'landownerBundle',
        sort: sortLandowners,
        group: include.Tract ? 'misc' : undefined,
        visible: include.Landowner
      }),
      new DataTableColumn({
        id: 23,
        sortable: true,
        text: i18n.t('logger'),
        value: 'logger',
        group: include.Tract ? 'misc' : undefined,
        visible: include.Logger
      }),
      new DataTableColumn({
        id: 24,
        sortable: true,
        text: i18n.t('hauler'),
        value: 'hauler',
        group: include.Tract ? 'misc' : undefined,
        visible: false
      }),
      new DataTableColumn({
        id: 25,
        sortable: true,
        text: i18n.t('supplier'),
        value: 'supplier',
        group: include.Tract ? 'misc' : undefined,
        visible: false
      }),
      new DataTableColumn({
        id: 26,
        sortable: true,
        text: i18n.t('driver'),
        value: 'driver',
        group: include.AllowHideDriver ? 'misc' : undefined,
        visible: include.Driver
      }),
      new DataTableColumn({
        id: 31,
        sortable: true,
        text: i18n.t('driverAssignedAt'),
        value: 'driverAssignedAt',
        group: include.AllowHideDriver ? 'misc' : undefined,
        visible: include.DriverAssignedAt
      }),
      new DataTableColumn({
        id: 27,
        sortable: true,
        text: i18n.t('consultingForester'),
        value: 'consultingForester',
        group: include.Tract ? 'misc' : undefined,
        visible: false
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination',
        group: 'contract',
        visible: include.Destination
      }),
      new DataTableColumn({
        id: 21,
        sortable: true,
        text: i18n.t('deck'),
        value: 'deckName',
        group: 'load',
        visible: false
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('setting'),
        value: 'setting',
        group: include.Tract ? 'contract' : undefined,
        visible: false
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('product'),
        value: 'product',
        group: 'load'
      }),
      new DataTableColumn({
        id: 10,
        sortable: true,
        text: i18n.t('pieces'),
        value: 'pieces',
        align: 'right',
        group: 'load',
        visible: false
      }),
      new DataTableColumn({
        id: 11,
        sortable: true,
        text: i18n.t('averageLength'),
        value: 'averageLength',
        align: 'right',
        group: 'misc',
        visible: false
      }),
      new DataTableColumn({
        id: 12,
        sortable: true,
        text: i18n.t('plf'),
        value: 'plf',
        align: 'right',
        group: include.Tract ? 'load' : undefined,
        visible: include.Quality
      }),
      new DataTableColumn({
        id: 20,
        sortable: true,
        text: i18n.t('sed'),
        value: 'averageSED',
        align: 'right',
        group: include.Tract ? 'load' : undefined,
        visible: include.Quality
      }),
      new DataTableColumn({
        id: 13,
        sortable: true,
        text: i18n.t('grossTons'),
        value: 'grossWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight',
        visible: include.AllWeight
      }),
      new DataTableColumn({
        id: 14,
        sortable: true,
        text: i18n.t('defectTons'),
        value: 'defectWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight',
        visible: include.AllWeight || include.DefectWeight
      }),
      new DataTableColumn({
        id: 15,
        sortable: true,
        text: i18n.t('netTons'),
        value: 'netWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight',
        visible: include.AllWeight || include.NetWeight
      }),
      new DataTableColumn({
        id: 30,
        sortable: true,
        text: i18n.t('departureTons'),
        value: 'departureWeight',
        align: 'right',
        format: tonStringFromPounds,
        group: 'weight',
        visible: include.DepartureWeight
      }),
      new DataTableColumn({
        id: 19,
        sortable: true,
        text: i18n.t('createdAt'),
        value: 'loadCreatedAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load',
        visible: include.CreatedAt
      }),
      new DataTableColumn({
        id: 16,
        sortable: true,
        text: i18n.t('weighedOut'),
        value: 'weighedOutAt',
        align: 'center',
        format: (x) => utcToLocalDate(x, 'L - LT'),
        group: 'load',
        visible: false
      }),
      /* TODO: Remove if still unused by the time all interactive widgets are complete */
      // new DataTableColumn({
      //   id: 17,
      //   sortable: false,
      //   text: i18n.t('related'),
      //   value: 'related',
      //   align: 'center',
      //   hidable: false
      // }),
      new DataTableColumn({
        id: 18,
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        hidable: false
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: widget.label,
      userSettingKey: undefined
    })
  }
}
