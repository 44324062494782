import { isMobile } from '@/utils/DeviceUtility'

export const SETTING_TYPES = {
  CONTRACT_FORM: 'contractFormSettings',
  ACTIVITY_FORM: 'activityFormSettings',
  TRACT_FORM: 'tractFormSettings',
  TABLE: 'tableSettings',
  DASHBOARD: 'dashboardSettings'
}

const getSettingKeyForDevice = (string) => {
  const mobileExtension = 'Mobile'
  return isMobile() ? string.concat(mobileExtension) : string
}

export const SETTING_KEYS = {
  ACCOUNT_AUTOCOMPLETE: 'accountAutocompleteTags',
  DESTINATION_AUTOCOMPLETE: 'destinationAutocompleteTags',
  FROM_ACCOUNT_AUTOCOMPLETE: 'fromAccountAutocompleteTags',
  DEFAULT_ACCOUNT_AUTOCOMPLETE: 'defaultAccountAutocomplete',
  LAND_OWNER_ACCOUNT_AUTOCOMPLETE: 'landOwnerAccountAutocomplete',
  DRIVER_ACCOUNT_AUTOCOMPLETE: 'driverAccountAutocomplete',
  HAULER_ACCOUNT_AUTOCOMPLETE: 'haulerAccountAutocomplete',
  SUPPLIER_ACCOUNT_AUTOCOMPLETE: 'supplierAccountAutocomplete',
  TRACT_CONTRACT_ACCOUNT_AUTOCOMPLETE: 'tractContractAccountAutocomplete',
  TRACT_DESTINATION_AUTOCOMPLETE: 'tractDestinationAutocomplete',
  get TRACT_CRUISE_HIDDEN_COLUMNS () { return getSettingKeyForDevice('tractCruiseHiddenColumns') },
  get TRACT_CRUISE_DEPLETION_HIDDEN_COLUMNS () { return getSettingKeyForDevice('tractCruiseDepletionHiddenColumns') },
  get TICKET_GRID_HIDDEN_COLUMNS () { return getSettingKeyForDevice('ticketGridHiddenColumns') },
  BP_TICKET_GRID_HIDDEN_COLUMNS: 'byProductTicketGridHiddenColumns',
  TRANSFER_TICKET_GRID_HIDDEN_COLUMNS: 'transferTicketGridHiddenColumns',
  LYS_TICKET_GRID_HIDDEN_COLUMNS: 'logYardSaleTicketGridHiddenColumns',
  get TRACT_GRID_HIDDEN_COLUMNS () { return getSettingKeyForDevice('tractGridHiddenColumns') },
  PRODUCT_COSTS_HIDDEN_COLUMNS: 'productCostsHiddenColumns',
  TICKET_PRODUCT_COSTS_HIDDEN_COLUMNS: 'ticketCostsHiddenColumns',
  STANDING_TIMBER_HEADERS: 'standingTimberHeaders',
  COMMITTED_TIMBER_HEADERS: 'committedTimberHeaders',
  TRACT_PRODUCT_COST_HIDDEN_COLUMNS: 'tractProductCostsHiddenColumns',
  LOGS_CONTRACT_GRID_HIDDEN_COLUMNS: 'logsContractGridHiddenColumns',
  BP_CONTRACT_GRID_HIDDEN_COLUMNS: 'byProductContractGridHiddenColumns',
  TRANSFERS_AND_LYS_CONTRACT_GRID_HIDDEN_COLUMNS: 'transfersContractGridHiddenColumns',
  get DASHBOARD_LOG_CHARTS () { return getSettingKeyForDevice('dashboardLogCharts') },
  get DASHBOARD_BP_CHARTS () { return getSettingKeyForDevice('dashboardBpCharts') },
  get DASHBOARD_OPERATIONAL_CHARTS () { return getSettingKeyForDevice('dashboardOperationalCharts') },
  DASHBOARD_HIDDEN_SECTIONS: 'dashboardHiddenSections',
  DASHBOARD_BP_HIDDEN_SECTIONS: 'dashboardBpHiddenSections',
  DASHBOARD_CONTRACT_MODE: 'contractMode',
  CONTRACT_FILTER_TYPES: 'contractFilterTypes',
  CONSULTING_FORESTER_ACCOUNT_AUTOCOMPLETE: 'consultingForesterAccountAutocomplete',
  CONSUME_GRID_HIDDEN_COLUMNS: 'consumeGridHiddenColumns',
  DELIVERED_WOOD_HIDDEN_COLUMNS: 'deliveredWoodHiddenColumns',
  TIMBER_PORTFOLIO_HIDDEN_COLUMNS: 'timberPortfolioHiddenColumns'
}
