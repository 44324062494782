<template>
  <v-card data-testid="activity-form">
    <v-dialog
      v-model="costWarning"
      persistent
      width="400"
    >
      <ActivityCostWarning
        v-if="costWarning"
        :activityCost="activity.baseCost?.rate"
        @confirmation="handleWarningResult"
      />
    </v-dialog>
    <v-card-title :class="contractTypeColor">
      <span class="headline mr-4">{{title}}</span>
      <ActivityFormContractHeader :contract="contract" />
      <Icon
        iconColor="white"
        icon="mdi-close"
        dataTestId="activity-close"
        :small="false"
        :large="true"
        :tooltipText="$t('close')"
        @icon-clicked="$emit('close-form')"
      />
    </v-card-title>
    <v-card-text class="mt-4">
      <FormWrapper
        testId="save-activity"
        :disabled="isButtonDisabled"
        :buttonColor="contractTypeColor"
        @submit="saveChanges"
      >
        <v-container fluid grid-list-sm>
          <TemplateAutocomplete
            data-testid="activity-activity-template"
            :templateId="activity.activityTemplate ? activity.activityTemplate.activityTemplateId : undefined"
            :focus.sync="templateError"
            :includeReceivables="contractType !== ContractType.Transfer.value"
            :includeTransferActivities="contractType === ContractType.Transfer.value"
            :includeDefectActivities="contractType === ContractType.Production.value || contractType === ContractType.WoodsSale.value"
            includeModifiers
            :includeDepletionActivities="includeDepletion"
            @template-chosen="templateChosen"
          />
          <v-row dense>
            <v-col cols="12" sm="6">
              <DatePicker
                dataTestId="activity-effective-date"
                :focus.sync="datePickerError"
                :dateLabel="$t('effectiveDate')"
                :startDate="initialActivityDate"
                @date-picked="effectiveDateChosen"
                @valid-date="(val) => { isDateValid = val }"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <span v-if="activityIsRuntimeCounterparty">
                <v-text-field :value="counterpartyString" :label="$t('counterparty')" readonly outlined color="primary">
                  <template #prepend-inner>
                    <Icon
                    icon="mdi-lock-open"
                    icon-color="byproduct"
                    :tooltipText="$t('switchToConcreteCounterparty')"
                    :small="false"
                    @icon-clicked="activity.runtimeCounterparty = null"/>
                    <Icon
                    :icon="dynamicCounterpartyNotSet ? 'mdi-alert' : 'mdi-alpha-r-circle-outline'"
                    :small="false"
                    :tooltipText="runtimeCounterparty(activity.runtimeCounterparty)"
                    :iconColor="dynamicCounterpartyNotSet ? 'error' : 'info'"/>
                  </template>
                </v-text-field>
              </span>
              <AccountAutocomplete
                v-else
                class="ml-3"
                userSetting="activityAccount"
                data-testid="activity-account"
                :accountId="activity?.contractor?.accountId"
                :focus.sync="accountError"
                @account-chosen="accountChosen">
                  <template #prepend v-if="propActivity?.runtimeCounterparty !== null && propActivity?.runtimeCounterparty !== undefined && activity.activityTemplate?.category !== AccountingCategory.Accrual.value">
                    <Icon
                    icon="mdi-arrow-u-left-top-bold"
                    iconColor="black"
                    :tooltipText="$t('switchToRuntimeCounterparty')"
                    :small="false"
                    @icon-clicked="switchToRuntimeCounterparty()"/>
                  </template>
              </AccountAutocomplete>
            </v-col>
          </v-row>
          <v-row v-if="showRecoveryModeSelection">
            <Icon
              v-if="activity.recoveryMode.value === RecoveryMode.None.value && activity.contractor?.hasAdvances"
              icon="mdi-alert"
              iconColor="warning"
              :small="false"
              :tooltipText="$t('counterpartyHasOutstandingAdvance')"/>
            <v-col>
              <v-autocomplete
              v-model="activity.recoveryMode"
              :label="$t('recoveryMode')"
              return-object
              :items="mappedRecoverymodes"
              item-text="name"
              @change="activity.advanceId = undefined">
                <template #item="{item}">
                  {{ item.name }}
                  <v-spacer/>
                  <v-tooltip bottom color="primary" v-if="!item.disabled && item.definition">
                    <template #activator="{on}">
                      <v-icon right color="primary" v-on="on" small>
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span class="subtitle-1 white--text" style="white-space: pre-line" disabled>
                      {{ $t(item.definition, { tract: contract.tract })}}
                    </span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="9">
              <AdvanceAutocomplete
              v-if="activity.recoveryMode.value === RecoveryMode.Specific.value"
              :advanceId="activity.advanceId"
              :accountId="activity?.contractor?.accountId"
              @advance-chosen="advanceChosen"
            />
            </v-col>
          </v-row>
          <v-row v-if="showBaseCost">
            <v-col cols="12" class="mt-n3">
              <span class="title">{{$t('baseCost')}}</span>
            </v-col>
            <v-col cols="12">
              <BaseCostForm
                data-testid="activity-base-cost"
                :propBaseCost="activity.activityTemplate?.baseCost"
                :accountingCategory="activity?.activityTemplate?.category"
                :editing="true"
                :isBaseCostForm="true"
                :expectedValue="rateStats?.baseRecentAverage"
                :isLogsContract="contractType === ContractType.Production.value || contractType === ContractType.WoodsSale.value"
                @base-cost-changed="baseCostChanged"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import * as FieldRules from '@/utils/rules'
import { AccountingCategory, FinancialIntegrationType, ContractType, RuntimeCounterparty, RecoveryMode, TemplateSpecialization } from '@/utils/Enumerations'
import { mapActions, mapGetters } from 'vuex'
import { utcToLocalDate, localToUTC } from '@/utils/DateFormatter.js'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
import { TemplateSpecializationInfo } from './ActivityMapping'

export default {
  name: 'ActivityForm',

  props: {
    propActivity: {
      type: Object,
      default: undefined
    },
    contract: {
      type: Object,
      default: undefined
    },
    businessEntities: {
      type: Array,
      default: undefined
    },
    editing: {
      type: Boolean,
      default: false
    }
  },

  components: {
    DatePicker: () => import('../helper/DatePicker.vue'),
    BaseCostForm: () => import('./BaseCostForm.vue'),
    AccountAutocomplete: () => import('../autocomplete/AccountAutocomplete.vue'),
    AdvanceAutocomplete: () => import('../autocomplete/AdvanceAutocomplete.vue'),
    TemplateAutocomplete: () => import('../autocomplete/TemplateAutocomplete.vue'),
    ActivityCostWarning: () => import('./ActivityCostWarning.vue'),
    ActivityFormContractHeader: () => import('./ActivityFormContractHeader.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    TemplateSpecialization,
    TemplateSpecializationInfo,
    rules: FieldRules.default.rules,
    maxBaseRate: FieldRules.MAX_ACTIVITY_RATE,
    costWarning: false,
    datePickerError: false,
    templateError: false,
    accountError: false,
    selectedBusinessEntity: undefined,
    isBaseCost: false,
    firstEditLoad: false,
    accountAdvances: [],
    activity: {
      baseCost: {},
      contractor: {},
      activityTemplate: {},
      advanceNote: null,
      advanceId: null,
      recoveryMode: RecoveryMode.None
    },
    rateStats: undefined,
    tract: undefined,
    ContractType,
    AccountingCategory,
    RecoveryMode,
    landowners: [],
    isDateValid: true
  }),

  computed: {
    ...mapGetters('user-settings', ['mutatedUserSettings', 'userSettings']),
    ...mapGetters('user', ['companyInfo']),
    title () {
      return this.editing ? this.$t('editActivity') : this.$t('createActivity')
    },

    initialActivityDate () {
      return this.editing ? this.propActivity.effectiveDate : this.contract.effectiveDate
    },

    contractType () {
      return this.contract?.type
    },

    includeDepletion () {
      return this.tract?.type?.depletes === true
    },

    contractTypeColor () {
      return colorClassForContractType(this.contractType)
    },

    mappedRecoverymodes () {
      return RecoveryMode.enums.map(recoveryMode => ({
        ...recoveryMode,
        name: recoveryMode.name,
        definition: recoveryMode.explanation,
        excluded: (recoveryMode.value === RecoveryMode.Specific.value && (this.activityIsRuntimeCounterparty || !this.activity.contractor?.hasAdvances)) ||
          (recoveryMode.value === RecoveryMode.OldestOnTract.value && (this.contract.tract === null))
      })).filter(recoveryMode => !recoveryMode.excluded)
    },

    shouldRecoverWarning () {
      return this.activity.contractor?.hasAdvances && this.activity.recoveryMode.value === RecoveryMode.None.value
    },

    activityIsRuntimeCounterparty () {
      return this.activity.runtimeCounterparty !== null && this.activity.runtimeCounterparty !== undefined
    },

    counterpartyString () {
      if (this.dynamicCounterpartyNotSet) return this.$t('notSet')
      if (this.activity.contractor?.name) return this.activity.contractor.name
      switch (this.activity.runtimeCounterparty) {
        case RuntimeCounterparty.Landowner.value:
          return this.tract?.landOwnerAccountName || this.$t('multipleLandowners')
        case RuntimeCounterparty.Driver.value:
          return this.$t('ticketDriver')
        default:
          return this.$t('notAvailable')
      }
    },

    dynamicCounterpartyNotSet () {
      if (this.activity.runtimeCounterparty === null || this.activity.runtimeCounterparty === undefined) return false
      if (this.activity.runtimeCounterparty === RuntimeCounterparty.Landowner.value && this.landowners.length > 0) return false
      if (this.activity.runtimeCounterparty === RuntimeCounterparty.Driver.value) return false
      return this.activity.contractor === null || this.activity.contractor === undefined
    },

    showRecoveryModeSelection () {
      const contractsThatAcceptAdvances = [
        ContractType.Production.value,
        ContractType.WoodsSale.value,
        ContractType.ByproductSale.value,
        ContractType.ByproductPurchase.value
      ]

      return this.activity?.activityTemplate?.category === AccountingCategory.Payable.value && contractsThatAcceptAdvances.includes(this.contractType) &&
        this.activity?.activityTemplate?.specialization !== TemplateSpecialization.Penalty.value
    },

    showBaseCost () {
      return this.isBaseCost &&
      this.activity.activityTemplate !== undefined &&
      this.activity.activityTemplate.specialization !== TemplateSpecialization.Transfer.value &&
      (this.activity.activityTemplate.specialization !== TemplateSpecialization.Penalty.value ||
        this.activity.activityTemplate.category === AccountingCategory.Receivable.value)
    },

    isButtonDisabled () {
      return !this.isDateValid || !this.activity?.activityTemplate || !this.activity?.contractor?.name
    }
  },

  async created () {
    if (this.editing) {
      this.activity = JSON.parse(JSON.stringify(this.propActivity))
      this.activity.activityTemplate.baseCost = JSON.parse(JSON.stringify(this.propActivity.baseCost))
      this.activity.effectiveDate = utcToLocalDate(this.propActivity.effectiveDate)
      this.selectedBusinessEntity = this.businessEntities.find(entity => entity.businessEntityId === this.propActivity.activityTemplate.businessEntityId).name
      this.isBaseCost = true
      this.firstEditLoad = true
      this.activity.recoveryMode = RecoveryMode.forInt(this.propActivity.recoveryMode)
    } else {
      this.selectedBusinessEntity = this.businessEntities[0]?.name
    }
    if (this.contract.tractId) {
      this.landowners = await this.fetchTractLandowners(this.contract.tractId)
      this.tract = await this.fetchTract(this.contract.tractId)
    }
  },

  beforeDestroy () {
    this.updateUserSettings(this.mutatedUserSettings)
  },

  methods: {
    ...mapActions('activity', ['updateActivity', 'createActivity']),
    ...mapActions('activity-detail', ['updateActivityDetail']),
    ...mapActions('user-settings', ['updateUserSettings']),
    ...mapActions('activity-templates', ['fetchActivityTemplateStatsById']),
    ...mapActions('tract', ['fetchTract', 'fetchTractLandowners']),
    wait (timeToDelay) { return new Promise((resolve) => setTimeout(resolve, timeToDelay)) },
    effectiveDateChosen (date) { this.activity.effectiveDate = localToUTC(date) },
    runtimeCounterparty: (val) => RuntimeCounterparty.fromInt(val),
    accountChosen (account) {
      this.activity.contractor = account
      if (this.propActivity.contractor?.accountId === account?.accountId) {
        this.activity.recoveryMode = RecoveryMode.forInt(this.propActivity.recoveryMode)
        this.activity.advanceId = this.propActivity.advanceId
        return
      }

      this.activity.advanceId = null
      const recoversByDefault = (this.activityIsRuntimeCounterparty || account?.hasAdvances) &&
        (this.activity.activityTemplate.category === AccountingCategory.Payable.value) &&
        (this.contract.type !== ContractType.Transfer.value && this.contract.type !== ContractType.LogYardSale.value)
      this.activity.recoveryMode = recoversByDefault ? RecoveryMode.Oldest : RecoveryMode.None
    },
    baseCostChanged (baseCost) { this.activity.baseCost = baseCost },
    /*
    Base cost refreshes when template is changed. The wait function gives
    time for the component to rerender with the new template's values. Since
    the first edit gets the value from the prop it doesn't need the wait.
    */
    async templateChosen (template) {
      this.isBaseCost = false
      if (this.firstEditLoad) this.firstEditLoad = false
      else await this.wait(0)
      this.activity.activityTemplate = template
      if (template === undefined) return
      this.isBaseCost = true
      this.rateStats = await this.fetchActivityTemplateStatsById(template.activityTemplateId)
      if (template.category === AccountingCategory.Accrual.value) this.activity.runtimeCounterparty = null
    },
    advanceChosen (advance) {
      this.activity.advanceId = advance ? advance.advanceId : null
    },

    saveChanges () {
      if (!this.validate()) {
        return
      }

      const activityRequestObject = this.getRequestObject()
      if (this.editing) {
        if (TemplateSpecializationInfo.get(this.activity?.activityTemplate?.specialization).baseCost === false || this.checkActivityDetails(this.activity?.baseCost?.rate ?? 0)) {
          this.updateExistingActivity(activityRequestObject)
        } else {
          this.costWarning = true
        }
      } else {
        this.createNewActivity(activityRequestObject)
      }
    },

    validate () {
      if (this.activity.activityTemplate.name === undefined) {
        this.templateError = !this.templateError
        this.setSnackError(this.$t('noTemplateChosen'))
        return false
      }

      if (!this.activity.contractor?.name && (this.activity.runtimeCounterparty === null || this.activity.runtimeCounterparty === undefined)) {
        this.accountError = !this.accountError
        this.setSnackError(this.$t('noAccountChosen'))
        return false
      }

      if (this.activity.runtimeField === undefined &&
        this.activity.contractor?.exportCode === '' &&
        this.activity?.activityTemplate?.category !== AccountingCategory.Accrual.value &&
        this.companyInfo.financialIntegrationType !== FinancialIntegrationType.None.value &&
        !this.contract?.isDraft) {
        this.accountError = !this.accountError
        this.setSnackError(this.$t('counterpartyExportCodeErrorActivity', { accountName: this.activity.contractor.name }))
        return false
      }

      if (this.showBaseCost && this.activity.baseCost.rate > this.maxBaseRate) {
        this.templateError = !this.templateError
        this.setSnackError(this.$t('invalidActivityRate'))
        return false
      }

      return true
    },

    async updateExistingActivity (activityRequestObject) {
      try {
        await this.updateActivity(activityRequestObject)
        this.$emit('activity-updated')
      } catch (error) {
        const { code } = error
        this.handleError(code)
      }
    },

    async createNewActivity (activityRequestObject) {
      try {
        await this.createActivity(activityRequestObject)
        this.$emit('activity-updated')
      } catch (error) {
        const { code } = error
        this.handleError(code)
      }
    },

    getRequestObject () {
      const baseRequestObj = {
        baseCost: this.showBaseCost ? this.activity.baseCost : null,
        effectiveDate: this.activity.effectiveDate,
        contractorId: this.activity.contractor?.accountId,
        contractId: this.contract.contractId,
        templateId: this.activity.activityTemplate.activityTemplateId,
        advanceId: this.showRecoveryModeSelection && this.activity.recoveryMode.value !== RecoveryMode.None.value ? this.activity.advanceId : null,
        activityId: this.editing ? this.activity.activityId : null,
        recoveryMode: this.showRecoveryModeSelection ? this.activity.recoveryMode.value ?? RecoveryMode.None.value : RecoveryMode.None.value,
        runtimeField: undefined
      }
      if (this.activityIsRuntimeCounterparty) {
        delete baseRequestObj.contractorId
        baseRequestObj.runtimeCounterparty = this.propActivity.runtimeCounterparty
      }
      return baseRequestObj
    },

    checkActivityDetails (activityCost) {
      const details = this.activity.activityDetails
      for (let i = 0; i < details.length; i++) {
        if (details[i].cost.rate < activityCost) {
          return false
        }
      }
      return true
    },

    handleError (errorCode) {
      const isTemplateError = errorCode === 'E0027' ||
        errorCode === 'E0009' ||
        errorCode === 'E0026' ||
        errorCode === 'E0010' ||
        errorCode === 'E0030' ||
        errorCode === 'E0029'

      const isDateError = errorCode === 'E0033' ||
        errorCode === 'E0034' ||
        errorCode === 'E0007' ||
        errorCode === 'E0010'

      const isAccountError = errorCode === 'E0008' ||
        errorCode === 'E0031'

      if (isTemplateError) {
        this.templateError = !this.templateError
      } else if (isDateError) {
        this.datePickerError = !this.datePickerError
      } else if (isAccountError) {
        this.accountError = !this.accountError
      }
    },

    handleWarningResult (updateAnyway) {
      if (updateAnyway) {
        this.updateExistingActivity(this.getRequestObject())
      } else {
        this.costWarning = false
      }
    },

    switchToRuntimeCounterparty () {
      this.activity.runtimeCounterparty = this.propActivity.runtimeCounterparty
      this.activity.contractor = this.propActivity.contractor
      this.activity.recoveryMode = this.activity.activityTemplate.category === AccountingCategory.Payable.value ? RecoveryMode.Oldest : RecoveryMode.None
    }
  }
}
</script>
