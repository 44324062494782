<template>
  <ShortcutWrapper @n-shortcut="importUsersBtn">
    <v-row>
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <span class="title">
            {{ $t('roleAssignments') }}
            </span>
          </v-col>
          <v-col cols="auto">
            <Icon
            :icon="byRole ? 'mdi-account-supervisor-circle' : 'mdi-view-list'"
            :tooltipText="$t(byRole ? 'viewByUser' : 'viewByRole')"
            @icon-clicked="byRole = !byRole"
            :small="false"/>
          </v-col>
          <v-col cols="auto">
            <v-menu bottom left color="primary">
              <v-card class="py-2">
                <v-list-item @click="generateUserPermissionsPDF" id="download-user-permissions-pdf">
                  <v-avatar><v-icon color="secondary">mdi-file-question</v-icon></v-avatar>
                  <v-list-item-title>{{$t('userPermissionsPDF')}}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="generateUsersByRolePDF(claimsByUser)" id="download-users-by-role-pdf">
                  <v-avatar><v-icon color="secondary">mdi-file-account</v-icon></v-avatar>
                  <v-list-item-title>{{$t('usersByRoleAssignmentsPDF')}}</v-list-item-title>
                </v-list-item>
              </v-card>
              <template #activator="{on}">
                <v-icon v-on="on" color="primary">mdi-dots-vertical</v-icon>
              </template>
            </v-menu>
          </v-col>
          <v-col cols="auto">
            <Icon
            icon="mdi-plus"
            :small="false"
            :tooltipText="$t('importUsers')"
            @icon-clicked="importUsersBtn"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto" :style="{ width: byRole ? '1200px' : '1000px' }">
            <v-expansion-panels v-if="byRole" v-model="openPanels" popout accordion>
              <v-expansion-panel v-for="role in allRoles" :key="role.value">
                <v-expansion-panel-header class="pa-4" :style="{ height: $vuetify.breakpoint.xl ? '48px' : $vuetify.breakpoint.mdAndUp ? '64px' : $vuetify.breakpoint.smAndUp ? '100px' : '200px' }">
                  <v-row dense align="center" justify="space-between">
                    <v-col cols="12" md="auto" style="width: 180px;">
                      <span>{{$t(role.label)}}</span>
                    </v-col>
                    <v-col cols="12" md="" :style="{ textAlign: $vuetify.breakpoint.mdAndUp ? 'end' : 'start' }" class="mr-1">
                      <v-card flat><v-card-text class="pa-0"><span>{{$t(`${role.label}Description`)}}</span></v-card-text></v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="px-6 pt-4" style="width: 500px;" cols="auto">
                      <RolesUserBreakdown
                      :users="usersWithClaim(role)"
                      :role="role"
                      :allUsers="allUsers"
                      :claimsByUser="claimsByUser"
                      @assign-user="assignUserToRole"
                      @refresh-roles="$emit('refresh-roles')"/>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table v-else
            dense
            fixed-header
            :items-per-page="25"
            :height="tableHeight"
            item-key="applicationUserId"
            show-expand
            single-expand
            style="cursor: pointer"
            :items="claimsByUser"
            :headers="byUserHeaders"
            :expanded.sync="expandedByUser"
            @click:row="rowClicked"
            :hide-default-footer="claimsByUser?.length < 10">
              <template #expanded-item="{ item }">
                <td class="grey lighten-5" :colspan="3">
                  <v-card outlined class="my-4">
                    <v-data-table
                    dense
                    hide-default-footer
                    :headers="roleHeaders"
                    :items="expandedContentForUser(item)"
                    >
                    <template #item.assigned="{ item }">
                      <v-simple-checkbox @click="updateRoleAssignment(item)" color="success" :value="item.assigned"/>
                    </template>
                    </v-data-table>
                  </v-card>
                </td>
              </template>
              <template #item.userName="{item}">
                <span
                  :style="{ fontWeight: expandedByUser.some(ebu => ebu.applicationUserId === item.applicationUserId) ? 'bold' : 'normal' }">
                  {{item.userName}}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="importUsersDialog" width="1200px">
      <ImportUsers
      v-if="importUsersDialog"
      @close="closeImportUsersDialog"
      @usersImported="$emit('refresh-roles')"/>
    </v-dialog>
  </ShortcutWrapper>
</template>

<script>
import { UserClaims, UserClaimStatus } from '@/utils/Enumerations.js'
import RolesHeaders from '@/headers/Roles.js'
import { generateUserPermissionsPDF } from '@/components/settings-components/user-permissions/UserPermissionsPDF.js'
import { generateUsersByRolePDF } from '@/components/settings-components/user-permissions/UsersByRolePDF.js'
import { mapActions } from 'vuex'

export default {
  name: 'UsersByRole',

  components: {
    RolesUserBreakdown: () => import('@/components/settings-components/user-permissions/RolesUserBreakdown.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ImportUsers: () => import('@/components/settings-components/user-permissions/ImportUsers.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')
  },

  props: {
    claimsByUser: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    expanded: [],
    allUsers: [],
    importUsersDialog: false,
    isTouchScreen: navigator.maxTouchPoints > 0,
    byRole: true,
    expandedByUser: [],
    openPanels: []
  }),

  created () {
    this.openPanels = this.allRoles.length === 1 ? [0] : []
  },

  computed: {
    allRoles () {
      return UserClaims.enums
    },

    headers () {
      return RolesHeaders.usersByRoleHeaders()
    },

    byUserHeaders () {
      return RolesHeaders.rolesByUserHeaders()
    },

    roleHeaders () {
      return RolesHeaders.roleHeaders()
    },

    tableHeight () {
      return window.innerHeight > 700 ? 'calc(100vh - 554px)' : '256px'
    }
  },

  methods: {
    ...mapActions('user', ['updateUserClaims']),
    generateUserPermissionsPDF,
    generateUsersByRolePDF,
    usersWithClaim (claim) {
      return this.claimsByUser.filter(u => u.userClaims?.some(c => {
        return (c.systemClaim === claim.value && c.value === UserClaimStatus.Allow.value)
      }))
    },

    rowClicked (item) {
      let expanded = this.byRole ? this.expanded : this.expandedByUser
      this.$nextTick(() => {
        if (expanded.includes(item)) {
          expanded.splice(expanded.indexOf(item))
        } else {
          expanded = [item]
        }
      })
    },

    assignUserToRole (role) {
      this.$emit('assign-user', role)
    },

    importUsersBtn () {
      this.importUsersDialog = true
    },

    closeImportUsersDialog () {
      this.importUsersDialog = false
    },

    expandedContentForUser (user) {
      return this.allRoles.map(r => ({
        name: this.$t(r.label),
        roleValue: r.value,
        assigned: user.userClaims.some(uc => uc.systemClaim === r.value && uc.value === UserClaimStatus.Allow.value),
        applicationUserId: user.applicationUserId
      }))
    },

    async updateRoleAssignment (role) {
      await this.updateUserClaims({
        id: role.applicationUserId,
        claims: [
          { systemClaim: role.roleValue, value: role.assigned ? UserClaimStatus.NotSet.value : UserClaimStatus.Allow.value }
        ]
      })
      this.$emit('refresh-roles')
    }
  }
}
</script>
