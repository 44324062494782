<template>
  <v-col>
    <v-row dense>
      <v-col cols="12" sm="6" lg="3" v-if="hasDepartedAt">
        <DatePicker
          v-if="showOtherDates"
          :dateLabel="$t('departedAt')"
          dataTestId="ticket-departedat-date"
          :allowFuture="false"
          :startDate="departedAt"
          :max="new Date().toISOString()"
          @date-picked="departedAtDateSelected"
          @valid-date="(val) => {
            isValidDepartureDate = val
            validEntry()
          }" />
      </v-col>
      <v-col cols="12" sm="6" :lg="isByproductPurchase ? '4' : '3'">
        <DatePicker
          v-if="weighedInAt && showOtherDates && weighedInDate !== ''"
          :dateLabel="$t('weighedInAt')"
          dataTestId="ticket-weighin-date"
          :allowFuture="false"
          :startDate="weighedInAt"
          :max="new Date().toISOString()"
          @date-picked="weighedInDateSelected"
          @valid-date="(val) => {
            isValidInDate = val
            validEntry()
          }" />
      </v-col>
      <v-col cols="12" sm="6" :lg="isByproductPurchase ? '4' : '3'">
        <DatePicker
          v-if="weighedOutAt !== ''"
          :dateLabel="$t('weighedOutAt')"
          data-testid="ticket-weighout-date"
          :allowFuture="false"
          :startDate="weighedOutAt"
          :max="new Date().toISOString()"
          @date-picked="weighedOutDateSelected"
          @valid-date="(val) => {
            isValidOutDate = val
            validEntry()
          }" />
      </v-col>
    </v-row>
    <v-row v-if="showClockTime" dense>
      <v-col cols="12" sm="6" :lg="isByproductPurchase ? '4' : '3'" v-if="hasDepartedAt">
        <TimePicker
          v-if="showOtherDates"
          :isEditing="isEditing"
          :startTime="departedAtTime"
          :expandable="false"
          :label="$t('departedAt')"
          data-testid="ticket-departure-time"
          @time-picked="departedAtTimeSelected"
          @valid-time="(val) => {
            isValidInTime = val
            validEntry()
          }" />
      </v-col>
      <v-col cols="12" sm="6" :lg="isByproductPurchase ? '4' : '3'">
        <TimePicker
          v-if="showOtherDates"
          :isEditing="isEditing"
          :startTime="weighedInAtTime"
          :expandable="false"
          data-testid="ticket-weighin-time"
          :label="$t('weighedInAt')"
          @time-picked="weighedInTimeSelected"
          @valid-time="(val) => {
            isValidInTime = val
            validEntry()
          }" />
      </v-col>
      <v-col cols="12" sm="6" :lg="isByproductPurchase ? '4' : '3'">
        <TimePicker
          :expandable="false"
          :label="$t('weighedOutAt')"
          data-testid="ticket-weightout-time"
          :startTime="weighedOutAtTime"
          :isEditing="isEditing"
          @time-picked="weighedOutTimeSelected"
          @valid-time="(val) => {
            isValidOutTime = val
            validEntry()
          }" />
      </v-col>
    </v-row>
    <v-row v-if="!weighOutOnly" dense>
      <v-spacer/>
      <v-col cols="auto">
        <v-switch
        v-model="showOtherDates"
        :label="$t('showOtherDates')"
        color="primary"
        />
      </v-col>
      <v-col cols="auto">
        <v-switch
        v-model="showClockTime"
        :label="$t('editClockTime')"
        color="primary"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn
        text
        color="black"
        data-testid="reset-date-button"
        @click="resetDates">
          {{ $t('resetDates') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import moment from 'moment'
import { localToUTC } from '@/utils/DateFormatter.js'
import { ContractType, ErrorSource } from '@/utils/Enumerations.js'
export default {
  name: 'TicketEditTime',

  components: {
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    TimePicker: () => import('@/components/helper/TimePicker.vue')
  },

  props: {
    departedAtDate: {
      type: String
    },
    weighedInDate: {
      type: String
    },
    weighedOutDate: {
      type: String
    },
    loadCreatedAt: {
      type: String,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    weighOutOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    contractType: {
      type: Number,
      default: undefined
    }
  },

  data: () => ({
    departedAt: '',
    weighedInAt: '',
    weighedOutAt: '',
    departedAtTime: '',
    weighedInAtTime: '',
    weighedOutAtTime: '',
    showOtherDates: false,
    isValidDepartureTime: true,
    isValidInTime: true,
    isValidOutTime: true,
    isValidDepartureDate: true,
    isValidInDate: true,
    isValidOutDate: true,
    showClockTime: false
  }),

  watch: {
    showOtherDates () {
      this.applyTime()
    }
  },

  computed: {
    hasDepartedAt () {
      return this.contractType === ContractType.ByproductSale.value ||
        this.contractType === ContractType.LogYardSale.value
    },

    isByproductPurchase () {
      return this.contractType === ContractType.ByproductPurchase.value
    }
  },

  created () {
    this.resetDates()
  },

  methods: {
    resetDates () {
      this.departedAt = ''
      this.departedAtTime = ''
      this.weighedInAt = ''
      this.weighedInAtTime = ''
      this.weighedOutAt = ''
      this.weighedOutAtTime = ''
      this.isValidDepartureDate = true
      this.isValidDepartureTime = true
      this.isValidInDate = true
      this.isValidInTime = true
      this.isValidOutDate = true
      this.isValidOutTime = true
      this.validEntry()

      const generateTimeFromDate = (dateString) => {
        const m = moment.utc(dateString).local()
        return `${m.hours()}:${m.minutes()}`
      }

      let targetOutDate
      if (this.weighedOutDate !== undefined && this.weighedOutDate !== null) {
        targetOutDate = this.weighedOutDate
      } else {
        targetOutDate = this.isEditing ? this.weighedInDate ?? this.loadCreatedAt ?? this.departedAtDate : moment.utc().format()
      }

      const targetDepartureDate = this.departedAtDate ?? this.weighedInDate ?? targetOutDate
      const targetInDate = this.weighedInDate ?? targetOutDate

      this.$nextTick(() => {
        this.departedAt = moment.utc(targetDepartureDate).local().format().slice(0, 10)
        this.weighedInAt = moment.utc(targetInDate).local().format().slice(0, 10)
        this.weighedOutAt = moment.utc(targetOutDate).local().format().slice(0, 10)

        this.departedAtTime = generateTimeFromDate(targetDepartureDate)
        this.weighedInAtTime = generateTimeFromDate(targetInDate)
        this.weighedOutAtTime = generateTimeFromDate(targetOutDate)
      })
    },

    departedAtDateSelected (date) {
      this.departedAt = date
      this.applyTime()
    },

    departedAtTimeSelected (time) {
      this.departedAtTime = time
      this.applyTime()
    },

    weighedInDateSelected (date) {
      this.weighedInAt = date
      this.applyTime()
    },

    weighedOutDateSelected (date) {
      this.weighedOutAt = date
      this.applyTime()
    },

    weighedInTimeSelected (time) {
      this.weighedInAtTime = time
      this.applyTime()
    },

    weighedOutTimeSelected (time) {
      this.weighedOutAtTime = time
      this.applyTime()
    },

    applyTime () {
      const departedAtTimeComponents = this.departedAtTime.split(':')
      const weighedInAtTimeComponents = this.weighedInAtTime.split(':')
      const weighedOutAtTimeComponents = this.weighedOutAtTime.split(':')

      const departedAtLocal = moment(this.departedAt).hours(departedAtTimeComponents[0]).minutes(departedAtTimeComponents[1]).format()
      const weighedInLocal = moment(this.weighedInAt).hours(weighedInAtTimeComponents[0]).minutes(weighedInAtTimeComponents[1]).format()
      const weighedOutLocal = moment(this.weighedOutAt).hours(weighedOutAtTimeComponents[0]).minutes(weighedOutAtTimeComponents[1]).format()

      const departedAt = localToUTC(departedAtLocal, true)
      const weighedIn = localToUTC(weighedInLocal, true)
      const weighedOut = localToUTC(weighedOutLocal, true)

      if (moment(weighedIn).isAfter(weighedOut) && this.showOtherDates) {
        this.setSnackError({
          message: this.$t('weighedInDateAfterWeighedOutDate'),
          code: 'SSDF',
          source: ErrorSource.WebClient
        })
      }

      const time = {
        departedAt: departedAt,
        weighedInAt: weighedIn,
        weighedOutAt: weighedOut,
        applyAllDates: this.showOtherDates
      }

      this.$emit('time-applied', time)
    },

    validEntry () {
      const validDepartureAt = (this.isValidDepartureTime && this.isValidDepartureDate) || !this.showOtherDates
      const validWeighedInAt = (this.isValidInTime && this.isValidInDate) || !this.showOtherDates
      const validWeighedOutAt = this.isValidOutTime && this.isValidOutDate

      this.$emit('valid-entry', validDepartureAt && validWeighedInAt && validWeighedOutAt)
    }
  }
}
</script>
