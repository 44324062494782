import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import store from '@/store/index.js'

export default {
  setDeviceRegistrations (state, deviceRegistrations) {
    state.deviceRegistrations = deviceRegistrations
  },
  initializeFilter (state) {
    state.filter = {
      includeLoader: false,
      includeTransporter: false,
      ...JSON.parse(localStorage.getItem(LocalStorageKeys.DEVICE_REGISTRATIONS_TABLE_FILTER) ?? '{}')
    }
  },
  setFilter (state, filter) {
    state.filter = filter
    localStorage.setItem(LocalStorageKeys.DEVICE_REGISTRATIONS_TABLE_FILTER, JSON.stringify(filter))
    store.dispatch('device-registration/fetchDeviceRegistrations', state.filter)
  }
}
