import i18n from '@/i18n'
import PDFMake from 'pdfmake'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { generateSvg, angelLogo } from '@/images/AngelSvgLibrary'
import { theme } from '@/plugins/theme'
import { UserClaims, UserClaimStatus } from '@/utils/Enumerations.js'
import moment from 'moment'

const mdiCheck = '<svg viewBox="0 2 24 20"><path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>'

export function generateUsersByRolePDF (claimsByUser) {
  const columnHeaders = UserClaims.enums.map(e => ({ text: e.name, style: 'h1', alignment: 'center' }))
  columnHeaders.unshift({ text: i18n.t('user'), style: 'h1' })

  const tableBody = [columnHeaders]

  claimsByUser.sort((a, b) => a.userName.localeCompare(b.userName))

  claimsByUser.forEach(({ userName, userClaims }) => {
    const row = [{ text: userName }]
    UserClaims.enums.forEach(uc => {
      const claimId = uc.value
      row.push(
        userClaims.some(c => c.systemClaim === claimId && c.value === UserClaimStatus.Allow.value)
          ? {
            svg: mdiCheck,
            alignment: 'center',
            height: 14
          }
          : ''
      )
    })
    tableBody.push(row)
  })
  const content = [
    {
      text: i18n.t('roleAssignments'),
      style: 'title'
    },
    {
      table: {
        headerRows: 1,
        dontBreakRows: true,
        body: tableBody
      },
      layout: {
        fillColor: function (rowIndex) {
          return (rowIndex % 2 === 0) ? null : '#eeeeee'
        }
      }
    }
  ]

  const documentDefinition = {
    pageOrientation: 'landscape',

    footer: function (currentPage) {
      return {
        margin: [30, 30, 30, -100],
        columns: [
          {
            svg: generateSvg(angelLogo, theme.primary),
            width: 16
          },
          {
            text: i18n.t('printedOnDate', { date: utcToLocalDate(Date.now(), 'L - LT') }),
            alignment: 'center',
            margin: [0, 10, 0, 0]
          },
          {
            text: currentPage.toString(),
            width: 16,
            margin: [0, 10, 0, 0]
          }
        ]
      }
    },

    content,

    styles: {
      title: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      h1: {
        bold: true
      }
    },

    defaultStyle: {
      fontSize: 11
    },

    pageMargins: [40, 40, 40, 80]
  }
  PDFMake.createPdf(documentDefinition).download(`Role_Assignments_${moment().format('YYMMDD')}`)
}
