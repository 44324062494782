import Vuetify from 'vuetify'
import Vue from 'vue'
import { theme } from '@/plugins/theme'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  iconfont: 'mdi',
  theme: {
    themes: {
      light: {
        ...theme
      }
    }
  }
})
