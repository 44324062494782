<template>
  <v-row>
    <v-col cols="12" :lg="showGrossProfit ? 9 : 12" :xl="showGrossProfit ? 10 : 12">
      <v-card flat :min-height="$vuetify.breakpoint.lgAndUp ? 500 : 0" data-testid="activity-table">
        <v-card-title>
          <v-spacer/>
          <Icon
          icon="mdi-refresh"
          dataTestId="activities-refresh-button"
          :small="false"
          :tooltipText="$t('refresh')"
          @icon-clicked="refreshActivities"/>
          <Icon
          icon="mdi-plus"
          dataTestId="activities-add-button"
          :small="false"
          :tooltipText="$t('newActivity')"
          @icon-clicked="createActivity"/>
        </v-card-title>
        <v-card-text>
          <ContractDetailActivities
            :contract="contract"
            :entities="activitiesByEntity"
            :allActivities="allActivities"
            @table-event="tableAction"
            @refresh-activities="refreshActivities"
            :tract="tract"
          />
        </v-card-text>
        <v-card-actions v-if="showFinishButton">
          <v-spacer/>
          <v-btn color="primary" @click="$emit('finish-editing')">
            {{ $t('finishEditingContract') }}
          </v-btn>
        </v-card-actions>
        <v-dialog
          v-model="dialog"
          :fullscreen="this.$vuetify.breakpoint.mdAndDown"
          :max-width="width"
        >
          <ActivityForm
            v-if="creatingActivity || editingActivity"
            :propActivity="focusedActivity"
            :editing="editingActivity"
            :contract="contract"
            :businessEntities="businessEntities"
            @activity-updated="refreshActivities"
            @close-form="closeForm"
          />
          <DetailForm
            :activity="focusedActivity"
            :propDetail="focusedDetail"
            :editing="editingDetail"
            :contract="contract"
            :showTitle="true"
            :rateStats="rateStats"
            v-if="creatingDetail || editingDetail"
            @cancel="closeForm"
            @detail-added="refreshActivities"
            @detail-updated="refreshActivities"
          />
          <ConfirmDelete
            v-if="deleting"
            :title="$t('activity')"
            @delete="deleteItem"
            @cancel-delete="closeForm"
          />
        </v-dialog>
      </v-card>
    </v-col>
    <v-col cols="12" sm="8" md="6" lg="3" xl="2" v-if="showGrossProfit">
      <v-card flat>
        <v-card-title>
          <span>{{ $t('grossProfitPerTon') }}</span>
          <v-spacer/>
          <Icon
          iconColor="info"
          v-if="showPayByLoadWarning"
          icon="mdi-information-outline"
          :small="false"
          :tooltipText="$t('profitPerTonCannotBeCalculatedForPayByLoadEditing')"/>
          <Icon
          iconColor="info"
          v-if="showNonInventoryWarning"
          icon="mdi-information-outline"
          :small="false"
          :tooltipText="$t('nonInventoryCostsExcluded')"/>
        </v-card-title>
        <v-card-text>
          <v-data-table
          :items="grossProfitByProduct"
          :headers="grossProfitByProductHeaders"
          data-testid="gross-profit-by-product-table"
          sort-by="amount"
          sort-desc
          hide-default-footer
          dense>
            <template #item.amount="{item}">
              {{formatMoney(item.amount)}}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ContractType } from '@/utils/Enumerations.js'
import { CostType, PayBy, TemplateAccountingCategory, TemplateCostType, ActivityModifier } from '../../utils/Enumerations'
import { formatMoney } from '@/utils/NumericMutations.js'

export default {
  name: 'Activities',

  props: {
    contract: Object,
    showFinishButton: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ContractDetailActivities: () => import('@/components/contract/contract-detail/ContractDetailActivities.vue'),
    ConfirmDelete: () => import('../helper/ConfirmDelete.vue'),
    ActivityForm: () => import('@/components/activity/ActivityForm.vue'),
    DetailForm: () => import('./activity-detail/DetailForm.vue'),
    Icon: () => import('../helper/Icon.vue')
  },

  data: () => ({
    creatingActivity: false,
    editingActivity: false,
    deleting: false,
    editingDetail: false,
    loading: false,
    creatingDetail: false,
    dialog: false,
    width: '1000',
    focusedActivity: {},
    focusedDetail: {},
    rateStats: undefined,
    tract: undefined
  }),

  computed: {
    ...mapGetters('activity', ['allActivities', 'activitiesByEntity']),
    ...mapGetters('user', ['businessEntities']),
    grossProfitByProductHeaders () {
      return [
        { text: this.$t('product'), value: 'productName' },
        { text: this.$t('amount'), value: 'amount', align: 'right' }
      ]
    },
    showGrossProfit () {
      return this.contract.type === ContractType.WoodsSale.value
    },
    showPayByLoadWarning () {
      return this.allActivities?.flatMap(a => a.activityDetails).some(ad => ad.cost.payBy === PayBy.Load.value)
    },
    showNonInventoryWarning () {
      return this.allActivities?.some(a => a.activityTemplate.costType === TemplateCostType.nonInventoryCost.value)
    },

    grossProfitByProduct () {
      if (this.showPayByLoadWarning) {
        return []
      }

      const updateProductAmount = (product, amount) => {
        product.amount = product.amount + amount
        return product
      }

      const createProductEntry = (product, amount) => ({
        productName: product.name,
        productId: product.productId,
        amount: amount
      })

      const map = this.allActivities
        .filter(a => a.activityTemplate.costType !== CostType.NonInventory.value)
        .reduce((productMap, activity) => {
          const factor = activity.activityTemplate.category === TemplateAccountingCategory.AccountsReceivable.value ? 1 : -1
          activity.activityDetails.forEach(ad => {
            const existingProductEntry = productMap.get(ad.product.productId)

            const amount = activity.activityTemplate.modifier === ActivityModifier.DistanceTraveled.value
              ? ad.cost.rate * this.contract.distance * factor
              : ad.cost.rate * factor

            const newProductEntry = existingProductEntry
              ? updateProductAmount(existingProductEntry, amount)
              : createProductEntry(ad.product, amount)

            productMap.set(ad.product.productId, newProductEntry)
          })

          return productMap
        }, new Map())

      return Array.from(map.values())
    }
  },

  watch: {
    dialog (val) {
      if (!val) {
        this.closeForm()
      }
    }
  },

  async created () {
    if (this.contract?.tractId) {
      await this.getTractContents()
      this.tract = await this.fetchTract(this.contract.tractId)
    }
  },

  methods: {
    ...mapActions('activity', ['deleteActivity', 'fetchActivitiesByEntity']),
    ...mapActions('tract-contents', ['fetchTractContents']),
    ...mapActions('activity-templates', ['fetchActivityTemplateStatsById']),
    ...mapActions('tract', ['fetchTract']),
    formatMoney,

    async refreshRateStatsById (activityTemplateId) {
      this.rateStats = await this.fetchActivityTemplateStatsById(activityTemplateId)
    },

    editActivity (activity) {
      this.focusedActivity = activity
      this.editingActivity = true
    },

    createActivity () {
      this.closeForm()
      this.focusedActivity = {}
      this.creatingActivity = true
      this.dialog = true
    },

    async deleteItem () {
      const activityDeleteRequestObj = {
        contractId: this.contract.contractId,
        activityId: this.focusedActivity.activityId
      }

      await this.deleteActivity(activityDeleteRequestObj)
      this.refreshActivities()
    },

    createNewDetail (activityId) {
      this.closeForm()
      const activityIndex = this.allActivities.findIndex(a => a.activityId === activityId)
      this.focusedActivity = this.allActivities[activityIndex]
      this.creatingDetail = true
      this.dialog = true
      this.refreshRateStatsById(this.focusedActivity.activityTemplate.activityTemplateId)
    },

    editDetail (detail) {
      const activityId = this.allActivities.findIndex(a => a.activityId === detail.activityId)
      this.focusedActivity = this.allActivities[activityId]
      this.focusedDetail = detail
      this.editingDetail = true
      this.dialog = true
      this.refreshRateStatsById(this.focusedActivity.activityTemplate.activityTemplateId)
    },

    tableAction (event) {
      this.closeForm()
      this.width = '1000'
      if (event.type === 'edit') {
        this.editActivity(event.activity)
      } else if (event.type === 'delete') {
        this.width = '400px'
        this.focusedActivity = event.activity
        this.deleting = true
        this.dialog = true
      } else if (event.type === 'edit-detail') {
        const detail = event.activity
        this.editDetail(detail)
      } else if (event.type === 'new-detail') {
        const activityId = event.activity
        this.createNewDetail(activityId)
      }

      this.dialog = true
    },

    closeForm () {
      this.dialog = false
      this.editingActivity = false
      this.creatingActivity = false
      this.editingDetail = false
      this.creatingDetail = false
      this.deleting = false
      this.focusedActivity = {}
      this.focusedDetail = {}
      this.width = '1000'
    },

    async refreshActivities () {
      this.closeForm()
      await this.fetchActivitiesByEntity(this.contract.contractId)
      this.$emit('refreshed')
    },

    async getTractContents () {
      this.loading = true
      try {
        await this.fetchTractContents(this.contract.tractId)
      } finally {
        this.loading = false
      }
    },

    isOpenPanel (index) {
      return this.openPanels.some(panelIndex => panelIndex === index)
    }
  }
}
</script>
