<template>
  <v-card v-if="loggingMethod" id="logging-method-form">
    <v-card-title class="primary white--text">
      <v-row class="pa-2" justify="space-between">
        <span class="headline">
          {{ title }}
        </span>
        <BaseDialogActions hideRefresh hideFullscreen />
      </v-row>
    </v-card-title>
    <v-card-text class="text-right">
      <v-text-field
      class="py-6"
      v-model="loggingMethod.name"
      :rules="[rules.required]"
      :label="$t('name')"
      color="black"
      :counter="maxNameLength"
      :maxlength="maxNameLength"
      ref="loggingMethodName"/>
      <v-btn id="logging-method-submit" color="primary" @click="submitClicked" :disabled="!canSubmit">
        {{ buttonText }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import fieldRules from '@/utils/rules.js'

export default {
  name: 'LoggingMethodForm',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    propLoggingMethod: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  created () {
    if (this.propLoggingMethod) {
      this.loggingMethod = JSON.parse(JSON.stringify(this.propLoggingMethod))
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.loggingMethodName.focus()
      document.addEventListener('keyup', this.onKeyup)
    }, 0)
  },

  beforeDestroy () {
    document.removeEventListener('keyup', this.onKeyup)
  },

  computed: {
    editing () {
      return this.loggingMethod?.loggingMethodId
    },

    title () {
      return this.editing ? this.$t('editLoggingMethod') : this.$t('createNewLoggingMethod')
    },

    buttonText () {
      return this.editing ? this.$t('saveChanges') : this.$t('create')
    },

    canSubmit () {
      return this.loggingMethod.name.length > 0 && this.loggingMethod.name.length <= this.maxNameLength
    }
  },

  data: () => ({
    loggingMethod: {
      name: ''
    },
    maxNameLength: 32,
    rules: fieldRules.rules
  }),

  methods: {
    ...mapActions('logging-method', ['createLoggingMethod', 'updateLoggingMethod']),

    async submitClicked () {
      if (this.canSubmit) {
        this.editing ? await this.updateLoggingMethod(this.loggingMethod) : await this.createLoggingMethod(this.loggingMethod)
        this.$emit('logging-method-mutated')
      } else {
        this.setSnackError(this.$t('nameIsRequired'))
      }
    },

    onKeyup (e) {
      if (e.key.toLowerCase() === 'enter') {
        this.submitClicked()
      }
    }
  }
}
</script>
