<template>
  <v-card v-if="harvestType" id="harvest-type-form">
    <v-card-title class="primary white--text">
      <v-row class="pa-2" justify="space-between">
        <span class="headline">
          {{ title }}
        </span>
        <BaseDialogActions hideRefresh hideFullscreen />
      </v-row>
    </v-card-title>
    <v-card-text class="text-right">
      <v-text-field
      class="py-6"
      v-model="harvestType.name"
      :rules="[rules.required]"
      :label="$t('name')"
      color="black"
      :counter="maxNameLength"
      :maxlength="maxNameLength"
      ref="harvestTypeName"/>
      <v-btn id="harvest-type-submit" color="primary" @click="submitClicked" :disabled="!canSubmit">
        {{ buttonText }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import fieldRules from '@/utils/rules.js'

export default {
  name: 'HarvestTypeForm',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    propHarvestType: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  created () {
    if (this.propHarvestType) {
      this.harvestType = JSON.parse(JSON.stringify(this.propHarvestType))
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.harvestTypeName.focus()
      document.addEventListener('keyup', this.onKeyup)
    }, 0)
  },

  beforeDestroy () {
    document.removeEventListener('keyup', this.onKeyup)
  },

  computed: {
    editing () {
      return this.harvestType?.harvestTypeId
    },

    title () {
      return this.editing ? this.$t('editHarvestType') : this.$t('createNewHarvestType')
    },

    buttonText () {
      return this.editing ? this.$t('saveChanges') : this.$t('create')
    },

    canSubmit () {
      return this.harvestType.name.length > 0 && this.harvestType.name.length <= this.maxNameLength
    }
  },

  data: () => ({
    harvestType: {
      name: ''
    },
    maxNameLength: 32,
    rules: fieldRules.rules
  }),

  methods: {
    ...mapActions('harvest-type', ['createHarvestType', 'updateHarvestType']),

    async submitClicked () {
      if (this.canSubmit) {
        this.editing ? await this.updateHarvestType(this.harvestType) : await this.createHarvestType(this.harvestType)
        this.$emit('harvest-type-mutated')
      } else {
        this.setSnackError(this.$t('nameIsRequired'))
      }
    },

    onKeyup (e) {
      if (e.key.toLowerCase() === 'enter') {
        this.submitClicked()
      }
    }
  }
}
</script>
