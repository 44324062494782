<template>
  <v-row>
    <v-col class="ma-0 pa-0">
      <v-select
      color="grey darken-1"
      item-color="primary"
      ref="accountTagFilter"
      v-model="chosenTags"
      multiple
      :menu-props="{ bottom: true, offsetY: true }"
      :label="$t('filterByTags')"
      item-text="value"
      item-value="value"
      return-object
      :loading="tagsLoading"
      :items="tags"
      clearable
      @click:clear="clearTags"
      @blur="applyFilter"
      >
        <template #selection="{item, index}">
          <v-chip close small
            @click:close="removeTag(index)"
          >{{item.value}}</v-chip>
        </template>
      </v-select>
    </v-col>
    <v-col cols="auto">
      <v-btn
      :disabled="applyButtonDisabled"
      class="primary"
      @click="applyFilter">
        {{$t('apply')}}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { isEqual } from 'lodash'
import { mapActions } from 'vuex'
export default {
  name: 'AccountTagFilter',

  data: () => ({
    tags: [],
    chosenTags: [],
    appliedTags: [],
    savedTagIds: [],
    tagsLoading: false
  }),

  created () {
    const savedTagIds = localStorage.getItem(LocalStorageKeys.ACCOUNTS_GRID_TAG_IDS)
    this.savedTagIds = savedTagIds ? savedTagIds.split(',') : []
    this.refresh()
  },

  computed: {
    applyButtonDisabled () {
      return isEqual(this.appliedTags, this.chosenTags)
    }
  },

  methods: {
    ...mapActions('tags', ['fetchTags']),

    async refresh () {
      this.tagsLoading = true

      try {
        const tags = await this.fetchTags(0)
        this.tags = tags
        if (this.savedTagIds.length > 0) {
          this.savedTagIds.forEach(tagId => {
            const tag = this.tags.find(t => t.tagId === parseInt(tagId))
            if (tag) {
              this.chosenTags.push(tag)
            }
          })
          this.applyFilter()
        }
      } finally {
        this.tagsLoading = false
      }
    },

    applyFilter () {
      if (isEqual(this.appliedTags, this.chosenTags)) {
        return
      }

      this.$emit('tags-chosen', this.chosenTags)
      this.appliedTags = Object.assign([], this.chosenTags)
      const tagString = this.chosenTags.map(t => t.tagId).join(',')
      localStorage.setItem(LocalStorageKeys.ACCOUNTS_GRID_TAG_IDS, tagString)
    },

    removeTag (index) {
      this.chosenTags.splice(index, 1)
      this.applyFilter()
      this.$refs.accountTagFilter.blur()
    },

    clearTags () {
      this.chosenTags = []
      this.applyFilter()
      this.$refs.accountTagFilter.blur()
    }
  }
}
</script>
