<template>
  <v-row no-gutters align="center">
    <slot name="prepend-search"/>
    <v-col style="min-width: 200px;" >
      <v-text-field
        v-if="showSearch"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        clearable
        class="mr-2 my-n3"
        color="primary"
        style="min-width: 200px;"
      ></v-text-field>
      <slot name="search-replacement"/>
    </v-col>
    <v-col cols="auto">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on }">
          <v-switch
            color="primary"
            small
            v-if="showDense"
            class="ml-5"
            v-model="dense"
            v-on="on"
          ></v-switch>
        </template>
        <span class="white--text subtitle-1">{{ $t("denseView") }}</span>
      </v-tooltip>
    </v-col>
    <slot name="custom-controls"></slot>
    <v-col cols="auto" v-for="action in actions" :key="action.actionName" >
      <Icon
        :dataTestId="`table-${action.text}`"
        :elementId="action.id"
        margin="mr-2"
        :small="false"
        :icon="action.icon"
        :tooltipText="action.text"
        :disabled="action.disabled"
        @icon-clicked="$emit(`table-action`, action.actionName)"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TableActions',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    actions: {
      type: Array
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    showDense: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    dense (val) {
      this.$emit('dense-toggled', val)
    },
    search (val) {
      this.$emit('search', val)
    }
  },

  data: () => ({
    dense: true,
    search: ''
  })
}
</script>
