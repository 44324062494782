<template>
  <v-row>
    <v-col cols="12" class="mt-2">
      <span class="subtitle-1">
        {{ $t('users') }}
        <Icon
        icon="mdi-plus"
        :small="false"
        @icon-clicked="assignUserToRole()"
        icon-color="success"
        :tooltipText="$t('assignUserToRole', {roleName: i18nRoleName})"/>
      </span>
    </v-col>
    <v-col cols="12">
      <v-card outlined class="mb-4 mt-n4">
        <v-data-table
        dense
        item-key="applicationUserId"
        :headers="headers"
        :items="users"
        hide-default-footer
        :items-per-page="-1">
          <template #item.userName="{item}">
              {{ item.userName }}
            </template>
            <template #item.actions="{item}">
              <Icon
              icon="mdi-delete-forever"
              :tooltipText="$t('unassignFromRole')"
              iconColor="error"
              @icon-clicked="unassignFromRole(item)"
              />
            </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog v-model="unassignRoleDialog" width="400px">
      <ConfirmDialog
      v-if="unassignRoleDialog"
      :title="$t('unassignUserFromRole', {name: focusedUser.userName, roleName: $t(role.label)})"
      :body="$t('unassignUserFromRoleBody', {name: focusedUser.userName, roleName: $t(role.label)})"
      :confirmText="$t('unassignUser')"
      @confirm="confirmUnassignUser()"
      @cancel="closeDialogs()"/>
    </v-dialog>
  </v-row>
</template>

<script>
import RolesHeaders from '@/headers/Roles.js'
import { mapActions } from 'vuex'
import { UserClaimStatus } from '@/utils/Enumerations.js'
export default {
  name: 'RolesUserBreakdown',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    users: {
      type: [],
      required: true
    },

    role: {
      type: Object,
      required: true
    },

    allUsers: {
      type: [],
      default: []
    }
  },

  data: () => ({
    unassignRoleDialog: false,
    focusedUser: false
  }),

  computed: {
    headers () {
      return RolesHeaders.roleFormHeaders()
    },
    i18nRoleName () {
      return this.$t(this.role.label)
    }
  },

  methods: {
    ...mapActions('user', ['updateUserClaims']),
    unassignFromRole (user) {
      this.focusedUser = user
      this.unassignRoleDialog = true
    },

    async confirmUnassignUser () {
      await this.updateUserClaims({
        id: this.focusedUser.applicationUserId,
        claims: [
          { systemClaim: this.role.value, value: UserClaimStatus.NotSet.value }
        ]
      })
      this.closeDialogs()
      this.$emit('refresh-roles')
    },

    closeDialogs () {
      this.focusedUser = undefined
      this.unassignRoleDialog = false
      this.assignRoleDialog = false
    },

    assignUserToRole () {
      this.$emit('assign-user', this.role)
    }
  }
}
</script>
