import i18n from '@/i18n'
import { localeCompareAlphanumeric } from '@/utils/base'

export function allReceivableHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('hashSign'),
      value: 'ticketNumber'
    },
    {
      sortable: true,
      text: i18n.t('extTicketNum1'),
      value: 'extTicketNumber1',
      sort: localeCompareAlphanumeric,
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('extTicketNum2'),
      value: 'extTicketNumber2',
      sort: localeCompareAlphanumeric,
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('account'),
      value: 'accountName'
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityWithSplit',
      sort: (a, b) => (a.name.localeCompare(b.name)) || (a.split - b.split)
    },
    {
      sortable: true,
      text: i18n.t('tract'),
      value: 'tractName'
    },
    {
      sortable: true,
      text: i18n.t('product'),
      value: 'product'
    },
    {
      sortable: true,
      text: i18n.t('netWeightTons'),
      value: 'netWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('payOn'),
      value: 'computedAtPayOn',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('payBy'),
      value: 'computedAtPayBy',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('payOnTons'),
      value: 'payByTons',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('rate'),
      value: 'computedAtRate',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('penalty'),
      value: 'penaltyAmount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    },
    {
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}

export function allBPReceivableHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('hashSign'),
      value: 'ticketNumber'
    },
    {
      sortable: true,
      text: i18n.t('extTicketNum1'),
      value: 'extTicketNumber1',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('extTicketNum2'),
      value: 'extTicketNumber2',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('account'),
      value: 'accountName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('product'),
      value: 'product',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('netWeightTons'),
      value: 'netWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('payOn'),
      value: 'computedAtPayOn',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('payBy'),
      value: 'computedAtPayBy',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('payOnTons'),
      value: 'payByWeight',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('rate'),
      value: 'computedAtRate',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('penalty'),
      value: 'penaltyAmount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    },
    {
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}

export function receivablesByAccountHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('account'),
      value: 'accountName'
    },
    {
      sortable: true,
      text: i18n.t('ticketCount'),
      value: 'ticketCount',
      align: 'right'
    },
    {
      sortable: true,
      align: 'right',
      text: i18n.t('totalNetWeight'),
      value: 'totalNetWeight'
    },
    {
      sortable: true,
      text: i18n.t('totalReceivable'),
      value: 'totalReceivable',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('averageRate'),
      value: 'averageRate',
      align: 'right'
    },
    {
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}

export function ticketReceivableHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('counterparty'),
      value: 'accountName'
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityWithSplit',
      sort: (a, b) => (a.name.localeCompare(b.name)) || (a.split - b.split)
    },
    {
      sortable: true,
      text: i18n.t('payOn'),
      value: 'payOn'
    },
    {
      sortable: true,
      text: i18n.t('payBy'),
      value: 'payBy'
    },
    {
      sortable: true,
      text: i18n.t('payOnTons'),
      value: 'payByTons'
    },
    {
      sortable: true,
      text: i18n.t('rate'),
      value: 'rate',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('penalty'),
      value: 'penaltyAmount',
      align: 'right'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    }
  ]
}
