<template>
  <v-container fluid>
    <v-row dense justify="center">
      <v-col cols="12">
        <v-row no-gutters>
          <v-spacer/>
          <Icon v-if="!mobileCompatible"
            margin="mr-3 ml-1"
            :small="false"
            :icon="tableView ? 'mdi-graph' : 'mdi-table'"
            iconColor="primary"
            :tooltipText="tableView ? $t('viewCharts') : $t('viewTable')"
            @icon-clicked="toggleTableView"
          />
          <TimeframeSelector
            :timeframes="metricsTimeframes"
            :allowCustom="false"
            @item-selected="timeFrameSelected"
            labelVersion="labelForTractMetrics"
            class="mb-n6"
          />
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <StatsCard
        icon="mdi-truck"
        :title="$t('createdLoadsThisPeriod')"
        :content="numberWithCommas(tractMetrics?.totalLoads)"
        :style="cardStyle"/>
      </v-col>
      <v-col v-if="tableView && tractMetrics && tableType" class="d-flex justify-center">
        <v-card class="pa-4" :style="cardStyle">
          <v-row dense no-gutters align-content="space-between">
            <v-col>
              <p class="title" style="font-weight:900;color:rgba(0,0,0,0.6);">{{ tableType.title }}</p>
            </v-col>
            <v-col cols="auto">
              <Icon
              :icon="tableTypes[(tableTypes.indexOf(tableType)+1)%tableTypes.length].icon"
              :small="false"
              :tooltipText="tableTypes[(tableTypes.indexOf(tableType)+1)%tableTypes.length].tooltip"
              style="position:relative;right:0;"
              @icon-clicked="toggleTableType"
            />
            </v-col>
          </v-row>
          <v-data-table
            :headers="currentTableHeaders"
            :items="tractMetrics[tableType.key]"
            disable-pagination
            hide-default-footer
          >
            <template v-for="slot in currentTableHeaders" v-slot:[`item.${slot.key}`]="{ item }">
              <span :key="slot.key">
                {{ item.key }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col v-show="!tableView" cols="12" sm="6" lg="4">
        <canvas id="created-loads-by-product-chart"/>
      </v-col>
      <v-col v-show="!tableView" cols="12" sm="6" lg="4">
        <canvas id="created-loads-by-destination-chart"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Chart } from 'chart.js'
import { mapActions } from 'vuex'
import { timeframesInLocalTime as tf, timeframeToUtcIsoString } from '@/utils/DateFormatter'
import { numberWithCommas } from '@/utils/NumericMutations'
import { getLogChartColors } from '@/components/dashboard/ChartColors.js'

export default {
  name: 'TractMetrics',

  components: {
    TimeframeSelector: () => import('@/components/dashboard/TimeframeSelector.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    StatsCard: () => import('@/components/dashboard/StatsCard.vue')
  },

  props: {
    tract: {
      type: Object,
      required: true
    },
    mobileCompatible: {
      type: Boolean,
      default: false
    }
  },

  created () {
    this.$emit('extra-menu-options', this.mobileMenuOptions)
    this.tableType = this.tableTypes[0]
  },

  data: () => ({
    tractMetrics: undefined,
    timeframe: undefined,
    createdLoadsByProductChart: undefined,
    createdLoadsByDestinationChart: undefined,
    tableView: true,
    tableType: undefined
  }),

  computed: {
    metricsTimeframes () {
      return [
        tf.today,
        tf.sinceMonday,
        tf.last7Days,
        tf.last14Days,
        tf.last30Days
      ].map(timeframeToUtcIsoString)
    },

    mobileMenuOptions () {
      return {
        tab: this.$t('metrics'),
        actions: [
          {
            icon: this.tableView ? 'mdi-graph' : 'mdi-table',
            tooltipText: this.tableView ? this.$t('viewCharts') : this.$t('viewTable'),
            action: this.toggleTableView
          }
        ]
      }
    },

    tableTypes () {
      return [
        {
          key: 'loadsByProduct',
          icon: 'mdi-pine-tree-variant',
          label: 'product',
          text: this.$t('product'),
          tooltip: this.$t('loadsByProduct'),
          title: this.$t('createdLoadsByProduct'),
          dataKey: 'productName'
        },
        {
          key: 'loadsByDestinationAccount',
          icon: 'mdi-domain',
          label: 'destination',
          text: this.$t('destination'),
          tooltip: this.$t('loadsByDestination'),
          title: this.$t('createdLoadsByDestination'),
          dataKey: 'accountName'
        }
      ]
    },

    currentTableHeaders () {
      return [
        {
          sortable: true,
          text: this.tableType.text,
          value: this.tableType.dataKey
        },
        {
          sortable: true,
          text: this.$t('loads'),
          value: 'loadCount',
          align: 'center'
        }
      ]
    },

    cardStyle () {
      return {
        width: '600px',
        minWidth: '50%',
        maxWidth: '100%'
      }
    }
  },

  methods: {
    ...mapActions('tract', ['getTractMetrics']),
    numberWithCommas,

    timeFrameSelected (tf) {
      this.timeframe = tf
      this.refreshTractMetrics()
    },

    async refreshTractMetrics () {
      this.tractMetrics = await this.getTractMetrics({
        tractId: this.tract.tractId,
        startDate: this.timeframe.sinceTime,
        endDate: this.timeframe.untilTime
      })
      this.setupCharts()
    },

    isNoData (data) {
      return data.length === 0 || data.every(i => i === 0)
    },

    getChartTitle (text) {
      return {
        font: {
          family: 'Roboto, sans-serif',
          size: 16
        },
        display: true,
        text: text
      }
    },

    getChartSubtitle (isDisplayed) {
      return {
        display: isDisplayed,
        text: this.$t('noDataAvailableForChart'),
        color: 'black',
        font: {
          size: 16,
          family: 'Roboto, sans-serif',
          weight: 400
        },
        padding: {
          top: 20
        }
      }
    },

    setupCharts () {
      this.createCreatedLoadsByProductChart()
      this.createCreatedLoadsByDestinationChart()
    },

    createCreatedLoadsByProductChart () {
      if (this.createdLoadsByProductChart) this.createdLoadsByProductChart.destroy()
      const data = []
      const labels = []
      this.tractMetrics.loadsByProduct.forEach(product => {
        labels.push(product.productName)
        data.push(product.loadCount)
      })
      if (this.isNoData(data)) labels.length = 0
      const dataSet = {
        labels: labels,
        datasets: [{
          data: data,
          hoverOffset: 4,
          backgroundColor: getLogChartColors(labels.length)
        }]
      }
      const config = {
        type: 'pie',
        data: dataSet,
        options: {
          plugins: {
            title: this.getChartTitle(this.$t('createdLoadsByProduct')),
            subtitle: this.getChartSubtitle(this.isNoData(data))
          }
        }
      }
      const cvx = document.getElementById('created-loads-by-product-chart')
      this.createdLoadsByProductChart = new Chart(cvx, config)
    },

    createCreatedLoadsByDestinationChart () {
      if (this.createdLoadsByDestinationChart) this.createdLoadsByDestinationChart.destroy()
      const data = []
      const labels = []
      this.tractMetrics.loadsByDestinationAccount.forEach(product => {
        labels.push(product.accountName)
        data.push(product.loadCount)
      })
      if (this.isNoData(data)) labels.length = 0
      const dataSet = {
        labels: labels,
        datasets: [{
          data: data,
          hoverOffset: 4,
          backgroundColor: getLogChartColors(labels.length)
        }]
      }
      const config = {
        type: 'pie',
        data: dataSet,
        options: {
          plugins: {
            title: this.getChartTitle(this.$t('createdLoadsByDestination')),
            subtitle: this.getChartSubtitle(this.isNoData(data))
          }
        }
      }
      const cvx = document.getElementById('created-loads-by-destination-chart')
      this.createdLoadsByDestinationChart = new Chart(cvx, config)
    },

    toggleTableView () {
      this.tableView = !this.tableView
      this.$emit('extra-menu-options', this.mobileMenuOptions)
    },

    toggleTableType () {
      this.tableType = this.tableTypes[(this.tableTypes.indexOf(this.tableType) + 1) % this.tableTypes.length]
    }
  }
}
</script>
