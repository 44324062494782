<template>
  <v-card>
    <v-card-title :class="contractColor">
      <span class="headline">{{$t('advancedSearch')}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text class="mt-6">
      <FormWrapper
      :buttonText="$t('searchTickets')"
      :buttonColor="contractColor"
      @submit="searchClicked"
      >
      <v-row dense>
        <v-col cols="12" class="px-4">
          <v-text-field
            v-model="ticketNumberString"
            :label="$t('commaSeparatedTicketList')"
            :hint="$t('ticketNumbersHint')"
            color="primary"
            clearable
          />
        </v-col>
        <v-col cols="12">
          <v-divider class="grey"></v-divider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <ContractKeySearch :showAll="true"
          :initialContractKeys="contractKeys"
          :contractMode="contractMode"
          :disabled="hasEnteredTicketNumbers"
          @contract-strings-selected="contractStringsSelected"/>
        </v-col>
      </v-row>
      <v-row dense>
      <v-col cols="12" md="6">
        <v-select
        :items="statuses"
        :label="$t('status')"
        :disabled="hasEnteredTicketNumbers"
        v-model="selectedStatuses"
        multiple
        return-object
        data-testid="adv-search-status-select"
        text="text"
        value="text">
        </v-select>
        <ProductAutocomplete
        :disabled="hasEnteredTicketNumbers"
        :productId="productId"
        :contract="contract"
        data-testid="adv-search-product-autocomplete"
        @product-chosen="productSelected"/>
      </v-col>
      <v-col cols="12" md="6">
        <TimeFilter
          v-if="dateRange"
          @dates-chosen="datesChosen"
          :propStartDate="dateRange ? dateRange.startDate : null"
          :propEndDate="dateRange ? dateRange.endDate : null"
          :disabled="hasEnteredTicketNumbers"
          :showApply="false"/>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
          v-if="contractMode.value === ContractMode.Logs.value"
          :items="this.foresters"
          :label="$t('forester')"
          v-model="selectedForester"
          clearable
          :disabled="hasEnteredTicketNumbers"
          return-object
          item-text="name"/>
        </v-col>
      </v-row>
      <v-row dense class="mt-n6">
        <v-col cols="auto">
          <v-select
          clearable
          v-model="hasImages"
          :disabled="hasEnteredTicketNumbers"
          :label="$t('hasImages')"
          :item-text="i => $t(i.label)"
          :item-value="i => i.value"
          :items="[{ label: 'yes', value: true }, { label: 'no', value: false }]"
          style="min-width: 150px;"
          />
        </v-col>
        <v-col cols="auto">
          <v-checkbox
          v-model="includeOffice"
          :disabled="hasEnteredTicketNumbers"
          :label="$t('includeOffice')"
          color="primary"
          data-testid="adv-search-include-web-checkbox"/>
        </v-col>
        <v-col cols="auto">
          <v-checkbox
          v-if="contractMode.value === ContractMode.Logs.value"
          v-model="includeLoader"
          :disabled="hasEnteredTicketNumbers"
          :label="$t('includeLoader')"
          color="primary"
          data-testid="adv-search-include-loader-checkbox"
          />
        </v-col>
        <v-col cols="auto">
          <v-checkbox
          v-if="this.contractMode.value === ContractMode.Logs.value || this.contractMode.value === ContractMode.Byproducts.value"
          v-model="includeReceiver"
          :disabled="hasEnteredTicketNumbers"
          :label="$t('includeReceiver')"
          color="primary"
          data-testid="adv-search-include-receiver-checkbox"
          />
        </v-col>
        <v-col cols="auto">
          <v-checkbox
          v-if="contractMode.value === ContractMode.Transfers.value || contractMode.value === ContractMode.LogYardSale.value"
          v-model="includeYardOperator"
          :disabled="hasEnteredTicketNumbers"
          :label="$t('includeYardOperator')"
          color="primary"
          data-testid="adv-search-include-yard-operator-checkbox"
          />
        </v-col>
        <v-col cols="auto">
          <v-checkbox
          v-if="this.contractMode.value === ContractMode.Byproducts.value"
          v-model="includeTransporter"
          :disabled="hasEnteredTicketNumbers"
          :label="$t('includeTransporter')"
          color="primary"
          data-testid="adv-search-include-transporter-checkbox"
          />
        </v-col>
      </v-row>
    </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { TicketStatus, ContractMode, ContractType } from '../../../utils/Enumerations.js'
import TicketQuery from '@/model/TicketParams.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { colorClassForContractMode } from '@/utils/componentHelpers'
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'AdvancedSearch',

  props: {
    contractMode: {
      type: Object
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    TimeFilter: () => import('@/components/ticket/TimeFilter.vue'),
    ContractKeySearch: () => import('@/components/ticket/advanced-search/ContractKeySearch.vue'),
    ProductAutocomplete: () => import('@/components/autocomplete/ProductAutocomplete.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  data: () => ({
    tab: 0,
    productId: null,
    selectedStatuses: [],
    dateRange: null,
    ticketNumberString: '',
    contractKeys: {
      tractName: null,
      settingName: null,
      destinationAccountName: null,
      accountName: null,
      fromAccountName: null
    },
    hasImages: undefined,
    selectedForester: null,
    foresters: [],
    includeReceiver: true,
    includeOffice: true,
    includeLoader: true,
    includeYardOperator: true,
    includeTransporter: true,
    statuses: [],
    ContractMode
  }),

  computed: {
    hasEnteredTicketNumbers () {
      return this.ticketNumberString?.trim().length > 0
    },

    contract () {
      return this.contractMode.value === ContractMode.Byproducts.value
        ? { type: ContractType.ByproductSale.value }
        : { type: ContractType.Production.value }
    },

    contractColor () {
      return colorClassForContractMode(this.contractMode.value)
    }
  },

  async created () {
    this.foresters = await this.getAllForesterUsers()
    this.createStatuses()
    this.loadCachedState()
  },

  beforeDestroy () {
    const searchState = {
      ticketNumberString: this.ticketNumberString,
      includeReceiver: this.includeReceiver,
      includeOffice: this.includeOffice,
      includeLoader: this.includeLoader,
      includeYardOperator: this.includeYardOperator,
      includeTransporter: this.includeTransporter,
      hasImages: this.hasImages,
      selectedStatuses: this.selectedStatuses,
      dateRange: this.dateRange,
      contractKeys: this.contractKeys,
      productId: this.productId,
      foresterUserId: this.selectedForester?.applicationUserId
    }

    let localStorageKey
    switch (this.contractMode.value) {
      case 0:
        localStorageKey = LocalStorageKeys.ADVANCED_SEARCH_LOGS_STATE
        break
      case 1:
        localStorageKey = LocalStorageKeys.ADVANCED_SEARCH_BP_STATE
        break
      case 2:
        localStorageKey = LocalStorageKeys.ADVANCED_SEARCH_TRANSFER_STATE
        break
      case 3:
        localStorageKey = LocalStorageKeys.ADVANCED_SEARCH_LYS_STATE
        break
      default:
        console.warn('Invalid contract mode')
    }

    localStorage.setItem(localStorageKey, JSON.stringify(searchState))
  },

  methods: {
    ...mapActions('user', ['getAllForesterUsers']),
    loadCachedState () {
      let localStorageKey
      switch (this.contractMode.value) {
        case 0:
          localStorageKey = LocalStorageKeys.ADVANCED_SEARCH_LOGS_STATE
          break
        case 1:
          localStorageKey = LocalStorageKeys.ADVANCED_SEARCH_BP_STATE
          break
        case 2:
          localStorageKey = LocalStorageKeys.ADVANCED_SEARCH_TRANSFER_STATE
          break
        case 3:
          localStorageKey = LocalStorageKeys.ADVANCED_SEARCH_LYS_STATE
          break
      }

      const fromLocalStorage = localStorage.getItem(localStorageKey)

      if (fromLocalStorage) {
        const parsed = JSON.parse(fromLocalStorage)
        this.includeReceiver = parsed.includeReceiver ?? true
        this.includeOffice = parsed.includeOffice ?? true
        this.includeLoader = parsed.includeLoader ?? true
        this.includeYardOperator = parsed.includeYardOperator ?? true
        this.includeTransporter = parsed.includeTransporter ?? true
        this.hasImages = parsed.hasImages
        this.selectedStatuses = parsed.selectedStatuses
        this.selectedForester = this.foresters.find(f => parsed.foresterUserId === f.applicationUserId)
        this.dateRange = {
          startDate: utcToLocalDate(parsed.dateRange?.startDate),
          endDate: utcToLocalDate(parsed.dateRange.endDate).slice(0, 10)
        }
        this.contractKeys = parsed.contractKeys
        this.productId = parsed.productId
        this.ticketNumberString = parsed.ticketNumberString
      } else {
        this.dateRange = {
          startDate: moment().subtract(30, 'days').format(),
          endDate: moment().format()
        }
      }
    },

    searchClicked () {
      this.$emit('search-clicked', this.getQuery())
    },

    createStatuses () {
      this.statuses = [
        TicketStatus.InTransit,
        TicketStatus.WeighedIn,
        TicketStatus.WeighedOut,
        TicketStatus.ReadyForPayment,
        TicketStatus.Posted,
        TicketStatus.Exported
      ].map(s => ({
        text: s.name
      }))
      this.selectedStatuses = this.statuses.filter(status => status.text !== this.$t('exported'))
    },

    datesChosen (dateFilter) {
      this.dateRange = dateFilter
    },

    productSelected (product) {
      this.productId = product?.productId
    },

    contractStringsSelected ({ tractName, settingName, destinationAccountName, accountName, fromAccountName, tractId }) {
      this.contractKeys = {
        tractName: tractName?.trim(),
        settingName: settingName?.trim(),
        destinationAccountName: destinationAccountName?.trim(),
        accountName: accountName?.trim(),
        fromAccountName: fromAccountName?.trim(),
        tractId: tractId
      }
    },

    getQuery () {
      if (this.hasEnteredTicketNumbers) {
        const ticketNumbers = this.ticketNumberString.trim().split(',').map(el => {
          const n = Number(el)
          return n === 0 ? n : n || el
        })
        return new TicketQuery({
          ticketNumbers: ticketNumbers,
          weighedIn: true,
          weighedOut: true,
          readyForPayment: true,
          exported: true,
          inTransit: true,
          posted: true,
          contractMode: this.contractMode.value,
          includeLoader: this.contractMode.value === ContractMode.Logs.value ? this.includeLoader : false,
          includeReceiver: this.contractMode.value === ContractMode.Logs.value || this.contractMode.value === ContractMode.Byproducts.value ? this.includeReceiver : false,
          includeYardOperator: this.contractMode.value === ContractMode.Transfers.value || this.contractMode.value === ContractMode.LogYardSale.value ? this.includeYardOperator : false,
          includeTransporter: this.contractMode.value === ContractMode.Byproducts.value ? this.includeTransporter : false
        })
      }

      let weighedIn = false
      let weighedOut = false
      let readyForPayment = false
      let exported = false
      let inTransit = false
      let posted = false

      this.selectedStatuses.forEach(status => {
        switch (status.text) {
          case this.$t('weighedIn'):
            weighedIn = true
            break
          case this.$t('weighedOut'):
            weighedOut = true
            break
          case this.$t('readyForPayment'):
            readyForPayment = true
            break
          case this.$t('inTransit'):
            inTransit = true
            break
          case this.$t('posted'):
            posted = true
            break
          case this.$t('exported'):
            exported = true
            break
        }
      })

      const contractKeys = {
        ...this.contractKeys
      }

      if (contractKeys.tractId) {
        contractKeys.tractName = ''
      }

      const queryOptions = {
        weighedIn: weighedIn,
        weighedOut: weighedOut,
        readyForPayment: readyForPayment,
        exported: exported,
        inTransit: inTransit,
        posted: posted,
        hasImages: this.hasImages ?? undefined,
        contractMode: this.contractMode.value,
        includeLoader: this.contractMode.value === ContractMode.Logs.value ? this.includeLoader : false,
        includeReceiver: this.contractMode.value === ContractMode.Logs.value || this.contractMode.value === ContractMode.Byproducts.value ? this.includeReceiver : false,
        includeTransporter: this.contractMode.value === ContractMode.Byproducts.value ? this.includeTransporter : false,
        includeOffice: this.includeOffice,
        includeYardOperator: this.contractMode.value === ContractMode.Transfers.value || this.contractMode.value === ContractMode.LogYardSale.value ? this.includeYardOperator : false,
        excludeConsumed: this.excludeConsumed,
        tractForester: this.selectedForester,
        productId: this.productId,
        dateRange: this.dateRange,
        ...contractKeys
      }

      return new TicketQuery(queryOptions)
    }
  }
}
</script>
