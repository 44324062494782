<template>
  <CsvImport v-bind="{
    parse,
    preview,
    upload,
    exampleData,
    entityName,
    rowName,
    disabled
  }">
    <template #preview-item.activityStatus="{ value }">
      <Icon v-if="value === enums.ActivityStatus.Active.value"
        icon="mdi-checkbox-marked-circle"
        iconColor="success"
        :tooltipText="enums.ActivityStatus.Active.name"
      />
      <Icon v-else
        icon="mdi-cancel"
        iconColor="black"
        :tooltipText="enums.ActivityStatus.Inactive.name"
      />
    </template>
    <template #preview-item.rate="{ item }">
      <span>{{ item.baseCost.rate === undefined ? $t('notAvailable') : formatMoney(item.baseCost.rate) }}</span>
    </template>
    <template #preview-item.payOn="{ item }">
      <span>{{ PayOn.fromInt(item.baseCost.payOn) }}</span>
    </template>
    <template #preview-item.payBy="{ item }">
      <span>{{ PayBy.fromInt(item.baseCost.payBy) }}</span>
    </template>
    <template #preview-item.businessEntityName="{ item }">
      <span>{{ businessEntities.find(entity => entity.businessEntityId === item.businessEntityId)?.name }}</span>
    </template>
  </CsvImport>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ActivityModifier, ActivityStatus, TemplateAccountingCategory, TemplateCostType, PayOn, PayBy, UserClaims, TemplateSpecialization } from '@/utils/Enumerations.js'
import { CsvColumn, CsvTypeTransformation } from '../../../utils/csv/parse.js'
import ActivityHeaders from '@/headers/Activity.js'
import { formatMoney } from '../../../utils/NumericMutations'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'ActivityTemplateImports',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    CsvImport: () => import('./CsvImport.vue')
  },

  data: () => ({
    PayOn,
    PayBy
  }),

  computed: {
    ...mapGetters('user', ['businessEntities']),
    parse () {
      return {
        schema: async () => {
          const businessEntities = await this.fetchAllBusinessEntities()
          const validateStringLength = maxLength =>
            s => !s
              ? this.$t('stringCannotBeEmpty')
              : s.length > maxLength
                ? this.$t('stringCannotBeLongerThanNCharacters', { n: maxLength })
                : true

          return {
            name: CsvColumn.build(() => ({
              name: 'name',
              type: CsvTypeTransformation.from({
                validate: validateStringLength(64)
              })
            })),
            glCode: CsvColumn.from({
              name: 'glCode',
              type: CsvTypeTransformation.from({
                validate: validateStringLength(32)
              })
            }),
            glOffset: CsvColumn.from({
              name: 'glOffset',
              type: CsvTypeTransformation.from({
                validate: validateStringLength(32)
              })
            }),
            businessEntityId: CsvColumn.build(() => ({
              name: 'entity',
              type: CsvTypeTransformation.from({
                transform: e => businessEntities.find(be => be.name === e)?.businessEntityId,
                validate: id => id !== undefined ? true : this.$t('invalidBusinessEntityName')
              })
            })),
            costType: CsvColumn.build(types => ({
              name: 'costType',
              type: types.enumNameToInt(TemplateCostType, this.$t('costType'))
            })),
            category: CsvColumn.build(types => ({
              name: 'category',
              type: types.enumNameToInt(TemplateAccountingCategory, this.$t('category'))
            })),
            modifier: CsvColumn.build(types => ({
              name: 'modifier',
              type: types.enumNameToInt(ActivityModifier, this.$t('modifier'))
            })),
            activityStatus: CsvColumn.build(types => ({
              name: 'status',
              type: types.enumNameToInt(ActivityStatus, this.$t('status'))
            })),
            baseCost: {
              rate: CsvColumn.build(types => ({
                name: 'rate',
                type: types.floatOptional.validateWith(f => f < 0 ? this.$t('negativeRateNotAllowed') : true)
              })),
              payBy: CsvColumn.build(types => ({
                name: 'payBy',
                type: types.enumNameToIntOptional(PayBy, this.$t('payBy'))
              })),
              payOn: CsvColumn.build(types => ({
                name: 'payOn',
                type: types.enumNameToIntOptional(PayOn, this.$t('payOn'))
              }))
            },
            specialization: CsvColumn.build(types => ({
              name: 'templateSpecialization',
              type: types.enumNameToInt(TemplateSpecialization, this.$t('templateSpecialization'))
            }))
          }
        }
      }
    },

    preview: () => ({
      headers: ActivityHeaders.activityTemplateBulkImportPreviewHeaders()
    }),

    upload () {
      return {
        action: this.createActivityTemplate,
        map: t => {
          if (this.nullBaseCost(t)) t.baseCost = null
          return t
        }
      }
    },

    enums: () => ({ ActivityStatus }),

    rowName () {
      return template => template.name
    },

    entityName () {
      return this.$t('activityTemplate')
    },

    exampleData () {
      return 'data:text/csv;charset=utf-8,name,entity,category,costType,glCode,glOffset,rate,payOn,payBy,modifier,status,templateSpecialization\nDefault 1,"Acme Lumber Co.",Accounts Payable,Production Cost,GL8496,GL-OFFSET,60.75,Gross,Weight,None,Active,None\nDefault 2,"Acme Lumber Co.",Accruals,Non-Inventory Cost,GL8499,GL-OFFSET,20.99,Gross,Weight,None,Inactive,Transfer\nDefault 3,"Acme Lumber Co.",Accounts Receivable,Unspecified,GL8502,GL-OFFSET,23.99,Gross,Weight,Distance Traveled,Active,None\nDefault 4,"Acme Lumber Co.",Accruals,Stumpage Cost,GL8498,GL-OFFSET,19.99,Gross,Weight,Distance Traveled,Inactive,None'
    },

    disabled () {
      return !userAssignedClaim(UserClaims.TemplateManager)
    }
  },

  methods: {
    ...mapActions('user', ['fetchAllBusinessEntities']),
    ...mapActions('activity-templates', ['createActivityTemplate']),
    formatMoney,

    nullBaseCost (t) {
      return t.specialization === TemplateSpecialization.Transfer.value ||
        (t.specialization === TemplateSpecialization.Penalty.value && [TemplateAccountingCategory.AccountsPayable.value, TemplateAccountingCategory.Accruals.value].includes(t.category))
    }
  }
}
</script>
