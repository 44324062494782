<template>
  <v-card min-height="800px">
    <v-card-title class="headline py-4 primary white--text">
      <span>{{ $t("payablesFor", { account: accountName }) }}</span>
      <v-spacer/>
      <span class="mr-2" >{{$t("accountTotal", { amount: totalPayable })}} </span>
      <div v-if="isTDialog">
         <BaseDialogActions hideRefresh />
      </div>
      <Icon
      v-else
      iconColor="white"
      icon="mdi-close"
      dataTestId="payable-by-tract-close-button"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <p class="headline mt-2" v-if="noPayables">
        {{$t('noTractPayables')}}
      </p>
      <v-expansion-panels popout accordion v-model="openPanels" class="mt-10" tile v-else>
        <v-expansion-panel
        v-for="(tract, index) in tractPayables"
        :key="index">
          <v-expansion-panel-header :ripple="true">
            <v-layout>
              {{$t("tractNamePlaceholder", { tractName: tract.tractName })}}
              <v-spacer/>
            </v-layout>
            <template #actions>
              <v-container>
                <v-row justify="end">
                  {{tract.totalPayable}}
                </v-row>
                <v-row justify="end">
                  {{tract.totalNetWeight}}
                </v-row>
                <v-row justify="end">
                  {{tract.loadCount}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <PayableTable
              :hideFooter="true"
              :dense="true"
              :payables.sync="tract.payables"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatMoney, numberWithCommas, getFormattedTons } from '@/utils/NumericMutations'
import PayableHeaders from '@/headers/Payable'
import { mapActions } from 'vuex'

export default {
  name: 'PayablesByTractDialog',

  props: {
    accountPayable: Object,
    account: {
      type: Object,
      default: undefined
    },
    isTDialog: Boolean
  },

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    PayableTable: () => import('./PayableTable.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  computed: {
    ticketHeaders () { return PayableHeaders.payablesByTractHeaders(this.$i18n.locale) },

    totalPayable () {
      return formatMoney(this.payables.reduce((a, b) => a + b.amount, 0))
    },

    accountName () {
      if (this.account) {
        return this.account.name
      } else {
        return this.accountPayable.accountName
      }
    }
  },

  async created () {
    if (this.account) {
      const payables = await this.getPayablesForAccount(this.account.accountId)
      this.payables = payables
      this.noPayables = payables.length === 0
      this.createTractPayables(payables)
    } else {
      this.createTractPayables(this.accountPayable.payables)
    }
    this.openPanels = this.tractPayables.length === 1 ? [0] : []
  },

  data: () => ({
    dialogId: 'payables-by-tract-dialog',
    amountTotal: 0,
    openPanels: [],
    tractPayables: [],
    payables: [],
    noPayables: false
  }),

  methods: {
    ...mapActions('payable', ['getPayablesForAccount']),
    formatMoney,
    numberWithCommas,
    getFormattedTons,

    createTractPayables (payables) {
      this.payables = payables
      const tractPayables = {}

      payables.forEach(payable => {
        if (tractPayables[payable.tractName]) {
          tractPayables[payable.tractName].payables.push(payable)
        } else {
          tractPayables[payable.tractName] = {
            tractName: payable.tractName,
            payables: [payable]
          }
        }
      })

      this.tractPayables = Object.values(tractPayables)

      let uniqueTicketNumbers = {}
      let payableTotal = 0
      let totalNetWeight = 0

      this.tractPayables.forEach(tp => {
        tp.payables.forEach(payable => {
          payableTotal += payable.amount
          totalNetWeight += payable.netWeight
          uniqueTicketNumbers[payable.ticketNumber] = payable.ticketNumber
        })
        tp.totalPayable = this.formatMoney(payableTotal)
        tp.totalNetWeight = `${this.getFormattedTons(totalNetWeight)} tons`
        tp.loadCount = `${Object.keys(uniqueTicketNumbers).length} loads`

        uniqueTicketNumbers = {}
        payableTotal = 0
        totalNetWeight = 0
      })
    }
  }
}
</script>
