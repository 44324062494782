export function allJournalEntriesCustomCells () {
  return [
    {
      slotName: 'net-weight',
      value: 'netWeight'
    },
    {
      slotName: 'defect-weight',
      value: 'defectWeight'
    },
    {
      slotName: 'gross-weight',
      value: 'gross'
    },
    {
      slotName: 'pay-on',
      value: 'computedAtPayOn'
    },
    {
      slotName: 'pay-by',
      value: 'computedAtPayBy'
    },
    {
      slotName: 'product',
      value: 'product'
    },
    {
      slotName: 'computed-at',
      value: 'computedAt',
      dateFormat: ('MM/DD/YYYY')
    },
    {
      slotName: 'rate',
      value: 'computedAtRate'
    },
    {
      slotName: 'amount',
      value: 'amount'
    },
    {
      slotName: 'actions',
      value: 'actions'
    },
    {
      slotName: 'activity',
      value: 'activityName'
    }
  ]
}

export function journalEntriesByAccountCustomCells () {
  return [
    {
      slotName: 'average-rate',
      value: 'averageRate'
    },
    {
      slotName: 'total-tickets',
      value: 'totalTickets'
    },
    {
      slotName: 'total-journalEntry',
      value: 'totalJournalEntry'
    },
    {
      slotName: 'total-net-weight',
      value: 'totalNetWeight'
    },
    {
      slotName: 'actions',
      value: 'actions'
    }
  ]
}
