export function allReceivablesCustomCells () {
  return [
    {
      slotName: 'net-weight',
      value: 'netWeight'
    },
    {
      slotName: 'pay-by-tons',
      value: 'payByTons'
    },
    {
      slotName: 'defect-weight',
      value: 'defectWeight'
    },
    {
      slotName: 'gross-weight',
      value: 'gross'
    },
    {
      slotName: 'pay-on',
      value: 'computedAtPayOn'
    },
    {
      slotName: 'pay-by',
      value: 'computedAtPayBy'
    },
    {
      slotName: 'product',
      value: 'product'
    },
    {
      slotName: 'computed-at',
      value: 'computedAt',
      dateFormat: ('MM/DD/YYYY')
    },
    {
      slotName: 'rate',
      value: 'computedAtRate'
    },
    {
      slotName: 'amount',
      value: 'amount'
    },
    {
      slotName: 'penaltyAmount',
      value: 'penaltyAmount'
    },
    {
      slotName: 'actions',
      value: 'actions'
    },
    {
      slotName: 'activityWithSplit',
      value: 'activityWithSplit'
    }
  ]
}

export function receivablesByAccountCustomCells () {
  return [
    {
      slotName: 'average-rate',
      value: 'averageRate'
    },
    {
      slotName: 'total-tickets',
      value: 'totalTickets'
    },
    {
      slotName: 'total-receivable',
      value: 'totalReceivable'
    },
    {
      slotName: 'total-net-weight',
      value: 'totalNetWeight'
    },
    {
      slotName: 'actions',
      value: 'actions'
    }
  ]
}
