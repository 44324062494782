import { SettlementKeys, SettlementSubKeys } from '@/components/settlements/SettlementKeys.js'
import { PayPeriodStatus } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'

export default {
  setDateConfiguration (state, dateConfiguration) {
    Object.values(SettlementSubKeys.AP).forEach((value) => {
      state[SettlementKeys.AccountsPayable][value] = undefined
    })
    Object.values(SettlementSubKeys.AR).forEach((value) => {
      state[SettlementKeys.AccountsReceivable][value] = undefined
    })
    Object.values(SettlementSubKeys.AC).forEach((value) => {
      state[SettlementKeys.Accruals][value] = undefined
    })

    state.dateConfiguration = dateConfiguration
  },

  resetModules (state) {
    Object.values(SettlementSubKeys.AP).forEach((value) => {
      state[SettlementKeys.AccountsPayable][value] = undefined
    })
    Object.values(SettlementSubKeys.AR).forEach((value) => {
      state[SettlementKeys.AccountsReceivable][value] = undefined
    })
    Object.values(SettlementSubKeys.AC).forEach((value) => {
      state[SettlementKeys.Accruals][value] = undefined
    })

    state.dateConfiguration = {
      payPeriod: undefined,
      fiscalYear: undefined
    }
  },

  setModuleData (state, moduleData) {
    state[moduleData.moduleKey][moduleData.subModuleKey] = moduleData.data
  },

  setCurrentModule (state, moduleInformation) {
    state.currentModuleKey = moduleInformation.moduleKey
    state.currentSubModuleKey = moduleInformation.subModuleKey
  },

  setLoading (state, loading) {
    state.loading = loading
  },

  setUseMetricTons (state, useMetricTons) {
    state.useMetricTons = useMetricTons
    localStorage.setItem(LocalStorageKeys.SETTLEMENTS_USE_METRIC_TONS, useMetricTons)
  },

  setUseByproductMetricTons (state, useByproductMetricTons) {
    state.useByproductMetricTons = useByproductMetricTons
    localStorage.setItem(LocalStorageKeys.SETTLEMENTS_USE_BYPRODUCT_METRIC_TONS, useByproductMetricTons)
  },

  setARSalesAnalysisBusinessEntity (state, arSalesAnalysisBusinessEntity) {
    state.arSalesAnalysisBusinessEntity = arSalesAnalysisBusinessEntity
  },
  setTractPayments: (state, tractPayments) => { state.tractPayments = tractPayments },

  toggleContractStatus (state, contract) {
    const currentPayPeriod = state.dateConfiguration.payPeriod
    const savedContract = state.settlementContractStatuses[currentPayPeriod.payPeriodId][contract.contractId]
    let newVerifiedStatus = savedContract.verified === 0 ? 1 : 0

    if (savedContract.amt !== savedContract.oldAmt) {
      newVerifiedStatus = 1
    }

    state.settlementContractStatuses[currentPayPeriod.payPeriodId][contract.contractId] = {
      amt: savedContract.amt,
      oldAmt: savedContract.amt,
      verified: newVerifiedStatus
    }
    persistStatus.byContract.write(state.settlementContractStatuses)
  },

  initializeContractStatuses (state, { settlements, payPeriods }) {
    const currentPayPeriod = state.dateConfiguration.payPeriod

    let statuses = {}

    if (!persistStatus.byContract.has()) {
      statuses[currentPayPeriod.payPeriodId] = settlements.reduce((contractStatuses, { contractInfo }) => {
        contractStatuses[contractInfo.contractId] = {
          amt: contractInfo.amount,
          oldAmt: contractInfo.amount,
          verified: 0
        }
        return contractStatuses
      }, {})
    } else {
      statuses = persistStatus.byContract.read()
      payPeriods.filter(payPeriod => payPeriod.status === PayPeriodStatus.Exported.value).forEach(payPeriod => {
        delete statuses[payPeriod.payPeriodId]
      })

      if (!statuses[currentPayPeriod.payPeriodId]) {
        statuses[currentPayPeriod.payPeriodId] = settlements.reduce((contractStatuses, { contractInfo }) => {
          contractStatuses[contractInfo.contractId] = {
            amt: contractInfo.amount,
            oldAmt: contractInfo.amount,
            verified: 0
          }
          return contractStatuses
        }, {})
      } else {
        settlements.forEach(({ contractInfo }) => {
          const contractFromCookie = statuses[currentPayPeriod.payPeriodId][contractInfo.contractId]
          statuses[currentPayPeriod.payPeriodId][contractInfo.contractId] = {
            amt: contractInfo.amount,
            oldAmt: contractFromCookie ? contractFromCookie.oldAmt : contractInfo.amount,
            verified: contractFromCookie ? contractFromCookie.verified ?? 0 : 0
          }
        })
      }
    }

    state.settlementContractStatuses = statuses
    persistStatus.byContract.write(state.settlementContractStatuses)
  },

  togglePayeeStatus (state, payee) {
    const currentPayPeriod = state.dateConfiguration.payPeriod
    const savedPayee = state.settlementPayeeStatuses[currentPayPeriod.payPeriodId][payee.payeeId][state.currentSubModuleKey]
    let newVerifiedStatus = savedPayee.verified === 0 ? 1 : 0

    if (savedPayee.amt !== savedPayee.oldAmt) {
      newVerifiedStatus = 1
    }

    state.settlementPayeeStatuses[currentPayPeriod.payPeriodId][payee.payeeId][state.currentSubModuleKey] = {
      amt: savedPayee.amt,
      oldAmt: savedPayee.amt,
      verified: newVerifiedStatus
    }

    persistStatus.byPayee.write(state.settlementPayeeStatuses)
  },

  clearStaleSubModuleData (state, { isByproduct = false, allData = false }) {
    const volatileLogsKeys = {
      accountsPayable: [
        SettlementSubKeys.AP.SettlementsByContract,
        SettlementSubKeys.AP.PaymentRegister,
        SettlementSubKeys.AP.ProductionSummary,
        SettlementSubKeys.AP.TicketRegister,
        SettlementSubKeys.AP.SettlementsByPayee,
        SettlementSubKeys.AP.MiscPayments // Includes advances
      ],
      accountsReceivable: [
        SettlementSubKeys.AR.ReceivableRegister,
        SettlementSubKeys.AR.ProductionSummary,
        SettlementSubKeys.AR.SalesAnalysis
      ],
      accruals: [
        SettlementSubKeys.AC.Register
      ]
    }

    const volatileByproductsKeys = {
      accountsPayable: [
        SettlementSubKeys.AP.BPPaymentRegister,
        SettlementSubKeys.AP.ByproductSettlementsByPayee
      ],
      accountsReceivable: [
        SettlementSubKeys.AR.BPReceivableRegister,
        SettlementSubKeys.AR.BPReceivablesByPayer
      ],
      accruals: [
        SettlementSubKeys.AC.BPRegister
      ]
    }
    if (!allData) {
      const volatileKeysByContractType = isByproduct ? volatileByproductsKeys : volatileLogsKeys
      const keys = Object.keys(volatileKeysByContractType)
      keys.forEach(key => {
        const settlementSubKeys = volatileKeysByContractType[key]
        settlementSubKeys.forEach(subModuleKey => {
          if (state.currentSubModuleKey !== subModuleKey) {
            state[key][subModuleKey] = undefined
          }
        })
      })
    } else {
      Object.values(SettlementSubKeys.AP).forEach((value) => {
        state[SettlementKeys.AccountsPayable][value] = undefined
      })
      Object.values(SettlementSubKeys.AR).forEach((value) => {
        state[SettlementKeys.AccountsReceivable][value] = undefined
      })
      Object.values(SettlementSubKeys.AC).forEach((value) => {
        state[SettlementKeys.Accruals][value] = undefined
      })
    }
  },

  initializePayeeStatuses (state, { payees, payPeriods }) {
    const currentPayPeriod = state.dateConfiguration.payPeriod

    let statuses = {}
    if (!persistStatus.byPayee.has()) {
      statuses[currentPayPeriod.payPeriodId] = payees.reduce((payeeStatuses, payee) => {
        payeeStatuses[payee.payeeId] ??= {}
        payeeStatuses[payee.payeeId][state.currentSubModuleKey] = {
          amt: sumPayeeFinancials(payee),
          oldAmt: sumPayeeFinancials(payee),
          verified: 0
        }
        return payeeStatuses
      }, {})
    } else {
      statuses = persistStatus.byPayee.read()
      payPeriods.filter(payPeriod => payPeriod.status === PayPeriodStatus.Exported.value).forEach(payPeriod => {
        delete statuses[payPeriod.payPeriodId]
      })

      if (statuses[currentPayPeriod.payPeriodId] === undefined) {
        statuses[currentPayPeriod.payPeriodId] = payees.reduce((payeeStatuses, payee) => {
          payeeStatuses[payee.payeeId] ??= {}
          payeeStatuses[payee.payeeId][state.currentSubModuleKey] = {
            amt: sumPayeeFinancials(payee),
            oldAmt: sumPayeeFinancials(payee),
            verified: 0
          }
          return payeeStatuses
        }, {})
      } else {
        payees.forEach((payee) => {
          const payeeInCookieRoot = statuses[currentPayPeriod.payPeriodId][payee.payeeId]
          const payeeInCookie = payeeInCookieRoot ? payeeInCookieRoot[state.currentSubModuleKey] : undefined
          statuses[currentPayPeriod.payPeriodId][payee.payeeId] ??= {}
          statuses[currentPayPeriod.payPeriodId][payee.payeeId][state.currentSubModuleKey] = {
            amt: sumPayeeFinancials(payee),
            oldAmt: payeeInCookie ? payeeInCookie.oldAmt : sumPayeeFinancials(payee),
            verified: payeeInCookie ? payeeInCookie.verified ?? 0 : 0
          }
        })
      }
    }

    state.settlementPayeeStatuses = statuses
    persistStatus.byPayee.write(statuses)
  }
}

const sumPayeeFinancials = (payee) => {
  return payee.contracts.reduce((total, contract) => total + contract.amount, 0) +
    (payee.tractPayables?.reduce((total, tractPayment) => total + tractPayment.amount, 0) ?? 0) +
    payee.advances.reduce((total, advance) => total + advance.amount, 0)
}

const persistStatus = {
  byContract: {
    read () {
      const v = localStorage.getItem(LocalStorageKeys.SETTLEMENT_CONTRACT_STATUSES)
      return v ? JSON.parse(v) : undefined
    },
    write (statuses) {
      localStorage.setItem(LocalStorageKeys.SETTLEMENT_CONTRACT_STATUSES, JSON.stringify(statuses))
    },
    has () {
      return localStorage.getItem(LocalStorageKeys.SETTLEMENT_CONTRACT_STATUSES) !== null
    }
  },
  byPayee: {
    read () {
      const v = localStorage.getItem(LocalStorageKeys.SETTLEMENT_PAYEE_STATUSES)
      return v ? JSON.parse(v) : undefined
    },
    write (statuses) {
      localStorage.setItem(LocalStorageKeys.SETTLEMENT_PAYEE_STATUSES, JSON.stringify(statuses))
    },
    has () {
      return localStorage.getItem(LocalStorageKeys.SETTLEMENT_PAYEE_STATUSES) !== null
    }
  }
}
