import { LocalStorageKeys, LocalStorageActor } from '@/utils/LocalStorageActor'
import { TractStatus } from '@/utils/Enumerations.js'
import i18n from '@/i18n'

export default {
  standingTimberFilter: new LocalStorageActor({
    keyGetter: () => LocalStorageKeys.STANDING_TIMBER_FILTER,

    init: () => ({ status: [], tractType: [], tractForester: [], team: [], myTracts: false }),

    read: ({ status, tractType, tractForester, team = [], myTracts = false } = {}, { context }) => ({
      status,
      tractType: tractType.map(id => context.rootGetters['tract-type/allTractTypes'].find(tt => tt.tractTypeId === id)),
      tractForester,
      team: team.map(id => context.rootGetters['team/allTeams'].find(t => t.teamId === id)),
      myTracts
    }),

    write: ({ status, tractType, tractForester, team, myTracts }) => ({
      status,
      tractForester,
      tractType: tractType.map(tt => tt.tractTypeId),
      team: team.map(t => t.teamId),
      myTracts
    })
  }),

  committedTimberFilter: new LocalStorageActor({
    keyGetter: () => LocalStorageKeys.COMMITTED_TIMBER_FILTER,

    init: () => ({ status: [], tractType: [], tractForester: [], team: [], myTracts: false }),

    read: ({ status, tractType, tractForester, team = [], myTracts = false } = {}, { context }) => ({
      status,
      tractType: tractType.map(id => context.rootGetters['tract-type/allTractTypes'].find(tt => tt.tractTypeId === id)),
      tractForester,
      team: team.map(id => context.rootGetters['team/allTeams'].find(t => t.teamId === id)),
      myTracts
    }),

    write: ({ status, tractType, tractForester, team, myTracts }) => ({
      status,
      tractForester,
      tractType: tractType.map(tt => tt.tractTypeId),
      team: team.map(t => t.teamId),
      myTracts
    })
  }),

  timberPortfolioFilter: new LocalStorageActor({
    keyGetter: () => LocalStorageKeys.TIMBER_PORTFOLIO_FILTER,

    init: () => ({ status: ExecutedAndActive, tractType: [], tractForester: [], team: [], myTracts: false }),

    read: ({ status = ExecutedAndActive, tractType, tractForester, team = [], myTracts = false } = {}, { context }) => ({
      status,
      tractType: tractType.map(id => context.rootGetters['tract-type/allTractTypes'].find(tt => tt.tractTypeId === id)),
      tractForester,
      team: team.map(id => context.rootGetters['team/allTeams'].find(t => t.teamId === id)),
      myTracts
    }),

    write: ({ status = ExecutedAndActive, tractType, tractForester, team, myTracts }) => ({
      status,
      tractForester,
      tractType: tractType.map(tt => tt.tractTypeId ?? tt),
      team: team.map(t => t.teamId ?? t),
      myTracts
    })
  })
}

const ExecutedAndActive = [
  TractStatus.find(ts => ts.name === i18n.t('executed')).value,
  TractStatus.find(ts => ts.name === i18n.t('active')).value
]
