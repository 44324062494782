<template>
  <v-container fluid data-testid="defects-table">
    <v-row>
      <v-col cols="12">
        <span class="mr-2 subtitle-1">
          {{$t('logyardConfiguration')}}
        </span>
        <Icon
        icon="mdi-ruler"
        dataTestId="diameter-weight-view-button"
        :small="false"
        :tooltipText="$t('viewDiameterWeightTable')"
        @icon-clicked="openOrUpdateDialog({ id: dialogId, width: '800px', allowFullscreen: false })"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-text-field
          :label="$t('defectCollectionMode')"
          color="primary"
          :value="defectTypeFromInt"
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-text-field
        :label="$t('collectLogCounts')"
        color="primary"
        outlined
        :value="location.collectLogCounts ? $t('yes') : $t('no')"
        readonly
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto" v-if="location.defectCollectionMode === 2 || location.collectLogCounts">
        <v-text-field
          :label="$t('segmentLength')"
          color="primary"
          :value="location.segmentLength"
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-text-field
          :label="$t('ntepCompliant')"
          color="primary"
          :value="location.ntepCompliant ? $t('yes') : $t('no')"
          outlined
          readonly
        />
      </v-col>
      <v-col cols="12" class="mt-n4 mb-3">
        <span class="subtitle-1">
          {{ $t('byproductTicketCreationUsingTransporter') }}:
          <Icon
          :small="false"
          :tooltipText="transporterTicketCategoryIsByproduct ? $t('allowsByproductTicketCreatingUsingTransporter') : $t('notAllowsByproductTicketCreationUsingTransporter')"
          :icon="transporterTicketCategoryIsByproduct ? 'mdi-checkbox-marked-circle' : 'mdi-close'"
          :iconColor="transporterTicketCategoryIsByproduct ? 'success' : 'error'"/>
          <Icon
          v-if="location.allowTransporterDepartureWeightEntry"
          :small="false"
          :tooltipText="$t('allowsDepartureWeightEntryInTransporter')"
          icon="mdi-scale"/>
        </span>
      </v-col>
    </v-row>
    <v-row v-if="location.collectLogCounts">
      <v-col cols="12">
        <span class="mr-2 subtitle-1">
          {{$t('logLengths')}}
        </span>
      </v-col>
    </v-row>
    <v-row v-if="location.collectLogCounts">
      <v-col cols="12">
        <v-chip v-for="(l, index) in sortedLogLengths"
        :key="index"
        color="black"
        class="white--text ml-2 mb-6"
        readonly>
          <span>{{ `${(l.length).toFixed(2)}'` }}</span>
        </v-chip>
      </v-col>
    </v-row>
    <v-row dense>
      <span class="subtitle-1 font-weight-bold error--text" v-if="showNoDiameterWarning">
        {{$t('noDiameterWeightsAddedForLocation')}}
      </span>
    </v-row>
    <Dialog :stateId="dialogId" maxWidth="800px">
      <DiameterWeightDialog :location="location" @diameter-weights="setDiameterWeights" @close="refresh"/>
    </Dialog>
  </v-container>
</template>

<script>
import { DefectCollectionType, TransporterTicketCategory } from '@/utils/Enumerations.js'
import { mapActions } from 'vuex'
export default {
  name: 'DefectConfiguration',

  props: {
    location: {
      type: Object,
      required: true
    }
  },

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    DiameterWeightDialog: () => import('@/components/settings-components/company-config/DiameterWeightDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    dialogId: `defect-configuration-${Math.floor(Math.random() * 65536).toString(16).padStart(4, '0')}`,
    diameterWeightTableDialog: false,
    diameterWeights: []
  }),

  computed: {
    defectTypeFromInt () { return DefectCollectionType.fromInt(this.location.defectCollectionMode) },

    showNoDiameterWarning () {
      return this.location.defectCollectionMode === 2 && this.diameterWeights.length === 0
    },

    sortedLogLengths () {
      const logLengths = this.location.logLengths
      return logLengths.sort((a, b) => a.length - b.length)
    },

    transporterTicketCategoryIsByproduct () {
      return this.location.transporterTicketCategory === TransporterTicketCategory.Byproducts.value
    }
  },

  watch: {
    diameterWeightTableDialog (isOpen) {
      if (!isOpen) {
        this.diameterWeightTableDialog = false
        this.refresh()
      }
    }
  },

  mounted () {
    this.refresh()
  },

  methods: {
    ...mapActions('defects', ['fetchDiameterInfoForLocation']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    async refresh () {
      this.diameterWeights = await this.fetchDiameterInfoForLocation(this.location.locationId)
    },
    setDiameterWeights (weights) {
      this.diameterWeights = weights
    }
  }
}
</script>

<style scoped>
