import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchLoggingMethods (context) {
    const response = await ApiServer.get('loggingMethods')
    const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name))
    context.commit('setLoggingMethods', sortedData)
    return sortedData
  },
  async deleteLoggingMethod (context, loggingMethodId) {
    const response = await ApiServer.delete(`loggingMethods/${loggingMethodId}`)
    context.commit('removeLoggingMethod', loggingMethodId)
    return response.data
  },
  async createLoggingMethod (context, loggingMethod) {
    const response = await ApiServer.post('loggingMethods', loggingMethod)
    context.commit('addLoggingMethod', response.data)
    return response.data
  },
  async updateLoggingMethod (context, loggingMethod) {
    let basePath = `loggingMethods/${loggingMethod.loggingMethodId}`
    if (loggingMethod.businessEntityId) basePath += `?businessEntityId=${loggingMethod.businessEntityId}`
    const response = await ApiServer.put(basePath, loggingMethod)
    context.commit('updateLoggingMethod', response.data)
    return response.data
  }
}
