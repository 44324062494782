<template>
  <v-card>
    <v-card-title class="error headline white--text">
      {{$t('activityCostWarning')}}
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <span class="subtitle-1">
          {{
            $t('activityCostDescription')
          }}
        </span>
      </v-container>
    </v-card-text>
    <v-card-actions class="pb-4">
      <v-btn data-testid="activity-warn-close-button" color="black" outlined text @click="$emit('confirmation', false)">{{$t('cancel')}}</v-btn>
      <v-spacer/>
      <v-btn data-testid="activity-warn-submit-button" color="error" @click="$emit('confirmation', true)">
        {{$t('saveChanges')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
export default {
  name: 'ActivityCostWarning',

  props: {
    activityCost: Number
  },

  methods: {
    formatMoney
  }
}
</script>
