<template>
  <v-row>
    <v-col cols="12" v-if="!isEditing">
      <span class="subtitle-1">{{ $t('defectCollectionSetting', {setting: $t('settings')}) }}</span>
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        v-model="settings.collectionBehavior"
        item-color="primary"
        :label="$t('defectCollectionSetting', { setting: $t('behavior') })"
        :items="ReceiverDefectCollectionBehavior.enums"
        item-text="name"
        return-object
        color="black"
        @change="modifyDefectSettings"
        :readonly="!isEditing"
        :append-icon="displayDropDownIcon"
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        v-model="settings.collectionUnit"
        item-color="primary"
        :label="$t('defectCollectionSetting', { setting: $t('unit') })"
        :items="collectionUnits"
        item-text="name"
        return-object
        color="black"
        @change="modifyDefectSettings"
        :readonly="!isEditing"
        :append-icon="displayDropDownIcon"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ReceiverDefectCollectionBehavior, ReceiverDefectCollectionUnit } from '@/utils/Enumerations.js'
export default {
  name: 'DefectCollectionSettings',

  props: {
    propDefectSettings: {
      type: Object,
      default: undefined
    },
    isEditing: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    settings: {
      collectionBehavior: ReceiverDefectCollectionBehavior.Inbound,
      collectionUnit: ReceiverDefectCollectionUnit.Pounds
    },
    ReceiverDefectCollectionBehavior
  }),

  created () {
    if (this.propDefectSettings) this.settings = JSON.parse(JSON.stringify(this.propDefectSettings))
  },

  computed: {
    collectionUnits () {
      if (this.settings.collectionBehavior.value !== ReceiverDefectCollectionBehavior.Unloader.value) {
        return ReceiverDefectCollectionUnit.enums.filter(u => u.value !== ReceiverDefectCollectionUnit.Segmented.value)
      } else {
        return ReceiverDefectCollectionUnit.enums
      }
    },
    displayDropDownIcon () {
      return this.isEditing ? '$dropdown' : ''
    }
  },

  methods: {
    modifyDefectSettings () {
      if (this.settings.collectionUnit.value === ReceiverDefectCollectionUnit.Segmented.value && this.settings.collectionBehavior.value !== ReceiverDefectCollectionBehavior.Unloader.value) {
        this.settings.collectionUnit = ReceiverDefectCollectionUnit.Pounds
      }
      this.$emit('changeDefectSettings', this.settings)
    }
  }
}
</script>
