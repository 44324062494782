import { UserClaims } from '@/utils/Enumerations'
import i18n from '@/i18n'
import PDFMake from 'pdfmake'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { generateSvg, angelLogo } from '@/images/AngelSvgLibrary'
import { theme } from '@/plugins/theme'

const userClaims = [
  {
    claim: UserClaims.GlobalAdmin,
    description: i18n.t('globalAdminDescription'),
    examples: [
      i18n.t('regionalManagers'),
      i18n.t('millManagers'),
      i18n.t('informationTechnologyAcronym')
    ],
    claims: {
      userManagement: [
        i18n.t('importUsers'),
        i18n.t('assignOrUpdateUserRoles')
      ],
      companyInfo: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('businessEntities') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('locations') }),
        i18n.t('updateN', { n: i18n.t('companyInfo') })
      ],
      setup: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('certifications') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('products') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('tags') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('teams') })
      ]
    }
  },
  {
    claim: UserClaims.ContractManager,
    description: i18n.t('contractManagerDescription'),
    examples: [
      i18n.t('employeesCreatingManagingTractsContracts'),
      i18n.t('accountants'),
      i18n.t('foresters')
    ],
    claims: {
      accounting: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('advances') }),
        i18n.t('createN', { n: i18n.t('advanceModifications') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('aManualRecoveryOnAdvances') })
      ],
      contracts: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('contracts') })
      ],
      setup: [
        i18n.t('createUpdateDeleteEntity', { entity: `${i18n.t('fiscalYears')}/${i18n.t('payPeriods')}` }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('settings') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('activityTemplateBundles') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('tractTypes') }),
        i18n.t('downloadBulkData')
      ],
      tracts: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('tractPayables') })
      ]
    }
  },
  {
    claim: UserClaims.ExportManager,
    description: i18n.t('exportManagerDescription'),
    examples: [
      i18n.t('accountant')
    ],
    claims: {
      accounting: [
        i18n.t('createN', { n: i18n.t('exportBatches') })
      ]
    }
  },
  {
    claim: UserClaims.TemplateManager,
    description: i18n.t('templateManagerDescription'),
    examples: [
      i18n.t('accountant'),
      i18n.t('controller')
    ],
    claims: {
      setup: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('activityTemplates') })
      ]
    }
  },
  {
    claim: UserClaims.ContractReviewer,
    description: i18n.t('contractReviewerDescription'),
    examples: [
      i18n.t('accountant'),
      i18n.t('headRole', { role: i18n.t('forester') })
    ],
    claims: {
      contracts: [
        i18n.t('approveN', { n: i18n.t('contracts') })
      ]
    }
  },
  {
    claim: UserClaims.InventoryManager,
    description: i18n.t('inventoryManagerDescription'),
    examples: [
      i18n.t('yardManager'),
      i18n.t('accountant'),
      i18n.t('forester')
    ],
    claims: {
      companyInfo: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('decks') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('defectDefinitions') })
      ],
      ticketsTab: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('consumptionBatches') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('transferBatches') })
      ],
      setup: [
        i18n.t('importLogCounts')
      ]
    }
  },
  {
    claim: UserClaims.AccountManager,
    description: i18n.t('accountManagerDescription'),
    examples: [
      i18n.t('accountant'),
      i18n.t('forester')
    ],
    claims: {
      accounts: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('accounts') })
      ]
    }
  },
  {
    claim: UserClaims.TicketManager,
    description: i18n.t('ticketManagerDescription'),
    examples: [
      i18n.t('accountant'),
      i18n.t('forester')
    ],
    claims: {
      tickets: [
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('tickets') }),
        i18n.t('createUpdateDeleteEntity', { entity: i18n.t('ticketCorrections') }),
        i18n.t('createN', { n: i18n.t('ticketLogCounts') }),
        i18n.t('createN', { n: i18n.t('ticketDefects') })
      ],
      contracts: [
        i18n.t('importTicketCSV'),
        i18n.t('updateTicketCSV')
      ]
    }
  }
]

export function generateUserPermissionsPDF () {
  const content = userClaims.reduce((a, uc, i) => {
    a.push(
      {
        text: i18n.t(uc.claim.label),
        style: 'title',
        pageBreak: `${i !== 0 ? 'before' : ''}`
      },
      uc.description,
      {
        text: i18n.t('examplesOfEmployeesWhoWouldHaveThisPermission'),
        style: 'h1'
      },
      {
        ul: uc.examples,
        style: 'indent'
      },
      {
        text: i18n.t('claims'),
        style: 'h1'
      }
    )
    return a.concat(
      Object.keys(uc.claims).reduce((a, claimKey) => {
        a.push(
          {
            text: i18n.t(claimKey.toString()),
            style: 'h2'
          },
          {
            ul: uc.claims[claimKey],
            style: 'paragraph'
          }
        )
        return a
      }, [])
    )
  }, [])

  const documentDefinition = {
    footer: function (currentPage) {
      return {
        margin: [40, -6, 40, 0],
        columns: [
          {
            svg: generateSvg(angelLogo, theme.primary),
            width: 16
          },
          {
            text: i18n.t('printedOnDate', { date: utcToLocalDate(Date.now(), 'L - LT') }),
            alignment: 'center',
            margin: [0, 6, 0, 0]
          },
          {
            text: currentPage.toString(),
            width: 16,
            margin: [0, 6, 0, 0]
          }
        ]

      }
    },

    content,

    styles: {
      title: {
        fontSize: 18,
        bold: true,
        margin: [0, 20, 0, 10]
      },
      h1: {
        fontSize: 15,
        bold: true,
        margin: [0, 20, 0, 10]
      },
      h2: {
        margin: [0, 0, 0, 5]
      },
      paragraph: {
        margin: [15, 0, 0, 10]
      },
      indent: {
        margin: [15, 0, 0, 0]
      }
    },

    pageMargins: [80, 40, 80, 60]
  }

  PDFMake.createPdf(documentDefinition).download('user-permissions')
}
