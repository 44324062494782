<template>
  <v-expansion-panels popout v-model="openPanel" accordion tile>
    <v-expansion-panel v-for="(location, index) in entity.locations" :key="index">
      <LocationPanel
        :propLocation="location"
        :businessEntityId="entity.businessEntityId"
        :locations="entityLocations"
        :index="index"
        :entityTab="entityTab"
        :entityIndex="entityIndex"
        :openPanel="openPanel"
        @entity-mutated="$emit('location-mutated')"
      />
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'Locations',

  components: {
    LocationPanel: () => import('@/components/settings-components/company-config/LocationPanel.vue')
  },

  props: {
    entity: {
      type: Object,
      default: undefined,
      required: true
    },
    entityTab: {
      type: Number,
      default: undefined
    },
    entityIndex: {
      type: Number,
      default: undefined
    }
  },

  watch: {
    entityTab: {
      async handler (val) {
        if (this.entityIndex === val && this.entityLocations.length === 1) {
          this.openPanel = 0
        }
      }
    }
  },

  async created () {
    if (this.entityLocations.length === 1) {
      this.openPanel = 0
    }
  },

  computed: {
    entityLocations () {
      return this.entity.locations
    }
  },

  data: () => ({
    openPanel: undefined
  })
}
</script>
