<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="auto" class="ma-0 pa-0">
        <Selector
        class="mt-2"
        :label="$t('selectEntity')"
        :items="entities"
        @item-selected="entitySelected"
        variant="outlined"/>
      </v-col>
      <v-spacer />
      <v-col align="right" class="ma-0 pa-0">
        <span class="mt-4" style="color: black; font-size: 20px">
          {{ grandTotal }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels accordion popout v-model="openPanels" tile class="pa-1">
        <v-expansion-panel v-for="(payment, index) in paymentsByEntity" :key="`payment-${index}`">
          <v-expansion-panel-header>
            <v-row dense>
              <v-col cols="auto">
                <v-icon color="rgba(0,0,0,0.6)" small>mdi-account</v-icon>
                {{getTitleForPaymentPanel(payment.payeeName, payment.payeeCode, payment.payeeExportCode)}}
              </v-col>
            </v-row>
            <v-spacer/>
            <template #actions>
              {{getTotalAmountForRegisterPanel(payment)}}
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <PaymentRegisterCard
              :payment="payment"
              :isByproduct="isByproduct"
              :isExportBatch="isExportBatch"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { getEntities, getFinancialsByEntity, PayablesHelpers } from '@/utils/RegisterHelpers'

export default {
  name: 'PaymentRegister',

  props: {
    paymentRegisterObject: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: false,
      default: false
    },
    isExportBatch: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Selector: () => import('@/components/core/Selector.vue'),
    PaymentRegisterCard: () => import('@/components/settlements/accounts-payable-module/payment-register/PaymentRegisterCard.vue')
  },

  data: () => ({
    openPanels: [],
    entities: [],
    selectedEntity: undefined
  }),

  created () {
    this.openPanels = this.paymentsByEntity.length === 1 ? [0] : []
  },

  mounted () {
    this.getEntities()
  },

  watch: {
    entities: {
      handler (val) {
        if (val.length > 0) {
          this.selectedEntity = val[0]
        }
      },
      deep: true
    }
  },

  computed: {
    grandTotal () {
      return PayablesHelpers.getGrandTotal(this.paymentsByEntity)
    },

    payments () {
      const { payments } = this.paymentRegisterObject
      if (payments === undefined) { return [] }

      return payments
    },

    paymentsByEntity () {
      if (this.selectedEntity === undefined) { return this.payments }
      return getFinancialsByEntity(this.payments, this.selectedEntity, 0)
    }
  },

  methods: {
    getEntities () {
      this.entities = getEntities(this.payments, 0)
    },

    entitySelected (entity) {
      this.selectedEntity = entity
    },

    getTitleForPaymentPanel (name, code = '', exportCode = '') {
      let formattedName = name

      if (code !== '') formattedName += ` \u2016 ${code}`
      if (exportCode !== '') formattedName += ` \u2016 ${exportCode}`

      return formattedName
    },

    getTotalAmountForRegisterPanel (payment) {
      return PayablesHelpers.totalStringForAccount(payment)
    }
  }
}
</script>
