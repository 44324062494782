<template>
  <v-row v-if="!loading" class="px-3">
    <v-col cols="12">
      <v-tabs v-model="currentTab" class="card-tabs">
        <v-tab v-if="payables.length !== 0">
          {{$t('payables')}}
        </v-tab>
        <v-tab v-if="receivables.length !== 0">
          {{$t('receivables')}}
        </v-tab>
        <v-tab v-if="journalEntries.length !== 0">
          {{$t('accruals')}}
        </v-tab>
        <v-tab v-if="defects.length !== 0">
          {{$t('defects')}}
        </v-tab>
      </v-tabs>
      <v-card v-if="displayCard" outlined style="border-radius: 0px 4px 4px 4px">
        <v-card-text>
          <v-tabs-items v-model="currentTab" touchless class="">
            <v-tab-item v-if="payables.length !== 0">
              <PayableExpansionPanels :ticket="ticket" :payables.sync="payables" :contractMode="contractMode"/>
            </v-tab-item>
            <v-tab-item v-if="receivables.length !== 0">
              <ReceivableExpansionPanels :ticket="ticket" :receivables.sync="receivables"/>
            </v-tab-item>
            <v-tab-item v-if="journalEntries.length !== 0">
              <JournalEntryExpansionPanels :ticket="ticket" :journalEntries.sync="journalEntries"/>
            </v-tab-item>
            <v-tab-item v-if="defects.length !== 0">
              <TicketDefectsExpansionPanels :ticket="ticket" :ticketDefects.sync="defects"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-container fluid v-else>
    <v-row justify="center" align="center" height="200px">
      <v-progress-circular indeterminate color="primary" size="26"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
import { TicketStatus } from '@/utils/Enumerations.js'
export default {
  name: 'TicketFinancials',

  components: {
    PayableExpansionPanels: () => import('@/components/accounting/accounts-payable/PayableExpansionPanels.vue'),
    ReceivableExpansionPanels: () => import('@/components/accounting/accounts-receivable/ReceivableExpansionPanels.vue'),
    JournalEntryExpansionPanels: () => import('@/components/accounting/journal-entries/JournalEntryExpansionPanels.vue'),
    TicketDefectsExpansionPanels: () => import('@/components/defects/TicketDefectsExpansionPanel.vue')
  },

  props: {
    ticketId: {
      type: Number,
      required: true,
      default: undefined
    },
    ticket: {
      type: Object
    },
    refreshToggle: {
      type: Boolean,
      required: false,
      default: false
    },
    contractMode: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: () => ({
    currentTab: 0,
    payables: [],
    receivables: [],
    journalEntries: [],
    defects: [],
    loading: false
  }),

  watch: {
    refreshToggle () {
      this.getFinancials()
    }
  },

  created () {
    this.getFinancials()
  },

  computed: {
    displayCard () {
      return (this.payables.length + this.receivables.length + this.journalEntries.length + this.defects.length) > 0
    }
  },

  methods: {
    ...mapActions('ticket', ['getTicketPayables']),
    ...mapActions('receivable', ['getReceivablesForTicket']),
    ...mapActions('journal-entries', ['getJournalEntriesForTicket']),
    ...mapActions('defects', ['fetchDefectsForTicketById']),
    async getFinancials () {
      this.loading = true
      try {
        if (this.ticket.status === TicketStatus.ReadyForPayment.value ||
          this.ticket.status === TicketStatus.Posted.value ||
          this.ticket.status === TicketStatus.Exported.value) {
          this.payables = await this.getTicketPayables(this.ticketId)
          this.journalEntries = await this.getJournalEntriesForTicket(this.ticketId)
          this.receivables = await this.getReceivablesForTicket(this.ticketId)
        }

        if (this.ticket.defectWeight > 0) {
          this.defects = await this.fetchDefectsForTicketById(this.ticketId)
        }
      } finally {
        this.loading = false
      }
    },

    contractTypeColor () {
      return colorClassForContractType(this.ticket?.contractType)
    }
  }
}
</script>
