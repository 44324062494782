import { PublicClientApplication } from '@azure/msal-browser'
import { graphMsalConfig } from './config.js'
import { ErrorCodes } from '../utils/constants'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { get } from 'axios'
import store from '@/store'
import ApiServer from '@/utils/api/ApiServer'

const ALL_TENANTS_CHARACTER = '*'
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me?$select=customSecurityAttributes'

const SECURITY_ATTR_SCOPE = 'CustomSecAttributeAssignment.Read.All'
const ATTRIBUTE_SET_NAME = 'ANGELATTRSET'
const ATTRIBUTE_NAME = 'ACCESS'

const TENANT_NOT_DEFINED_ERROR_CODE = 'POU9D'

const DefaultTenantConfiguration = {
  isMultiTenant: false,
  selectedTenant: null,
  subtenants: [],
  userTenantCodes: []
}

class MultiTenantHandler {
  msalClient = null
  configuration = null

  constructor () {
    this.msalClient = new PublicClientApplication(graphMsalConfig)
    const fromLocalStorage = localStorage.getItem(LocalStorageKeys.MULTI_TENANT_CONFIGURATION)
    this.configuration = fromLocalStorage ? JSON.parse(fromLocalStorage) : null
  }

  setTenant (tenant) {
    this.storeMultiTenantConfiguration({
      ...this.configuration,
      selectedTenant: tenant
    })
  }

  get isTenantSet () {
    return this.configuration?.selectedTenant
  }

  get filteredTenants () {
    if (!this.isInitialized) return []
    return this.configuration.subtenants.filter(subtenant => this.configuration.userTenantCodes.includes(subtenant.code))
  }

  get isInitialized () {
    return this.configuration ?? false
  }

  get isMultiTenant () {
    if (!this.isInitialized) {
      return true
    }

    return this.configuration?.isMultiTenant === true
  }

  get isLoggedIn () {
    return this.msalClient?.getAllAccounts().length > 0
  }

  async initialize () {
    if (!this.isLoggedIn || this.isTenantSet) return

    const serverTenants = await this.getServerSubtenants()

    // If the server returns no subtenants, we assume they are a single tenant user.
    if (serverTenants.length === 0) {
      this.storeMultiTenantConfiguration(DefaultTenantConfiguration)
      return
    }

    let graphTenantCodes = await this.getGraphTenantCodes()
    graphTenantCodes = this.filterGraphTenantCodes(serverTenants, graphTenantCodes)

    // If their custom security attributes field is empty and subtenants exist, throw an error.
    if (graphTenantCodes.length === 0 && serverTenants.length > 0) {
      throw new Error(ErrorCodes.NO_TENANTS_CONFIGURED)
    }

    // If a user has a * character and other tenants defined in their custom security attributes, throw an error.
    if (graphTenantCodes.length > 1 && graphTenantCodes.includes(ALL_TENANTS_CHARACTER)) {
      throw Error(ErrorCodes.INVALID_MT_CONFIGURATION)
    }

    const accessToAllTenants = graphTenantCodes.length === 1 && graphTenantCodes[0] === ALL_TENANTS_CHARACTER
    const containsValidTenant = graphTenantCodes.some(code => serverTenants.some(subtenant => subtenant.code === code))

    // If a user has something other than tenant code(s) in their custom security attributes, we throw an error.
    if (!accessToAllTenants && !containsValidTenant) {
      throw new Error(ErrorCodes.NO_TENANTS_CONFIGURED)
    }

    this.storeMultiTenantConfiguration({
      isMultiTenant: true,
      selectedTenant: null,
      subtenants: serverTenants,
      userTenantCodes: accessToAllTenants ? serverTenants.map(subtenant => subtenant.code) : graphTenantCodes
    })

    if (graphTenantCodes.length === 1 && !accessToAllTenants) {
      this.setTenant(serverTenants.find(subtenant => subtenant.code === graphTenantCodes[0]))
    } else if (accessToAllTenants && serverTenants.length === 1) { // A user has access to all tenants and there is only one tenant.
      this.setTenant(serverTenants[0])
    }
  }

  storeMultiTenantConfiguration (configuration) {
    this.configuration = configuration
    localStorage.setItem(LocalStorageKeys.MULTI_TENANT_CONFIGURATION, JSON.stringify(configuration))
  }

  async getGraphTenantCodes () {
    try {
      const loginRequest = {
        account: this.msalClient.getAllAccounts()[0],
        scopes: [SECURITY_ATTR_SCOPE]
      }

      const { accessToken } = await this.msalClient.acquireTokenSilent(loginRequest)
      const headers = { Authorization: `Bearer ${accessToken}` }
      const { data: graphProfile } = await get(GRAPH_ENDPOINT, { headers })

      return graphProfile.customSecurityAttributes?.[ATTRIBUTE_SET_NAME]?.[ATTRIBUTE_NAME] ?? []
    } catch (error) {
      console.error('Error getting graph security attributes:', error)
      throw new Error(ErrorCodes.ADMIN_CONSENT_NOT_GRANTED)
    }
  }

  async getServerSubtenants () {
    try {
      return (await ApiServer.get('subtenants')).data
    } catch (error) {
      // User's token is for a Azure Entra tenant that is not defined in tenants.json
      if (error.response?.data?.code === TENANT_NOT_DEFINED_ERROR_CODE) {
        console.error('Error getting subtenants:', error)
        store.dispatch('user/setInvalidAccount', this.msalClient.getAllAccounts()[0])
      }

      throw error
    }
  }

  filterGraphTenantCodes (serverTenants, graphTenantCodes) {
    if (graphTenantCodes.length === 1 && graphTenantCodes[0] === ALL_TENANTS_CHARACTER) return graphTenantCodes

    const serverTenantCodes = serverTenants.map(tenant => tenant.code)
    return graphTenantCodes
      .map(code => code.trim().toUpperCase())
      .filter(code => serverTenantCodes.includes(code))
  }
}

export default new MultiTenantHandler()
