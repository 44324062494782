<template>
  <div>
    <DataTable
    :items.sync="mappedItems"
    :headers="headers"
    :loading="loading"
    @create="editTicket"
    defaultSort="ticketNumber"
    :sortDesc="true"
    tableKey="ticketId"
    @refresh="$emit('refresh')"
    @email-csv="emailCSV"
    :actions="tableActions"
    :customCells="customColumns"
    :showSearch="isTractDetail"
    resize
    defaultHeight="calc(100vh - 461px)">
      <template #search-replacement v-if="!isTractDetail">
        <v-col style="min-width: 200px;" class="pa-0">
          <v-text-field
            v-model="search"
            :label="$t('search')"
            @keydown.enter="searchBtnClicked"
            @click:clear="searchBtnClicked(true)"
            append-icon="mdi-magnify"
            single-line
            clearable
            color="primary"
          >
            <template #append-outer>
              <v-btn small color="primary" class="no-margin-or-padding-button" :disabled="!search" @click="searchBtnClicked">
                Search
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </template>
      <template #custom-controls>
        <template v-if="!isTractDetail">
          <v-col cols="auto">
            <v-row no-gutters>
              <v-col v-for="filter in filterButtons" :key="filter.name" cols="auto">
                <Icon
                  dataTestId="ticket-filter"
                  :iconColor="filter.active ? 'black' : 'grey'"
                  :icon="filter.icon"
                  :tooltipText="$t(filter.name)"
                  :small="false"
                  @icon-clicked="filterClicked(filter)"
                  :elementId="`${kebabCase(filter.name)}-filter-tickets-table`"
                />
              </v-col>
            </v-row>
          </v-col>
        </template>
        <v-col cols="auto">
          <div style="height: 48px; border-left: solid rgba(0, 0, 0, .12) 1px; margin-left: 17px; margin-right: 18px;"/>
        </v-col>
        <v-col cols="auto">
          <Icon
          v-if="!isTractDetail"
          iconColor="black"
          icon="mdi-clipboard-search"
          dataTestId="advanced-search"
          :small="false"
          :tooltipText="$t('advancedSearch')"
          @icon-clicked="advanceSearchClicked"
          elementId="advanced-search-button-tickets-table"
          />
        </v-col>
        <v-col cols="auto">
          <Icon
          v-if="showConsumption"
          iconColor="primary"
          icon="mdi-saw-blade"
          dataTestId="ticket-consume"
          :small="false"
          :disabled="!userAssignedClaim(UserClaims.InventoryManager)"
          :tooltipText="$t('inventoryOperations')"
          @icon-clicked="$emit('manage-consumption')"
          elementId="inventory-operations-button-tickets-table"
          />
        </v-col>
        <v-col cols="auto">
          <Icon
          v-if="showTicketReconciliation"
          iconColor="primary"
          icon="mdi-clipboard-check-outline"
          dataTestId="ticket-reconcile"
          :small="false"
          :tooltipText="$t('reconcileTickets')"
          :disabled="!userAssignedClaim(UserClaims.TicketManager)"
          @icon-clicked="$emit('reconcile')"
          elementId="ticket-reconciliation-button-tickets-table"
          />
        </v-col>
        <v-col cols="auto">
          <Icon
          v-if="!isTractDetail"
          iconColor="primary"
          icon="mdi-briefcase-edit"
          dataTestId="ticket-post"
          :small="false"
          margin="mr-2"
          :tooltipText="$t('managePostedLoads')"
          @icon-clicked="$emit('post-loads')"
          elementId="ticket-posting-button-tickets-table"
          />
        </v-col>
        <v-col cols="auto">
          <TabbedColumnAdjuster
          :propColumnSet.sync="columnSet"
          :propTabs="columnAdjusterTabs"
          @new-columns-selected="newColumnsSelected"
          :dynamicSize="isTractDetail"
          elementId="column-adjuster-button-tickets-table"
          />
        </v-col>
      </template>
      <template #filters>
        <TicketFilterChips
        v-if="!isTractDetail"
        :isTractDetail="isTractDetail"/>
        <TimeFilter v-if="dateFilter.active"/>
      </template>
      <template #product="slotProps">
        {{ slotProps.item.product ? slotProps.item.product : $t('other') }}
      </template>
      <template #status="slotProps">
        <v-row align="center" justify="center" style="flex-wrap: nowrap;">
          <Icon
          dataTestId="ticket-table-status-icon"
          :iconColor="getStatus(slotProps.item).iconColor"
          :icon="getStatus(slotProps.item).icon"
          :tooltipText="getStatus(slotProps.item).tooltipText"
          :small="false"
          />
          <Icon v-if="slotProps.item.rolloutStatus === RolloutStatus.Active.value" :tooltipText="$t('rolloutActive')" icon="rollout" />
          <Icon
            dataTestId="ticket-table-draft-icon"
            iconColor="black"
            icon="mdi-file"
            v-if="slotProps.item.isOnDraftContract"
            :tooltipText="$t('draftTicket')"
          />
          <Icon
            v-if="slotProps.item.hasImages"
            iconColor="black"
            icon="mdi-image-outline"
            :tooltipText="$t('ticketHasImages')"
          />
          <Icon
            v-if="slotProps.item.hasDefectImages"
            iconColor="grey"
            icon="mdi-image-broken-variant"
            :tooltipText="$t('ticketHasDefectImages')"
          />
          <Icon
            v-if="slotProps.item.consumed"
            dataTestId="ticket-table-consumed-icon"
            iconColor="grey"
            icon="mdi-saw-blade"
            :tooltipText="$t('consumed')"
          />
          <Icon
            v-if="showApprovalStatusWarning(slotProps.item)"
            icon="mdi-file-document-check-outline"
            iconColor="error"
            :tooltipText="approvalStatusWarningText(slotProps.item)"
          />
          <Icon
            v-if="slotProps.item.correctionType !== null"
            icon="mdi-auto-fix"
            iconColor="black"
            :tooltipText="`${$t('ticketCorrection')}: ${CorrectionType.fromInt(parseInt(slotProps.item.correctionType))}`"
          />
          <Icon
            v-if="slotProps.item.proximityTriggered"
            dataTestId="ticket-table-prox-icon"
            icon="mdi-alert-circle-outline"
            iconColor="error"
            @icon-clicked="openMap(slotProps.item)"
            :tooltipText="$t('createdAwayFromTract')"></Icon>
          <Icon
            v-if="allLocationsByAccountId[slotProps.item.destinationAccountId]?.collectLogCounts && !slotProps.item.hasLogCounts && contractMode.value !== 1"
            icon="mdi-numeric-off"
            iconColor="error"
            :tooltipText="$t('missingLogCounts')"
          />
          <Icon
            v-if="(inWeightWarning || outWeightWarning) && checkTicketForWarning(slotProps.item)"
            icon="mdi-alert"
            icon-color="warning"
            :tooltipText="getWarningTooltip(slotProps.item)"
          />
          <Icon
            v-if="slotProps.item.isOverweight"
            icon="weight-alert"
            :tooltipText="$t('overweight')"
          />
        </v-row>
      </template>
      <template #ticket-number="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.ticketNumber}}</span>
      </template>
      <template #source="{item}">
        <Icon v-if="item.origin !== TicketOrigin.WebClient.value" :icon="kebabCase(TicketOrigin.labelFromInt(item.origin))" :small="false" :tooltipText="TicketOrigin.fromInt(item.origin)"/>
      </template>
      <template #ext-one="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.extTicketNumber1}}</span>
      </template>
      <template #ext-two="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.extTicketNumber2}}</span>
      </template>
      <template #trailer-identifier="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.trailerIdentifier}}</span>
      </template>
      <template #tract="{item}" v-if="!isTractDetail">
        <Icon
        v-if="item.tractIsCertified"
        icon="mdi-certificate-outline"
        iconColor="black"
        :tooltipText="$t('tractIsCertified')"
        />
        <span id="table-shortcut" @click="viewTicket(item)">{{item.tract ?? $t('notAvailable')}}</span>
      </template>
      <template #destination="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.destination}}</span>
      </template>
      <template #deck-name="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.deckName ?? $t('notAvailable')}}</span>
      </template>
      <template #account="{item}">
        <AccountName
          :certificationStatus="item.accountCertificationStatus"
          :accountId="item.accountId"
        >
          <span id="table-shortcut" @click="viewTicket(item)">{{item.account}}</span>
        </AccountName>
      </template>
      <template #landowner="{item}">
        <span v-if=item.tractLandOwnerName id="table-shortcut" @click="viewTicket(item)">{{item.tractLandOwnerName ?? $t('notAvailable')}}</span>
        <span v-else-if="item.landownerCount > 1" @click="viewTicket(item)" style="cursor: pointer;">
          <v-tooltip bottom color="black">
            <template #activator="{on}">
              <v-icon v-on="on" color="black">
                mdi-dots-horizontal
              </v-icon>
            </template>
            <span class="subtitle-1 white--text">
              {{ $t('multipleLandowners') }}
            </span>
          </v-tooltip>
        </span>
        <span v-else id="table-shortcut" @click="viewTicket(item)">
          {{ $t('notAvailable') }}
        </span>
      </template>
      <template #logger="{item}">
        <AccountName
          :certificationStatus="item.loggerCertificationStatus"
          :accountId="item.loggerAccountId"
        >
          <span id="table-shortcut" @click="viewTicket(item)">{{item.logger ?? $t('notAvailable')}}</span>
        </AccountName>
      </template>
      <template #hauler="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.hauler ?? $t('notAvailable')}}</span>
      </template>
      <template #supplier="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.supplier ?? $t('notAvailable')}}</span>
      </template>
      <template #driver="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.driver ?? $t('notAvailable')}}</span>
      </template>
      <template #consulting-forester="{item}">
        <span id="table-shortcut" @click="viewTicket(item)">{{item.consultingForester ?? $t('notAvailable')}}</span>
      </template>
      <template #from-account="{item}">
        <AccountName
          :certificationStatus="item.fromAccountCertificationStatus"
          :accountId="item.fromAccountId"
        >
          <span id="table-shortcut" @click="viewTicket(item)">{{item.fromAccount ?? $t('notAvailable')}}</span>
        </AccountName>
      </template>
      <template #pieces="{item}">
        {{item.pieces > 0 ? item.pieces : $t('notAvailable')}}
      </template>
      <template #related="slotProps">
        <Icon
        dataTestId="ticket-detail"
        icon="mdi-information-outline"
        margin="mr-1"
        :tooltipText="$t('ticketDetails')"
        @icon-clicked="viewTicket(slotProps.item)"/>
        <Icon
        v-if="!isTractDetail && isLogs"
        dataTestId="ticket-tract"
        icon="mdi-crosshairs-gps"
        iconColor="black"
        margin="mr-1"
        :tooltipText="$t('viewTract')"
        @icon-clicked="viewTract(slotProps.item)"/>
        <Icon
        dataTestId="ticket-contract"
        icon="mdi-altimeter"
        :tooltipText="$t('viewContract')"
        small
        @icon-clicked="viewContractDetail(slotProps.item)"
        />
      </template>
      <template #actions="slotProps">
        <Icon
        dataTestId="ticket-edit"
        icon="mdi-pencil"
        iconColor="success"
        :disabled="slotProps.item.status === 6 || slotProps.item.status === 7 || !userAssignedClaim(UserClaims.TicketManager)"
        :tooltipText="$t('edit')"
        @icon-clicked="editTicket(slotProps.item)"/>
        <Icon
        dataTestId="ticket-delete"
        icon="mdi-delete-forever"
        :disabled="slotProps.item.status === 6 || slotProps.item.status === 7 || !userAssignedClaim(UserClaims.TicketManager)"
        iconColor="error"
        :tooltipText="$t('delete')"
        @icon-clicked="deleteTicket(slotProps.item)"/>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  TicketStatusIcon,
  ContractApprovalStatus,
  CorrectionType,
  ContractMode,
  UserClaims,
  TicketOrigin,
  RolloutStatus
} from '@/utils/Enumerations.js'
import { TicketOriginImage } from '@/model/Ticket.js'
import TicketHeaders from '@/headers/Ticket'
import { userAssignedClaim } from '../../utils/ClaimUtility'
import { kebabCase } from 'lodash'

export default {
  name: 'TicketTable',

  components: {
    TicketFilterChips: () => import('./TicketFilterChips.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    TabbedColumnAdjuster: () => import('@/components/helper/TabbedColumnAdjuster.vue'),
    Icon: () => import('../helper/Icon.vue'),
    AccountName: () => import('@/components/account/AccountName.vue'),
    TimeFilter: () => import('./TimeFilter.vue')
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    contractMode: {
      type: Object,
      default: () => ({ value: 0 })
    },
    isTractDetail: {
      required: false,
      default: false
    }
  },

  data: () => ({
    RolloutStatus,
    TicketOrigin,
    CorrectionType,
    search: '',
    columnSet: null,
    TicketOriginImage,
    dateFilter: {
      active: false,
      startDate: '',
      endDate: ''
    },
    ticketMedians: {
      inboundWeight: 0,
      outboundWeight: 0
    },
    inWeightWarning: false,
    outWeightWarning: false,
    ticketMap: false,
    UserClaims
  }),

  watch: {
    filters: {
      handler (val, _) {
        const dateFilter = val.dateRange
        if (!dateFilter.active) {
          this.dateFilter = {
            active: false,
            startDate: '',
            endDate: ''
          }
        }
      },
      deep: true
    },

    allTickets: {
      handler (val) {
        this.calculateTicketWeightMedians()
        let eligibleInWeights = 0
        let eligibleOutWeights = 0
        this.allTickets.forEach(t => {
          if (t.inWeight !== 0) eligibleInWeights++
          if (t.outWeight !== 0) eligibleOutWeights++
        })
        this.inWeightWarning = eligibleInWeights >= 5
        this.outWeightWarning = eligibleOutWeights >= 5
      }
    },

    contractMode (_) {
      this.initializeHeaders()
    },

    isPhraseFilterActive (val) {
      if (!val) {
        this.search = ''
      }
    }
  },

  computed: {
    ...mapGetters('ticket', ['allTickets', 'ticketsLoading', 'filters']),
    ...mapGetters('tract', ['allTracts']),
    ...mapGetters('locations', ['allLocationsByAccountId']),
    headers () {
      if (!this.columnSet) return []
      return this.columnSet.getColumns()
    },

    mappedItems () {
      return this.allTickets.map(t => Object.assign(t, { landownerBundle: JSON.stringify({ loAccount: t.tractLandOwnerName, loCount: t.landownerCount }) }))
    },

    filterButtons () {
      const filterButtons = Object
        .values(this.filters)
        .slice(0, -5)
      return filterButtons
    },

    isPhraseFilterActive () {
      return this.filters.phrase.active
    },

    columnAdjusterTabs () {
      return ['load', 'misc', 'contract', 'weight']
    },

    customColumns () {
      return [
        {
          slotName: 'ticket-number',
          value: 'ticketNumber'
        },
        {
          slotName: 'source',
          value: 'origin'
        },
        {
          slotName: 'ext-one',
          value: 'extTicketNumber1'
        },
        {
          slotName: 'ext-two',
          value: 'extTicketNumber2'
        },
        {
          slotName: 'trailer-identifier',
          value: 'trailerIdentifier'
        },
        {
          slotName: 'tract',
          value: 'tract'
        },
        {
          slotName: 'account',
          value: 'account'
        },
        {
          slotName: 'landowner',
          value: 'landownerBundle'
        },
        {
          slotName: 'logger',
          value: 'logger'
        },
        {
          slotName: 'hauler',
          value: 'hauler'
        },
        {
          slotName: 'supplier',
          value: 'supplier'
        },
        {
          slotName: 'driver',
          value: 'driver'
        },
        {
          slotName: 'from-account',
          value: 'fromAccount'
        },
        {
          slotName: 'destination',
          value: 'destination'
        },
        {
          slotName: 'deck-name',
          value: 'deckName'
        },
        {
          slotName: 'related',
          value: 'related'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'product',
          value: 'product'
        },
        {
          slotName: 'pieces',
          value: 'pieces'
        },
        {
          slotName: 'consulting-forester',
          value: 'consultingForester'
        }
      ]
    },

    tableActions () {
      return [
        (!this.isTractDetail) ? {
          actionName: 'email-csv',
          icon: 'mdi-file-delimited-outline',
          text: this.$t('emailCSV'),
          id: 'live-export-email-csv-button'
        } : undefined,
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh'),
          id: 'refresh-button-tickets-table'
        },
        (!this.isTractDetail) ? {
          actionName: 'create',
          icon: 'mdi-plus',
          text: this.$t('new'),
          disabled: !userAssignedClaim(UserClaims.TicketManager),
          id: 'create-button-tickets-table'
        } : undefined
      ].filter(a => a !== undefined)
    },

    isLogs () {
      return this.contractMode === ContractMode.Logs
    },

    isByproducts () {
      return this.contractMode === ContractMode.Byproducts
    },

    isLogYardSales () {
      return this.contractMode === ContractMode.LogYardSale
    },

    showTicketReconciliation () {
      return (this.isLogs || this.isByproducts || this.isLogYardSales) && !this.isTractDetail
    },

    showConsumption () {
      return (this.isLogs || this.isByproducts) && !this.isTractDetail
    }
  },

  created () {
    this.initializeHeaders()
    this.calculateTicketWeightMedians()
  },

  methods: {
    kebabCase,

    ...mapMutations('global', ['setRouterJump']),
    ...mapMutations('ticket', ['applyFilter', 'removeFilter', 'setDateFilter']),
    ...mapMutations('snackbar', ['setSnack']),
    userAssignedClaim,

    initializeHeaders () {
      let columnSet
      switch (this.contractMode.value) {
        case 0:
          columnSet = TicketHeaders.ticketCRUDHeaders()
          break
        case 1:
          columnSet = TicketHeaders.bpTicketCRUDHeaders()
          break
        case 2:
          columnSet = TicketHeaders.transferColumns()
          break
        case 3:
          columnSet = TicketHeaders.logYardSaleColumns()
          break
      }

      this.columnSet = columnSet
      if (this.isTractDetail) {
        this.columnSet.columns = this.columnSet.columns.filter(c => c.id !== 8 && c.id !== 5)
      }
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    getStatus (ticket) {
      return TicketStatusIcon(ticket?.status, ticket?.transitStatus)
    },

    calculateTicketWeightMedians () {
      let tickets = [...this.allTickets]

      tickets.sort((a, b) => a.outWeight - b.outWeight)
      tickets = tickets.filter(t => t.outWeight !== 0)
      let midpoint = Math.floor(tickets.length / 2)
      const outboundMedian = tickets.length % 2 === 1
        ? tickets[midpoint]?.outWeight
        : (tickets[midpoint - 1]?.outWeight + tickets[midpoint]?.outWeight) / 2

      tickets = [...this.allTickets]
      tickets.sort((a, b) => a.inWeight - b.inWeight)
      tickets = tickets.filter(t => t.inWeight !== 0)
      midpoint = Math.floor(tickets.length / 2)
      const inboundMedian = tickets.length % 2 === 1
        ? tickets[midpoint]?.inWeight
        : (tickets[midpoint - 1]?.inWeight + tickets[midpoint]?.inWeight) / 2

      this.ticketMedians.outboundWeight = outboundMedian
      this.ticketMedians.inboundWeight = inboundMedian
    },

    checkTicketForWarning (ticket) {
      const inWeightDeltaPercentage = ticket.inWeight > 0 && this.ticketMedians.inboundWeight !== 0 && this.inWeightWarning
        ? Math.abs(ticket.inWeight - this.ticketMedians.inboundWeight) / this.ticketMedians.inboundWeight
        : 0

      const outWeightDeltaPercentage = ticket.outWeight > 0 && this.ticketMedians.outboundWeight !== 0 && this.outWeightWarning
        ? Math.abs(ticket.outWeight - this.ticketMedians.outboundWeight) / this.ticketMedians.outboundWeight
        : 0

      return inWeightDeltaPercentage >= 0.25 || outWeightDeltaPercentage > 0.25
    },

    getWarningTooltip (ticket) {
      let tooltip = ''
      const inWeightDeltaPercentage = ticket.inWeight > 0 && this.ticketMedians.inboundWeight !== 0 && this.inWeightWarning
        ? Math.abs(ticket.inWeight - this.ticketMedians.inboundWeight) / this.ticketMedians.inboundWeight
        : 0

      const outWeightDeltaPercentage = ticket.outWeight > 0 && this.ticketMedians.outboundWeight !== 0 && this.outWeightWarning
        ? Math.abs(ticket.outWeight - this.ticketMedians.outboundWeight) / this.ticketMedians.outboundWeight
        : 0

      if (inWeightDeltaPercentage >= 0.25) tooltip += 'inbound'
      if (outWeightDeltaPercentage >= 0.25) tooltip === '' ? tooltip += 'outbound' : tooltip += ', outbound'

      return this.$t('ticketWeightError', { typeString: tooltip })
    },

    getDeviceString (slotProps) {
      switch (slotProps.item.getTicketOrigin()) {
        case TicketOriginImage.RECEIVER:
          return this.$t('createdOnReceiver')
        case TicketOriginImage.LOADER:
          return this.$t('createdOnLoader')
        case TicketOriginImage.YARD_OPERATOR:
          return this.$t('createdOnYardOperator')
        case TicketOriginImage.TRANSPORTER:
          return this.$t('createdOnTransporter')
        case TicketOriginImage.KIOSK:
          return this.$t('createdOnKiosk')
        default:
          return ''
      }
    },

    searchBtnClicked (clear) {
      if (clear === true) {
        this.search = ''
      }

      this.$emit('search-btn-clicked', this.search)
    },

    viewTicket (ticket) {
      this.$emit('ticket-action', {
        actionType: 'view-detail',
        ticket: ticket
      })
    },

    viewAccount (ticket) {
      this.$emit('ticket-action', {
        actionType: 'view-account',
        ticket: ticket
      })
    },

    viewContractDetail (ticket) {
      this.$emit('ticket-action', {
        actionType: 'contract-detail',
        ticket: ticket
      })
    },

    viewTract (ticket) {
      this.$emit('ticket-action', {
        actionType: 'view-tract',
        ticket: ticket
      })
    },

    openMap (ticket) {
      this.$emit('ticket-action', {
        actionType: 'open-map',
        ticket: ticket
      })
    },

    editTicket (ticket) {
      this.$emit('ticket-action', {
        actionType: 'open-form',
        ticket: ticket
      })
    },

    deleteTicket (ticket) {
      this.$emit('ticket-action', {
        actionType: 'delete-ticket',
        ticket: ticket
      })
    },

    findTicket () {
      this.$emit('ticket-action', {
        actionType: 'find-ticket'
      })
    },

    advanceSearchClicked () {
      this.$emit('ticket-action', {
        actionType: 'advanced-search'
      })
    },

    filterClicked (filter) {
      if (filter.active) {
        this.removeFilter(filter)
      } else {
        if (filter.name !== 'dateRange') {
          this.applyFilter(filter)
        } else {
          this.dateFilter.active = true
        }
      }
    },

    emailCSV () {
      this.$emit('ticket-action', {
        actionType: 'email-csv'
      })
    },

    showApprovalStatusWarning (ticket) {
      return ticket.contractApprovalStatus !== ContractApprovalStatus.Approved.value && ticket.contractApprovalStatus !== null
    },

    approvalStatusWarningText (ticket) {
      return ContractApprovalStatus.fromInt(ticket.contractApprovalStatus)
    }
  }
}
</script>

<style>
  #pointer {
    cursor: pointer;
  }

  #table-shortcut:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #D15F27;
  }
</style>
