<template>
  <v-autocomplete
  color="black"
  item-color="primary"
  v-model="chosenTractType"
  data-testid="tract-type"
  :label="$t('tractType')"
  :items="allTractTypes"
  return-object
  item-text="name"
  item-value="name"
  :rules="[rules.required]"
  :persistent-hint="persistentHint"
  :hint="hint"
  >
    <template #item="{item}">
      <span>
        <Icon :icon="getCategoryIcon(item)" :iconColor="getIconColor(item)" :tooltipText="getCategoryTooltip(item)" :small="false"/>
        <Icon v-if="item.depletes" icon="mdi-wallet-bifold" :tooltipText="$t('depletes')" :small="false"/>
        {{item.name}}
      </span>
    </template>
    <template #selection="{item}">
      <span>
        <Icon :icon="getCategoryIcon(item)" :iconColor="getIconColor(item)" :tooltipText="getCategoryTooltip(item)" :small="false"/>
        <Icon v-if="item.depletes" icon="mdi-wallet-bifold" :tooltipText="$t('depletes')" :small="false"/>
        {{item.name}}
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TractTypeCategory } from '@/utils/Enumerations.js'
import { rules } from '@/utils/FormRules'
import { theme, helpers } from '@/plugins/theme.js'

export default {
  name: 'TractTypeSelector',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    tractTypeId: {
      type: Number,
      default: undefined
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    persistentHint: {
      type: Boolean,
      default: false,
      required: false
    },
    propFetchTractTypes: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  data: () => ({
    TractTypeCategory,
    chosenTractType: undefined,
    rules
  }),

  created () {
    this.refreshTractTypes(this.tractTypeId, true)
  },

  watch: {
    chosenTractType (val) {
      this.$emit('tract-type', val)
    }
  },

  computed: {
    ...mapGetters('tract-type', ['allTractTypes'])
  },

  methods: {
    ...mapActions('tract-type', ['fetchTractTypes', 'createTractType']),
    getCategoryIcon: ({ category }) => TractTypeCategory.Stumpage.value === category ? 'mdi-alpha-s-circle-outline' : 'mdi-alpha-d-circle-outline',
    getIconColor: ({ category }) => TractTypeCategory.Stumpage.value === category ? helpers.getShade(10, theme.info) : helpers.getTint(35, theme.info),
    getCategoryTooltip: ({ category }) => TractTypeCategory.fromInt(category),

    async refreshTractTypes (tractTypeId, creation = false) {
      if (!creation || this.propFetchTractTypes) await this.fetchTractTypes()
      if (tractTypeId) {
        this.chosenTractType = this.allTractTypes.find(tt => tt.tractTypeId === tractTypeId)
      }
    }
  }
}
</script>
