<template>
  <v-expansion-panels popout v-model="openPanels" accordion tile>
    <v-expansion-panel
      v-for="(payableByBusinessEntity) in payablesByAccount"
      :key="payableByBusinessEntity.businessEntityName"
    >
      <v-expansion-panel-header>
        {{ payableByBusinessEntity.businessEntityName }}
        <v-spacer/>
        <template #actions>
          {{ formatMoney(payableByBusinessEntity.amount) }}
          <v-icon class="ml-2"
            >mdi-chevron-down</v-icon
          >
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          v-if="payableByBusinessEntity.payables.length > 0"
          :headers="headers"
          :items.sync="payableByBusinessEntity.payables"
          :hide-default-footer="payableByBusinessEntity.payables.length < 10"
        >
          <template v-slot:item.amount="{ item }">{{
            formatMoney(item.amount)
          }}</template>
          <template v-slot:item.grossAmount="{ item }">{{
            formatMoney(item.grossAmount)
          }}</template>
          <template v-slot:item.recovered="{ item }">{{
            formatRecovered(item)
          }}</template>
          <template v-slot:item.rate="{ item }">
            <span>{{ formatMoney(item.computedAtRate) }}</span>
          </template>
          <template #item.payOn="{item}">{{
            payOn(item.computedAtPayOn)
          }}</template>
          <template #item.payBy="{item}">{{
            payBy(item.computedAtPayBy)
          }}</template>
          <template v-slot:item.createdAt="{ item }">{{
            utcToLocalDate(item.ticketCreatedAt)
          }}</template>
          <template #item.activityWithSplit="{item}">
            <Icon v-if="item.specialization === TemplateSpecialization.Penalty.value" :tooltipText="$t('penalty')" icon="weight-alert" :small="false"/>
            <span>
              {{ item.activityName }}
            </span>
            <span v-if="item.computedAtSplit">
              ({{ formatPercentage(item.computedAtSplit) }})
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <Icon
              iconColor="black"
              icon="mdi-information-outline"
              dataTestId="payable-detail-button"
              :disabled="item.recoveryMethod === 1"
              :tooltipText="$t('details')"
              @icon-clicked="viewPayable(item)"
            >
            </Icon>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { formatMoney, formatPercentage } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { ticketPayableHeaders } from '@/headers/Payable.js'
import { PayOn, PayBy, TemplateSpecialization } from '@/utils/Enumerations.js'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
export default {
  name: 'PayableExpansionPanels',

  props: {
    payables: {
      type: Array,
      required: true
    },
    ticket: {
      type: Object,
      required: false
    },
    contractMode: {
      type: Number,
      required: false,
      default: 0
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    TemplateSpecialization,
    openPanels: []
  }),

  created () {
    this.openPanels = this.payablesByAccount.length === 1 ? [0] : []
  },

  computed: {
    headers () {
      const headers = ticketPayableHeaders(this.contractMode)
      headers.pop()
      return headers
    },

    payablesByAccount () {
      const payablesByAccount = []
      this.payables.forEach(p => {
        const entityIndex = payablesByAccount.findIndex(pbv => pbv.businessEntityName === p.businessEntityName)
        const mappedPayable = {
          ...p,
          activityWithSplit: {
            name: p.activityName,
            split: p.computedAtSplit
          }
        }
        if (entityIndex === -1) {
          payablesByAccount.push({
            businessEntityName: p.businessEntityName,
            amount: p.amount,
            payables: [mappedPayable]
          })
        } else {
          payablesByAccount[entityIndex].payables.push(mappedPayable)
          payablesByAccount[entityIndex].amount += p.amount
        }
      })

      return payablesByAccount
    },

    contractTypeColor () {
      return colorClassForContractType(this.ticket?.contractType)
    }
  },

  methods: {
    formatMoney,
    formatPercentage,
    utcToLocalDate,
    payOn: (x) => PayOn.fromInt(x),
    payBy: (x) => PayBy.fromInt(x),

    formatRecovered (item) {
      return item.specialization === TemplateSpecialization.Penalty.value
        ? this.$t('notApplicable')
        : item.grossAmount - item.amount === 0
          ? ''
          : formatMoney(item.grossAmount - item.amount)
    }
  }
}
</script>
