<template>
  <v-container fluid>
    <v-row>
      <v-col xs12>
        <material-card
        color="primary"
        :title="$t('userManagement')"
        :text="$t('userManagementSubtitle')"
        class="padding-3"
        full-width>
          <v-tabs v-model="currentTab">
            <v-tab><v-icon class="mr-1">mdi-shield-account</v-icon>{{$t('roleAssignments')}}</v-tab>
            <v-tab><v-icon class="mr-1">mdi-account-group</v-icon>{{$t('users')}}</v-tab>
          </v-tabs>
          <v-container fluid>
            <v-window v-model="currentTab">
              <v-window-item>
                <UsersByRole
                class="mt-4"
                :claimsByUser="claimsByUser"
                @assign-user="assignUserToRole"
                @refresh-roles="getClaims()"/>
              </v-window-item>
              <v-window-item>
                <v-row>
                  <v-col cols="auto" :style="{ width: '1000px' }">
                    <v-data-table
                    :loading="usersLoading"
                    fixed-header
                    :height="tableHeight"
                    :items="allUsers"
                    :headers="userTableHeaders"
                    :items-per-page="25"
                    :footer-props="{
                      'items-per-page-options': [10, 25, 50, 100, -1]
                    }"
                    :hide-default-footer="allUsers.length < 10"
                    dense>
                      <template #item.status="{ item }">
                        <v-simple-checkbox color="success" :value="item.status === UserStatus.Active.value" @click="updateUserStatus(item)"/>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-container>
        </material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="assignRoleDialog" width="600px">
      <AssignUserToRole
      v-if="assignRoleDialog"
      :role="focusedRole"
      @close="closeDialogs()"
      @assign-user="assignUser"
      @refresh-users="refreshUsers()"
      :availableUsers="usersWithoutFocusedRole"/>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UserClaimStatus, UserStatus } from '@/utils/Enumerations.js'
export default {
  name: 'UserPermissions',

  components: {
    UsersByRole: () => import('@/components/settings-components/user-permissions/UsersByRole.vue'),
    AssignUserToRole: () => import('@/components/settings-components/user-permissions/AssignUserToRole.vue')
  },

  data: () => ({
    UserStatus,
    users: [],
    claimsByUser: [],
    assignRoleDialog: false,
    focusedRole: undefined,
    currentTab: 0
  }),

  computed: {
    ...mapGetters('user', ['userClaims', 'allUsers', 'activeUsers', 'usersLoading']),

    usersWithoutFocusedRole () {
      return this.activeUsers.filter(u => {
        const userWithClaims = this.claimsByUser.find(uc => uc.applicationUserId === u.applicationUserId)
        const claim = userWithClaims?.userClaims.find(c => c.systemClaim === this.focusedRole.value)
        return !claim || claim.value === UserClaimStatus.NotSet.value
      })
    },

    userTableHeaders () {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('preferredUsername'),
          value: 'preferredUsername'
        },
        {
          text: this.$t('active'),
          value: 'status',
          align: 'center'
        }
      ]
    },

    tableHeight () {
      return window.innerHeight > 700 ? 'calc(100vh - 487px)' : '256px'
    }
  },

  async created () {
    await this.getClaims()
  },

  methods: {
    ...mapActions('user', ['fetchAllUserClaims', 'getAllUsers', 'updateUserClaims', 'updateUserInfo']),
    async getClaims () {
      const claimsByUser = await this.fetchAllUserClaims()
      await this.refreshUsers()
      this.claimsByUser = this.activeUsers.map(u => ({
        applicationUserId: u.applicationUserId,
        userName: u.name,
        preferredUsername: u.preferredUsername,
        userClaims: claimsByUser.find(cbu => cbu.applicationUserId === u.applicationUserId)?.userClaims ?? []
      }))
    },

    closeDialogs () {
      this.assignRoleDialog = false
      this.focusedRole = undefined
    },

    assignUserToRole (role) {
      this.assignRoleDialog = true
      this.focusedRole = role
    },

    async assignUser (user) {
      await this.updateUserClaims({
        id: user.applicationUserId,
        claims: [
          { systemClaim: this.focusedRole.value, value: UserClaimStatus.Allow.value }
        ]
      })
      await this.getClaims()
      this.closeDialogs()
    },

    async refreshUsers () {
      this.users = await this.getAllUsers()
    },

    async updateUserStatus (user) {
      await this.updateUserInfo({
        applicationUserId: user.applicationUserId,
        name: user.name,
        objectId: user.objectId,
        preferredUsername: user.preferredUsername,
        status: user.status === UserStatus.Active.value ? UserStatus.Inactive.value : UserStatus.Active.value,
        person: user.person
      })
      this.refreshUsers()
      this.getClaims()
    }
  }
}
</script>
