import i18n from '../i18n'

export default {
  accountHeaders () {
    return ([
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: true,
        text: i18n.t('code'),
        value: 'code'
      },
      {
        sortable: true,
        text: i18n.t('exportCode'),
        value: 'exportCode'
      },
      {
        sortable: true,
        text: i18n.t('insuranceExpiryDate'),
        value: 'insuranceExpiryDate',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('tags'),
        value: 'accountTags',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('related'),
        value: 'related',
        align: 'center'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ])
  }
}

export function addressTableHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('streetAddressOne'),
      value: 'addressLine1'
    },
    {
      sortable: true,
      text: i18n.t('streetAddressTwo'),
      value: 'addressLine2'
    },
    {
      sortable: true,
      text: i18n.t('city'),
      value: 'city'
    },
    {
      sortable: true,
      text: i18n.t('zipCode'),
      value: 'postalCode'
    },
    {
      sortable: true,
      text: i18n.t('state'),
      value: 'state'
    },
    {
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ]
}

export function accountBulkImportPreviewHeaders () {
  return ([
    {
      sortable: true,
      text: i18n.t('name'),
      value: 'name'
    },
    {
      sortable: true,
      text: i18n.t('code'),
      value: 'code'
    },
    {
      sortable: true,
      text: i18n.t('exportCode'),
      value: 'exportCode'
    },
    {
      sortable: true,
      text: i18n.t('shippingAddress'),
      value: 'shipping'
    },
    {
      sortable: true,
      text: i18n.t('billingAddress'),
      value: 'billing'
    },
    {
      sortable: false,
      text: i18n.t('tags'),
      value: 'tags'
    }
  ])
}

export function accountPayableHeaders () {
  return ([
    {
      sortable: true,
      text: '',
      value: 'exportBatchId',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'paymentNote'
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activityName'
    },
    {
      sortable: true,
      text: i18n.t('entity'),
      value: 'businessEntityName'
    },
    {
      sortable: true,
      text: i18n.t('account'),
      value: 'accountName',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('issuedAt'),
      value: 'issuedAt',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('payDate'),
      value: 'payDate',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('createdBy'),
      value: 'createdByUser',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('createdAt'),
      value: 'createdAt',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    },
    {
      sortable: false,
      text: i18n.t('actions'),
      value: 'actions',
      align: 'center'
    }
  ])
}

export function accountPaymentRegisterHeaders () {
  return [
    {
      sortable: true,
      text: i18n.t('title'),
      value: 'note',
      align: 'left'
    },
    {
      sortable: true,
      text: i18n.t('activity'),
      value: 'activity',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glCode'),
      value: 'glCode',
      align: 'center'
    },
    {
      sortable: true,
      text: i18n.t('glOffset'),
      value: 'glOffset'
    },
    {
      sortable: true,
      text: i18n.t('amount'),
      value: 'amount',
      align: 'right'
    }
  ]
}

export function overweightThresholdHeaders (isCRUD = false) {
  var result = [{
    value: 'threshold',
    text: i18n.t('thresholdLb'),
    align: 'right'
  },
  {
    value: 'penaltyAmount',
    text: i18n.t('penalty'),
    align: 'right'
  }]

  if (isCRUD) {
    result.push(
      {
        value: 'actionLeft',
        text: '',
        align: 'left'
      },
      {
        value: 'actionRight',
        text: '',
        align: 'center'
      }
    )
  }

  return result
}
