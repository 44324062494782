<template>
  <v-card>
    <v-card-title class="primary">
      <span class="white--text headline">
        {{ $t('modifyAdvanceAmount') }}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper @submit="saveChanges">
        <v-container grid-list-sm>
          <v-row dense>
            <v-col cols="12">
              <MoneyTextField
                data-testid="advance-mod-amount"
                :label="$t('amount')"
                :allowNegative="true"
                @val-changed="advanceAmountEntered"
                :extraRules="['validAdvanceAmount']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="title font-weight-bold">{{this.$t('title')}}</span>
              <v-textarea
                v-model="modification.note"
                data-testid="advance-mod-note"
                outlined
                auto-grow
                counter
                maxlength="64"
                clearable
                dense
                color="black"
                name="input-7-4"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { MAX_ADVANCE_AMOUNT } from '@/utils/rules.js'
export default {
  name: 'AdvanceModForm',

  components: {
    MoneyTextField: () => import('@/components/helper/MoneyTextField.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    advanceId: {
      type: Number,
      default: -1,
      required: true
    }
  },

  data: () => ({
    modification: {
      modificationType: '',
      ModificationAmount: -1,
      note: ''
    }
  }),

  methods: {
    ...mapActions('advances', ['createAdvanceModification']),
    advanceAmountEntered (amount) { this.modification.ModificationAmount = amount },
    async saveChanges () {
      if (this.modification.ModificationAmount > MAX_ADVANCE_AMOUNT) {
        this.setSnackError(this.$t('invalidAdvanceAmount'))
      } else {
        const formattedModification = {
          advanceId: this.advanceId,
          modificationType: 0,
          ModificationAmount: this.modification.ModificationAmount,
          note: this.modification.note
        }
        await this.createAdvanceModification(formattedModification)
        this.$emit('advance-modified')
        this.$emit('close')
      }
    }
  }
}
</script>
