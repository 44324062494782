<template>
  <v-row class="ma-4" no-gutters>
    <v-col cols="12">
      <v-card outlined style="z-index: 1;">
        <v-card-title>
          <span class="subtitle-1">{{ $t('bundleTemplates') }}</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
          dense
          item-key="templateBundleId"
          :items.sync="propBundle.activityBuilders"
          :loading.sync="templatesLoading"
          :headers="bundleHeaders"
          :hide-default-footer="propBundle.activityBuilders?.length <= 10"
          :items-per-page="10"
          :footerProps="{ 'itemsPerPageOptions': [10, -1] }"
          >
            <template #item.activityStatus="{ item }">
              <Icon
              dataTestId="activity-status-icon"
              :icon="item.activityStatus === 1 ? 'mdi-cancel' : 'mdi-checkbox-marked-circle'"
              :iconColor="item.activityStatus === 1 ? 'black' : 'success'"
              :tooltipColor="item.activityStatus === 1 ? 'black' : 'success'"
              :tooltipText="item.activityStatus === 1 ? $t('inactive') : $t('active')"
              :small="false"
              />
              <Icon
              v-if="item.specialization === TemplateSpecialization.Penalty.value"
              icon="weight-alert"
              :tooltipText="$t('penalty')"
              :small="false"
              />
              <Icon
              v-if="item.tractLevelCapture"
              icon="mdi-crosshairs-gps"
              iconColor="black"
              :tooltipText="$t('tractLevelCapture')"
              :small="false"
              />
              <Icon
              v-if="isDepletion(item)"
              icon="mdi-wallet-bifold"
              :small="false"
              iconColor="black"
              :tooltipText="$t('depletion')"/>
            </template>
            <template #item.counterpartyName="{ item }">
              <span v-if="item.counterpartyName">
                <Icon
                iconColor="black"
                icon="mdi-account"
                :tooltipText="$t('account')"/>
                {{ item.counterpartyName }}
              </span>
              <span v-else-if="typeof item.runtimeField === 'number'">
                <Icon
                iconColor="black"
                icon="mdi-alpha-r-circle-outline"
                :tooltipText="$t('runtimeCounterparty')"/>
                {{ tractAccountEnumFor(item.runtimeField)?.name }}
              </span>
              <span v-else>
                {{$t('notAvailable')}}
              </span>
            </template>
            <template #item.category="{ item }">
              {{ accountingCategoryFromInt(item.category) }}
            </template>
            <template #item.costBehavior="{ item }">
              {{ typeFromInt(item.costBehavior) }}
            </template>
            <template #item.glCode="{ item }">
              {{ item.glCode }}
            </template>
            <template #item.glOffset="{ item }">
              {{ !item.glOffset ? "N/A" : item.glOffset }}
            </template>
            <template #item.payOn="{ item }">
              <span>{{ payOnFromInt(item.payOn) }}</span>
            </template>
            <template #item.payBy="{ item }">
              <span>{{ payByFromInt(item.payBy) }}</span>
            </template>
            <template #item.baseRate="{ item }">
              <span v-if="item.limitToBaseRate">
                <Icon
                  icon="mdi-lock"
                  iconColor="grey"
                  :tooltipText="$t('limitedToBaseRate')"
                  small
                />
              </span>
              <span>{{ isNonReceivablePenalty(item) ? $t('notApplicable') : formatMoney(item.baseRate) }} </span>
            </template>
            <template #item.modifier="{ item }">
              <span>{{ isNonReceivablePenalty(item) ? $t('notApplicable') : modifierFromInt(item.modifier) }}</span>
            </template>
            <template #item.actions="{ item }">
              <Icon
                icon="mdi-pencil"
                iconColor="success"
                dataTestId="bundle-edit-button"
                :tooltipText="$t('editCounterparty')"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                @icon-clicked="openEditBuilderDialog(item)"/>
              <Icon
                iconColor="error"
                icon="mdi-delete-forever"
                dataTestId="remove-template"
                :tooltipText="$t('deleteTemplate')"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                @icon-clicked="openDeleteTemplateDialog(item)"
              />
            </template>
          </v-data-table>
        </v-card-text>

      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card outlined style="border-radius: 0px 0px 4px 4px; z-index: 0;" class="mx-3 mt-n1">
        <v-card-title>
          <v-row no-gutters align="start">
            <v-col cols="auto">
              <span class="subtitle-1 mr-4">{{ $t('selectTemplates') }}</span>
            </v-col>
            <v-col>
              <v-select
              style="max-width: 200px; padding: 0px; margin: 0px; margin-bottom: -16px;"
              v-model="selectedCategory"
              :items="categories"
            />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            fixed-header
            height="352px"
            item-key="activityTemplateId"
            dense
            :items.sync="filteredActivities"
            :loading.sync="templatesLoading"
            :headers="templateHeaders"
            :hide-default-footer="filteredActivities?.length <= 10"
            :items-per-page="25"
            :footerProps="{ 'itemsPerPageOptions': [10, 25, 50, -1] }"
          >
            <template #item.activityStatus="{ item }">
              <Icon
              dataTestId="activity-status-icon"
              :icon="item.activityStatus === 1 ? 'mdi-cancel' : 'mdi-checkbox-marked-circle'"
              :iconColor="item.activityStatus === 1 ? 'black' : 'success'"
              :tooltipColor="item.activityStatus === 1 ? 'black' : 'success'"
              :tooltipText="item.activityStatus === 1 ? $t('inactive') : $t('active')"
              :small="false"
              />
              <Icon
              v-if="item.tractLevelCapture"
              icon="mdi-crosshairs-gps"
              iconColor="black"
              :tooltipText="$t('tractLevelCapture')"
              :small="false"
              />
              <Icon
              v-if="item.specialization === TemplateSpecialization.Depletion.value"
              icon="mdi-wallet-bifold"
              :small="false"
              iconColor="black"
              :tooltipText="$t('depletion')"/>
              <Icon
              v-if="item.specialization === TemplateSpecialization.Penalty.value"
              icon="weight-alert"
              :tooltipText="$t('penalty')"
              :small="false"
              />
            </template>
            <template #item.costType="{ item }">
              <span>{{ typeFromInt(item.costType) }}</span>
            </template>
            <template #item.payBy="{ item }">
              <span v-if="item.baseCost">{{ payByFromInt(item.baseCost.payBy) }}</span>
              <span v-else>{{ isNonReceivablePenalty ? PayBy.Load.name : $t('notAvailable')}}</span>
            </template>
            <template #item.category="{ item }">
              <span>{{ accountingCategoryFromInt(item.category) }}</span>
            </template>
            <template #item.glCode="{ item }">
              <span>{{ item.glCode }}</span>
            </template>
            <template #item.modifier="{ item }">
              <span>{{ isNonReceivablePenalty(item) ? $t('notApplicable') : modifierFromInt(item.modifier) }}</span>
            </template>
            <template #item.glOffset="{ item }">
              <span>{{
                !item.glOffset ? "N/A" : item.glOffset
              }}</span>
            </template>
            <template #item.payOn="{ item }">
              <span v-if="item.baseCost">{{ payOnFromInt(item.baseCost.payOn) }}</span>
              <span v-else>{{$t('notAvailable')}}</span>
            </template>
            <template #item.rate="{ item }">
              <span v-if="item.baseCost">{{ formatMoney(item.baseCost.rate) }}</span>
              <span v-else>{{$t('notAvailable')}}</span>
            </template>
            <template #item.actions="{ item }">
              <Icon
                iconColor="success"
                icon="mdi-plus"
                dataTestId="add-template"
                :small="false"
                :tooltipText="$t('addTemplate')"
                @icon-clicked="openAddTemplateDialog(item)"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)"
              />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { PayOn, PayBy, AccountingCategory, ActivityModifier, CostType, TemplateAccountingCategory, RuntimeCounterparty, UserClaims, TemplateSpecialization } from '@/utils/Enumerations'
import { mapGetters, mapActions } from 'vuex'
import ActivityTemplateHeaders from '@/headers/Activity'
import { formatMoney } from '@/utils/NumericMutations'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
export default {
  name: 'BundleBreakdown',

  props: {
    propBundle: {
      type: Object,
      required: true
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    TemplateSpecialization,
    PayBy,
    selectedPayables: [],
    selectedReceivables: [],
    selectedAccruals: [],
    showInactive: false,
    selectedCategory: 'Accounts Payable',
    UserClaims
  }),

  computed: {
    ...mapGetters('activity-templates', ['allActivityTemplates', 'templatesLoading']),
    templateHeaders () {
      if (TemplateAccountingCategory.toInt(this.selectedCategory) === 1) {
        return [...ActivityTemplateHeaders.activityTemplateHeaders().filter(header => header.text !== 'Type')]
      } else return [...ActivityTemplateHeaders.activityTemplateHeaders().filter(header => header.value !== 'related')]
    },
    bundleHeaders () {
      return ActivityTemplateHeaders.templateBundleHeaders()
    },
    categories () {
      return TemplateAccountingCategory.names
    },
    customTableCells () {
      return [
        {
          slotName: 'status',
          value: 'activityStatus'
        },
        {
          slotName: 'pay-by',
          value: 'payBy'
        },
        {
          slotName: 'type',
          value: 'costType'
        },
        {
          slotName: 'pay-on',
          value: 'payOn'
        },
        {
          slotName: 'category',
          value: 'category'
        },
        {
          slotName: 'modifier',
          value: 'modifier'
        },
        {
          slotName: 'gl-offset',
          value: 'glOffset'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'base-cost-rate',
          value: 'rate'
        }
      ]
    },

    filteredActivities () {
      return this.allActivityTemplates.filter(template =>
        template.category === TemplateAccountingCategory.toInt(this.selectedCategory) &&
        template.activityStatus === 0 &&
        template.specialization !== TemplateSpecialization.Transfer.value &&
        (template.specialization !== TemplateSpecialization.Depletion.value || !this.propBundle?.activityBuilders?.some(ab => ab.specialization === TemplateSpecialization.Depletion.value)))
    }
  },

  watch: {
    selectedPayables () {
      this.selected = [...this.selectedPayables, ...this.selectedReceivables, ...this.selectedAccruals]
    },

    selectedReceivables () {
      this.selected = [...this.selectedPayables, ...this.selectedReceivables, ...this.selectedAccruals]
    },

    selectedAccruals () {
      this.selected = [...this.selectedPayables, ...this.selectedReceivables, ...this.selectedAccruals]
    }
  },

  created () {
    this.selected = this.propBundle?.templates ? this.propBundle.templates : []
    this.templateBundleRequestObj = { ...this.propBundle }
  },

  methods: {
    ...mapActions('activity-templates', ['fetchActivityTemplates']),
    formatMoney,
    userAssignedClaim,
    categoryToInt: (x) => TemplateAccountingCategory.toInt(x),
    modifierFromInt: (x) => ActivityModifier.fromInt(x),
    typeFromInt: (x) => CostType.fromInt(x),

    async refreshActivityTemplates () {
      await this.fetchActivityTemplates()
    },

    payByFromInt (val) {
      return PayBy.fromInt(val)
    },

    payOnFromInt (val) {
      return PayOn.fromInt(val)
    },

    accountingCategoryFromInt (val) {
      return AccountingCategory.fromInt(val)
    },

    tractAccountEnumFor (runtimeField) {
      return RuntimeCounterparty.forInt(runtimeField)
    },

    setSelectedCategory (category) {
      this.selectedCategory.name = category
      this.selectedCategory.id = this.categoryToInt(category)
      this.$emit('category-selected', this.selectedCategory)
    },

    openAddTemplateDialog (template) {
      const requestObj = {
        templateBundleId: this.propBundle.templateBundleId,
        activityTemplateId: template.activityTemplateId,
        activityTemplateName: template.name,
        counterpartyId: null,
        category: template.category,
        specialization: template.specialization
      }
      this.$emit('add-template-to-bundle', requestObj)
    },

    openEditBuilderDialog (template) {
      this.$emit('edit-template-builder', template)
    },

    openDeleteTemplateDialog (template) {
      const requestObj = {
        templateBundleId: this.propBundle.templateBundleId,
        activityBuilderId: template.activityBuilderId
      }
      this.$emit('delete-template-from-bundle', requestObj)
    },

    isDepletion (item) {
      return this.allActivityTemplates
        .find(at => item.activityTemplateId === at.activityTemplateId)
        .specialization === TemplateSpecialization.Depletion.value
    },

    isNonReceivablePenalty (template) {
      return template.specialization === TemplateSpecialization.Penalty.value && template.category !== AccountingCategory.Receivable.value
    }
  }
}
</script>
