<template>
  <div>
    <v-row class="mt-2 mb-n4">
      <v-col cols="12">
        <span class="title">
          {{ title }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-n12">
        <RateVerificationTable
        :items="aggregatedData"
        :groupingKeys="groupingKeys"
        expandToContract
        @refresh-batch="$emit('refresh-batch')"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rateVerificationGroupingKeys } from '@/headers/Cycles.js'
import { TemplateSpecialization } from '@/utils/Enumerations.js'
export default {
  name: 'RateVerificationSBP',

  components: {
    RateVerificationTable: () => import('./RateVerificationTable.vue')
  },

  props: {
    payees: {
      type: Array,
      default: () => []
    },
    singlePayee: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    title () {
      return this.singlePayee ? this.$t('counterpartyColon', { counterparty: this.payees[0].payeeName }) : this.$t('allPayees')
    },

    groupingKeys () {
      return rateVerificationGroupingKeys(!this.singlePayee)
    },

    aggregatedData () {
      if (this.payees.length === 0) return []

      let index = 0
      const rateKeyMap = new Map()
      this.payees.forEach(payee => payee.contracts.forEach(contract => contract.loadSummaries.forEach(loadSummary => {
        const ticket = { ticketId: loadSummary.ticketId, postBatchId: loadSummary.postBatchId }

        loadSummary.paymentDetails.filter(pd => pd.activityTemplateSpecialization !== TemplateSpecialization.Penalty.value).forEach(paymentDetail => {
          const rateKey = [paymentDetail.activity, loadSummary.product, paymentDetail.computedAtRate, payee.payeeName].join(',')

          const rateKeyMapEntry = rateKeyMap.get(rateKey) ?? {
            activity: paymentDetail.activity,
            product: loadSummary.product,
            rate: paymentDetail.computedAtRate,
            counterparty: payee.payeeName,
            contracts: new Map(),
            index: index++
          }

          const entryContract = rateKeyMapEntry.contracts.get(contract.contractId) ?? {
            contractId: contract.contractId,
            accountName: contract.accountName,
            destinationName: contract.destinationName,
            settingName: contract.settingName,
            tractName: contract.tractName,
            type: contract.type,
            fromAccountName: contract.fromAccountName,
            tickets: []
          }

          if (!entryContract.tickets.some(t => t.ticketId === ticket.ticketId)) entryContract.tickets.push(ticket)

          rateKeyMapEntry.contracts.set(contract.contractId, entryContract)
          rateKeyMap.set(rateKey, rateKeyMapEntry)
        })
      })))
      return Array.from(rateKeyMap.values())
    }
  }
}
</script>
