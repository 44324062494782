<template>
  <v-container fluid>
    <ShortcutWrapper
    :propShortcuts="this.shortcuts.shortcuts"
    :propInitiatingKey="this.shortcuts.initiatingKey"
    @shortcut="handleShortcut">
      <v-container fluid>
        <v-tabs
          class="grey-tabs"
          background-color="transparent"
          v-model="currentTab"
          data-testid="settings-tabs">
          <v-tab class="setup-tab"><v-icon class="mr-1">mdi-wrench-cog</v-icon>{{ $t('setup') }}</v-tab>
          <v-tab class="active-devices-tab"><v-icon class="mr-1">mdi-tablet-cellphone</v-icon>{{ $t('devices') }}</v-tab>
          <v-tab class="company-information-tab"><v-icon class="mr-1">mdi-office-building-cog</v-icon>{{ $t('company') }}</v-tab>
          <v-tab class="company-information-tab"><v-icon class="mr-1">mdi-account-cog</v-icon>{{ $t('profile') }}</v-tab>
          <v-tab v-if="userAssignedClaim(UserClaims.GlobalAdmin)" class="company-information-tab"><v-icon class="mr-1">mdi-account-group</v-icon>{{ $t('users') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab" class="tertiary" touchless>
          <v-tab-item class="tertiary"><TableSetup/></v-tab-item>
          <v-tab-item class="tertiary"><DeviceRegistrations/></v-tab-item>
          <v-tab-item class="tertiary"><CompanyConfig/></v-tab-item>
          <v-tab-item class="tertiary"><UserInfo v-if="currentTab===3"/></v-tab-item>
          <v-tab-item v-if="userAssignedClaim(UserClaims.GlobalAdmin)" class="tertiary"><UserPermissions/></v-tab-item>
        </v-tabs-items>
      </v-container>
    </ShortcutWrapper>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { settingsNavigationShortcuts } from '../utils/Shortcuts'
import { userAssignedClaim } from '../utils/ClaimUtility'
import { UserClaims } from '@/utils/Enumerations.js'

export default {
  name: 'Settings',

  components: {
    DeviceRegistrations: () => import('@/components/settings-components/DeviceRegistrations.vue'),
    TableSetup: () => import('@/components/settings-components/Setup'),
    CompanyConfig: () => import('../components/settings-components/company-config/CompanyConfig.vue'),
    UserInfo: () => import('@/components/settings-components/UserInfo.vue'),
    UserPermissions: () => import('@/components/settings-components/user-permissions/UserPermissions.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')
  },

  data: () => ({
    currentTab: 0,
    shortcuts: settingsNavigationShortcuts,
    UserClaims
  }),

  computed: {
    ...mapGetters('global', ['settingName'])
  },

  watch: {
    settingName () {
      if (this.settingName === 'imports' && !userAssignedClaim(UserClaims.ContractManager, UserClaims.TemplateManager, UserClaims.InventoryManager, UserClaims.GlobalAdmin, UserClaims.AccountManager)) {
        this.setSettingName(undefined)
      } else if (this.settingName === 'downloads' && !userAssignedClaim(UserClaims.ContractManager)) {
        this.setSettingName(undefined)
      } else if (this.settingName === 'devices') {
        this.currentTab = 1
        this.setSettingName(undefined)
      } else if (this.settingName === 'companyinfo') {
        this.currentTab = 2
        this.setSettingName(undefined)
      } else if (this.settingName) {
        this.currentTab = 0
      }
    }
  },

  created () {
    if (this.settingName === 'devices') {
      this.currentTab = 1
      this.setSettingName(undefined)
    } else if (this.settingName === 'companyinfo') {
      this.currentTab = 2
      this.setSettingName(undefined)
    }
  },

  methods: {
    ...mapMutations('global', ['setSettingName']),
    userAssignedClaim,

    handleShortcut (id) {
      this.setSettingName(this.shortcuts.shortcuts.find(s => s.id === id).route)
    }
  }
}
</script>
