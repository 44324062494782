import { ContractMode } from '@/utils/Enumerations'
// Deprecated - Legacy colors
export const chartColors = [
  '#D15F27',
  '#D68D5F',
  '#B24008',
  '#FF8D55',
  '#A83600',
  '#E9BE9D',
  '#D6642C',
  '#E5733B',
  '#C7551D'
]

export const byproductColors = [
  '#111111',
  '#555555',
  '#333333',
  '#aaaaaa',
  '#444444',
  '#cccccc'
]

export const chartColorFor = (index) => chartColors[index % chartColors.length]
export const byproductColorFor = (index) => byproductColors[(index) % 256 % byproductColors.length]

/* New chart colors
If you want to view the source colors in their editor, copy and paste the link, ctrl+click will not work */

// Source: https://gka.github.io/palettes/#/35|s|f9f6df,ff8d55,d15f27,980000,191410|ffffe0,ff005e,93003a|1|1
export const logChartColors = ['#f9f6df', '#fcecd1', '#fee1c3', '#ffd7b5', '#ffcda8', '#ffc39b', '#feb98f', '#fcb082', '#faa677', '#f79d6b', '#f49360', '#ef8a56', '#eb814c', '#e57842', '#e07039', '#d96830', '#d26028', '#cb5823', '#c4501f', '#bc491c', '#b44218', '#ab3c16', '#a23614', '#983112', '#8e2c11', '#832810', '#79250f', '#6e220f', '#63200f', '#571e0f', '#4b1c0e', '#3f1a0f', '#33180f', '#26160f', '#191410']
// Source: https://gka.github.io/palettes/#/65|d|191410,980000,d15f27,ff8d55,f6f0c6|f6f0c6,aac6e9,2660af,002885,000014|1|1
export const logChartColorsExtended = ['#191410', '#27160f', '#34180f', '#411a0f', '#4d1c0e', '#591e0f', '#65200f', '#71230f', '#7c260f', '#872a10', '#922e11', '#9c3313', '#a63815', '#af3f17', '#b8451a', '#c04d1d', '#c85521', '#cf5d26', '#d7652d', '#de6d36', '#e4763e', '#e97f48', '#ee8951', '#f2925b', '#f69c66', '#f8a671', '#fbb07c', '#fcbb87', '#fcc593', '#fcd09f', '#fbdaac', '#f9e5b9', '#f6f0c6', '#e9e8cc', '#dddfd0', '#d2d7d2', '#c7cfd4', '#bbc7d4', '#b1bed4', '#a6b6d3', '#9baed1', '#91a6cf', '#869ecd', '#7c96ca', '#718ec7', '#6787c4', '#5c7fc0', '#5177bc', '#4570b8', '#3869b4', '#2961b0', '#2359aa', '#2051a2', '#1d499a', '#1b4291', '#183b87', '#16347c', '#142d71', '#132765', '#122158', '#101c4b', '#0f163d', '#0d1130', '#0b0922', '#000014']
// Source: https://gka.github.io/palettes/#/35|s|dfdfdf,000000|dfdfdf,8eadff|1|0
export const byproductChartColors = ['#dfdfdf', '#d8d8d8', '#d0d0d0', '#c9c9c9', '#c2c2c2', '#bbbbbb', '#b4b4b4', '#adadad', '#a5a5a5', '#9f9f9f', '#989898', '#919191', '#8a8a8a', '#838383', '#7d7d7d', '#767676', '#6f6f6f', '#696969', '#636363', '#5c5c5c', '#565656', '#505050', '#4a4a4a', '#444444', '#3e3e3e', '#383838', '#323232', '#2d2d2d', '#272727', '#222222', '#1c1c1c', '#171717', '#111111', '#0a0a0a', '#000000']
// Source: https://gka.github.io/palettes/#/66|d|dfdfdf,000000|000000,1d499a,aac6e9,d7dde4|1|0
export const byproductChartColorsExtended = ['#dfdfdf', '#d7d7d7', '#d0d0d0', '#c8c8c8', '#c1c1c1', '#bababa', '#b2b2b2', '#ababab', '#a4a4a4', '#9d9d9d', '#969696', '#8e8e8e', '#888888', '#818181', '#7a7a7a', '#737373', '#6c6c6c', '#666666', '#5f5f5f', '#595959', '#525252', '#4c4c4c', '#464646', '#404040', '#3a3a3a', '#343434', '#2e2e2e', '#282828', '#222222', '#1d1d1d', '#181818', '#121212', '#0a0a0a', '#040a15', '#071125', '#091730', '#0b1c3b', '#0d2146', '#0f2651', '#112c5c', '#133167', '#163673', '#183c7f', '#1a428b', '#1c4897', '#234e9d', '#2b55a2', '#335ca6', '#3b64ab', '#436baf', '#4b72b4', '#5379b8', '#5b80bd', '#6387c1', '#6b8ec6', '#7395ca', '#7b9dcf', '#83a4d3', '#8cabd8', '#94b2dd', '#9cbae1', '#a5c1e6', '#afc8e8', '#bccfe7', '#cad6e5', '#d7dde4']

const EXTENDED_COLORS_CUTOFF = 16
const EXTENDED_COLORS_CUTOFF_BYPRODUCT = 8

// TODO MK: Refactor this, this could be 1 function
export const getChartColor = (index, dataSize, contractMode) => {
  let colorSet = []
  switch (contractMode) {
    case ContractMode.Byproducts.value: colorSet = dataSize > EXTENDED_COLORS_CUTOFF_BYPRODUCT ? byproductChartColorsExtended : byproductChartColors; break
    case ContractMode.Logs.value:
    default: colorSet = dataSize > EXTENDED_COLORS_CUTOFF ? logChartColorsExtended : logChartColors; break
  }
  const correctedIndex = index % colorSet.length
  const correctedDataSize = dataSize > colorSet.length ? colorSet.length : dataSize
  const result = Math.floor(((colorSet.length - 1) / (correctedDataSize + 1)) * (correctedIndex + 1))
  return colorSet[result]
}

export const getLogChartColors = (dataSize) => {
  return Array(dataSize).fill(null).map((_, index) => logChartColorFor(index, dataSize))
}

export const logChartColorFor = (index, dataSize) => {
  return getChartColor(index, dataSize, ContractMode.Logs.value)
}

export const getByproductChartColors = (dataSize) => {
  return Array(dataSize).fill(null).map((_, index) => byproductChartColorFor(index, dataSize))
}

export const byproductChartColorFor = (index, dataSize) => {
  return getChartColor(index, dataSize, ContractMode.Byproducts.value)
}

export const chartColorForContractMode = (index, dataSize, contractModeValue) => {
  switch (contractModeValue) {
    case ContractMode.Byproducts.value: return byproductChartColorFor(index, dataSize)
    case ContractMode.Logs.value:
    default: return logChartColorFor(index, dataSize)
  }
}
