<template>
  <v-card>
    <v-dialog
    v-model="baseCostWarningDialog"
    persistent
    width="400">
      <DetailCostWarning v-if="baseCostWarningDialog"
      :activityCost="activity.baseCost.rate"
      :detailCost="lowestRate"
      :contract="contract"
      @cancel="baseCostWarningDialog = false"
      @create-detail="autoPopulate(true)"
      />
    </v-dialog>
    <v-card-title class="headline white--text primary">
      <span>
        {{$t('autoPopulateDialogTitle', {name: activity.name})}}
      </span>
      <v-spacer/>
      <Icon
        dataTestId="close-autopopulate-form"
        iconColor="white"
        icon="mdi-close"
        :small="false"
        :large="true"
        :tooltipText="$t('close')"
        @icon-clicked="$emit('cancel')"
      />
    </v-card-title>
    <v-card-text class="mt-5">
      <p>{{$t('autoPopulateDialogText')}}
      </p>
      <p class="subtitle-2">{{$t('includedProducts')}}</p>
      <table style="border-spacing: 20px 5px;" class="ml-n5 mt-n4">
        <tr v-for="tc in autopopulateItems" :key="tc.productId">
          <td>
            {{tc.product}}
          </td>
          <td>
            {{formatMoney(tc.rate)}}
          </td>
          <td>
            <span class="error--text" v-if="tc.baseRateWarning">
              (rate below activity base rate)
            </span>
          </td>
        </tr>
      </table>
    </v-card-text>
    <v-card-actions>
      <p>* rate inherited from template details</p>
      <v-spacer/>
      <v-btn @click="autoPopulate" color="primary" class="mb-2">{{ $t('autoPopulate') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { autoPopulate } from './SynchronusAutoPopulate.js'
import { formatMoney } from '@/utils/NumericMutations.js'
export default {
  name: 'AutoDetailPopulate',

  props: {
    activity: Object,
    tractContents: Array,
    contract: Object
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    DetailCostWarning: () => import('@/components/activity/activity-detail/DetailCostWarning.vue')
  },

  data: () => ({
    detailsPopulated: 0,
    baseCostWarningDialog: false
  }),

  watch: {
    detailsPopulated (val) {
      if (val === this.tractContents.length) {
        this.setSnack(this.$t('autopopulateSuccess', { amount: this.templateDetails.length, name: this.activity.activityTemplate.name }))
        this.$emit('populated')
      }
    }
  },

  computed: {
    ...mapGetters('product', ['allProducts']),
    templateDetails () {
      return this.activity.activityTemplate.details
    },

    lowestRate () {
      return Math.min(
        ...this.autopopulateItems
          .filter(t => t.baseRateWarning)
          .map(tc => tc.rate)
      )
    },

    autopopulateItems () {
      return this.tractContents.reduce((items, tc) => {
        const item = {
          product: tc.product,
          productId: tc.productId,
          rate: this.activity.baseCost.rate
        }

        const detail = this.templateDetails.find(d => d.productId === item.productId)
        if (detail) {
          item.rate = detail.rate
          item.product = item.product + '*'
          item.baseRateWarning = this.activity.baseCost.rate > detail.rate
        }

        items.push(item)
        return items
      }, [])
    }
  },

  methods: {
    ...mapActions('activity-detail', ['createActivityDetail']),
    formatMoney,

    async autoPopulate (confirmed) {
      const hasBaseCostWarning = this.autopopulateItems.find(i => i.baseRateWarning === true)
      if (confirmed !== true && hasBaseCostWarning) {
        this.baseCostWarningDialog = true
        return
      }

      const result = await autoPopulate(this.autopopulateItems, this.activity.activityId, this.contract?.contractId)
      this.detailsPopulated += result.successes
      if (result.failures !== 0) {
        this.setSnackError(`There was an error autopopulating ${result.failures} activity details.`)
        this.$emit('error')
      }
    }
  }
}
</script>
