<template>
  <ShortcutWrapper
  :nShortcutRequiredClaim="UserClaims.ContractManager"
  @n-shortcut="mutate(null, 'create')">
    <v-row dense data-testid="tract-type-table">
      <v-col cols="12">

        <Dialog
        :stateId="dialogId"
        maxWidth="900px"
        @click:outside="close"
        @dialog-closing="close"
        @close="close">
          <TractTypeForm
          :propTractType="focusedTractType"
          @close="close"
          @tract-type-mutated="refreshTractTypes" />
        </Dialog>

        <v-card flat style="max-width: 1000px;">

          <v-card-title>
            <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            clearable
            data-testid="tract-type-search"
            color="primary"
            :label="$t('searchTractTypes')" />
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-switch
                color="primary"
                small
                class="ml-5"
                v-model="dense"
                v-on="on">
              </v-switch>
              </template>
              <span class="white--text subtitle-1">{{
                $t("denseView")
              }}</span>
            </v-tooltip>
            <v-btn
            icon
            color="primary"
            @click="refreshTractTypes">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
            rounded
            icon
            color="primary"
            data-testid="tract-type-create-button"
            @click="mutate(null, 'create')"
            :disabled="!userAssignedClaim(UserClaims.ContractManager)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-data-table
            :items="allTractTypes"
            :headers="tractTypeHeaders"
            :search="search"
            :dense="dense"
            :loading="loading">
              <template #item.status="{ item }">
                <Icon v-if="item.depletes" icon="mdi-wallet-bifold" :tooltipText="$t('depletes')" iconColor="black" />
              </template>
              <template #item.name="{ item }">
                <span>
                  {{ item.name }}
                </span>
              </template>
              <template #item.collectPieceInfo="{ item }">
                <v-icon v-if="item.collectPieceInfo" color="success">mdi-checkbox-marked-circle</v-icon>
              </template>
              <template #item.category="{ item }">
                {{ categoryFromInt(item.category) }}
              </template>
              <template #item.actions="{ item }">
                <v-icon
                small
                class="mr-2"
                color="success"
                data-testid="tract-type-edit-button"
                @click="mutate(item, 'edit')"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)">
                  mdi-pencil
                </v-icon>
                <v-icon
                small
                color="error"
                data-testid="tract-type-delete-button"
                @click="mutate(item, 'delete')"
                :disabled="!userAssignedClaim(UserClaims.ContractManager)">
                  mdi-delete-forever
                </v-icon>
              </template>
            </v-data-table>
            <v-dialog width="400px" v-model="confirmDeleteDialog">
              <ConfirmDelete
              v-if="confirmDeleteDialog"
              :title="confirmDeleteDialogTitle"
              @delete="performDelete"
              @cancel-delete="close" />
            </v-dialog>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </ShortcutWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TractTypeCategory, UserClaims } from '@/utils/Enumerations.js'
import TractTypeHeaders from '@/headers/TractType'
import { userAssignedClaim } from '@/utils/ClaimUtility'
import { uniqueDialogId } from '@/utils/componentHelpers'
import fieldRules from '@/utils/rules.js'

export default {
  name: 'TractTypeSettings',

  components: {
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    TractTypeForm: () => import('@/components/settings-components/tract-settings/tract-type/TractTypeForm.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  created () {
    this.refreshTractTypes()
  },

  computed: {
    ...mapGetters('tract-type', ['allTractTypes']),

    tractTypeHeaders () {
      return TractTypeHeaders.tractTypeHeaders()
    },

    editing () {
      return this.focusedTractType?.tractTypeId
    },

    formButtonText () {
      return this.editing ? this.$t('saveChanges') : this.$t('create')
    },

    confirmDeleteDialogTitle () {
      return this.$t('tractType')
    }
  },

  data: () => ({
    confirmDeleteDialog: false,
    dense: true,
    loading: false,
    search: '',
    focusedTractType: undefined,
    dialogId: uniqueDialogId('tract-type-settings-dialog'),
    rules: fieldRules.rules,
    UserClaims
  }),

  methods: {
    ...mapActions('tract-type', ['fetchTractTypes', 'deleteTractType']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    userAssignedClaim,

    categoryFromInt: (x) => TractTypeCategory.fromInt(x),

    refreshTractTypes () {
      this.close()
      this.loading = true
      this.fetchTractTypes()
        .finally(() => {
          this.loading = false
        })
    },

    mutate (tractType, mutationType) {
      this.focusedTractType = JSON.parse(JSON.stringify(tractType))

      switch (mutationType) {
        case 'delete':
          this.confirmDeleteDialog = true
          break
        case 'edit':
          this.openOrUpdateDialog(this.dialogId)
          break
        case 'create':
          this.openOrUpdateDialog(this.dialogId)
          break
      }
    },

    close () {
      this.confirmDeleteDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)
      this.focusedTractType = undefined
    },

    async performDelete () {
      await this.deleteTractType(this.focusedTractType.tractTypeId)
      this.refreshTractTypes()
      this.close()
    }
  }
}
</script>
