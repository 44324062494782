<template>
  <v-card>
    <v-card-title
    :class="`headline white--text ${!isByproduct ? 'primary' : ''}`"
    :style="isByproduct ? 'background-color: #363636' : ''">
      <span>
        {{ $t('correctionsFor', { name })}}
      </span>
      <v-spacer/>
      <span class="mr-3">{{ totalCorrected }}</span>
      <BaseDialogActions hideRefresh />
    </v-card-title>
    <v-card-text class="mt-6">
      <v-data-table
        group-by="ticketNumber"
        :headers="headers"
        :items="mappedItems"
        dense
        sort-by="activityBundle"
      >
        <template #group.header="{ headers, isOpen, group, toggle }">
          <th :colspan="headers.length">
              <Icon
              :icon="isOpen ? 'mdi-minus' : 'mdi-plus'"
              @icon-clicked="toggle()"/>
              <span class="subtitle-1">
                {{ `${$t('ticketNumber')}${group}` }}
              </span>
          </th>
        </template>
        <template #item.activityBundle="{item}">
          <Icon
          dataTestId="payment-register-corrections"
          margin="mt-n1 mr-1"
          icon="mdi-auto-fix"
          small
          :iconColor="item.correctionSide === 0 ? 'error' : 'success'"
          :tooltipColor="item.correctionSide === 0 ? 'error' : 'success'"
          :tooltipText="getCorrectionTooltipText(item)"
          />
          <span>
            {{item.activity}}
          </span>
        </template>
        <template #body.append>
          <tr class="font-weight-bold">
            <td class="text-left">{{ $t('total') }}</td>
            <td v-for="(_, i) in Array.from({ length: headers.length - 3 })" :key="`${name}-${i}`"/>
            <td class="text-right">{{totalCorrected}}</td>
          </tr>
        </template>
        <template #item.netWeight="{item}">
          {{ tonStringFromPounds(item.netWeight) }}
        </template>
        <template #item.amount="{item}">
          {{ formatMoney(item.amount) }}
        </template>
        <template #item.grossAmount="{item}">
          {{ formatMoney(item.grossAmount) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations'
import { CorrectionTypes, CorrectionSide } from '@/utils/Enumerations.js'
import { settlementCorrectionDialogHeaders } from '@/headers/Cycles.js'

export default {
  name: 'SettlementsCorrectionsDialog',

  props: {
    corrections: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: false,
      default: false
    },
    includeGross: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  data: () => ({
    dialog: false
  }),

  computed: {
    totalCorrected () {
      return formatMoney(this.corrections.reduce((total, curr) => total + curr.amount, 0))
    },
    headers () {
      return settlementCorrectionDialogHeaders({
        isByproduct: this.isByproduct,
        includeGross: this.includeGross
      })
    },
    customCells () {
      return [
        {
          slotName: 'activity',
          value: 'activity'
        }
      ]
    },
    mappedItems () {
      return this.corrections.map(c => ({
        ...c,
        activityBundle: {
          activity: c.activity,
          correctionSide: c.correctionSide
        }
      }))
    }
  },

  methods: {
    formatMoney,
    tonStringFromPounds,
    close () {
      this.dialog = false
    },

    getCorrectionTooltipText ({ correctionType, correctionSide }) {
      return `${CorrectionTypes.find(ct => ct.value === correctionType).name}
        ======
        ${CorrectionSide.find(cs => cs.value === correctionSide).name}`
    }
  }
}
</script>
