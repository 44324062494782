<template>
  <v-card>
    <v-card-title :class="contractClass">
      <Icon
      :icon="headerIcon.icon"
      :tooltipText="$t(headerIcon.tooltip)"
      iconColor="white"
      :small="false"
      margin="mr-2"
      />
      <span>
        {{$t('activitiesForPayeeTitle', { payeeName: counterpartyName })}}
      </span>
      <v-spacer></v-spacer>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6">
            <v-card outlined class="fill-height">
              <v-card-title>
                <span class="subtitle-1">{{$t('contractInformation')}}</span>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="6" v-for="(ci, i) in contractIcons" :key="i">
                    <Icon
                      :icon="ci.icon"
                      :tooltipText="$t(ci.tooltip)"
                      iconColor="grey"
                    />
                    <span class="ml-2">{{ci.value(contractInfo)}}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card outlined class="fill-height">
              <v-card-title>
                <span class="subtitle-1">{{$t('totalsForEntity', { entity: counterpartyName })}}</span>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="6" v-for="(tt, i) in totalsTable" :key="i">
                    <v-row>
                      <v-col cols="6" xl="4">
                        <span>{{$t(tt.label)}}: </span>
                      </v-col>
                      <v-col cols="auto">
                        <span>{{tt.value ?? $t('notAvailable')}}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="py-0 my-n2">
            <v-switch
            v-model="ticketSummaries"
            color="primary"
            data-testid="ticket-summary-switch"
            :label="$t('showTicketSummaries')"
          ></v-switch>
          </v-col>
          <v-col cols="12">
            <v-expansion-panels popout v-model="openPanels" multiple>
              <v-expansion-panel v-for="(activity) in paymentRollup" :key="activity.activityId">
                <v-expansion-panel-header :style="{ height: 'auto', paddingTop: '8px', paddingBottom: '8px' }">
                  <v-row dense align="center">
                    <v-col cols="12" :md="isFullscreen ? 9 : 8">
                      <Icon v-if="showPenaltyIcon(activity)" icon="weight-alert" :small="false" :tooltipText="$t('penalty')"/>
                      {{activity.activityName}}
                    </v-col>
                    <v-col cols="12" :md="isFullscreen ? 3 : 4">
                      <table style="border-collapse: separate; table-layout: fixed; width: 100%; min-width: 180px" class="body-2">
                        <tr>
                          <td style="width: 100px;">{{$t('payout')}}: </td>
                          <td class="text-right">
                            {{formatMoney(activity.payout ?? activity.amount)}}
                            {{getRecoveredText(activity)}}
                          </td>
                        </tr>
                        <tr>
                          <td>{{$t('avgRate')}}: </td>
                          <td class="text-right">
                            <Icon
                            v-if="splitByActivity.get(activity.activityName)?.length > 0"
                            iconColor="primary"
                            icon="mdi-percent-circle-outline"
                            :small="false"
                            :tooltipText="$t('reflectsOwnershipPercentage', { x: ownershipPercentageValue(splitByActivity.get(activity.activityName)) })"/>
                            {{ getRateText(activity.averageRate, activity.payBy, activity.modifier) }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{$t('unit')}}: </td>
                          <td class="text-right">
                            {{getUnitText(activity)}}
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                  :items="activity.productSummaries"
                  :headers="productHeaders(activity)"
                  dense
                  :hide-default-footer="activity.productSummaries.length < 10"
                  class="mt-2"
                  >
                    <template #item.payOnTons="{item}">
                      <span>{{getPayOnTonsForProductSummary(item, activity)}}</span>
                    </template>
                    <template #item.averageRate="{item}">
                      {{ getRateText(item.rate, activity.payBy, activity.modifier) }}
                      <span v-if="splitByActivity.get(activity.activityName)?.length > 0">
                       x {{ ownershipPercentageValue(splitByActivity.get(activity.activityName)) }}
                      </span>
                    </template>
                    <template #item.penaltyAmount="{item}">
                      <span>{{formatMoney((item.rate * item.netTons) - item.payout)}}</span>
                    </template>
                    <template #item.payout="{item}">
                      <span>{{formatMoney(item.payout)}}</span>
                    </template>
                    <template #item.recovered="{item}">
                      <span>{{formatMoney(item.grossPayout - item.payout)}}</span>
                    </template>
                    <template #item.grossTons="{item}">
                      <span>{{item.grossTons}}t</span>
                    </template>
                    <template #item.netTons="{item}">
                      <span>{{item.netTons}}t</span>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" v-if="ticketSummaries">
            <v-card outlined>
              <v-card-title>
                <span class="subtitle-1">{{$t('ticketSummaries')}}</span>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :items="loadSummariesForTable"
                  :headers="loadSummaryHeaders"
                  :hide-default-footer="loadSummariesForTable.length < 10"
                  dense>
              <template #item.actions="{item}">
                <Icon
                icon="mdi-briefcase"
                :tooltipText="$t('unpostTicket')"
                iconColor="error"
                @icon-clicked="showUnpostDialog(item)"/>
              </template>
              </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="showUnpostTicketsDialog" max-width="400px">
      <ConfirmDialog
      :title="$t('unpostTicket')"
      :body="$t('unpostTicketBodySBP')"
      :confirmText="$t('unpostTicket')"
      @cancel="closeUnpostDialog"
      @confirm="unpostFocusedTicket"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { settlementProductHeaders, contractTicketHeaders } from '@/headers/Cycles.js'
import { PayOn, PayBy, ActivityModifier, ContractType } from '@/utils/Enumerations.js'
import { contractIconsFor } from '../ContractIcons'
import { chunk } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { formatMoney, numberWithCommas, tonStringFromPounds, formatPercentage } from '@/utils/NumericMutations'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
import { TemplateSpecialization } from '@/utils/Enumerations'
export default {
  name: 'SettlementActivityBreakdown',

  props: {
    propPayee: {
      type: Object,
      required: true
    },
    propContractInfo: {
      type: Object,
      required: true
    },
    isReceivable: {
      type: Boolean,
      required: false
    }
  },

  components: {
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    ticketSummaries: false,
    activityModifier: ActivityModifier,
    showUnpostTicketsDialog: false,
    focusedTicketForUnpost: undefined,
    loadSummariesForTable: undefined,
    splitByActivity: new Map(),
    openPanels: []
  }),

  watch: {
    propContractInfo: {
      handler (_) {
        this.calculateLoadSummaries()
      },
      deep: true
    }
  },

  created () {
    this.calculateLoadSummaries()
    this.initSplitByActivityMap()
    this.openPanels = this.paymentRollup.length === 1 ? [0] : []
  },

  computed: {
    ...mapGetters('dialog', ['topDialogState']),
    ...mapGetters('settlements', ['currentSubModuleKey', 'dateConfiguration']),
    isTransfer () { return this.propContractInfo.type === ContractType.Transfer.value },
    isByproduct () { return this.propContractInfo.type === ContractType.ByproductSale.value || this.propContractInfo.type === ContractType.ByproductPurchase.value },

    contractClass () {
      return colorClassForContractType(this.propContractInfo.type)
    },

    loadSummaryHeaders () {
      const headers = contractTicketHeaders()
      return headers
        .filter(h => (!this.isTransfer && !this.isReceivable) || h.value !== 'grossAmount')
    },

    isFullscreen () {
      return this.topDialogState?.fullscreen ?? false
    },

    payeeHasPayout () {
      return this.propPayee.payout !== undefined && this.propPayee.payout !== null
    },

    loadSummaries () {
      return (this.payeeHasPayout) ? this.propPayee.loadSummaries : this.propContractInfo.loadSummaries
    },

    totals () {
      return (this.isReceivable)
        ? {
          payout: this.propContractInfo.amount,
          loads: this.propContractInfo.loads,
          grossTons: this.propContractInfo.grossTons,
          netTons: this.propContractInfo.netTons,
          recovered: 0
        } : {
          payout: this.payee.payout,
          loads: this.contractInfo.loads,
          grossTons: this.contractInfo.grossTons,
          netTons: this.contractInfo.netTons,
          recovered: (typeof this.payee.grossPayout === 'number') ? this.payee.grossPayout - this.payee.payout : 0
        }
    },

    grossTons () {
      return (this.isReceivable) ? this.propPayee.grossTons : this.contractInfo.grossTons
    },

    paymentRollup () {
      return (this.payeeHasPayout) ? this.propPayee.paymentRollup : this.propContractInfo.paymentRollup
    },

    contractInfo () {
      if (this.propContractInfo.loads) {
        return this.propContractInfo
      } else {
        const contractInfo = { ...this.propContractInfo }
        contractInfo.loads = this.propContractInfo.loadSummaries.length
        return contractInfo
      }
    },

    payee () {
      if ((this.propPayee.payout !== undefined && this.propPayee.payout !== null) || this.isReceivable) {
        return this.propPayee
      } else {
        const payee = { ...this.propContractInfo }
        payee.payeeName = this.propPayee.payeeName
        payee.payout = this.propContractInfo.amount
        payee.grossPayout = this.propContractInfo.grossAmount
        return payee
      }
    },

    counterpartyName () {
      return this.propPayee?.counterpartyName ?? this.propPayee?.payeeName
    },

    contractIcons () {
      return this.contractIconsFor(this.contractInfo.type, 'contract', (this.isReceivable) ? {
        destination: {
          value: c => c.destinationAccountName
        },
        fromAccount: {
          value: c => c.fromAccountName
        }
      } : {})
    },

    headerIcon () {
      const headerIcons = [
        {
          contractTypes: [ContractType.Production.value, ContractType.WoodsSale.value],
          icon: 'mdi-clipboard-outline',
          tooltip: 'logContract'
        },
        {
          contractTypes: [ContractType.ByproductSale.value, ContractType.ByproductPurchase.value],
          icon: 'mdi-circular-saw',
          tooltip: 'byproductContract'
        },
        {
          contractTypes: [ContractType.Transfer.value],
          icon: 'mdi-transfer',
          tooltip: 'transferContract'
        }
      ]
      return headerIcons.find(hi => hi.contractTypes.some(ct => ct === this.contractInfo.type)) ?? headerIcons[0]
    },

    totalsTable () {
      return [
        {
          label: 'payout',
          value: (this.totals.recovered > 0)
            ? `${formatMoney(this.totals.payout)} (${this.$t('xRecovered', { x: formatMoney(this.totals.recovered) })})`
            : `${formatMoney(this.totals.payout)}`
        },
        {
          label: 'loads',
          value: this.totals.loads
        },
        {
          label: 'grossTons',
          value: this.totals.grossTons
        },
        {
          label: 'netTons',
          value: this.totals.netTons
        }
      ]
    },

    isExportBatch () {
      return this.dateConfiguration?.exportBatch?.exportBatchId !== undefined
    }
  },

  methods: {
    ...mapActions('posting', ['unpostTicket']),
    formatMoney,
    numberWithCommas,
    tonStringFromPounds,
    chunk,
    contractIconsFor,

    getUnitText ({
      payOn,
      payBy,
      modifier,
      grossTons,
      grossMetricTons,
      defectTons,
      mismanufactureDefectTons,
      natureDefectTons,
      netTons,
      netMetricTons
    }) {
      if (payBy === PayBy.Load.value) return this.$t('payByLoad')
      if (modifier === ActivityModifier.DistanceTraveled.value) { return this.$t('distanceInMiles', { distance: this.contractInfo.distance }) }

      const isMetric = payBy === PayBy.MetricWeight.value

      let weight = 0
      switch (payOn) {
        case PayOn.Gross.value:
          weight = isMetric ? grossMetricTons : grossTons
          break
        case PayOn.Net.value:
          weight = isMetric ? netMetricTons : netTons
          break
        // Metric weight not suppored for defect types
        case PayOn.Defect.value: weight = defectTons; break
        case PayOn.NatureDefect.value: weight = natureDefectTons; break
        case PayOn.MismanufacturedDefect.value: weight = mismanufactureDefectTons; break
        default: weight = netTons
      }

      const weightText = isMetric
        ? this.$t('metricTonsTotal', { totalWeight: weight })
        : this.$t('shortTonsTotal', { totalWeight: weight })

      return `${PayOn.fromInt(payOn)}: ${weightText}`
    },

    getRateText (rate, payBy, modifier) {
      const formattedRate = formatMoney(rate)

      if (modifier === ActivityModifier.DistanceTraveled.value) return `${formattedRate}/m`

      if (payBy === PayBy.Load.value) return formattedRate

      const denominator = payBy === PayBy.MetricWeight.value ? 'mt' : 't'
      return `${formattedRate}/${denominator}`
    },

    getPayOnTonsForProductSummary (productSummary, activity) {
      if (activity.payBy === PayBy.Load.value) return this.$t('payByLoad')

      let payOnTons
      const isMetric = activity.payBy === PayBy.MetricWeight.value

      switch (activity.payOn) {
        case PayOn.Gross.value:
          payOnTons = isMetric ? productSummary.grossMetricTons : productSummary.grossTons
          break
        case PayOn.Net.value:
          payOnTons = isMetric ? productSummary.netMetricTons : productSummary.netTons
          break
        // Metric Tons not suppored for defect types
        case PayOn.Defect.value: payOnTons = productSummary.defectTons; break
        case PayOn.NatureDefect.value: payOnTons = productSummary.natureDefectTons; break
        case PayOn.MismanufacturedDefect.value: payOnTons = productSummary.mismanufactureDefectTons; break
        default: payOnTons = productSummary.netTons
      }

      return isMetric
        ? this.$t('metricTonsTotal', { totalWeight: payOnTons })
        : this.$t('shortTonsTotal', { totalWeight: payOnTons })
    },

    getNetTons ({ inWeight, outWeight, defectWeight }) {
      return tonStringFromPounds(inWeight - outWeight - defectWeight)
    },

    getGrossTons ({ inWeight, outWeight }) {
      return tonStringFromPounds(inWeight - outWeight)
    },

    getRecoveredText ({ payout, grossPayout }) {
      if (payout === grossPayout) {
        return ''
      } else {
        return `(${formatMoney(grossPayout - payout)} Recovered)`
      }
    },

    showUnpostDialog (ticket) {
      if (this.isExportBatch) {
        this.setSnackError(this.$t('ticketsCannotBeUnpostedInExportBatch'))
        return
      }

      this.focusedTicketForUnpost = ticket
      this.showUnpostTicketsDialog = true
    },

    closeUnpostDialog () {
      this.focusedTicketForUnpost = undefined
      this.showUnpostTicketsDialog = false
    },

    async unpostFocusedTicket () {
      await this.unpostTicket(this.focusedTicketForUnpost)
      this.$emit('refresh-settlement')
      this.closeUnpostDialog()
    },

    calculateLoadSummaries () {
      this.loadSummariesForTable = JSON.parse(JSON.stringify(this.loadSummaries))
      this.loadSummariesForTable.forEach(item => {
        item.gross = this.getGrossTons(item)
        item.netWeight = this.getNetTons(item)
        item.grossAmount = this.formatMoney(item.grossAmount - item.amount)
        item.amount = this.formatMoney(item.amount)
        item.defectWeight = this.tonStringFromPounds(item.defectWeight)
      })
    },

    initSplitByActivityMap () {
      const loadSummaryRoot = this.payeeHasPayout ? this.propPayee.loadSummaries : this.propPayee.contracts.flatMap(c => c.loadSummaries)
      if (loadSummaryRoot.length === 0 || !loadSummaryRoot[0].paymentDetails) return
      loadSummaryRoot.forEach(ls => ls.paymentDetails.forEach(pd => {
        const key = pd.activity
        const splits = this.splitByActivity.get(key) ?? []
        if (pd.computedAtSplit !== null && pd.computedAtSplit !== 100) splits.push(pd.computedAtSplit)
        this.splitByActivity.set(key, splits)
      }))
    },

    ownershipPercentageValue (val) {
      return val.length === 1 ? formatPercentage(val[0]) : this.$t('multipleSplits')
    },

    showPenaltyIcon (activity) {
      return activity.activityTemplateSpecialization === TemplateSpecialization.Penalty.value
    },

    productHeaders (activity) {
      return settlementProductHeaders().filter(h =>
        ((!this.isTransfer &&
        !this.isReceivable &&
        activity.activityTemplateSpecialization !== TemplateSpecialization.Penalty.value) || h.value !== 'recovered') &&
        (this.isReceivable || h.value !== 'penaltyAmount')
      )
    }
  }
}
</script>
