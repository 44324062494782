<template>
  <v-card>
    <v-card-title :class="`${colorClassForContractType(contract.type)} headline`">
      {{$t('detailCostWarning')}}
    </v-card-title>
    <v-card-text class="subtitle-1 mt-4">
      {{
        $t('detailCostDescription', {
        activityCost: formatMoney(activityCost),
        detailCost: formatMoney(detailCost)
        })
      }}
    </v-card-text>
    <v-card-actions class="px-0 pb-4">
      <v-btn color="black" outlined text @click="$emit('cancel', false)">{{$t('cancel')}}</v-btn>
      <v-spacer/>
      <v-btn :class="colorClassForContractType(contract.type)" @click="$emit('create-detail', shouldUpdateActivity)">
        {{saveBtnText}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
export default {
  name: 'DetailCostWarning',

  props: {
    activityCost: Number,
    detailCost: Number,
    contract: Object
  },

  data: () => ({
    shouldUpdateActivity: false
  }),

  computed: {
    saveBtnText () {
      return this.$t('proceed')
    }
  },

  methods: {
    formatMoney,
    colorClassForContractType
  }
}
</script>
