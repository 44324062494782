<template>
  <v-card data-testid="add-template-dialog">
    <v-card-title class="white--text primary headline">
      {{ title }}
      <v-icon v-if="isPenalty" class="ml-2" color="white">{{ weightAlert.path }}</v-icon>
    </v-card-title>
    <v-card-text>
      <FormWrapper @submit="saveBtnClicked" :disabled="!hasValidSelection">
        <v-container class="mt-4">
          <v-row>
            <v-col>
              <v-expansion-panels popout accordion light v-model="openPanel">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t('account') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <AccountAutocomplete
                      :accountId="propBuilder?.counterpartyId"
                      :hint="$t('specifyCounterpartyActivity')"
                      clearable
                      unsavedTags
                      chipStyle="below"
                      @account-chosen="selectAccount"
                      :fetchTagsAndAccounts="false"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t('runtimeField') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-radio-group v-model="selectedRuntimeField">
                      <v-radio
                      v-for="accountType in tractAccountTypes"
                      :key="accountType.value"
                      :label="accountType.name"
                      :value="accountType.value"/>
                    </v-radio-group>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-checkbox
              v-if="includeBaseCost"
              v-model="limitToBaseRate"
              :label="$t('limitToBaseRate')"
              />
            </v-col>
          </v-row>
        </v-container>
        <template #left-action>
          <v-btn
            class="black--text"
            data-testid="cancel-template"
            text
            outlined
            @click="$emit('cancel')"
          >
            {{ $t('cancel') }}
          </v-btn>
        </template>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { RuntimeCounterparty, AccountingCategory, TemplateSpecialization } from '@/utils/Enumerations.js'
import { TemplateSpecializationInfo } from '../../activity/ActivityMapping'
import { weightAlert } from '@/images/AngelSvgLibrary'

export default {
  name: 'SelectCounterpartyDialog',

  components: {
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue')
  },

  props: {
    propBuilder: {
      type: Object,
      required: false,
      default: undefined
    },
    newTemplateRequest: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    weightAlert,
    TemplateSpecializationInfo,
    TemplateSpecialization,
    selectedAccount: undefined,
    limitToBaseRate: false,
    selectedRuntimeField: 0,
    openPanel: 0
  }),

  computed: {
    ...mapGetters('account', ['allAccounts']),

    tractAccountTypes () {
      return RuntimeCounterparty.enums
        // TODO: Remove filter when runtime landowner accruals are supported
        .filter(e => this.newTemplateRequest?.category !== AccountingCategory.Accrual.value || e.label !== 'tractLandowner')
    },

    currentSelection () {
      return {
        account: (this.openPanel === 0) ? this.selectedAccount : undefined,
        runtimeField: (this.openPanel === 1) ? this.selectedRuntimeField : undefined
      }
    },

    hasValidSelection () {
      return (this.openPanel === 1) ? this.currentSelection.runtimeField !== undefined : true
    },

    title () {
      const counterparty = (this.propBuilder)
        ? this.propBuilder.activityTemplateName
        : this.newTemplateRequest.activityTemplateName
      return (counterparty)
        ? this.$t(this.propBuilder ? 'editCounterpartyFor' : 'selectCounterpartyFor', { counterparty })
        : this.$t(this.propBuilder ? 'editCounterparty' : 'selectCounterparty')
    },

    includeBaseCost () {
      const specialization = this.newTemplateRequest?.specialization ?? this.propBuilder?.specialization
      const category = this.newTemplateRequest?.category ?? this.propBuilder?.category
      return specialization !== TemplateSpecialization.Transfer.value && (specialization !== TemplateSpecialization.Penalty.value || category === AccountingCategory.Receivable.value)
    },

    isPenalty () {
      return (this.newTemplateRequest?.specialization ?? this.propBuilder?.specialization) === TemplateSpecialization.Penalty.value
    }
  },

  async created () {
    await this.fetchAccounts()
    await this.fetchAccountTags()
    this.setInitialState(true)
  },

  methods: {
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tags', ['fetchAccountTags']),
    selectAccount (account) {
      this.selectedAccount = account
    },
    saveBtnClicked () {
      this.$emit('counterparty-selected', this.currentSelection, this.limitToBaseRate)
      this.setInitialState(false)
    },

    setInitialState (usePropBuilder) {
      if (this.propBuilder && usePropBuilder) {
        this.selectedRuntimeField = this.propBuilder.runtimeField ?? 0
        this.selectedAccount = (this.propBuilder.counterpartyId)
          ? { accountId: this.propBuilder.counterpartyId, name: this.propBuilder.counterpartyName }
          : undefined
        this.limitToBaseRate = this.propBuilder.limitToBaseRate ?? false
      } else {
        this.selectedRuntimeField = 0
        this.selectedAccount = undefined
        this.limitToBaseRate = false
      }
      const propBuilderRuntimeField = this.propBuilder?.runtimeField !== null && this.propBuilder?.runtimeField !== undefined
      this.openPanel = (this.selectedAccount === undefined && propBuilderRuntimeField) ? 1 : 0
    }
  }
}
</script>
