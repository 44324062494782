<template>
  <v-card>
    <v-card-title class="primary">
      <v-row>
        <v-col cols="auto">
          <span class="headline white--text">{{titleText}}</span>
        </v-col>
      </v-row>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
      :disabled="!validForm"
      :buttonText="formButtonText"
      @submit="formButtonPressed">
        <v-container grid-list-lg class="mt-4">
          <TemplateAutocomplete
          :templateId="accountPayment.activityTemplateId"
          @template-chosen="accountPayment.activityTemplateId = $event.activityTemplateId"
          :propAccountingCategories="[AccountingCategory.Payable.value]"/>
          <v-row justify="end">
            <v-col cols="6">
              <MoneyTextField
                :initialValue="accountPayment.amount"
                :label="$t('amount')"
                allowNegative
                @val-changed="accountPayment.amount = $event"
                :extraRules="['validMiscPayable']"
              />
            </v-col>
            <v-col cols="6">
              <DatePicker
                dataTestId="issued-date"
                :dateLabel="$t('issuedAt')"
                :startDate="issuedAtStartDate"
                @valid-date="validIssuedAt = $event"
                @date-picked="issuedAtChosen"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="accountPayment.paymentNote"
                data-testid="payment-note"
                :hint="$t('paymentTitle')"
                color="black"
                name="input-7-4"
                maxLength="128"
                counter="128"
                outlined
                auto-grow
                clearable
                dense/>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { localToUTC, utcToLocalDate } from '@/utils/DateFormatter.js'
import { AccountingCategory } from '@/utils/Enumerations'
import { MAX_MISC_PAYABLE, MIN_MISC_PAYABLE } from '@/utils/rules'
import { formatMoney } from '@/utils/NumericMutations.js'
export default {
  name: 'AccountPayableForm',

  props: {
    propAccountPayable: {
      type: Object,
      default: undefined
    },
    accountId: {
      type: Number,
      required: true
    }
  },

  components: {
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    TemplateAutocomplete: () => import('@/components/autocomplete/TemplateAutocomplete.vue'),
    MoneyTextField: () => import('@/components/helper/MoneyTextField.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue')
  },

  data: () => ({
    accountPayment: {
      activityTemplateId: undefined,
      amount: 0,
      paymentNote: '',
      issuedAt: ''
    },
    validIssuedAt: true,
    issuedAtStartDate: undefined,
    AccountingCategory
  }),

  computed: {
    isEditing () {
      return !!this.propAccountPayable
    },
    titleText () {
      return this.isEditing ? this.$t('editAccountPayment') : this.$t('createAccountPayment')
    },
    formButtonText () {
      return this.isEditing ? this.$t('saveChanges') : this.$t('create')
    },
    validForm () {
      const { activityTemplateId, amount, paymentNote } = this.accountPayment
      return (
        activityTemplateId > 0 &&
        amount !== 0 &&
        paymentNote &&
        this.validIssuedAt
      )
    }
  },

  created () {
    if (this.isEditing) {
      this.accountPayment = JSON.parse(JSON.stringify(this.propAccountPayable))
      this.issuedAtStartDate = this.accountPayment.issuedAt ? utcToLocalDate(this.accountPayment.issuedAt) : utcToLocalDate(this.accountPayment.createdAt)
    } else {
      this.issuedAtStartDate = new Date().toISOString()
    }
  },

  methods: {
    formButtonPressed () {
      if (this.accountPayment.amount > MAX_MISC_PAYABLE || this.accountPayment.amount < MIN_MISC_PAYABLE) {
        this.setSnackError(this.$t('invalidAccountPaymentAmount', { min: formatMoney(MIN_MISC_PAYABLE), max: formatMoney(MAX_MISC_PAYABLE) }))
        return
      }
      const requestObject = this.accountPayment
      this.$emit(this.isEditing ? 'edit-payable' : 'create-payable', {
        accountPayable: { ...requestObject, accountId: this.accountId },
        accountId: this.accountId
      })
    },

    issuedAtChosen (date) {
      this.issuedAtStartDate = date
      this.accountPayment.issuedAt = localToUTC(date)
    }
  }
}
</script>
