import store from '@/store/index.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'

export default {
  initializeFilter (state) {
    state.filter = {
      includeOpen: true,
      includeOnHold: false,
      includeClosed: false,
      includeExpired: false,
      limitToDraft: false,
      limitToPaused: false,
      limitToInternal: false,
      includeProduction: true,
      includeWoodsSale: true,
      includeByProduct: false,
      includeLogYardSale: false,
      ...JSON.parse(localStorage.getItem(LocalStorageKeys.CONTRACTS_TABLE_FILTER) ?? '{}')
    }
  },
  setFilter (state, filter) {
    state.filter = filter
    localStorage.setItem(LocalStorageKeys.CONTRACTS_TABLE_FILTER, JSON.stringify(filter))
    store.dispatch('contract/fetchContracts', state.filter)
  },
  setLoading (state, status) {
    state.contractsLoading = status
  },
  setContracts (state, contracts) {
    state.contracts = contracts
  },
  addContract (state, contract) {
    state.contracts.push(contract)
  },
  removeContract (state, contractId) {
    state.contracts = state.contracts.filter(s => s.contractId !== contractId)
  },
  updateContract (state, contract) {
    const index = state.contracts.findIndex(c => c.contractId === contract.contractId)
    if (index !== -1) {
      Object.assign(state.contracts[index] = contract)
    }
  },
  setSelectedContractId (state, contractId) {
    state.selectedContractId = contractId
  }
}
