export default {
  setLoggingMethodLoadingStatus (state, status) {
    state.loggingMethodLoadingStatus = status
  },
  setLoggingMethods (state, loggingMethods) {
    state.loggingMethods = loggingMethods
  },
  addLoggingMethod (state, loggingMethod) {
    state.loggingMethods.push(loggingMethod)
  },
  removeLoggingMethod (state, loggingMethodId) {
    state.loggingMethods = state.loggingMethods.filter(t => t.loggingMethodId !== loggingMethodId)
  },
  updateLoggingMethod (state, loggingMethod) {
    const index = state.loggingMethods.findIndex(t => t.loggingMethodId === loggingMethod.loggingMethodId)
    if (index !== -1) {
      Object.assign(state.loggingMethods[index], loggingMethod)
    }
  }
}
