// Legna colors source: https://dev.azure.com/legnasoftware/_git/GraphicsAssets?path=/Legna-Font-Colors-20190827.pdf
// Some colors from: https://v2.vuetifyjs.com/en/styles/colors/#material-colors
import colors from 'vuetify/lib/util/colors'
import { chunk } from 'lodash'

export const theme = {
  primary: '#D15F27', // Heartwood Orange
  secondary: '#191410', // Darkened Walnut
  tertiary: '#F9F6DF', // Sapwood White
  accent: colors.blue.accent1,
  error: colors.red.darken1,
  info: '#5062cc', // This is indigo.lighten1 with saturation increased by 20/100 (done with a Hue/Saturation layer in Photopea)
  success: colors.green.darken1,
  warning: colors.orange.darken1,
  byproduct: '#363636',
  transfer: colors.grey.base,
  logyardsale: colors.brown.base
}

export const helpers = {
  // Based on Material Colors from Vuetify 2: https://v2.vuetifyjs.com/en/styles/colors/#material-colors
  getVuetifyPalette: (color = 'grey') => {
    const result = [colors[color].base]
    for (let i = 1; i < 5; i++) {
      if (color !== 'grey' || i < 2) result.unshift(colors[color][`lighten${i}`]) // Vuetify grey skews really light for some reason
      if (i < 4) result.push(colors[color][`darken${i}`])
    }
    return result
  },

  getTranslucent: (percentage, hex = '#000000', format = 'hex') => {
    const { R, G, B } = hexColorToChunked(hex)
    const A = percentage / 100
    switch (format) {
      case 'rgba': return rgbaToString(R, G, B, A)
      case 'hex':
      default: return rgbaToHex(R, G, B, A)
    }
  },

  getTint: (percentage, hex = '#000000FF', format = 'hex') => {
    const { R, G, B, A } = hexColorToChunked(hex)
    const transform = (chunk) => chunk + ((255 - chunk) * percentage / 100)
    switch (format) {
      case 'rgba': return rgbaToString(
        transform(R),
        transform(G),
        transform(B),
        A
      )
      case 'hex':
      default: return rgbaToHex(
        transform(R),
        transform(G),
        transform(B),
        A
      )
    }
  },

  getShade: (percentage, hex = '#000000FF', format = 'hex') => {
    const { R, G, B, A } = hexColorToChunked(hex)
    const transform = (chunk) => chunk * (1 - (percentage / 100))
    switch (format) {
      case 'rgba': return rgbaToString(
        transform(R),
        transform(G),
        transform(B),
        A
      )
      case 'hex':
      default: return rgbaToHex(
        transform(R),
        transform(G),
        transform(B),
        A
      )
    }
  }
}

const hexColorToChunked = (hex) => {
  const chunked = chunk(hex.replace('#', '').split(''), 2)
  const getChunkAt = (index) => Number(`0x${chunked[index].join('')}`)
  return {
    R: getChunkAt(0),
    G: getChunkAt(1),
    B: getChunkAt(2),
    A: chunked[3] ? getChunkAt(3) / 255 : '1'
  }
}

const decimalToHex = (decimal) => parseInt(decimal).toString(16).padEnd(2, '0')

const rgbaToHex = (R, G, B, A = 1) => `#${decimalToHex(R)}${decimalToHex(G)}${decimalToHex(B)}${decimalToHex(A * 255)}`

const rgbaToString = (R, G, B, A = 1) => `rgba(${R}, ${G}, ${B}, ${A})`
