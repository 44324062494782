<template>
  <v-card>
    <v-card-title class="primary">
      <span class="headline white--text">{{title}}</span>
      <v-spacer/>
      <Icon
      dataTestId="contact-close"
      iconColor="white"
      icon="mdi-close"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="contactForm"
        :buttonText="submitBtnText"
        @submit="saveChangesPressed"
      >
        <v-container fluid grid-list-xl>
          <v-row class="mt-1">
            <v-col cols="12">
              <span class="title">{{ $t('personalInfo') }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col cols="12" md="6" :lg="isCompanyInfoContact ? '6' : '4'">
              <v-text-field
              v-model="contact.person.firstName"
              data-testid="contact-firstname"
              ref="firstField"
              color="black"
              maxLength="64"
              counter="64"
              :label="$t('firstName')"
              :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" :lg="isCompanyInfoContact ? '6' : '4'">
              <v-text-field
              v-model="contact.person.lastName"
              data-testid="contact-lastname"
              maxLength="64"
              counter="64"
              color="black"
              :label="$t('lastName')"
              :rules="[rules.required]"/>
            </v-col>
            <v-col cols="12" md="6" lg="4" v-if="!isCompanyInfoContact">
              <v-text-field
              v-model="contact.position"
              data-testid="contact-position"
              maxLength="32"
              counter="32"
              color="black"
              :label="$t('position')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="title">{{ $t('contactInfo') }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
              v-model="contact.person.phoneNumber"
              v-mask="phoneMask"
              data-testid="contact-phonenumber"
              color="black"
              :label="$t('phoneNumber')"
              :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" lg="8">
              <v-text-field
              v-model="contact.person.email"
              data-testid="contact-email"
              maxLength="128"
              counter="128"
              color="black"
              :label="$t('email')"
              :rules="[rules.required, rules.email]"/>
            </v-col>
          </v-row>
          <span v-if="!isCompanyInfoContact">
            <v-row>
              <v-col cols="12">
                <span class="title">{{ $t('preferences') }}</span>
              </v-col>
            </v-row>
            <v-row align="center" class="mt-n5">
              <v-col>
                <v-checkbox
                v-model="contact.isDefault"
                data-testid="contact-default-check"
                color="primary"
                :label="$t('defaultContact')"/>
              </v-col>
              <v-col>
                <v-checkbox
                v-model="contact.receivesDailyTicketReport"
                color="primary"
                :label="$t('dailyTicketReportRecipient')"
                :disabled="dtrDisabled"
                :persistent-hint="dtrDisabled"
                :hint="dtrDisabled ? $t('accountMustBeExternal') : ''"/>
              </v-col>
              <v-col>
                <v-checkbox
                v-model="contact.receivesSettlementReport"
                color="primary"
                :label="$t('settlementReportRecipient')"/>
              </v-col>
            </v-row>
          </span>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask'
import fieldRules from '@/utils/rules.js'
export default {
  name: 'ContactForm',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  props: {
    propContact: {
      type: Object,
      default: undefined
    },
    isCompanyInfoContact: {
      type: Boolean,
      default: false
    },
    dtrDisabled: {
      type: Boolean,
      default: false
    }
  },

  directives: {
    mask
  },

  computed: {
    submitBtnText () {
      return this.propContact && !this.isContactEmpty(this.propContact.person) ? this.$t('saveChanges') : this.$t('newContact')
    },

    title () {
      return this.propContact && !this.isContactEmpty(this.propContact.person) ? this.$t('editingContact') : this.$t('creatingContact')
    }
  },

  created () {
    if (this.propContact) {
      this.contact = JSON.parse(JSON.stringify(this.propContact))
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.focus()
    }, 0)
  },

  data: () => ({
    phoneMask: '(###)-###-####',
    rules: fieldRules.rules,
    contact: {
      isDefault: false,
      receivesDailyTicketReport: false,
      position: '',
      person: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      }
    }
  }),

  methods: {
    saveChangesPressed () {
      if (!this.$refs.form.$refs.contactForm.validate()) {
        return
      }

      if (this.propContact) {
        this.$emit('contact-updated', this.contact)
      } else {
        this.$emit('contact-created', this.contact)
      }
    },

    isContactEmpty (contact) {
      return contact.firstName === '' &&
      contact.lastName === '' &&
      contact.email === '' &&
      contact.phoneNumber === ''
    }
  }
}
</script>
