import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations'
import { AddressType, ActivityModifier, PayBy, PayOn } from '@/utils/Enumerations'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import i18n from '@/i18n'

PDFMake.vfs = pdfFonts.pdfMake.vfs

export async function generateLogsContract (contract, tract, destination, account, activities, landowners) {
  if (account.length === 1) {
    const pdf = await getLogsContractPdf(contract, tract, destination, account[0], activities, landowners)
    pdf.download(`logs_contract_${account[0].name.replaceAll(/\s/g, '_').replaceAll('.', '')}`)
  } else {
    const zip = new JSZip()
    for (const counterparty of account) {
      const activitiesForPdf = activities.filter(act => counterparty.activities.includes(act.activityId))
      const pdf = await getLogsContractPdf(contract, tract, destination, counterparty, activitiesForPdf, landowners)
      const blob = await getBlobFromPdf(pdf)
      zip.file(`logs_contract_${counterparty.name.replaceAll(/\s/g, '_').replaceAll('.', '')}.pdf`, blob)
    }

    const downloadableZip = await zip.generateAsync({ type: 'blob' })
    saveAs(downloadableZip, 'logs_contracts')
  }
}

const getBlobFromPdf = (pdfData) => {
  return new Promise((resolve) => {
    const pdf = PDFMake.createPdf(pdfData.docDefinition)
    delete pdfData.docDefinition
    pdf.getBlob(data => {
      resolve(data)
    })
  })
}

export function getLogsContractPdf (contract, tract, destination, account, activities, landowners) {
  const divider = () => [
    {
      canvas: [
        { type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 1 }
      ],
      margin: [0, 19, 0, 19]
    }
  ]

  const accountBlock = () => {
    const billingAddress = account.addresses.find(ad => ad.address.type === AddressType.BillTo.value)

    if (billingAddress !== undefined) {
      const { address } = billingAddress

      return [
        { text: account.name, listType: 'none', style: 'title', alignment: 'right' },
        { text: address.addressLine1, listType: 'none', style: 'subtitle', alignment: 'right' },
        { text: address.addressLine2, listType: 'none', style: 'subtitle', alignment: 'right' },
        { text: `${address.city}, ${address.state} ${address.postalCode}`, listType: 'none', style: 'subtitle', alignment: 'right' }
      ]
    } else {
      return [
        { text: account.name, listType: 'none', style: 'title', alignment: 'right' }
      ]
    }
  }

  const companyInfo = () => {
    return [
      { text: destination.name, listType: 'none', style: 'title', alignment: 'left' },
      { text: destination.address.addressLine1, listType: 'none', style: 'subtitle', alignment: 'left' },
      { text: destination.address.addressLine2, listType: 'none', style: 'subtitle', alignment: 'left' },
      { text: `${destination.address.city}, ${destination.address.state} ${destination.address.postalCode}`, listType: 'none', style: 'subtitle', alignment: 'left' }
    ]
  }

  const headers = () => [
    {
      columns: [
        companyInfo(),
        accountBlock()
      ]
    },
    divider()
  ]

  const leftTractHeaders = () => [
    { text: 'Tract Name:', listType: 'none', style: 'header', alignment: 'left' },
    { text: 'Tract Type:', listType: 'none', style: 'header', alignment: 'left' },
    { text: 'Forester:', listType: 'none', style: 'header', alignment: 'left' }
  ]

  const leftTractResponses = () => [
    { text: tract.name, listType: 'none', style: 'subtitle', alignment: 'left' },
    { text: tract.type.name, listType: 'none', style: 'subtitle', alignment: 'left' },
    { text: tract.foresterUser?.name ?? 'N/A', listType: 'none', style: 'subtitle', alignment: 'left' }
  ]

  const rightTractHeaders = () => [
    { text: 'County:', listType: 'none', style: 'header', alignment: 'left' },
    { text: 'State:', listType: 'none', style: 'header', alignment: 'left' },
    { text: 'Latitude:', listType: 'none', style: 'header', alignment: 'left' },
    { text: 'Longitude:', listType: 'none', style: 'header', alignment: 'left' }
  ]

  const rightTractResponses = () => [
    { text: tract.spot.countrySecondarySubdivision, listType: 'none', style: 'subtitle', alignment: 'left' },
    { text: tract.spot.countrySubdivision, listType: 'none', style: 'subtitle', alignment: 'left' },
    { text: tract.spot.latitude, listType: 'none', style: 'subtitle', alignment: 'left' },
    { text: tract.spot.longitude, listType: 'none', style: 'subtitle', alignment: 'left' }
  ]

  const certifications = () => {
    const certifications = tract.tractCertifications.map(c => c.name).join(',   ') || 'N/A'

    return [
      {
        columns: [
          {
            width: 130,
            text: 'Tract Certifications:',
            style: 'header'
          },
          {
            text: certifications,
            width: '*',
            listType: 'none',
            style: 'subtitle',
            alignment: 'top'
          }
        ],
        margin: [12, 8, 0, 0]
      }
    ]
  }

  const landownersBlock = () => {
    const landownersString = landowners.map(lo => `${lo.accountName} (${lo.ownership}%)`).join(',   ') || 'N/A'
    return [
      {
        columns: [
          {
            width: 130,
            text: 'Tract Landowners:',
            style: 'header'
          },
          {
            text: landownersString,
            width: '*',
            listType: 'none',
            style: 'subtitle',
            alignment: 'top'
          }
        ],
        margin: [12, 8, 0, 0]
      }
    ]
  }

  const tractInfo = () => [
    { text: 'Tract Properties', style: 'title', margin: [0, 0, 0, 8], alignment: 'left' },
    {
      columns: [
        { ol: leftTractHeaders(), width: 'auto' },
        { ol: leftTractResponses(), width: 'auto' },
        { ol: rightTractHeaders(), width: 'auto' },
        { ol: rightTractResponses(), width: 'auto' }
      ]
    },
    landownersBlock(),
    certifications()
  ]

  const contractInfo = () => [
    { text: 'Contract Info', style: 'title', margin: [0, 0, 0, 8], alignment: 'left' },
    {
      columns: [
        {
          width: 'auto',
          ol: [
            { text: 'Destination:', listType: 'none', style: 'header', alignment: 'left' },
            { text: 'Effective Date:', listType: 'none', style: 'header', alignment: 'left' },
            { text: 'Expiration Date:   ', listType: 'none', style: 'header', alignment: 'left' }
          ],
          margin: [0, 0, 8, 0]
        },
        [
          {
            text: (contract.distance > 0) ? `${contract.destination} (${contract.distance}mi.)` : contract.destination,
            listType: 'none',
            style: 'subtitle',
            alignment: 'left'
          },
          { text: utcToLocalDate(contract.effectiveDate), listType: 'none', style: 'subtitle', alignment: 'left' },
          { text: utcToLocalDate(contract.expirationDate), listType: 'none', style: 'subtitle', alignment: 'left' }
        ]
      ]
    }
  ]

  const fullInfo = () => [
    {
      columns: [
        tractInfo(),
        contractInfo()
      ]
    }
  ]

  const activityGrid = () => {
    const ac = [[
      { text: 'Activity', style: 'header' },
      { text: 'Product', style: 'header' },
      { text: 'Pay By', style: 'header' },
      { text: 'Pay On', style: 'header' },
      { text: 'Rate', style: 'header' },
      { text: 'Expected Tons', alignment: 'right', style: 'header' }
    ]]

    for (const activity of activities.sort(a => a.activityTemplate.name)) {
      const distance = activity.activityTemplate.modifier === ActivityModifier.DistanceTraveled.value && contract.distance !== 0
        ? ` (${contract.distance}mi.)`
        : ''

      const baseRate = activity.baseCost ? formatMoney(activity.baseCost.rate) + distance : i18n.t('notApplicable')

      ac.push([
        { text: activity.activityTemplate.name, style: 'header' },
        { text: 'Base', style: 'header' },
        { text: activity.baseCost ? PayBy.fromInt(activity.baseCost.payBy) : i18n.t('notApplicable'), style: 'header' },
        { text: activity.baseCost ? PayOn.fromInt(activity.baseCost.payOn) : i18n.t('notApplicable'), style: 'header' },
        { text: baseRate, style: 'header' },
        { text: '', alignment: 'right', style: 'header' }
      ])

      for (const activityDetail of activity.activityDetails.sort(a => a.product.name)) {
        const volume = tract.tractContents.find(c => c.productId === activityDetail.product.productId)?.expected ?? 0
        const detailRate = formatMoney(activityDetail.cost.rate) + distance

        ac.push([
          { text: '', style: 'subtitle' },
          { text: activityDetail.product.name, style: 'subtitle' },
          { text: activityDetail.cost ? PayBy.fromInt(activityDetail.cost.payBy) : i18n.t('notApplicable'), style: 'subtitle' },
          { text: activityDetail.cost ? PayOn.fromInt(activityDetail.cost.payOn) : i18n.t('notApplicable'), style: 'subtitle' },
          { text: detailRate, style: 'subtitle' },
          { text: tonStringFromPounds(volume), alignment: 'right', style: 'subtitle' }
        ])
      }
    }

    return [
      {
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex % 2 === 0) ? 'white' : 'lightgrey'
          },
          vLineWidth: function (i) {
            return 0
          },
          hLineWidth: function (i) {
            if (i === 1) {
              return 1
            } else {
              return 0
            }
          }
        },
        table: {
          widths: ['auto', '*', '*', '*', '*', 'auto'],
          headerRows: 1,
          body: ac
        },
        margin: [0, 25, 0, 0]
      }
    ]
  }

  const content = [
    headers(),
    fullInfo(),
    activityGrid()
  ]

  const documentDefinition = {
    content,
    pageOrientation: 'landscape',
    styles: {
      title: {
        fontSize: 15,
        bold: true
      },
      subtitle: {
        fontSize: 8,
        bold: false
      },
      header: {
        fontSize: 8,
        bold: true
      }
    },
    footer: [
      { text: utcToLocalDate(new Date()), margin: [8, 8, 25, 8], alignment: 'right', style: 'subtitle' }
    ]
  }

  return PDFMake.createPdf(documentDefinition)
}
