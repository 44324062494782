<template>
  <v-container fluid class="pb-6 pt-4">
    <DestinationDateSelector
    :contractMode="ContractMode.Byproducts"
    :timeLabel="getTimeLabel"
    immediate
    @item-selected="destChosen">
      <template #prepend>
        <div class="ma-0 pa-0 mt-n1">
          <TicketsWidgetPicker v-if="chartSet" :propChartSet="chartSet" :openMenuKey="chartPickerMenuKey" @new-chart-layout="newChartLayout"/>
        </div>
      </template>
    </DestinationDateSelector>
    <v-row dense no-gutters>
      <v-spacer></v-spacer>
      <p class="mt-n4 pa-0">{{dateString}}</p>
    </v-row>
    <v-row v-if="widgetSlotSet.widgetSlots.length === 0" style="opacity: 0.2;">
      <div style="text-align: center; width: 100%; padding-top: min(48px, 10vh);">
        <v-icon size="40vmin" @click="addNewChart">mdi-chart-box-plus-outline</v-icon>
        <br>
        <span class="title" @click="addNewChart" style="user-select: none;">{{$t('clickToAddAWidget')}}</span>
      </div>
    </v-row>
    <GridContainer v-if="widgetSlotSet.widgetSlots.length" :rows="containerRows" :columns="containerColumns" flow="row" gap="20px" class="py-4">
      <GridArea v-for="(widget, idx) in widgetSlotSet.widgetSlots" :key="`bp-chart-widget-${idx}`" :area="widget.dimensions" :id="`${widget.id}Bp`">
        <StatsCard
        v-if="widget.id === 'weighedOutLoads'"
        color="secondary"
        icon="mdi-truck"
        :title="$t('completedLoadsThisPeriod')"
        :content="String(ticketSummaries.weighedOutLoads)"
        :loading="!ticketSummaries"/>
        <StatsCard
        v-if="widget.id === 'weighedOutTons'"
        color="secondary"
        icon="mdi-weight"
        :title="$t('completedTonsThisPeriod')"
        :content="String(ticketSummaries.weighedOutTons)"
        :loading="!ticketSummaries"/>
        <StatsCard
        v-if="widget.id === 'createdLoads'"
        color="grey"
        icon="mdi-truck"
        :title="$t('createdLoadsThisPeriod')"
        :content="String(ticketSummaries.createdLoads)"
        :loading="!ticketSummaries"/>
        <StackedBarChartCard
        v-if="widget.id === 'loadsByDestination' && byProductDestinationChart"
        :selectedDestName="selectedDestName"
        :data="byProductDestinationChart"
        :contractMode="ContractMode.Byproducts"
        type="Destination"
        :tooltip="$t('completedLoadsThisPeriodTooltip')"
        :customTitle="unit => $t('completedUnitByDestination', { unit })"
        elementId="loads-by-destination-chart"
        isInteractive
        @data-clicked="onDataClicked(widget.details.widget, 'destination', 'product', byProductDestinationChart, $event)"
        />
        <BarChartCardLoadsByProduct
        v-if="widget.id === 'loadsByProduct' && byProductDestinationChart"
        :data="byProductSummaryChart"
        :contractMode="ContractMode.Byproducts"
        :destinationName="selectedDestName"
        title="Loads by Byproduct"
        :customTitle="unit => $t('completedUnitByProduct', { unit })"
        :tooltip="$t('completedLoadsThisPeriodTooltip')"
        isInteractive
        @data-clicked="onDataClicked(widget.details.widget, 'product', undefined, byProductSummaryChart, $event)"
        />
        <StackedBarChartCard
        v-if="widget.id === 'loadsByContractAccount' && byProductAccountChart"
        :data="byProductAccountChart"
        :selectedDestName="selectedDestName"
        :contractMode="ContractMode.Byproducts"
        type="Account"
        :tooltip="$t('completedLoadsThisPeriodTooltip')"
        :customTitle="unit => $t('completedUnitByAccount', { unit })"
        elementId="loads-by-contract-account-chart"
        isInteractive
        @data-clicked="onDataClicked(widget.details.widget, 'account', 'product', byProductAccountChart, $event)"
        />
        <StackedBarChartCard
        v-if="widget.id === 'createdLoadsByContractAccount' && byProductCreatedAccountChart"
        :data="byProductCreatedAccountChart"
        :contractMode="ContractMode.Byproducts"
        :selectedDestName="selectedDestName"
        type="CreatedAccount"
        :tooltip="$t('createdLoadsThisPeriodTooltip')"
        :loadStatus="$t(widget.details?.loadStatus)"
        :allowToggle="widget.details?.loadStatus === 'completed' || widget.id === 'itemizedDefectSummary'"
        :customTitle="() => $t('createdLoadsByAccount')"
        elementId="loads-by-contract-account-chart"
        isInteractive
        @data-clicked="onDataClicked(widget.details.widget, 'account', 'product', byProductCreatedAccountChart, $event)"
        />
        <StackedBarChartCard
        v-if="widget.id === 'createdLoadsByDestination' && byProductCreatedDestinationChart"
        :selectedDestName="selectedDestName"
        :data="byProductCreatedDestinationChart"
        :contractMode="ContractMode.Byproducts"
        type="CreatedLoadsByDestination"
        elementId="loads-by-destination-chart"
        :tooltip="$t('createdLoadsThisPeriodTooltip')"
        :customTitle="() => $t('createdLoadsByDestination')"
        isInteractive
        @data-clicked="onDataClicked(widget.details.widget, 'destination', 'product', byProductCreatedDestinationChart, $event)"
        />
        <BarChartCardLoadsByProduct
        v-if="widget.id === 'createdLoadsByProduct' && byProductSummaryChart"
        :allowToggle="false"
        :data="byProductCreatedSummaryChart"
        :contractMode="ContractMode.Byproducts"
        :destinationName="selectedDestName"
        :tooltip="$t('createdLoadsThisPeriodTooltip')"
        :customTitle="() => $t('createdLoadsByProduct')"
        isInteractive
        @data-clicked="onDataClicked(widget.details.widget, 'product', undefined, byProductCreatedSummaryChart, $event)"
        />
        <StackedBarChartCard
        v-if="widget.id === 'loadsByDriver' && byDestinationLoadsByDriverChart"
        :allowToggle="false"
        :selectedDestName="selectedDestName"
        :selectedDestLocationId="locationId"
        :data="byDestinationLoadsByDriverChart"
        :contractMode="ContractMode.Byproducts"
        type="driver"
        elementId="loads-by-driver-chart"
        :tooltip="$t('loadsByDriver')"
        :customTitle="() => $t('loadsByDriver')"
        isInteractive
        @data-clicked="onDataClicked(widget.details.widget, 'driver', 'destination', byDestinationLoadsByDriverChart, $event)"
        />
      </GridArea>
    </GridContainer>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { utcToLocalDate, localToUTC, timeBetween } from '@/utils/DateFormatter.js'
import { BarChartLoadsByProduct } from '@/model/BarChart.js'
import { StackedBarChart } from '@/model/StackedBarChart.js'
import { ByproductDashboardWidgetSet } from '@/model/DashboardWidget.js'
import { WidgetSlot, WidgetSlotSet } from '@/model/WidgetSlot.js'
import { ContractMode } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { byproductsWidgetMappings } from '@/components/dashboard/WidgetMappings'
import { MAX_DAYS_FOR_WIDGET_INTERACTION } from './DashboardTimeframe'
import { getNumEmptySlots } from '@/utils/DashboardHelpers'

export default {
  name: 'ByproductSummaries',

  components: {
    GridArea: () => import('@/components/helper/GridArea.vue'),
    GridContainer: () => import('@/components/helper/GridContainer.vue'),
    DestinationDateSelector: () => import('@/components/dashboard/DestinationDateSelector.vue'),
    BarChartCardLoadsByProduct: () => import('@/components/dashboard/BarChartCardLoadsByProduct.vue'),
    TicketsWidgetPicker: () => import('@/components/dashboard/TicketsWidgetPicker.vue'),
    StackedBarChartCard: () => import('@/components/dashboard/StackedBarChartCard.vue'),
    StatsCard: () => import('@/components/dashboard/StatsCard.vue')
  },

  data: () => ({
    ContractMode,
    totalLoads: 0,
    totalTons: 0,
    sinceTime: '',
    untilTime: '',
    timeLabel: undefined,
    summaryRequestObj: undefined,
    containerRows: { sm: '60px', md: 'calc(45px + 0.5vw)' },
    containerColumns: { sm: 'repeat(12, 1fr)', md: 'repeat(24, 1fr)' },
    ticketSummaries: {
      weighedOutLoads: 0,
      weighedOutTons: 0,
      weighedOutLoadsByProduct: [],
      weighedOutLoadsByDestinationAccount: [],
      weighedOutLoadsByContractAccount: [],
      createdLoadsByProduct: [],
      createdLoadsByDestinationAccount: [],
      createdLoadsByContractAccount: [],
      loadsByDriver: [],
      createdLoads: 0,
      createdTons: 0
    },
    chartSet: undefined,
    chartPickerMenuKey: 0
  }),

  computed: {
    getTimeLabel () {
      return localStorage.getItem(LocalStorageKeys.DASHBOARD_BYPRODUCT_TIME_LABEL)
    },
    dateString () {
      if (this.summaryRequestObj === undefined) return ''

      return `${utcToLocalDate(this.summaryRequestObj.sinceTime)}-${utcToLocalDate(this.summaryRequestObj.untilTime, 'L - LT').slice(0, 10)}`
    },

    byProductCreatedSummaryChart () {
      return new BarChartLoadsByProduct(this.ticketSummaries.createdLoadsByProduct, 'productName', 'byproduct')
    },
    byProductSummaryChart () {
      return new BarChartLoadsByProduct(this.ticketSummaries.weighedOutLoadsByProduct, 'productName', 'byproduct')
    },
    byProductDestinationChart () {
      return new StackedBarChart(this.ticketSummaries.weighedOutLoadsByDestinationAccount, 'accountName', 'destination')
    },
    byProductCreatedDestinationChart () {
      return new StackedBarChart(this.ticketSummaries.createdLoadsByDestinationAccount, 'accountName', 'destination', 'product', { csv: { includeWeight: false, includeLoadCount: true } })
    },
    byProductAccountChart () {
      return new StackedBarChart(this.ticketSummaries.weighedOutLoadsByContractAccount, 'accountName', 'account')
    },
    byProductCreatedAccountChart () {
      return new StackedBarChart(this.ticketSummaries.createdLoadsByContractAccount, 'accountName', 'account', 'product', { csv: { includeWeight: false, includeLoadCount: true } })
    },
    byDestinationLoadsByDriverChart () {
      return new StackedBarChart(this.ticketSummaries.loadsByDriver.filter(lbd => !!lbd.accountName), 'accountName', 'driver', 'destination', { csv: { includeWeight: false, includeLoadCount: true } })
    },

    selectedDestName () {
      let destination = ''
      if (this.summaryRequestObj) {
        destination = this.summaryRequestObj.locationId !== -1 ? this.summaryRequestObj.location.name : ''
      }
      return destination
    },

    widgetSlotSet () {
      const selectedWidgetMappings = this.chartSet.widgets
        .filter(ch => ch.visible)
        .map(ch => ch.id)
        .map(chartId => byproductsWidgetMappings.find(wm => wm.widget.value === chartId))
        .filter(id => id !== undefined)

      const isCardWidget = w => w.widgetType === 'card'
      const numCards = selectedWidgetMappings.filter(isCardWidget).length
      const widgets = selectedWidgetMappings.map(wm => new WidgetSlot(wm.widget.label, this.widgetDimensionsFor(wm.widgetType), { widget: wm.widget }))
      if (getNumEmptySlots(numCards) > 0) {
        const li = selectedWidgetMappings.findLastIndex(isCardWidget)
        return new WidgetSlotSet([
          ...widgets.slice(0, li + 1),
          ...new Array(getNumEmptySlots(numCards)).fill(new WidgetSlot('empty', this.widgetDimensionsFor('card'))),
          ...widgets.slice(li + 1)
        ])
      } else {
        return new WidgetSlotSet(widgets)
      }
    },

    locationId () {
      return this.summaryRequestObj?.locationId
    }
  },

  created () {
    this.chartSet = new ByproductDashboardWidgetSet()
    if (!localStorage.getItem(LocalStorageKeys.DASHBOARD_BYPRODUCT_TIME_LABEL)) localStorage.setItem(LocalStorageKeys.DASHBOARD_BYPRODUCT_TIME_LABEL, this.timeLabel)
  },

  methods: {
    ...mapActions('dashboard', ['fetchByproductSummaries']),

    destChosen (config) {
      this.sinceTime = utcToLocalDate(config.sinceTime)
      this.untilTime = utcToLocalDate(config.untilTime)
      this.timeLabel = config.label
      if (this.timeLabel !== 'custom') localStorage.setItem(LocalStorageKeys.DASHBOARD_BYPRODUCT_TIME_LABEL, config.label)
      else localStorage.setItem(LocalStorageKeys.DASHBOARD_BYPRODUCT_TIME_LABEL, 'today')
      this.summaryRequestObj = {
        ...config,
        includeExternal: config.destinationAccountId === -1 || config.locationId !== -1,
        includeInternal: true
      }
      this.refreshData(this.summaryRequestObj)
    },

    async refreshData (config) {
      this.ticketSummaries = await this.fetchByproductSummaries(config)
    },

    newChartLayout (chartSet) {
      this.chartSet = chartSet
    },

    widgetDimensionsFor (widgetType) {
      switch (widgetType) {
        case 'chart':
          return [9, 12]
        case 'card':
          return [2, {
            lg: 12, xl: 8
          }]
      }
    },

    addNewChart () {
      this.chartPickerMenuKey += 1
    },

    onDataClicked (widget, xAxisLabel, yAxisLabel, data, dataObject) {
      if (!this.validateTimePeriodForInteraction()) return
      this.$emit('data-clicked', {
        widget: widget,
        requestObject: this.summaryRequestObj,
        data: data,
        primaryFilterKey: xAxisLabel,
        secondaryFilterKey: yAxisLabel,
        primaryFilterValue: dataObject.xAxisValue,
        secondaryFilterValue: dataObject.yAxisValue
      })
    },

    validateTimePeriodForInteraction () {
      const timePeriodLength = timeBetween(localToUTC(this.summaryRequestObj.sinceTime), this.summaryRequestObj.untilTime ? localToUTC(this.summaryRequestObj.untilTime) : Date.now())
      if (timePeriodLength.includes('days') && parseInt(timePeriodLength) > MAX_DAYS_FOR_WIDGET_INTERACTION) {
        this.setSnack(this.$t('timePeriodMustBe15DaysOrShorterToViewTickets'))
        return false
      }
      return true
    }
  }
}
</script>
