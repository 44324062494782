<template>
<BPCard
  dataTestId="accruals-table"
  iconTooltip="Show Accruals"
  :headerConfig="headerConfig"
  :contractMode.sync="contractMode"
  hideNonTract
>
  <DataTable
  :loading="loading"
  :headers.sync="journalEntryHeaders"
  :items.sync="journalEntries"
  :customCells="customCells"
  :showDense="false"
  :actions="tableActions"
  @refresh="refreshJournalEntries"
  @toggle="toggleAllJournalEntries"
  >
      <template #prepend-search>
        <EntitySelector
        :entities="entities"
        :initialEntity.sync="selectedEntity"
        @entity-selected="entitySelected"
        class="mr-2"
        />
      </template>

      <template #actions="{item}" v-if="showingAllJournalEntries">
          <Icon
          icon="mdi-clipboard-outline"
          iconColor="grey"
          dataTestId="accrual-ticket-button"
          :tooltipText="$t('viewTicket')"
          @icon-clicked="actionButtonPressed(item, 'ticket')"/>
          <Icon
          icon="mdi-altimeter"
          dataTestId="accrual-contract-button"
          :tooltipText="$t('viewContract')"
          @icon-clicked="actionButtonPressed(item, 'contract')"/>
      </template>
      <template #actions="{item}" v-else>
        <Icon
        @icon-clicked="actionButtonPressed(item, 'tract')"
        icon="mdi-clipboard-text"
        dataTestId="acctual-tract-button"
        :small=false
        :tooltipText="$t('viewJournalEntriesByTract')"
        />
      </template>

      <!-- JournalEntries By Account Custom Cells -->
      <template #total-tickets="{item}">{{
        item.ticketCount
      }}</template>
      <template #total-net-weight="{item}">{{
        tonStringFromPounds(item.totalNetWeight)
      }}</template>
      <template #total-journalEntry="{item}">{{
        formatMoney(item.totalJournalEntry)
      }}</template>
      <template #average-rate="{item}">{{
        formatMoney(item.averageRate)
      }}</template>
      <!-- All JournalEntries Custom Cells -->
      <template #in-weight="{item}">{{
        tonStringFromPounds(item.inWeight)
      }}</template>
      <template #out-weight="{item}">{{
        tonStringFromPounds(item.outWeight)
      }}</template>
      <template #defect-weight="{item}">{{
        tonStringFromPounds(item.defectWeight)
      }}</template>
      <template #net-weight="{item}">{{
        tonStringFromPounds(item.netWeight)
      }}</template>
      <template #product="{item}">{{
        item.product ? item.product : $t('other')
      }}</template>
      <template #pay-on="{item}">
        {{payOn(item.computedAtPayOn)}}
      </template>
      <template #pay-by="{item}">
        {{payBy(item.computedAtPayBy)}}
      </template>
      <template #amount="{item}">{{
        `${formatMoney(item.amount)}`
      }}</template>
      <template #rate="{item}">
        <span>{{ formatMoney(item.computedAtRate) }}</span>
      </template>
      <template #body.append>
        <tr class="font-weight-bold">
          <td class="text-left"/>
          <td class="text-right" v-if="showingAllJournalEntries && contractMode?.value === ContractMode.Logs.value"/>
          <td class="text-right" v-if="showingAllJournalEntries"/>
          <td class="text-right">
            <span v-if="!showingAllJournalEntries">
              {{totalUniqueTicketCount}}
            </span>
          </td>
          <td class="text-right" v-if="!showingAllJournalEntries">
            {{tonStringFromPounds(totalNetWeight)}}
          </td>
          <td class="text-right">
            <span v-if="!showingAllJournalEntries"> {{formatMoney(totalJournalEntry)}} </span>
          </td>
          <td class="text-right"/>
          <td class="text-right" v-if="showingAllJournalEntries"/>
          <td class="text-right" v-if="showingAllJournalEntries">
            {{tonStringFromPounds(totalNetWeight)}}
          </td>
          <td class="text-right" v-if="showingAllJournalEntries"/>
          <td class="text-right" v-if="showingAllJournalEntries"/>
          <td class="text-right" v-if="showingAllJournalEntries"/>
          <td class="text-right" v-if="showingAllJournalEntries">
            {{formatMoney(totalJournalEntry)}}
          </td>
          <td class="text-right"/>
        </tr>
      </template>
      <template #activity="{item}">
        <Icon
        v-if="item.specialization !== TemplateSpecialization.None.value"
        :icon="TemplateSpecialization.forInt(item.specialization)?.icon"
        :tooltipText="TemplateSpecialization.forInt(item.specialization)?.name"/>
        <span>{{item.activityName}}</span>
      </template>
  </DataTable>
  <Dialog :stateId="dialogId" @dialog-closing="resetDialogs">
    <ContractDetails v-if="focusedContractId" :contractId="focusedContractId"/>
    <TicketDetails v-if="focusedTicketId" :ticketId="focusedTicketId" :contractMode="contractMode"/>
    <JournalEntriesByTract v-if="focusedAccountJournalEntry" @journalEntry-detail="$emit('journalEntry-detail')" :accountJournalEntry="focusedAccountJournalEntry" :isTDialog="true"/>
  </Dialog>
</BPCard>
</template>

<script>
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import { formatMoney, numberWithCommas, tonStringFromPounds } from '@/utils/NumericMutations'
import { allJournalEntriesCustomCells, journalEntriesByAccountCustomCells } from '@/components/accounting/journal-entries/JournalEntryCustomCells.js'
import { allJournalEntryHeaders, allBPJournalEntryHeaders, journalEntriesByAccountHeaders } from '@/headers/JournalEntry'
import { PayOn, PayBy, ContractMode, TemplateSpecialization } from '@/utils/Enumerations.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AccountingHelpers from '@/components/accounting/AccountingHelpers.js'
import { uniqueDialogId } from '@/utils/componentHelpers'

export default {
  name: 'Accruals',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    ContractDetails: () => import('@/components/contract/contract-detail/ContractDetail.vue'),
    TicketDetails: () => import('@/components/ticket/ticket-details/TicketDetails.vue'),
    JournalEntriesByTract: () => import('@/components/accounting/journal-entries/JournalEntryByTract.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    BPCard: () => import('@/components/core/BPCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    ContractMode,
    TemplateSpecialization,
    dialogId: uniqueDialogId('accruals'),
    loading: false,
    selectedEntity: undefined,
    showingAllJournalEntries: false,
    dialog: false,
    focusedTicketId: undefined,
    focusedContractId: undefined,
    focusedAccountJournalEntry: undefined,
    entities: [],
    contractMode: null
  }),

  computed: {
    ...mapGetters('journal-entries', ['allJournalEntries']),
    journalEntries () {
      if (this.showingAllJournalEntries) {
        return this.journalEntriesForEntity
      } else {
        return this.journalEntriesByAccount
      }
    },

    headerConfig () {
      switch (this.contractMode?.value) {
        case ContractMode.Logs.value: return { title: this.$t('currentAccruals'), subtitle: this.$t('accrualsSubtitle') }
        case ContractMode.Byproducts.value: return { title: this.$t('currentByProductAccruals'), subtitle: this.$t('BPAccrualsSubtitle') }
        case ContractMode.Transfers.value: return { title: this.$t('transferAccruals'), subtitle: this.$t('transferAccrualDescription') }
      }

      return null
    },

    journalEntriesForEntity () {
      if (this.selectedEntity === undefined) { return [] }
      return this.allJournalEntries.filter(p => p.businessEntityId === this.selectedEntity.businessEntityId)
    },

    journalEntriesByAccount () {
      const journalEntriesByAccount = AccountingHelpers.groupAccountingItemsByAccountName(this.journalEntriesForEntity, 'journalEntries')
      journalEntriesByAccount.forEach(pba => {
        pba.totalJournalEntry = (pba.journalEntries.reduce((a, b) => a + b.amount, 0))
        pba.averageRate = (pba.journalEntries.reduce((a, b) => a + b.computedAtRate, 0) / pba.journalEntries.length)
        pba.totalNetWeight = (pba.journalEntries.reduce((a, b) => a + (b.inWeight - b.outWeight - b.defectWeight), 0))
        pba.ticketCount = AccountingHelpers.uniqueTicketCount(pba.journalEntries)
      })

      return journalEntriesByAccount
    },
    totalUniqueTicketCount () { return AccountingHelpers.uniqueTicketCount(this.journalEntriesForEntity) },
    totalNetWeight () { return AccountingHelpers.getUniqueNetWeight(this.journalEntriesForEntity) },
    totalGross () { return this.journalEntriesForEntity.reduce((a, b) => a + b.grossAmount, 0) },
    totalJournalEntry () { return this.journalEntriesForEntity.reduce((a, b) => a + b.amount, 0) },

    journalEntryHeaders () {
      const accountJournalEntriesHeader = journalEntriesByAccountHeaders()
      if (this.contractMode.value !== ContractMode.Logs.value) accountJournalEntriesHeader.pop()
      const allJournalEntriesHeader = this.contractMode.value !== ContractMode.Logs.value ? allBPJournalEntryHeaders() : allJournalEntryHeaders()
      return this.showingAllJournalEntries
        ? allJournalEntriesHeader
        : accountJournalEntriesHeader
    },

    tableActions () {
      return [
        {
          icon: this.showingAllJournalEntries ? 'mdi-account' : 'mdi-view-list',
          text: this.showingAllJournalEntries ? this.$t('viewByAccount') : this.$t('viewAllAccruals'),
          actionName: 'toggle'
        },
        {
          icon: 'mdi-refresh',
          text: 'Refresh',
          actionName: 'refresh'
        }
      ]
    },

    customCells () {
      return this.showingAllJournalEntries
        ? allJournalEntriesCustomCells()
        : journalEntriesByAccountCustomCells()
    }
  },

  watch: {
    contractMode (val) {
      localStorage.setItem(LocalStorageKeys.AC_CONTRACT_MODE, JSON.stringify(val))
      this.refreshJournalEntries()
    }
  },

  async created () {
    const contractModeCookie = localStorage.getItem(LocalStorageKeys.AC_CONTRACT_MODE)

    if (contractModeCookie) {
      this.contractMode = JSON.parse(contractModeCookie)
    } else {
      this.contractMode = ContractMode.Logs
    }
  },

  methods: {
    ...mapActions('journal-entries', ['fetchJournalEntries']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapMutations('global', ['setRouterJump']),

    entitySelected (val) {
      this.selectedEntity = val
    },

    formatMoney,
    numberWithCommas,
    tonStringFromPounds,
    payOn: (x) => PayOn.fromInt(x),
    payBy: (x) => PayBy.fromInt(x),

    async refreshJournalEntries () {
      this.loading = true
      try {
        await this.fetchJournalEntries(this.contractMode.value)
        this.getEntitiesFromJournalEntries()
      } finally {
        this.loading = false
      }
    },

    toggleAllJournalEntries () {
      this.showingAllJournalEntries = !this.showingAllJournalEntries
    },

    getEntitiesFromJournalEntries () {
      this.entities = AccountingHelpers.getUniqueEntitiesFromAccountingItems(this.allJournalEntries)

      if (this.entities.length > 0 && this.selectedEntity === undefined) {
        this.selectedEntity = this.entities[0]
      }
    },

    resetDialogs () {
      this.dialog = false
      this.focusedContractId = undefined
      this.focusedTicketId = undefined
      this.focusedAccountJournalEntry = undefined
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    actionButtonPressed (journalEntry, dialogType) {
      this.resetDialogs()
      switch (dialogType) {
        case 'contract': this.focusedContractId = journalEntry.contractId; break
        case 'ticket': this.focusedTicketId = journalEntry.ticketId; break
        case 'tract': this.focusedAccountJournalEntry = journalEntry; break
        default: return
      }

      this.dialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    }
  }
}
</script>
