import i18n from '@/i18n'

export default {
  loggingMethodHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'name'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  }
}
