export default {
  setHarvestTypeLoadingStatus (state, status) {
    state.harvestTypeLoadingStatus = status
  },
  setHarvestTypes (state, harvestTypes) {
    state.harvestTypes = harvestTypes
  },
  addHarvestType (state, harvestType) {
    state.harvestTypes.push(harvestType)
  },
  removeHarvestType (state, harvestTypeId) {
    state.harvestTypes = state.harvestTypes.filter(t => t.harvestTypeId !== harvestTypeId)
  },
  updateHarvestType (state, harvestType) {
    const index = state.harvestTypes.findIndex(t => t.harvestTypeId === harvestType.harvestTypeId)
    if (index !== -1) {
      Object.assign(state.harvestTypes[index], harvestType)
    }
  }
}
