<template>
  <v-card id="elementId" :class="`${elementClass} fill-height ${$vuetify.breakpoint.xs ? '' : 'px-1'}`" outlined>
    <v-card-title class="subtitle-1 pb-3">
      <v-row no-gutters>
        <v-col>
          <slot name="title"/>
          <span>
            {{ title }}
          </span>
        </v-col>
        <v-col cols="auto">
          <slot name="title.append"/>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pb-1">
      <slot/>
    </v-card-text>
    <v-card-actions>
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DetailCard',

  props: {
    elementId: {
      type: String,
      required: false,
      default: undefined
    },
    elementClass: {
      type: String,
      required: false,
      default: undefined
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
