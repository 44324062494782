<template>
  <v-row>
    <v-col>
      <v-row class="mt-1">
        <v-col cols="12">
          <v-container fluid>
            <v-expansion-panels popout multiple tile v-model="openPanels">
              <v-expansion-panel v-if="!noticketsAccruals" value="ticketAccruals">
                <v-expansion-panel-header>
                  {{$t('ticketAccruals')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-if="paymentsEntries.length > 0">
                    <p class="subtitle-1 mt-3 mb-n2">{{$t('contractPayments')}}</p>
                    <TicketFinancialsTable
                    :entries="paymentsEntries"
                    @aggregateSelected="contractPaymentSelected"/>
                  </span>
                  <span v-if="correctionEntries.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="correctionEntries"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && transferAccrualsEntries.length > 0" value="transferAccruals">
                <v-expansion-panel-header>
                  {{$t('transferAccruals')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  :entries="transferAccrualsEntries"
                  @aggregateSelected="transferAccrualSelected"/>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="!isByproducts && logYardSaleAccrualEntries.length > 0" value="logYardSaleAccruals">
                <v-expansion-panel-header>
                  {{$t('logYardSaleAccruals')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TicketFinancialsTable
                  :entries="logYardSaleAccrualEntries"
                  @aggregateSelected="logYardSaleAccrualSelected"/>

                  <span v-if="logYardSaleCorrectionEntries.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="logYardSaleCorrectionEntries"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-col>
      </v-row>
      <NoData v-if="showNoData" class="mb-4">
        {{ $t('noDataForSelection') }}
      </NoData>
    </v-col>
    <v-dialog v-model="detailDialogOpen" width="900px">
      <TicketwiseFinancialBreakdown
      v-if="detailDialogOpen"
      :isCorrection="focusedContractPaymentIsCorrection"
      :payableSummary="focusedContractPayment"
      :includeRecoveries="!focusedAccrualIsTransfer"
      :isByproducts="isByproducts"
      @close="closeDialogs"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { MiscPaymentType } from '../../../../utils/Enumerations'
export default {
  name: 'AccrualsAggregations',

  components: {
    TicketwiseFinancialBreakdown: () => import('@/components/accounting/adjusting-entries/v1/TicketwiseFinancialBreakdown.vue'),
    TicketFinancialsTable: () => import('@/components/accounting/adjusting-entries/v1/TicketFinancialsTable.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    accruals: {
      type: Object,
      required: true
    },
    isByproducts: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    detailDialogOpen: false,
    focusedContractPayment: undefined,
    focusedContractPaymentIsCorrection: false,
    focusedAccrualIsTransfer: false,
    openPanels: [],
    MiscPaymentType
  }),

  mounted () {
    if (this.isByproducts) {
      this.openPanels = !this.noticketsAccruals ? [0] : []
    } else {
      if ((!this.noticketsAccruals +
        (this.transferAccrualsEntries.length > 0) +
        (this.logYardSaleCorrectionEntries.length > 0)) === 1
      ) {
        this.openPanels = [0]
      }
    }
  },

  computed: {
    contractPaymentHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    correctionsHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    paymentsEntries () {
      return Array.from(this.accruals?.contractPayments?.values() ?? [])
    },
    correctionEntries () {
      return Array.from(this.accruals?.corrections?.values() ?? [])
    },
    logYardSaleCorrectionEntries () {
      return Array.from(this.accruals?.logYardSaleCorrections?.values() ?? [])
    },
    transferAccrualsEntries () {
      return Array.from(this.accruals?.transferAccruals?.values() ?? [])
    },
    logYardSaleAccrualEntries () {
      return Array.from(this.accruals?.logYardSaleAccruals?.values() ?? [])
    },
    noticketsAccruals () {
      return this.paymentsEntries.length === 0 && this.correctionEntries.length === 0
    },
    showNoData () {
      return this.noticketsAccruals &&
        this.transferAccrualsEntries.length === 0 &&
        this.logYardSaleAccrualEntries.length === 0 &&
        this.logYardSaleCorrectionEntries.length === 0 &&
        this.transferAccrualsEntries.length === 0
    },
    contractColor () {
      return this.isByproducts ? 'byproduct' : 'primary'
    },
    borderStyle () {
      return `border: 3px solid ${this.isByproducts ? '#363636' : '#D15F27'}`
    }
  },

  methods: {
    formatMoney,

    contractPaymentSelected (contractPayment) {
      this.detailDialogOpen = true
      this.focusedContractPayment = contractPayment
    },

    correctionSelected (correction) {
      this.focusedContractPayment = correction
      this.focusedContractPaymentIsCorrection = true
      this.detailDialogOpen = true
    },

    transferAccrualSelected (transferAccrual) {
      this.focusedContractPayment = transferAccrual
      this.focusedAccrualIsTransfer = true
      this.detailDialogOpen = true
    },

    logYardSaleAccrualSelected (logYardSaleAccrual) {
      this.focusedContractPayment = logYardSaleAccrual
      this.focusedAccrualIsLogYardSale = true
      this.detailDialogOpen = true
    },

    closeDialogs () {
      this.detailDialogOpen = false
      this.focusedContractPayment = undefined
      this.focusedContractPaymentIsCorrection = false
      this.focusedAccrualIsTransfer = false
      this.focusedAccrualIsLogYardSale = false
    }
  }
}
</script>
