export class LocalStorageActor {
  constructor ({ keyGetter, read, write, init }) {
    this.keyGetter = keyGetter
    this._readTransform = read
    this._writeTransform = write
    this._init = init
  }

  get key () { return this.keyGetter() }

  write (item) {
    try {
      localStorage.setItem(this.key, JSON.stringify(this._writeTransform(item)))
    } catch (e) {
      console.error(`Failed writing to local storage value '${this.key}'`, e)
    }
  }

  read (context) {
    let stored = localStorage.getItem(this.key)
    if (stored === 'undefined') stored = null

    const isPresent = stored !== null
    try {
      if (this._init && !isPresent) {
        const initial = this._init(context)
        this.write(initial)
        return initial
      } else {
        return this._readTransform(JSON.parse(stored), { context, isPresent })
      }
    } catch (e) {
      console.error(`Failed transforming local storage value '${this.key}'`, stored, e)
      return undefined
    }
  }
}

const getKeyForTenant = (key) => {
  const mtFromLocalStorage = localStorage.getItem(LocalStorageKeys.MULTI_TENANT_CONFIGURATION)
  const multiTenantConfig = mtFromLocalStorage ? JSON.parse(mtFromLocalStorage) : null
  if (multiTenantConfig?.isMultiTenant && !!multiTenantConfig?.selectedTenant) {
    return `${multiTenantConfig.selectedTenant.code}:${key}`
  }
  return key
}

export const LocalStorageKeys = {
  // Supertenant-level keys
  MULTI_TENANT_CONFIGURATION: 'MT_CONFIGURATION',
  // Tenant-level keys
  get ACCOUNTS_GRID_TAG_IDS () { return getKeyForTenant('ACCOUNTS_GRID_TAG_IDS') },
  get STANDING_TIMBER_FILTER () { return getKeyForTenant('STANDING_TIMBER_FILTER') },
  get COMMITTED_TIMBER_FILTER () { return getKeyForTenant('COMMITTED_TIMBER_FILTER') },
  get TIMBER_PORTFOLIO_FILTER () { return getKeyForTenant('TIMBER_PORTFOLIO_FILTER') },
  get SETTLEMENT_CONTRACT_STATUSES () { return getKeyForTenant('PP_SETTLEMENT_CONTRACT_STATUSES') },
  get SETTLEMENT_PAYEE_STATUSES () { return getKeyForTenant('PP_SETTLEMENT_PAYEE_STATUSES') },
  get PRODUCT_COSTS_INCLUDE_TICKETS () { return getKeyForTenant('PRODUCT_COSTS_INCLUDE_TICKETS') },
  get INCLUDE_TRACT_SUMMARY_PDF () { return getKeyForTenant('INCLUDE_TRACT_SUMMARY_PDF') },
  get PRODUCT_COST_SORT_CONFIG () { return getKeyForTenant('PRODUCT_COST_SORT_CONFIG') },
  get SETTLEMENTS_CONTRACT_MODE () { return getKeyForTenant('SETTLEMENTS_CONTRACT_MODE') },
  get ADJUSTING_ENTRIES_CONTRACT_MODE () { return getKeyForTenant('ADJUSTING_ENTRIES_CONTRACT_MODE') },
  get TRACT_STATUS_FILTER () { return getKeyForTenant('TRACT_STATUS_FILTER') },
  get PRECIPITATION_CHART_UNITS () { return getKeyForTenant('PRECIPITATION_CHART_UNITS') },
  get ADVANCED_SEARCH_BP_STATE () { return getKeyForTenant('ADVANCED_SEARCH_BP_STATE') },
  get ADVANCED_SEARCH_LOGS_STATE () { return getKeyForTenant('ADVANCED_SEARCH_LOGS_STATE') },
  get ADVANCED_SEARCH_TRANSFER_STATE () { return getKeyForTenant('ADVANCED_SEARCH_TRANSFER_STATE') },
  get ADVANCED_SEARCH_LYS_STATE () { return getKeyForTenant('ADVANCED_SEARCH_LYS_STATE') },
  get TRACT_MAP_SCALE_BAR_TYPE () { return getKeyForTenant('TRACT_MAP_SCALE_BAR_TYPE') },
  get TRACT_CONTRACT_REQUIRES_LOGGER () { return getKeyForTenant('TRACT_CONTRACT_REQUIRES_LOGGER') },
  get BP_CONTRACT_PICK_UP_LOADS () { return getKeyForTenant('BP_CONTRACT_PICK_UP_LOADS') },
  get LOG_EXPORT_FILTER_BY () { return getKeyForTenant('LOG_EXPORT_FILTER_BY') },
  get BYPRODUCT_EXPORT_FILTER_BY () { return getKeyForTenant('BYPRODUCT_EXPORT_FILTER_BY') },
  get TRANSFER_EXPORT_FILTER_BY () { return getKeyForTenant('TRANSFER_EXPORT_FILTER_BY') },
  get HIDE_ADVANCES_WITH_ZERO_BALANCE () { return getKeyForTenant('HIDE_ADVANCES_WITH_ZERO_BALANCE') },
  get LOGS_DASHBOARD_DESTINATION_ID () { return getKeyForTenant('LOGS_DASHBOARD_DESTINATION_ID') },
  get BYPRODUCTS_DASHBOARD_DESTINATION_ID () { return getKeyForTenant('BYPRODUCTS_DASHBOARD_DESTINATION_ID') },
  get TEMPLATE_ENTITY_ID () { return getKeyForTenant('TEMPLATE_ENTITY_ID') },
  get DASHBOARD_BYPRODUCT_TIME_LABEL () { return getKeyForTenant('DASHBOARD_BYPRODUCT_TIME_LABEL') },
  get DASHBOARD_TICKETS_TIME_LABEL () { return getKeyForTenant('DASHBOARD_TICKETS_TIME_LABEL') },
  get DEVICE_REGISTRATIONS_TABLE_FILTER () { return getKeyForTenant('DEVICE_REGISTRATIONS_TABLE_FILTER') },
  get DASHBOARD_LOCATION_ID () { return getKeyForTenant('DASHBOARD_LOCATION_ID') },
  get DASHBOARD_BP_LOCATION_ID () { return getKeyForTenant('DASHBOARD_BP_LOCATION_ID') },
  get SETTLEMENTS_USE_METRIC_TONS () { return getKeyForTenant('SETTLEMENTS_USE_METRIC_TONS') },
  get SETTLEMENTS_USE_BYPRODUCT_METRIC_TONS () { return getKeyForTenant('SETTLEMENTS_USE_BYPRODUCT_METRIC_TONS') },
  get TRACT_CRUISE_UNITS () { return getKeyForTenant('TRACT_CRUISE_UNITS') },
  get CONSUMPTION_BATCHES_TIME_LABEL () { return getKeyForTenant('CONSUMPTION_BATCHES_TIME_LABEL') },
  get CONSUMPTION_BATCHES_SINCE_TIME () { return getKeyForTenant('CONSUMPTION_BATCHES_SINCE_TIME') },
  get CONSUMPTION_BATCHES_UNTIL_TIME () { return getKeyForTenant('CONSUMPTION_BATCHES_UNTIL_TIME') },
  get LOGGER_ALLOCATION_PERIOD () { return getKeyForTenant('LOGGER_ALLOCATION_PERIOD') },
  get SHOW_OVERCUT_DEPLETION_IN_PROFIT () { return getKeyForTenant('SHOW_OVERCUT_DEPLETION_IN_PROFIT') },
  // Former cookies
  get DASHBOARD_DECK_ID () { return getKeyForTenant('DASHBOARD_DECK_ID') },
  get DASHBOARD_TIME_PERIOD () { return getKeyForTenant('DASHBOARD_TIME_PERIOD') },
  get TICKET_FORM_ENTERING_POUNDS () { return getKeyForTenant('ticketFormEnteringPounds') },
  get TRACT_DETAIL_VIEW_LOGGERS () { return getKeyForTenant('TRACT_DETAIL_VIEW_LOGGERS') },
  get VIEW_CRUISE_DATA () { return getKeyForTenant('TRACT_DETAIL_VIEW_CRUISE_DATA') },
  get TEMPLATE_FORM_BUSINESS_ENTITY () { return getKeyForTenant('TEMPLATE_FORM_BUSINESS_ENTITY') },
  get DRAWER_MINI () { return getKeyForTenant('DRAWER_MINI') },
  get ACCOUNT_GRID_TAG_IDS () { return getKeyForTenant('ACCOUNT_GRID_TAG_IDS') },
  get CONTRACT_GRID_TICKET_MODE () { return getKeyForTenant('CONTRACT_GRID_TICKET_MODE') },
  get TICKET_GRID_MODE () { return getKeyForTenant('TICKET_GRID_MODE') },
  get BP_TICKETS_VIEW () { return getKeyForTenant('BP_TICKETS_VIEW') },
  get BP_CONTRACTS_VIEW () { return getKeyForTenant('BP_CONTRACTS_VIEW') },
  get ACTIVITY_TEMPLATE_ENTITY_ID () { return getKeyForTenant('ACTIVITY_TEMPLATE_ENTITY_ID') },
  get LIMIT_TO_TRACT_LEVEL_CAPTURE () { return getKeyForTenant('LIMIT_TO_TRACT_LEVEL_CAPTURE') },
  get CONTRACTS_TABLE_FILTER () { return getKeyForTenant('CONTRACT_QUERY_PARAMS') },
  get CONTRACTS_OPERATION_ASDRAFT () { return getKeyForTenant('CONTRACTS_OPERATION_MARK_AS_DRAFT') },
  get DEFAULT_LOG_QUALITY_UNIT_ISPLF () { return getKeyForTenant('DEFAULT_LOG_QUALITY_UNIT_ISPLF') },
  get TRACT_DESTINATION_TO_CONTRACT () { return getKeyForTenant('TRACT_DESTINATION_TO_CONTRACT_FORM') },
  get CONTRACTS_OPERATION_USE_CRUISE_FOR_STUMPAGE () { return getKeyForTenant('CONTRACTS_OPERATION_USE_CRUISE_FOR_STUMPAGE') },
  get AP_CONTRACT_MODE () { return getKeyForTenant('AP_CONTRACT_MODE') },
  get AR_CONTRACT_MODE () { return getKeyForTenant('AR_CONTRACT_MODE') },
  get AC_CONTRACT_MODE () { return getKeyForTenant('AC_CONTRACT_MODE') }
}
