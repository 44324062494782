<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col>
            <DetailCard :title="$t('generalInformation')">
              <template v-for="p in payableProperties">
                <v-row no-gutters :key="p.label ?? p.key" v-if="displayField(p.key) || p.format">
                  <v-col cols="6" xs="6" sm="5">
                    {{$t(p.label ?? p.key)}}:
                  </v-col>
                  <v-col :style="infoCardRightColumn">
                    <span>
                      {{p.format ? Array.isArray(p.key) ? p.format(...p.key.map(key => payable[key])) : p.format(payable[p.key]) : payable[p.key]}}
                    </span>
                  </v-col>
                </v-row>
              </template>
              <template #actions>
                <v-row justify="end" class="mb-0">
                  <v-col cols="auto">
                    <v-btn
                    :color="color"
                    min-width="130px"
                    @click="poundMultiplier === 1 ? poundMultiplier = 2000 : poundMultiplier = 1">
                      <v-icon small class="mr-2" color="white">mdi-scale</v-icon>
                      <span>{{poundMultiplier === 1 ? 'LBS' : 'TONS'}}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </DetailCard>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="subtitle-1 mr-2">{{recoveriesText}}</span>
            <v-progress-circular indeterminate color="primary" v-if="recoveriesLoading" size="15">
            </v-progress-circular>
            <v-row v-for="recovery in recoveries" :key="recovery.recoveryId" class="mb-n5">
              <v-col >
                  <span>{{recovery.advanceNote}}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="font-weight-medium">{{formatMoney(recovery.amount)}}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col xs="12" sm="12" md="6">
        <v-card class="grey lighten-4" flat>
          <v-card-title>
            <v-col>
              <v-sheet elevation="2">
                <v-row no-gutters>
                  <v-col cols="12">
                    <p class="subtitle-1 mt-4 mb-n3" style="text-align: center;">{{$t('effectivePayable')}}</p>
                  </v-col>
                </v-row>
                <v-row class="subtitle-1 mt-4 mb-n3" no-gutters>
                  <v-col cols="12">
                    <h2 style="text-align:center; word-break:normal;">
                      {{payableAmount}}
                    </h2>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <h3 class="subtitle-1" style="text-align:center;">
                      {{formatMoney(payable.computedAtRate)}} per ton
                    </h3>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-card-title>
          <v-card-text style="max-width:340px; margin:auto;">
            <p class="subtitle-1" style="text-align: center;">Yield Breakdown</p>
            <canvas max-width="150px" id="weightChart"></canvas>
            <p class="mr-6" style="text-align: right">{{netPercentage}} net</p>
            <p class="mr-6 mt-n3" style="text-align: right">{{defectPercentage}} defect</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations'
import { PayOn, PayBy } from '@/utils/Enumerations'
import { Chart } from 'chart.js'
import { mapActions } from 'vuex'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { hexCodeForContractType } from '@/utils/componentHelpers'

export default {
  name: 'PayableDetailContent',

  components: {
    DetailCard: () => import('@/components/core/DetailCard.vue')
  },

  props: {
    propPayable: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    weightChart: null,
    poundMultiplier: 2000,
    recoveries: [],
    windowWidth: 0,
    payable: {},
    recoveriesLoading: false
  }),

  computed: {
    payableProperties () {
      return [
        {
          key: 'ticketNumber'
        },
        {
          key: 'extTicketNumber1',
          label: 'ext1'
        },
        {
          key: 'extTicketNumber2',
          label: 'ext2'
        },
        {
          key: 'contractAccountName',
          label: 'account'
        },
        {
          key: 'fromAccountName',
          label: 'fromAccount'
        },
        {
          key: 'settingName',
          label: 'setting'
        },
        {
          key: 'destinationName',
          label: 'destination'
        },
        {
          key: 'tractName',
          label: 'tract'
        },
        {
          key: 'activityName',
          label: 'activity'
        },
        {
          key: 'product',
          format: (v) => v ?? this.$t('other')
        },
        {
          key: 'computedAtPayOn',
          label: 'payOn',
          format: (v) => this.payOnFromInt(v)
        },
        {
          key: 'computedAtPayBy',
          label: 'payBy',
          format: (v) => this.payByFromInt(v)
        },
        {
          key: ['inWeight', 'outWeight'],
          label: 'gross',
          format: (inWeight, outWeight) => `${numberWithCommas((inWeight - outWeight) / this.poundMultiplier, this.poundMultiplier === 1 ? 0 : 3)} ${this.weightLabel}s`
        },
        {
          key: 'defectWeight',
          label: 'defect',
          format: (v) => `${numberWithCommas(v / this.poundMultiplier, this.poundMultiplier === 1 ? 0 : 3)} ${this.weightLabel}s`
        },
        {
          key: 'netWeight',
          label: 'net',
          format: (v) => `${((v / this.poundMultiplier).toFixed(this.poundMultiplier === 1 ? 0 : 3))} ${this.weightLabel}s`
        },
        {
          key: 'computedAt',
          label: 'calculated',
          format: (v) => utcToLocalDate(v, 'L - LT')
        }
      ]
    },

    weightLabel () {
      if (this.poundMultiplier === 1) {
        return 'lb'
      } else {
        return 'ton'
      }
    },

    payableAmount () {
      if (this.payable.amount === this.payable.grossAmount) {
        return `${formatMoney(this.payable.amount)}`
      }
      return `${formatMoney(this.payable.amount)} (${formatMoney(this.payable.grossAmount)})`
    },

    smallScreenSize () {
      return this.windowWidth < 960
    },

    recoveriesText () {
      const showNoRecoveries = !this.recoveriesLoading && this.recoveries.length === 0
      return showNoRecoveries ? this.$t('noRecoveriesPayable') : this.$t('recoveries')
    },

    pricePerTon () {
      if (this.payable.amount <= 0) {
        return this.formatMoney(0)
      }
      if (this.poundMultiplier === 1) {
        let precision = 0
        const ppt = this.payable.amount / this.payable.netWeight
        if (ppt < 0.01) {
          precision = 5
        } else {
          precision = 2
        }
        return `$${ppt.toFixed(precision)}`
      }
      return this.formatMoney(this.payable.amount / (this.payable.netWeight / this.poundMultiplier))
    },

    netPercentage () {
      const percentage = ((this.payable.netWeight / (this.payable.netWeight + this.payable.defectWeight)) * 100).toFixed(2)
      return `${percentage}%`
    },

    defectPercentage () {
      const percentage = ((this.payable.defectWeight / (this.payable.netWeight + this.payable.defectWeight)) * 100).toFixed(2)
      return `${percentage}%`
    },

    infoCardRightColumn () {
      return this.$vuetify.breakpoint.xs ? 'text-align:right;' : ''
    },

    color () {
      return hexCodeForContractType(this.payable.contractType)
    }
  },

  async mounted () {
    this.windowWidth = window.innerWidth
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.recoveriesLoading = true
    try {
      const response = await this.getPayable(this.propPayable.payableId)
      this.recoveries = response.recoveries
      this.payable = response
      this.createWeightChart()
    } finally {
      this.recoveriesLoading = false
    }
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    ...mapActions('payable', ['getPayable']),
    utcToLocalDate,
    formatMoney,
    numberWithCommas,
    payOnFromInt: (x) => PayOn.fromInt(x),
    payByFromInt: (x) => PayBy.fromInt(x),
    createWeightChart () {
      const chartElement = document.getElementById('weightChart')
      this.weightChart = new Chart(chartElement, {
        type: 'doughnut',
        data: this.getWeightData()
      })
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },

    getWeightData () {
      const { netWeight, defectWeight } = this.payable

      return {
        labels: [this.$t('net'), this.$t('defect')],
        datasets: [
          {
            backgroundColor: [
              this.color,
              this.color.concat('66')
            ],
            data: [
              netWeight,
              defectWeight
            ]
          }
        ]
      }
    },

    displayField (key) {
      return Array.isArray(key)
        ? key.every(k => this.payable[k] != null)
        : this.payable[key] != null && this.payable[key] !== ''
    }
  }
}
</script>
