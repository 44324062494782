import i18n from '../i18n'

export default {
  roleFormHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'userName',
        align: 'left'
      },
      {
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center'
      }
    ]
  },

  usersByRoleHeaders () {
    return [
      {
        sortable: false,
        text: i18n.t('role'),
        value: 'name',
        align: 'left'
      }
    ]
  },

  rolesByUserHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('name'),
        value: 'userName',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('preferredUsername'),
        value: 'preferredUsername',
        align: 'left'
      }
    ]
  },

  roleHeaders () {
    return [
      {
        sortable: true,
        text: i18n.t('role'),
        value: 'name',
        align: 'left'
      },
      {
        sortable: true,
        text: i18n.t('assigned'),
        value: 'assigned',
        align: 'center'
      }
    ]
  }
}
