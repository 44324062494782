<template>
  <div>
    <v-row class="mt-2 mb-n4">
      <v-col cols="12">
        <span v-if="singleContract" class="title">
          <span v-for="(ci, i) in contractIconsFor(contracts[0].type, 'contract')" :key="i">
            <Icon
            :icon="ci.icon"
            :tooltipText="$t(ci.tooltip)"
            iconColor="primary"/>
            <span class="mr-1 mt-n1 body-1">{{ ci.value(contracts[0].contractInfo) }}</span>
          </span>
        </span>
        <span v-else class="title">
          {{ $t('allContracts') }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-n12">
        <RateVerificationTable
        :items="aggregatedData"
        :groupingKeys="groupingKeys"
        :expandToContract="!singleContract"
        @refresh-batch="$emit('refresh-batch')"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rateVerificationGroupingKeys } from '@/headers/Cycles.js'
import { contractIconsFor } from '../accounts-payable-module/ContractIcons'
import { TemplateSpecialization } from '@/utils/Enumerations.js'

export default {
  name: 'RateVerificationSBC',

  components: {
    RateVerificationTable: () => import('./RateVerificationTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    singleContract: {
      type: Boolean,
      default: false
    },
    contracts: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    groupingKeys () {
      return rateVerificationGroupingKeys()
    },

    aggregatedData () {
      if (this.contracts.length === 0) return []

      let index = 0
      const rateKeyMap = new Map()
      this.contracts.forEach(contract => contract.payeeInfo.forEach(payee => payee.loadSummaries.forEach(loadSummary => {
        const ticket = { ticketId: loadSummary.ticketId, postBatchId: loadSummary.postBatchId }

        loadSummary.paymentDetails.filter(pd => pd.activityTemplateSpecialization !== TemplateSpecialization.Penalty.value).forEach(paymentDetail => {
          const rateKey = [paymentDetail.activity, loadSummary.product, paymentDetail.computedAtRate, payee.payeeName].join(',')

          const rateKeyMapEntry = rateKeyMap.get(rateKey) ?? {
            activity: paymentDetail.activity,
            product: loadSummary.product,
            rate: paymentDetail.computedAtRate,
            counterparty: payee.payeeName,
            contracts: new Map(),
            index: index++
          }
          const entryContract = rateKeyMapEntry.contracts.get(contract.contractInfo.contractId) ?? {
            contractId: contract.contractInfo.contractId,
            accountName: contract.contractInfo.accountName,
            destinationName: contract.contractInfo.destinationName,
            settingName: contract.contractInfo.settingName,
            tractName: contract.contractInfo.tractName,
            tickets: []
          }

          if (!entryContract.tickets.some(t => t.ticketId === ticket.ticketId)) entryContract.tickets.push(ticket)

          rateKeyMapEntry.contracts.set(contract.contractInfo.contractId, entryContract)
          rateKeyMap.set(rateKey, rateKeyMapEntry)
        })
      })))
      return Array.from(rateKeyMap.values())
    }
  },

  methods: {
    contractIconsFor
  }
}
</script>
