<template>
  <v-container fluid data-testid="activity-template-table">
    <v-row dense>
      <v-col>
        <material-card
          color="primary"
          :title="title"
          :text="$t('templateDescription')"
          full-width
        >
          <v-tabs v-model="tab">
            <v-tab>
              {{ $t('activityTemplates') }}
            </v-tab>
            <v-tab>
              {{ $t('templateBundles') }}
            </v-tab>
            <v-tab-item :transition="false" disabled>
              <TemplatesTab
                @confirm-delete-template="confirmDeleteTemplate"
                @category-selected="setSelectedCategory"
              />
            </v-tab-item>
            <v-tab-item :transition="false" disabled class="pb-2">
              <BundlesTab />
            </v-tab-item>
          </v-tabs>
        </material-card>
      </v-col>
    </v-row>
    <v-dialog width="400px" v-model="deleting">
      <ConfirmDelete
        @close="deleting = false"
        v-if="deleting"
        :title="$t('template')"
        @delete="deleteItem"
        @cancel-delete="close"
      />
    </v-dialog>

  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { AccountingCategory } from '@/utils/Enumerations'
export default {
  name: 'TemplateSettings',

  components: {
    TemplatesTab: () => import('./TemplatesTab.vue'),
    BundlesTab: () => import('./BundlesTab.vue'),
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue')
  },

  data: () => ({
    deleting: false,
    dialog: false,
    tab: 0,
    selectedCategory: AccountingCategory.Payable
  }),

  computed: {
    title () {
      if (this.tab === 0) {
        switch (this.selectedCategory.value) {
          case (AccountingCategory.Payable.value):
            return this.$t('accountsPayableActivityTemplates')
          case (AccountingCategory.Receivable.value):
            return this.$t('accountsReceivableActivityTemplates')
          case (AccountingCategory.Accrual.value):
            return this.$t('accrualsActivityTemplates')
          default:
            return this.$t('activityTemplates')
        }
      } else return this.$t('templateBundles')
    }
  },

  watch: {
    dialog (val) {
      if (!val) {
        this.close()
      }
    }
  },

  methods: {
    ...mapActions('activity-templates', [
      'fetchTemplateBundles',
      'fetchActivityTemplates',
      'deleteActivityTemplate'
    ]),

    async refreshActivityTemplates () {
      await this.fetchActivityTemplates()
      await this.fetchTemplateBundles()
    },

    async deleteItem () {
      await this.deleteActivityTemplate(this.focusedTemplate.activityTemplateId)
      this.close()
      await this.refreshActivityTemplates()
    },

    close () {
      this.dialog = false
      this.deleting = false
      this.focusedTemplate = Object.assign({}, {})
    },

    confirmDeleteTemplate (template) {
      this.focusedTemplate = Object.assign({}, template)
      this.deleting = true
    },

    setSelectedCategory (category) {
      this.selectedCategory = category
    }
  }
}
</script>
