export default class Ticket {
  constructor (serverTicket) {
    this.account = serverTicket.account
    this.accountId = serverTicket.accountId
    this.accountCode = serverTicket.accountCode
    this.accountCertificationStatus = serverTicket.accountCertificationStatus
    this.averageLength = serverTicket.averageLength
    this.consumed = serverTicket.consumed
    this.consumptionBatchId = serverTicket.consumptionBatchId
    this.contractId = serverTicket.contractId
    this.contractType = serverTicket.contractType
    this.correctionType = serverTicket.correctionType
    this.createdAt = serverTicket.createdAt
    this.deckId = serverTicket.deckId
    this.deckName = serverTicket.deckName
    this.defectWeight = serverTicket.defectWeight
    this.mismanufactureDefectWeight = serverTicket.mismanufactureDefectWeight
    this.natureDefectWeight = serverTicket.natureDefectWeight
    this.defects = serverTicket.defects
    this.departedAt = serverTicket.departedAt
    this.departureWeight = serverTicket.departureWeight
    this.destination = serverTicket.destination
    this.destinationCode = serverTicket.destinationCode
    this.destinationAccountId = serverTicket.destinationAccountId
    this.extTicketNumber1 = serverTicket.extTicketNumber1
    this.extTicketNumber2 = serverTicket.extTicketNumber2
    this.fromAccount = serverTicket.fromAccount
    this.fromAccountId = serverTicket.fromAccountId
    this.fromAccountCertificationStatus = serverTicket.fromAccountCertificationStatus
    this.fromDeckId = serverTicket.fromDeckId
    this.fromDeckName = serverTicket.fromDeckName
    this.inWeight = serverTicket.inWeight
    this.netWeight = serverTicket.inWeight - serverTicket.outWeight - serverTicket.defectWeight
    this.grossWeight = serverTicket.inWeight - serverTicket.outWeight
    this.isOnDraftContract = serverTicket.isOnDraftContract
    this.isExternal = serverTicket.isExternal
    this.latitude = serverTicket.latitude
    this.loadCreatedAt = serverTicket.loadCreatedAt
    this.logger = serverTicket.logger
    this.hauler = serverTicket.hauler
    this.haulerAccountId = serverTicket.haulerAccountId
    this.supplier = serverTicket.supplier
    this.supplierAccountId = serverTicket.supplierAccountId
    this.consultingForester = serverTicket.consultingForester
    this.consultingForesterId = serverTicket.consultingForesterId
    this.loggerAccountId = serverTicket.loggerAccountId
    this.loggerCertificationStatus = serverTicket.loggerCertificationStatus
    this.driver = serverTicket.driver
    this.driverAccountId = serverTicket.driverAccountId
    this.longitude = serverTicket.longitude
    this.modifiedAt = serverTicket.modifiedAt
    this.netWeight = serverTicket.inWeight - serverTicket.outWeight - serverTicket.defectWeight
    this.originationTicketID = serverTicket.originationTicketID
    this.outWeight = serverTicket.outWeight
    this.pieces = serverTicket.pieces
    this.plf = getPLF(serverTicket)
    this.product = serverTicket.product
    this.productID = serverTicket.productID
    this.proximityTriggered = serverTicket.proximityTriggered
    this.setting = serverTicket.setting
    this.settingCode = serverTicket.settingCode
    this.status = serverTicket.status
    this.origin = serverTicket.origin
    this.ticketId = serverTicket.ticketId
    this.ticketNumber = serverTicket.ticketNumber
    this.tract = serverTicket.tract
    this.tractType = serverTicket.tractType
    this.tractCode = serverTicket.tractCode
    this.tractCountrySubdivision2 = serverTicket.tractCountrySubdivision2
    this.tractId = serverTicket.tractId
    this.tractIsCertified = serverTicket.tractIsCertified ?? false
    this.tractLandOwnerName = serverTicket.tractLandOwnerName
    this.trailerIdentifier = serverTicket.trailerIdentifier
    this.transitStatus = serverTicket.transitStatus
    this.weighedInAt = serverTicket.weighedInAt
    this.weighedOutAt = serverTicket.weighedOutAt
    this.hasLogCounts = serverTicket.hasLogCounts
    this.hasImages = serverTicket.hasImages
    this.hasDefectImages = serverTicket.hasDefectImages
    this.averageSED = serverTicket.averageSED
    this.rolloutStatus = serverTicket.rolloutStatus
    this.rolledOutAt = serverTicket.rolledOutAt
    this.rolloutCompletedAt = serverTicket.rolloutCompletedAt
    this.lastKnownLatitude = serverTicket.lastKnownLatitude
    this.lastKnownLongitude = serverTicket.lastKnownLongitude
    this.contractApprovalStatus = serverTicket.contractApprovalStatus
    this.landownerCount = serverTicket.landownerCount
    this.destinationAccountTimeZoneOffset = serverTicket.destinationAccountTimeZoneOffset
    this.seal = serverTicket.seal
    this.driverAssignedAt = serverTicket.driverAssignedAt
    this.isOverweight = serverTicket.isOverweight
  }

  getTicketOrigin () {
    switch (this.origin) {
      case TicketOrigin.LOADER:
        return TicketOriginImage.LOADER
      case TicketOrigin.WEB_CLIENT:
        return TicketOriginImage.WEB_CLIENT
      case TicketOrigin.RECEIVER:
        return TicketOriginImage.RECEIVER
      case TicketOrigin.YARD_OPERATOR:
        return TicketOriginImage.YARD_OPERATOR
      case TicketOrigin.TRANSPORTER:
        return TicketOriginImage.TRANSPORTER
      case TicketOrigin.KIOSK:
        return TicketOriginImage.KIOSK
      default:
        return TicketOriginImage.WEB_CLIENT
    }
  }
}

export const TicketOrigin = {
  LOADER: 0,
  WEB_CLIENT: 1,
  RECEIVER: 2,
  YARD_OPERATOR: 3,
  TRANSPORTER: 4,
  KIOSK: 5
}

export const TicketOriginImage = {
  WEB_CLIENT: '',
  LOADER: './img/loader_icon_small.png',
  LOADER_SVG: './img/angel-loader-icon.svg',
  RECEIVER: './img/receiver_icon_small.png',
  TRANSPORTER: './img/transporter_icon_small.png',
  TRANSPORTER_SVG: './img/angel-transporter-icon.svg',
  YARD_OPERATOR: './img/yard_operator_icon_small.png',
  KIOSK: './img/angel-kiosk-icon.svg'
}

const getPLF = (ticket) => {
  if (ticket.inWeight > 0 && ticket.outWeight > 0 && ticket.pieces > 0 && ticket.averageLength > 0) {
    return ((ticket.inWeight - ticket.outWeight) / (ticket.pieces * ticket.averageLength)).toFixed(2)
  } else {
    return 'N/A'
  }
}
