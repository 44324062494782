<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="12" xl="5">
        <v-card outlined class="fill-height">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="7">
                <LoadableComponent :loading="loading">
                  <v-text-field
                  v-model="tract.name"
                  data-testid="tract-name"
                  ref="firstField"
                  :label="$t('tractName')"
                  :rules="[rules.required]"
                  color="black"
                  counter
                  maxlength="40"
                  persistent-hint
                  :hint="$t('required')"/>
                </LoadableComponent>
              </v-col>
              <v-col cols="12" md="5">
                <LoadableComponent :loading="loading">
                  <v-text-field
                  v-model="tract.code"
                  data-testid="tract-code"
                  :label="$t('tractCode')"
                  color="black"
                  counter
                  maxlength="10"/>
                </LoadableComponent>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xl="7">
        <v-card outlined class="fill-height">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6" xl="">
                <LoadableComponent :loading="loading">
                  <TractTypeSelector
                  data-testid="tract-type"
                  :tractTypeId="tract.type.tractTypeId"
                  @tract-type="tractTypeChosen"
                  :hint="$t('required')"
                  :persistentHint="true"
                  :propFetchTractTypes="false"/>
                </LoadableComponent>
              </v-col>
              <v-col cols="12" sm="6" md="3" xl="auto" :style="{ minWidth: '180px', maxWidth: $vuetify.breakpoint.xl ? '33%' : '' }">
                <LoadableComponent :loading="loading">
                  <v-autocomplete
                  clearable
                  :items="allHarvestTypes"
                  itemText="name"
                  itemValue="harvestTypeId"
                  :label="$t('harvestType')"
                  v-model="tract.harvestTypeId"
                  id="harvest-type-autocomplete"/>
                </LoadableComponent>
              </v-col>
              <v-col cols="12" sm="6" md="3" xl="auto" :style="{ minWidth: '180px', maxWidth: $vuetify.breakpoint.xl ? '33%' : '' }">
                <LoadableComponent :loading="loading">
                  <v-autocomplete
                  clearable
                  :items="allLoggingMethods"
                  itemText="name"
                  itemValue="loggingMethodId"
                  :label="$t('loggingMethod')"
                  v-model="tract.loggingMethodId"
                  id="logging-method-autocomplete"/>
                </LoadableComponent>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <LoadableComponent :loading="loading">
          <AccountAutocomplete
          id="tract-logger"
          data-testid="tract-logger"
          :accountId="tract.loggerAccountId"
          :title="$t('defaultLogger')"
          clearable
          showCertified
          userSetting="defaultLoggerAutocomplete"
          @account-chosen="defaultLoggerChosen"
          :fetchTagsAndAccounts="false"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <LoadableComponent :loading="loading">
          <AccountAutocomplete
          id="hauler"
          data-testid="tract-hauler"
          :accountId="tract.haulerAccountId"
          :title="$t('hauler')"
          clearable
          showCertified
          userSetting="haulerAccountAutocomplete"
          @account-chosen="haulerChosen"
          :fetchTagsAndAccounts="false"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <LoadableComponent :loading="loading">
          <AccountAutocomplete
          id="supplier"
          data-testid="tract-supplier"
          :accountId="tract.supplierAccountId"
          :title="$t('supplier')"
          clearable
          showCertified
          userSetting="supplierAccountAutocomplete"
          @account-chosen="supplierChosen"
          :fetchTagsAndAccounts="false"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <LoadableComponent :loading="loading">
          <AccountAutocomplete
          id="consulting-forester"
          data-testid="tract-consulting-forester"
          :accountId="tract.consultingForesterAccountId"
          :title="$t('consultingForester')"
          clearable
          showCertified
          userSetting="consultingForesterAccountAutocomplete"
          @account-chosen="consultingForesterChosen"
          :fetchTagsAndAccounts="false"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <LoadableComponent :loading="loading">
          <AccountAutocomplete
          id="contract-account"
          data-testid="tract-contract-account"
          :accountId="tract.contractAccountId"
          :title="$t('contractAccount')"
          clearable
          showCertified
          userSetting="tractContractAccountAutocomplete"
          @account-chosen="contractAccountChosen"
          :fetchTagsAndAccounts="false"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <LoadableComponent :loading="loading">
          <SettingAutocomplete
          :settingId="tract.contractSettingId"
          @setting-chosen="contractSettingChosen"
          hideRefresh
          data-testid="tract-contract-setting"
          :propFetchSettings="false"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" :md="!showTeamSelection ? 6 : 12" :lg="!showTeamSelection ? 4 : 12">
        <v-row>
          <v-col cols="12" md="6" lg="3" v-if="showTeamSelection"> <!-- Team -->
            <LoadableComponent :loading="loading">
              <TeamAutocomplete
              :propTeamId="propTract.teamId"
              @team-chosen="teamChosen"/>
            </LoadableComponent>
          </v-col>
          <v-col cols="12" lg="6" order-md="last" order-lg="0" v-if="showTeamSelection" id="tract-users-autocomplete"> <!-- Tract Users -->
            <LoadableComponent :loading="loading">
              <UserAutocomplete
              multiple
              chips
              smallChips
              deletableChips
              dataTestid="tract-users-autocomplete"
              :label="$t('users')"
              :fetchUsers="false"
              :propTeam="team"
              :propUserIds="propTract.userIds"
              :disabled="!team || !tract.teamId"
              :rules="!!team ? [rules.requiredSelect] : []"
              @user-chosen="usersChosen"/>
            </LoadableComponent>
          </v-col>
          <v-col cols="12" :md="showTeamSelection ? 6 : ''" :lg="showTeamSelection ? 3 : ''"> <!-- Forester -->
            <LoadableComponent :loading="loading">
              <UserAutocomplete
              :label="$t('forester')"
              :propUserId="tract.foresterUserId"
              data-testid="tract-forester"
              @user-chosen="foresterChosen"
              :fetchUsers="false"
              :allUserIds="!!team ? tract.userIds ?? [] : undefined"/> <!-- All users available unless a team is selected, in which case the chosen users are available -->
            </LoadableComponent>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isStumpageTract" cols="12" md="6">
        <LoadableComponent :loading="loading">
          <EntitySelector
            :entities="entities"
            :initialEntity.sync="tract.businessEntity"
            @entity-selected="entitySelected"
            :hint="$t('required')"
            :persistentHint="true"
            :label="$t('purchaser')"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" md="6" lg="2">
        <LoadableComponent :loading="loading">
          <MoneyTextField v-if="!type?.depletes"
          data-testid="tract-cost"
          :initialValue="tract.cost"
          :label="$t('cost')"
          @val-changed="tractCostEntered"
          :extraRules="['validTractCost']"
          />
          <v-text-field
          v-else-if="type?.depletes"
          :disabled="!streamlineDepletion"
          :label="$t('cost')"
          :value="tract?.type?.depletes ? formatMoney(originalTract?.cost) : formatMoney(0)"
          color="black"
          @focus="paymentForm = true"
          ref="depletionCostField">
            <template #append>
              <Icon
              v-if="streamlineDepletion"
              icon="mdi-cash-multiple"
              iconColor="black"
              @icon-clicked="if (streamlineDepletion) paymentForm = true"
              :tooltipText="$t('depletingTractCostSetViaCostPayments')"/>
              <Icon v-else
              icon="mdi-cash-multiple"
              iconColor="black"
              :tooltipText="$t('depletingTractCostSetViaCostPayments')"/>
            </template>
          </v-text-field>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <LoadableComponent :loading="loading">
          <DatePicker
            v-if="isStumpageTract"
            :dateLabel="$t('purchaseDate')"
            :startDate="tract.purchaseDate"
            dataTestId="tract-purchase-date"
            clearable
            @date-picked="purchaseDatePicked"
            @valid-date="(val) => {
              isValidPurchaseDate = val
            }"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <TractLandowners
            :propLandowners="landowners"
            :loading="loading"
            @ownership-changed="landowners = $event"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <LoadableComponent :loading="loading">
          <DatePicker
          @date-picked="startDatePicked"
          clearable
          dataTestId="tract-harvest-start-date"
          :startDate="tract.harvestStartDate"
          v-if="renderDates"
          @valid-date="(val) => {
            isValidStartDate = val
          }"
          :dateLabel="$t('harvestStart')"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" md="6">
        <LoadableComponent :loading="loading">
          <DatePicker
          @date-picked="endDatePicked"
          clearable
          dataTestId="tract-harvest-end-date"
          :startDate="tract.harvestEndDate"
          v-if="renderDates"
          @valid-date="(val) => {
            isValidEndDate = val
          }"
          :dateLabel="$t('harvestEnd')"/>
        </LoadableComponent>
      </v-col>
    </v-row>
    <v-row dense justify="end">
      <v-col cols="auto" style="width: 240px; height: 74px;">
        <LoadableComponent :loading="loading">
          <v-checkbox
          data-testid="contract-requires-ext-ticket-1"
          v-model="tract.requiresExt1"
          color="primary"
          :label="$t('requiresExt1')"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="auto" style="width: 240px; height: 74px;">
        <LoadableComponent :loading="loading">
          <v-checkbox
          data-testid="contract-requires-ext-ticket-2"
          v-model="tract.requiresExt2"
          color="primary"
          :label="$t('requiresExt2')"/>
        </LoadableComponent>
      </v-col>
      <v-col cols="auto" v-if="propTract.tractId">
        <v-checkbox
        v-if="didHarvestDatesChange"
        v-model="applyDateChangesToContracts"
        color="primary"
        label="Apply harvest dates to contracts"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6">
        <v-card outlined class="fill-height pt-3">
          <v-card-text>
            <LoadableComponent :loading="loading">
              <LoggerAutocomplete
              data-testid="tract-loggers"
              :loggers="tract.loggers"
              userSetting="accountLoggerAutocomplete"
              @loggers-mutated="loggersChosen"
              :fetchTagsAndAccounts="false"/>
            </LoadableComponent>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6">
        <v-card outlined class="fill-height">
          <v-card-text>
            <LoadableComponent :loading="loading">
              <Certifications
              label
              :category="0"
              data-testid="tract-certifications"
              :propCertifications="tract.certificationIds"
              @certifications="certificationChosen"
              useIdOnly
              :propFetchCertifications="false"/>
            </LoadableComponent>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <LoadableComponent :loading="loading" type="subtitle-1" :width="64">
          <span class="subtitle-1">{{$t('quality')}}</span>
        </LoadableComponent>
      </v-col>
      <v-col cols="12" lg="8" xl="6">
        <v-row dense>
          <v-col cols="12" sm="">
            <LoadableComponent :loading="loading">
              <v-text-field
              :label="$t('rpi')"
              v-model="tract.rpi"
              :rules="[rules.numberOrBlank, rules.twoDecimalPlacesOrFewer, rules.validRPI]"
              maxLength="5"/>
            </LoadableComponent>
          </v-col>
          <v-col cols="12" sm="">
            <LoadableComponent :loading="loading">
              <v-text-field
              :label="$t('dbh')"
              v-model="tract.dbh"
              :rules="[rules.numberOrBlank, rules.positiveIntegerOrBlank, rules.validDBH]"
              maxLength="3"/>
            </LoadableComponent>
          </v-col>
          <v-col cols="12" sm="">
            <LoadableComponent :loading="loading">
              <v-text-field
              :label="$t('age')"
              v-model="tract.age"
              :rules="[rules.numberOrBlank, rules.positiveIntegerOrBlank, rules.validAge]"
              maxLength="3"/>
            </LoadableComponent>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
    v-model="paymentForm"
    max-width="1200px"
    v-if="paymentForm">
      <TractPaymentForm
      v-if="paymentForm"
      :tractId="propTract.tractId"
      :performanceBonds="[]"
      restrictToCost
      @close="paymentForm = false"
      @submitted="costPaymentSubmitted"/>
    </v-dialog>
  </v-container>
</template>

<script>
import fieldRules from '@/utils/rules.js'
import { TractTypeCategory } from '@/utils/Enumerations.js'
import { formatMoney } from '@/utils/NumericMutations'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'GeneralInformation',

  props: {
    propTract: Object,
    propLandowners: Array,
    propOriginalTract: Object
  },

  components: {
    Certifications: () => import('@/components/settings-components/certification/Certifications.vue'),
    TractTypeSelector: () => import('@/components/tract/TractTypeSelector.vue'),
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue'),
    SettingAutocomplete: () => import('@/components/autocomplete/ContractSettingAutocomplete.vue'),
    UserAutocomplete: () => import('@/components/autocomplete/UserAutocomplete.vue'),
    LoggerAutocomplete: () => import('@/components/autocomplete/LoggerAutocomplete.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    MoneyTextField: () => import('@/components/helper/MoneyTextField.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    TractLandowners: () => import('@/components/tract/tract-form/TractLandowners.vue'),
    TeamAutocomplete: () => import('@/components/autocomplete/TeamAutocomplete.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    TractPaymentForm: () => import('@/components/tract/tract-payables/TractPaymentForm.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue')
  },

  data: () => ({
    rules: fieldRules.rules,
    entities: [],
    isValidStartDate: true,
    renderDates: true,
    isValidEndDate: true,
    isValidPurchaseDate: true,
    applyDateChangesToContracts: false,
    originalHarvestStart: null,
    originalHarvestEnd: null,
    originalTract: null,
    tract: {
      name: '',
      code: '',
      type: {
        tractTypeId: null
      },
      cost: 0,
      foresterUserId: null,
      businessEntity: null,
      loggerAccountId: null,
      haulerAccountId: null,
      supplierAccountId: null,
      contractAccountId: null,
      contractSettingId: null,
      contractSetting: null,
      loggers: [],
      loggerAccountIds: [],
      certificationIds: [],
      harvestStartDate: new Date().toISOString(),
      harvestEndDate: new Date().toISOString(),
      purchaseDate: new Date().toISOString(),
      requiresExt1: false,
      requiresExt2: false,
      consultingForesterAccountId: null,
      rpi: null,
      dbh: null,
      age: null,
      userIds: [],
      teamId: undefined,
      harvestTypeId: null,
      loggingMethodId: null
    },
    landowners: [],
    loading: true,
    team: undefined,
    paymentForm: false,
    payables: []
  }),

  computed: {
    ...mapGetters('team', ['allTeams']),
    ...mapGetters('harvest-type', ['allHarvestTypes']),
    ...mapGetters('logging-method', ['allLoggingMethods']),

    isStumpageTract () {
      return this.tract.type?.category === TractTypeCategory.Stumpage.value
    },

    didHarvestDatesChange () {
      if (this.tract.harvestStartDate === null || this.tract.harvestEndDate === null) return false
      const isStartDayEqual = moment(this.tract.harvestStartDate).isSame(moment(this.originalHarvestStart), 'day')
      const isEndDayEqual = moment(this.tract.harvestEndDate).isSame(moment(this.originalHarvestEnd), 'day')
      return !(isStartDayEqual && isEndDayEqual)
    },

    type () {
      return this.tract.type
    },

    teamUserIds () {
      return this.team?.users?.map(u => u.applicationUserId)
    },

    showTeamSelection () {
      return this.allTeams?.filter(t => t.isActive).length > 0 || this.propTract.teamId || this.propTract.users?.length > 0
    },

    editing () {
      return this.propTract?.tractId !== undefined && this.propTract?.tractId !== null
    },

    streamlineDepletion () {
      return this.editing &&
        this.tract?.cost === 0 &&
        this.payables.length === 0 &&
        this.originalTract?.type?.depletes // Depletion behavior must be saved in order to issue tract cost payable
    }
  },

  watch: {
    tract: {
      handler (val) {
        this.$emit('tract-changed', val)
      },
      deep: true
    },

    'propTract.tractId': {
      async handler (val) {
        if (val !== null && val !== undefined) {
          this.payables = await this.fetchTractPayables(this.propTract.tractId)
        }
      },
      immediate: true
    },

    type (val, oldVal) {
      if (val.depletes && !oldVal.depletes) {
        if (this.originalTract?.type?.depletes) {
          this.tract.cost = this.originalTract.cost
        } else {
          this.tract.cost = 0
          this.originalTract.cost = 0
        }
      }

      if (this.propTract.tractId || (this.tract.harvestStartDate || this.tract.harvestEndDate)) {
        return
      }

      const selectedDeliveredType = oldVal.tractTypeId === null && val.category === TractTypeCategory.Delivered.value
      const switchedFromStumpage = val.category === TractTypeCategory.Delivered.value && (!this.tract.harvestStartDate && !this.tract.harvestEndDate)
      if (selectedDeliveredType || switchedFromStumpage) {
        this.tract.harvestStartDate = moment().startOf('day').format()
        this.tract.harvestEndDate = moment().add(90, 'day').startOf('day').format()
        this.renderDates = false
        this.$nextTick(_ => {
          this.renderDates = true
        })
      }
    },

    applyDateChangesToContracts (shouldApplyDates) {
      this.$emit('apply-to-contracts-toggled', shouldApplyDates)
    },

    landowners: {
      handler (val) {
        this.$emit('ownership-changed', val)
      },
      deep: true
    },

    propLandowners: {
      handler () {
        this.landowners = this.propLandowners
      },
      deep: true
    },

    team (team) {
      this.tract.teamId = team?.teamId
    },

    propOriginalTract: {
      handler (val) {
        this.originalTract = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },

  async created () {
    this.setInitialValues(this.propTract, this.propLandowners)
    const [entities] = await Promise.all([
      this.fetchAllBusinessEntities(),
      this.fetchTeams(),
      this.fetchAccounts(),
      this.fetchAccountTags(),
      this.getAllUsers(),
      this.fetchCertifications(),
      this.fetchSettings(),
      this.fetchTractTypes(),
      this.fetchHarvestTypes(),
      this.fetchLoggingMethods()
    ])
    if (this.tract.teamId) this.team = this.allTeams.find(t => t.teamId === this.tract.teamId)
    this.entities = entities
    this.loading = false
  },

  methods: {
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tags', ['fetchAccountTags']),
    ...mapActions('user', ['fetchAllBusinessEntities', 'getAllUsers']),
    ...mapActions('certification', ['fetchCertifications']),
    ...mapActions('setting', ['fetchSettings']),
    ...mapActions('tract-type', ['fetchTractTypes']),
    ...mapActions('team', ['fetchTeams']),
    ...mapActions('tract', ['fetchTractPayables']),
    ...mapActions('harvest-type', ['fetchHarvestTypes']),
    ...mapActions('logging-method', ['fetchLoggingMethods']),
    formatMoney,

    setInitialValues (propTract, propLandowners) {
      this.tract.name = propTract.name
      this.tract.code = propTract.code
      this.tract.type = propTract.type
      this.tract.foresterUserId = propTract.foresterUser?.applicationUserId
      this.tract.harvestStartDate = propTract.harvestStartDate
      this.tract.harvestEndDate = propTract.harvestEndDate
      this.tract.purchaseDate = propTract.purchaseDate
      this.tract.cost = propTract.cost
      this.tract.businessEntity = {
        name: propTract.businessEntity,
        businessEntityId: propTract.businessEntityId
      }
      this.tract.loggerAccountId = propTract.loggerAccountId
      this.tract.haulerAccountId = propTract.haulerAccountId
      this.tract.supplierAccountId = propTract.supplierAccountId
      this.tract.contractAccountId = propTract.contractAccountId
      this.tract.contractSettingId = propTract.contractSettingId
      this.tract.contractSetting = propTract.contractSetting
      this.tract.requiresExt1 = propTract.requiresExt1 ?? false
      this.tract.requiresExt2 = propTract.requiresExt2 ?? false
      this.tract.certificationIds = propTract.certificationIds
      this.tract.loggers = propTract.loggers ?? []
      this.originalHarvestStart = this.propTract.harvestStartDate
      this.originalHarvestEnd = this.propTract.harvestEndDate
      this.tract.consultingForesterAccountId = propTract.consultingForesterAccountId
      this.tract.rpi = propTract.rpi || undefined
      this.tract.dbh = propTract.dbh || undefined
      this.tract.age = propTract.age || undefined
      this.landowners = propLandowners
      this.tract.userIds = propTract.userIds
      this.tract.teamId = propTract.teamId
      this.tract.harvestTypeId = propTract.harvestTypeId
      this.tract.loggingMethodId = propTract.loggingMethodId
    },

    tractCostEntered (cost) {
      this.tract.cost = cost
    },

    tractTypeChosen (tractType) {
      if (tractType.category === TractTypeCategory.Delivered.value) {
        this.tract.businessEntity = null
      }
      this.tract.type = tractType
    },

    certificationChosen (certifications) {
      this.tract.certificationIds = certifications
    },

    entitySelected (entity) {
      this.tract.businessEntity = entity
    },

    loggersChosen (loggers) {
      this.tract.loggerAccountIds = loggers.map(l => l.accountId)
    },

    defaultLoggerChosen (defaultLogger) {
      this.tract.loggerAccountId = defaultLogger?.accountId
    },

    haulerChosen (hauler) {
      this.tract.haulerAccountId = hauler?.accountId
    },

    supplierChosen (supplier) {
      this.tract.supplierAccountId = supplier?.accountId
    },

    consultingForesterChosen (consultingForester) {
      this.tract.consultingForesterAccountId = consultingForester?.accountId
    },

    contractAccountChosen (contractAccount) {
      this.tract.contractAccountId = contractAccount?.accountId
    },

    contractSettingChosen (setting) {
      this.tract.contractSettingId = setting?.settingId
    },

    foresterChosen (forester) {
      this.tract.foresterUserId = forester?.applicationUserId ?? null
    },

    startDatePicked (startDate) {
      this.tract.harvestStartDate = startDate
    },

    endDatePicked (endDate) {
      this.tract.harvestEndDate = endDate
    },

    purchaseDatePicked (purchaseDate) {
      this.tract.purchaseDate = purchaseDate
    },

    teamChosen (team) {
      this.team = team
      if (team?.users) this.usersChosen(team.users)
    },

    usersChosen (users) {
      if (users) { this.tract.userIds = users.map(u => u.applicationUserId) } else this.tract.userIds = []
    },

    userIdsChosen (userIds) {
      this.tract.userIds = userIds
    },

    costPaymentSubmitted (amount) {
      this.paymentForm = false
      this.tract.cost = amount
      this.originalTract.cost = amount
      this.$emit('cost-set', amount)
    }

  }
}
</script>
