<template>
  <v-card>
    <ShortcutWrapper @arrow-right-shortcut="viewNextDuplicate" @arrow-left-shortcut="viewPreviousDuplicate" isDialog>
      <v-card-title style="background-color: #d15f27; color: white;">
        <span class="headline white--text">
          {{ $t('extTicketNumber1Duplicates') }}
        </span>
        <v-spacer/>
        <BaseDialogActions hideRefresh/>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="subtitle-1" :style="{ textAlign: 'center', verticalAlign: 'center', fontWeight: 'bold' }">
              <v-btn :disabled="!hasMultipleDuplicates" icon color="primary" dense class="mr-2" @click="viewPreviousDuplicate">
                <v-icon color="primary">mdi-arrow-left-circle-outline</v-icon>
              </v-btn>
              <span :style="{ verticalAlign: 'center' }" class="px-6">{{$t('duplicateTicketN', { ticketNumber: currentDuplicateTicket.ticketNumber })}} ({{duplicatePage + 1}}/{{duplicateTickets.length}})</span>
              <v-btn :disabled="!hasMultipleDuplicates" icon color="primary" dense class="mr-2" @click="viewNextDuplicate">
                <v-icon color="primary">mdi-arrow-right-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" lg="3" v-if="$vuetify.breakpoint.lgAndUp">
              <TicketImageViewer
              v-if="currentTicketImageData"
              :ticketNumber="currentTicket.ticketNumber"
              :ticketImageData="currentTicketImageData"/>
            </v-col>
            <v-col cols="12" lg="6">
              <v-simple-table>
                <tbody>
                  <tr>
                    <th></th>
                    <th>{{$t('currentTicketN', { ticketNumber: currentTicket.ticketNumber })}}</th>
                    <th>{{$t('duplicateTicketN', { ticketNumber: currentDuplicateTicket.ticketNumber })}}</th>
                  </tr>
                  <tr v-for="(diff, key) in comparableTicketFields" :key="`tr-diff-${key}`">
                    <td>
                      <v-icon v-if="diff.icon" small color="grey" class="mb-1">{{diff.icon}}</v-icon>
                      {{diff.label}}
                      <v-icon v-if="diff.different" small color="primary">mdi-asterisk</v-icon>
                    </td>
                    <td>
                      <v-icon v-if="diff.current.icon" :color="diff.current.icon.iconColor" small class="pr-1">
                        {{diff.current.icon.icon}}
                      </v-icon>
                      <span :class="{ 'grey--text': !diff.different }">{{diff.current.value ?? $t('notAvailable')}}</span>
                    </td>
                    <td>
                      <v-icon v-if="diff.duplicate.icon" :color="diff.duplicate.icon.iconColor" small class="pr-1">
                        {{diff.duplicate.icon.icon}}
                      </v-icon>
                      <span :class="{ 'grey--text': !diff.different }">{{diff.duplicate.value ?? $t('notAvailable')}}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="6" v-if="$vuetify.breakpoint.mdAndDown">
              <TicketImageViewer
              v-if="currentTicketImageData"
              :ticketNumber="currentTicket.ticketNumber"
              :ticketImageData="currentTicketImageData"/>
            </v-col>
            <v-col cols="6" lg="3">
              <TicketImageViewer
              v-if="recognitionData[currentDuplicateTicket?.ticketId]"
              :ticketNumber="currentDuplicateTicket.ticketNumber"
              :ticketImageData="ticketImageData"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </ShortcutWrapper>
  </v-card>
</template>

<script>
import { utcToLocalDate } from '../../../utils/DateFormatter'
import { ticketRecognitionClient } from '../../../utils/TicketImages'
import { TicketStatus, TicketStatusIcon } from '../../../utils/Enumerations'
export default {
  name: 'DuplicateTickets',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    TicketImageViewer: () => import('@/components/ticket/TicketImageViewer.vue')
  },

  props: {
    currentTicket: {
      type: Object,
      required: true
    },
    duplicateTickets: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    openPanel: 0,
    duplicatePage: 0,
    currentTicketRecognitionData: [],
    recognitionData: {},
    currentTicketImageData: undefined
  }),

  async created () {
    this.currentTicketImageData = await ticketRecognitionClient.getRecognitionData(this.currentTicket?.ticketId, false)
    const recognitionData = {}
    for (const ticket of this.duplicateTickets) {
      const td = await ticketRecognitionClient.getRecognitionData(ticket.ticketId, false)
      recognitionData[ticket.ticketId] = td
    }
    this.recognitionData = recognitionData
  },

  computed: {
    hasMultipleDuplicates () {
      return this.duplicateTickets?.length > 1
    },

    extTicketNumber1 () {
      return this.duplicateTickets[0]?.extTicketNumber1
    },

    currentDuplicateTicket () {
      return this.duplicateTickets?.[this.duplicatePage]
    },

    ticketImageData () {
      return this.recognitionData?.[this.currentDuplicateTicket?.ticketId]
    },

    comparableTicketFields () {
      const sideBySideFields = {
        createdAt: { label: this.$t('createdAt'), getter: t => utcToLocalDate(t.loadCreatedAt, 'L - LT') },
        status: { label: this.$t('status'), getter: t => TicketStatus.fromInt(t.status), diff: true, valueIcon: t => TicketStatusIcon(t.status, this.$i18n.locale) },
        logger: { label: this.$t('logger'), getter: t => t.logger, diff: true },
        product: { label: this.$t('product'), getter: t => t.product, diff: true },
        driver: { label: this.$t('driver'), getter: t => t.driver },
        trailerId: { label: this.$t('trailerId'), getter: t => t.trailerIdentifier },
        destination: { label: this.$t('destination'), getter: t => t.destination, icon: 'mdi-domain', diff: true },
        account: { label: this.$t('account'), getter: t => t.account, icon: 'mdi-account', diff: true },
        tract: { label: this.$t('tract'), getter: t => t.tract, icon: 'mdi-crosshairs-gps', diff: true },
        setting: { label: this.$t('setting'), getter: t => t.setting, icon: 'mdi-map-marker-circle', diff: true }
      }

      const o = {}
      for (const key in sideBySideFields) {
        const { label, icon, getter, diff, valueIcon } = sideBySideFields[key]
        const currentValue = getter(this.currentTicket)
        const duplicateValue = getter(this.currentDuplicateTicket)
        o[key] = {
          label,
          icon,
          different: diff ? currentValue !== duplicateValue : false,
          current: {
            value: currentValue,
            icon: valueIcon ? valueIcon(this.currentTicket) : undefined
          },
          duplicate: {
            value: duplicateValue,
            icon: valueIcon ? valueIcon(this.currentDuplicateTicket) : undefined
          }
        }
      }
      return o
    }
  },

  methods: {
    viewNextDuplicate () {
      const numDuplicates = this.duplicateTickets?.length
      if (numDuplicates) {
        this.duplicatePage += 1
        this.duplicatePage %= numDuplicates
      } else {
        this.duplicatePage = 0
      }
    },

    viewPreviousDuplicate () {
      const numDuplicates = this.duplicateTickets?.length
      if (numDuplicates) {
        this.duplicatePage = (this.duplicatePage === 0) ? numDuplicates - 1 : this.duplicatePage - 1
        this.duplicatePage %= numDuplicates
      } else {
        this.duplicatePage = 0
      }
    }
  }
}
</script>
