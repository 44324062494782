<template>
  <DataTable
  :loading="contractsLoading"
  :items.sync="contractsWithFormattedDates"
  :headers="headers"
  :customCells="customColumns"
  :actions="tableActions"
  @refresh="$emit('refresh-contracts')"
  @create="$emit('contract-action', { actionType: 'create-contract' })"
  resize
  defaultHeight="calc(100vh - 461px)">
    <template #filters>
      <v-layout wrap>
        <v-chip-group v-if="!isTractDetail">
          <v-chip color="secondary" text-color="white" close @click:close="removeFilterItem(chip.key)" v-for="chip in filterChips" :key="chip.key">
            <span>
              {{(chip.text)}}
            </span>
          </v-chip>
        </v-chip-group>
      </v-layout>
    </template>
    <template #actions="{item}">
      <Icon
      icon="mdi-pencil"
      iconColor="success"
      dataTestId="contract-edit-button"
      :tooltipText="$t('edit')"
      @icon-clicked="editContract(item)"
      :disabled="!canEdit(item)"/>
      <Icon
      icon="mdi-delete-forever"
      iconColor="error"
      dataTestId="contract-delete-button"
      :tooltipText="$t('delete')"
      @icon-clicked="deleteContract(item)"
      :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
    </template>
    <template #custom-controls v-if="!isTractDetail">
      <v-col cols="auto">
        <ContractContextMenu
          :contractMode="contractMode"
          @download-csv="downloadCSV"
          @import-tickets="importTickets"
        />
      </v-col>
      <v-col cols="auto">
        <ContractFilter
          :contractMode="contractMode"
          :showApprovalsFilter="showApprovalsFilter"
        />
      </v-col>
      <v-col cols="auto">
        <ColumnAdjuster
          :propColumnSet.sync="columnSet"
          @new-columns-selected="newColumnsSelected"
        />
      </v-col>
    </template>
    <template #status="{item}">
      <Icon
      dataTestId="contract-status-icon"
      :icon="getStatusIcon(item).iconName"
      :iconColor="getStatusIcon(item).color"
      :tooltipColor="getStatusIcon(item).color"
      :tooltipText="getStatusIcon(item).msg"/>
      <Icon
      v-if="item.paused"
      dataTestId="contract-pauseicon"
      icon="mdi-pause-circle"
      iconColor="warning"
      :tooltipText="$t('contractOnPause')"/>
      <Icon
      icon="mdi-home"
      dataTestId="contract-external-icon"
      :tooltipColor="item.isExternal ? 'primary' : 'black'"
      :iconColor="item.isExternal ? 'grey' : 'black'"
      :tooltipText="item.isExternal ? 'External' : 'Internal'"/>
      <Icon
      icon="mdi-axe"
      dataTestId="contract-logger-icon"
      :iconColor="item.requiresLogger ? 'brown darken-4' : 'brown lighten-2'"
      :tooltipColor="item.requiresLogger ? 'brown darken-4' : 'brown lighten-1'"
      :tooltipText="item.requiresLogger ? 'Requires Logger' : 'No Logger Required'"/>
      <Icon
      dataTestId="contact-product-icon"
      :icon="item.acceptsAnyLoad ? 'mdi-lock-open' : 'mdi-lock'"
      :iconColor="item.acceptsAnyLoad ? 'grey' : 'black'"
      :tooltipColor="item.acceptsAnyLoad ? 'primary' : 'black'"
      :tooltipText="item.acceptsAnyLoad ? 'Accepts Any Product' : 'Restricted Products'"
      />
      <Icon
      v-if="item.isDraft"
      icon="mdi-file"
      dataTestId="contract-draft-icon"
      :tooltipColor="getTooltipColorForDraft(item.hasPendingTickets)"
      :iconColor="getTooltipColorForDraft(item.hasPendingTickets)"
      :tooltipText="getTooltipTextForDraft(item.hasPendingTickets)"/>
      <Icon
        v-if="showApprovalStatusWarning(item)"
        icon="mdi-file-document-check-outline"
        iconColor="error"
        :tooltipText="approvalStatusWarningText(item)"
      />
    </template>
    <template #type="{item}">{{
      contractTypeFromInt(item.type)
    }}</template>
    <template #tract="{item}" v-if="!isTractDetail">
      <Icon
      v-if="item.tractIsCertified"
      icon="mdi-certificate-outline"
      iconColor="black"
      :tooltipText="$t('tractIsCertified')"
      />
      <span id="table-shortcut" @click="showContractDetail(item)">{{item.tract || $t('notAvailable')}}</span>
    </template>
    <template #tractType="{item}" >
      <span id="table-shortcut" @click="showContractDetail(item)">{{item.tractType || $t('notAvailable')}}</span>
    </template>
    <template #destination="{item}">
      <span id="table-shortcut" @click="showContractDetail(item)">{{item.destination}}</span>
    </template>
    <template #account="{item}">
      <AccountName
        :certificationStatus="item.accountCertificationStatus"
        :accountId="item.accountId"
      >
        <span id="table-shortcut" @click="showContractDetail(item)">{{item.account}}</span>
      </AccountName>
    </template>
    <template #from-account="{item}">
      <AccountName
        :certificationStatus="item.fromAccountCertificationStatus"
        :accountId="item.fromAccountId"
      >
        <span id="table-shortcut" @click="showContractDetail(item)">{{item.fromAccount || $t('notAvailable')}}</span>
      </AccountName>
    </template>
    <template #setting="{item}">
      <span id="table-shortcut" @click="showContractDetail(item)">{{item.setting || $t('notAvailable')}}</span>
    </template>
    <template #details="{item}">
      <Icon v-if="!isTractDetail && isLogsContractMode"
      icon="mdi-crosshairs-gps"
      iconColor="black"
      margin="mr-1"
      dataTestId="contract-tract-button"
      :tooltipText="$t('viewTract')"
      @icon-clicked="viewTract(item)"/>
      <Icon
      icon="mdi-clipboard-outline"
      iconColor="grey"
      margin="mr-1"
      dataTestId="contract-ticket-button"
      :tooltipText="$t('viewTickets')"
      @icon-clicked="jumpToTickets(item)"/>
      <Icon
      icon="mdi-information-outline"
      margin=""
      dataTestId="contract-detail-button"
      :tooltipText="$t('details')"
      @icon-clicked="showContractDetail(item)"/>
    </template>
  </DataTable>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { LocalStorageKeys } from '@/utils/LocalStorageActor'
import RouterJump from '@/model/RouterJump.js'
import ContractHeaders from '@/headers/Contract'
import { ContractStatusReason, ContractType, ContractStatus, UserClaims, ContractApprovalStatus } from '@/utils/Enumerations.js'
import { saveAs } from 'file-saver'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { ContractMode } from '../../utils/Enumerations'

export default {
  name: 'ContractTable',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    AccountName: () => import('@/components/account/AccountName.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue'),
    ContractFilter: () => import('@/components/contract/ContractFilter.vue'),
    ContractContextMenu: () => import('@/components/contract/ContractContextMenu.vue')
  },

  props: {
    contractMode: {
      type: Object,
      default: () => ({
        value: 0
      })
    },
    isTractDetail: {
      type: Boolean,
      default: false
    },
    contracts: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    columnSet: null,
    UserClaims
  }),

  watch: {
    contractMode (_) {
      this.initializeHeaders()
    }
  },

  computed: {
    ...mapGetters('contract', ['contractsLoading', 'filter']),
    ...mapGetters('user', ['userInfo', 'companyInfo']),

    headers () {
      return this.columnSet?.getColumns() ?? []
    },

    contractsWithFormattedDates () {
      return this.contracts.map(c => ({
        ...c,
        expirationDate: utcToLocalDate(c.expirationDate, 'MM/DD/YYYY'),
        effectiveDate: utcToLocalDate(c.effectiveDate, 'MM/DD/YYYY')
      }))
    },

    isLogsContractMode () {
      return this.contractMode.value === ContractMode.Logs.value
    },

    tableActions () {
      return [
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh')
        },
        (!this.isTractDetail && !this.isLogsContractMode) ? {
          actionName: 'create',
          icon: 'mdi-plus',
          text: this.$t('newContract'),
          disabled: !userAssignedClaim(UserClaims.ContractManager)
        } : undefined
      ].filter(a => a !== undefined)
    },

    filterChips () {
      const chips = []

      if (this.filter.includeOpen) {
        chips.push({
          text: this.$t('open'),
          key: 'includeOpen'
        })
      }

      if (this.filter.includeExpired) {
        chips.push({
          text: this.$t('expired'),
          key: 'includeExpired'
        })
      }

      if (this.filter.includeOnHold) {
        chips.push({
          text: this.$t('onHold'),
          key: 'includeOnHold'
        })
      }

      if (this.filter.includeClosed) {
        chips.push({
          text: this.$t('closed'),
          key: 'includeClosed'
        })
      }

      if (this.filter.limitToInternal) {
        chips.push({
          text: this.$t('internalOnly'),
          key: 'limitToInternal'
        })
      }

      if (this.filter.limitToDraft) {
        chips.push({
          text: this.$t('draftOnly'),
          key: 'limitToDraft'
        })
      }

      if (this.filter.limitToPaused) {
        chips.push({
          text: this.$t('pausedOnly'),
          key: 'limitToPaused'
        })
      }

      if (this.filter.includeProduction) {
        chips.push({
          text: this.$t('production'),
          key: 'includeProduction'
        })
      }

      if (this.filter.includeWoodsSale) {
        chips.push({
          text: this.$t('woodsSale'),
          key: 'includeWoodsSale'
        })
      }

      if (this.filter.includeByProduct) {
        chips.push({
          text: this.$t('byproductSale'),
          key: 'includeByProduct'
        })
      }

      if (this.filter.includeByproductPurchase) {
        chips.push({
          text: this.$t('byproductPurchase'),
          key: 'includeByproductPurchase'
        })
      }

      // if (this.filter.includeLogYardSale) { // TODO: This chip is not relevant yet (this is the only contract type visible in its contract mode)
      //   chips.push({
      //     text: this.$t('logYardSale'),
      //     key: 'includeLogYardSale'
      //   })
      // }

      if (this.filter.approvalStatus === ContractApprovalStatus.PendingReview.value) {
        chips.push({
          text: this.$t('toReview'),
          key: 'approvalStatus'
        })
      }

      if (this.filter.approvalStatus === ContractApprovalStatus.Returned.value) {
        chips.push({
          text: this.$t('returned'),
          key: 'approvalStatus'
        })
      }

      return chips
    },

    customColumns () {
      var columns = [
        {
          slotName: 'account',
          value: 'account'
        },
        {
          slotName: 'from-account',
          value: 'fromAccount'
        },
        {
          slotName: 'setting',
          value: 'setting'
        },
        {
          slotName: 'destination',
          value: 'destination'
        },
        {
          slotName: 'details',
          value: 'details'
        },
        {
          slotName: 'type',
          value: 'type'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'accepts-any-load',
          value: 'acceptsAnyLoad'
        },
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'tract',
          value: 'tract'
        },
        {
          slotName: 'tractType',
          value: 'tractType'
        }
      ]

      return columns
    },

    showApprovalsFilter () {
      return this.companyInfo.requireApprovalForContractModifications && userAssignedClaim(UserClaims.ContractReviewer)
    }
  },

  created () {
    this.initializeHeaders()
    if (!this.showApprovalsFilter) {
      this.filter.approvalStatus = undefined
    }
  },

  methods: {
    utcToLocalDate,
    userAssignedClaim,
    ...mapActions('contract', ['removeFilter', 'setFilter']),
    ...mapMutations('global', ['setRouterJump']),

    contractTypeFromInt: (type) => ContractType.fromInt(type),
    contractStatusFromInt: (type) => ContractStatus.fromInt(type),

    initializeHeaders () {
      let columnSet
      switch (this.contractMode.value) {
        case 0:
          columnSet = ContractHeaders.logsContractHeaders()
          if (this.isTractDetail) {
            const dontShowIfDetail = ['tract', 'tractType', 'tractDefaultLogger']
            columnSet.columns = columnSet.columns.filter(c => !dontShowIfDetail.includes(c.value))
          }
          break
        case 1:
          columnSet = ContractHeaders.bpContractHeaders()
          break
        case 2:
        case 3:
          columnSet = ContractHeaders.transferAndLysContractHeaders()
          break
      }
      this.columnSet = columnSet
    },

    jumpToTickets (contract) {
      localStorage.setItem(LocalStorageKeys.BP_TICKETS_VIEW, false)
      const routerJump = new RouterJump('Contract', 'Tickets', contract)
      this.setRouterJump(routerJump)
      this.$router.push('tickets')
    },

    showContractDetail (contract) {
      const event = { actionType: 'contract-detail', contract: contract }
      this.$emit('contract-action', event)
    },

    viewTract (contract) {
      const event = { actionType: 'view-tract', contract: contract }
      this.$emit('contract-action', event)
    },

    editContract (contract) {
      const event = { actionType: 'edit-contract', contract: contract }
      this.$emit('contract-action', event)
    },

    importTickets (isUpdate) {
      this.$emit('contract-action', { actionType: 'import-tickets', isUpdate: isUpdate })
    },

    deleteContract (contract) {
      const event = { actionType: 'delete-contract', contract: contract }
      this.$emit('contract-action', event)
    },

    removeFilterItem (filterKey) {
      if ((filterKey === 'includeProduction' && !this.filter.includeWoodsSale) ||
        filterKey === 'includeLogYardSale' ||
        (filterKey === 'includeByProduct' && !this.filter.includeByproductPurchase)) {
        return
      }

      let defaultFilterValue = false
      if (filterKey === 'includeWoodsSale' && !this.filter.includeProduction) {
        const filter = JSON.parse(JSON.stringify(this.filter))
        filter.includeProduction = true
        filter[filterKey] = false
        this.setFilter(filter)
        return
      }

      if (filterKey === 'includeByproductPurchase' && !this.filter.includeByProduct) {
        const filter = JSON.parse(JSON.stringify(this.filter))
        filter.includeByProduct = true
        filter[filterKey] = false
        this.setFilter(filter)
        return
      }

      const keys = ['includeOpen', 'includeClosed', 'includeOnHold', 'includeExpired']
      const keyIndex = keys.findIndex(k => k === filterKey)
      if (keyIndex !== -1) {
        keys.splice(keyIndex, 1)
        let isOneEnabled = false
        keys.forEach(k => {
          isOneEnabled = this.filter[k] || isOneEnabled
        })

        if (!isOneEnabled && filterKey === 'includeOpen') {
          return
        }

        if (!isOneEnabled) {
          const filter = JSON.parse(JSON.stringify(this.filter))
          filter[filterKey] = false
          filter.includeOpen = true
          this.setFilter(filter)
          return
        }
      }

      if (filterKey === 'approvalStatus') {
        const filter = JSON.parse(JSON.stringify(this.filter))
        filter[filterKey] = undefined
        defaultFilterValue = undefined
        this.setFilter(filter)
      }
      this.removeFilter({ key: filterKey, defaultValue: defaultFilterValue })
    },

    downloadCSV () {
      const csvHeaders = ContractHeaders.csvHeaders(this.$i18n.locale, this.isLogsContractMode)
      let dataString = `${csvHeaders.map(h => h.text).join(',')} \n`
      this.contracts.forEach(contract => {
        for (const value of csvHeaders.map(h => h.value)) {
          switch (value) {
            case ('status'):
              dataString += `"${this.contractStatusFromInt(contract.status)}",`
              break
            case ('isExternal'):
              dataString += `"${contract.isExternal ? this.$t('external') : this.$t('internal')}",`
              break
            case ('requiresLogger'):
              dataString += `"${contract.requiresLogger ? this.$t('required') : this.$t('notRequired')}",`
              break
            case ('acceptsAnyLoad'):
              dataString += `"${contract.acceptsAnyLoad ? this.$t('acceptsAny') : this.$t('restrictedProducts')}",`
              break
            case ('isDraft'):
              dataString += `"${contract.isDraft ? this.$t('draft') : this.$t('notDraft')}",`
              break
            case ('type'):
              dataString += `"${this.contractTypeFromInt(contract.type)}",`
              break
            case ('effectiveDate'):
              dataString += `"${utcToLocalDate(contract.effectiveDate, 'MM/DD/YYYY')}",`
              break
            case ('expirationDate'):
              dataString += `"${utcToLocalDate(contract.expirationDate, 'MM/DD/YYYY')}",`
              break
            default:
              dataString += `"${contract[value].replaceAll('"', '""')}",`
          }
        }

        dataString += '\n'
      })
      const blob = new Blob([dataString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `Contracts-${moment().format()}.csv`)
    },

    getStatusIcon (contract) {
      const status = contract.status
      let statusReason = ContractStatusReason.fromInt(contract.statusReason)
      if (statusReason !== 'None') {
        statusReason = `${statusReason}`
      } else {
        statusReason = ''
      }
      switch (status) {
        case 0:
          return {
            iconName: 'mdi-checkbox-marked-circle',
            color: 'success',
            msg: this.$t('open')
          }
        case 1:
          return {
            iconName: 'mdi-cancel',
            color: 'error',
            msg: `${this.$t('closed')}
                  ${statusReason}`
          }
        case 2:
          return {
            iconName: 'mdi-minus-circle',
            color: 'warning',
            msg: `${this.$t('onHold')}
                  ${statusReason}`
          }
        case 3:
          return {
            iconName: 'mdi-close-circle',
            color: 'error',
            msg: `${this.$t('expired')}
                  ${statusReason}`
          }
        default:
          return {
            iconName: 'mdi-alert',
            color: 'error',
            msg: this.$t('exception')
          }
      }
    },

    getTooltipColorForDraft (hasPendingTickets) {
      return hasPendingTickets ? 'error' : 'black'
    },

    getTooltipTextForDraft (hasPendingTickets) {
      return hasPendingTickets ? this.$t('hasPendingTickets') : this.$t('draftContract')
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    showApprovalStatusWarning (contract) {
      return contract.approvalStatus !== ContractApprovalStatus.Approved.value && contract.approvalStatus !== null
    },

    approvalStatusWarningText (contract) {
      return ContractApprovalStatus.fromInt(contract.approvalStatus)
    },

    canEdit (contract) {
      if (!userAssignedClaim(UserClaims.ContractManager)) return false

      if (!this.companyInfo.requireApprovalForContractModifications) return true

      if (this.approvalStateAllowsModification(contract)) return true

      return this.previousModificationByUser(contract)
    },

    approvalStateAllowsModification (contract) {
      return [
        ContractApprovalStatus.Approved.value,
        ContractApprovalStatus.Returned.value,
        null
      ].includes(contract.approvalStatus)
    },

    previousModificationByUser (contract) {
      return contract.approvalStatus === ContractApprovalStatus.PendingModification.value
        ? contract.approvalRequestedByUserId === this.userInfo.applicationUserId
        : false
    }
  }
}
</script>

<style>
.v-data-footer .v-icon {
  color: grey !important;
}
</style>
