<template>
  <ShortcutWrapper :nShortcutRequiredClaim="UserClaims.ContractManager" @n-shortcut="mutate(null, 'create')">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">

          <Dialog
          :stateId="dialogId"
          maxWidth="400px"
          @click:outside="close"
          @dialog-closing="close"
          @close="close">
            <ConfirmDelete
            v-if="confirmDeleteDialog"
            @delete="performDelete"
            @cancel-delete="close"
            :title="$t('harvestType')"/>
            <HarvestTypeForm
            v-else
            :propHarvestType="harvestType"
            @harvest-type-mutated="refresh" />
          </Dialog>

          <v-row>
            <v-col style="max-width: 800px;">
              <DataTable
              defaultHeight="calc(100vh - 615px)"
              :customCells="customColumns"
              :headers="headers"
              :items="allHarvestTypes"
              :loading="loading"
              :showDense="false">

                <template #actions="{ item }">
                  <Icon
                  icon="mdi-pencil"
                  :tooltipText="$t('edit')"
                  @icon-clicked="mutate(item, 'edit')"
                  :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                  elementId="harvest-type-edit"/>
                  <Icon icon="mdi-delete-forever"
                  :tooltipText="$t('delete')"
                  @icon-clicked="mutate(item, 'delete')"
                  :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                  elementId="harvest-type-delete"/>
                </template>

                <template #custom-controls>
                  <Icon
                  icon="mdi-refresh"
                  :tooltipText="$t('refresh')"
                  @icon-clicked="refresh"
                  :small="false"/>
                  <Icon icon="mdi-plus"
                  :tooltipText="$t('newHarvestType')"
                  @icon-clicked="mutate(null, 'create')"
                  :small="false"
                  :disabled="!userAssignedClaim(UserClaims.ContractManager)"
                  elementId="harvest-type-create"/>
                </template>

              </DataTable>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </ShortcutWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UserClaims } from '@/utils/Enumerations.js'
import HarvestTypeHeaders from '@/headers/HarvestType'
import { userAssignedClaim } from '@/utils/ClaimUtility'
import { uniqueDialogId } from '@/utils/componentHelpers'

export default {
  name: 'TractSettings',

  components: {
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    HarvestTypeForm: () => import('./HarvestTypeForm.vue')
  },

  created () {
    this.refresh()
  },

  computed: {
    ...mapGetters('harvest-type', ['allHarvestTypes']),

    headers () {
      return HarvestTypeHeaders.harvestTypeHeaders()
    },

    customColumns () {
      return [{
        slotName: 'actions',
        value: 'actions'
      }]
    }
  },

  data: () => ({
    confirmDeleteDialog: false,
    loading: false,
    harvestType: undefined,
    dialogId: uniqueDialogId('harvest-type-settings-dialog'),
    UserClaims
  }),

  methods: {
    ...mapActions('harvest-type', ['fetchHarvestTypes', 'deleteHarvestType']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    userAssignedClaim,

    refresh () {
      this.close()
      this.loading = true
      this.fetchHarvestTypes().finally(() => {
        this.loading = false
      })
    },

    mutate (harvestType, mutationType) {
      this.harvestType = JSON.parse(JSON.stringify(harvestType))
      if (mutationType === 'delete') this.confirmDeleteDialog = true
      this.openOrUpdateDialog(this.dialogId)
    },

    close () {
      this.confirmDeleteDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)
      this.harvestType = undefined
    },

    async performDelete () {
      await this.deleteHarvestType(this.harvestType.harvestTypeId)
      this.refresh()
    }
  }
}
</script>
