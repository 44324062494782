import { AccountingCategory, TemplateSpecialization } from '@/utils/Enumerations.js'

export const TemplateSpecializationInfo = {
  None: {
    enum: TemplateSpecialization.None
  },

  Transfer: {
    enum: TemplateSpecialization.Transfer,
    categories: [
      AccountingCategory.Accrual
    ]
  },

  Penalty: {
    enum: TemplateSpecialization.Penalty,
    categories: [
      AccountingCategory.Payable,
      AccountingCategory.Receivable,
      AccountingCategory.Accrual
    ]
  },

  Depletion: {
    enum: TemplateSpecialization.Depletion,
    categories: [
      AccountingCategory.Accrual
    ]
  },

  get: (specializationValue) => Object.values(TemplateSpecializationInfo).find(i => i.enum.value === specializationValue) ?? undefined
}
