<template>
  <v-autocomplete
    :data-testid="this.dataTestid"
    color="black"
    item-color="primary"
    v-model="chosenSetting"
    item-value="settingId"
    :label="$t('setting')"
    :loading="settingsLoading"
    :items="allSettings"
    :filter="(_, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
    return-object
  >
    <template #prepend-inner>
      <v-icon>mdi-map-marker-circle</v-icon>
    </template>
    <template #append-outer>
      <v-icon v-if="!hideRefresh" @click="refreshSettings()" color="primary" tabindex="-1">mdi-refresh</v-icon>
    </template>
    <template #selection="{ item }">
      <span>
        {{ addNameWithCode(item.name, item.code) }}
      </span>
    </template>
    <template #item="{ item }">
      <span>
        {{ addNameWithCode(item.name, item.code) }}
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContractSettingAutocomplete',

  props: {
    settingId: {
      type: Number,
      required: false,
      default: undefined
    },
    dataTestid: String,
    hideRefresh: {
      type: Boolean,
      default: false
    },
    propFetchSettings: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    chosenSetting: null
  }),

  watch: {
    chosenSetting (setting) {
      this.$emit('setting-chosen', setting)
    }
  },

  computed: {
    ...mapGetters('setting', ['allSettings', 'settingsLoading'])
  },

  created () {
    this.refreshSettings(true)
  },

  methods: {
    ...mapActions('setting', ['fetchSettings']),

    async refreshSettings (creation = false) {
      if (!creation || this.propFetchSettings) {
        await this.fetchSettings()
      }

      if (this.settingId) {
        const settingIndex = this.allSettings.findIndex(v => v.settingId === this.settingId)
        if (settingIndex !== -1) {
          this.chosenSetting = this.allSettings[settingIndex]
        }
      }
    }
  }
}
</script>
