<template>
  <v-card min-height="800px">
    <v-card-title class="headline py-4 primary white--text">
      <span>{{ $t("journalEntriesFor", { account: accountName }) }}</span>
      <v-spacer/>
      <span class="mr-2" >{{$t("accountTotal", { amount: totalJournalEntry })}} </span>
      <div v-if="isTDialog">
         <BaseDialogActions hideRefresh />
      </div>
      <Icon
      v-else
      iconColor="white"
      icon="mdi-close"
      dataTestId="entry-by-tract-close-button"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <p class="headline mt-2" v-if="noJournalEntries">
        {{$t('noTractJournalEntries')}}
      </p>

      <v-expansion-panels popout accordion v-model="openPanels" tile class="mt-10" v-else>
        <!-- Transfer Journal Entries -->
        <v-expansion-panel
        v-if="transferJournalEntries.length > 0"
        key="transfer-journal-entries">
          <v-expansion-panel-header>
            <v-layout>
              <Icon :icon="ContractMode.Transfers.details.icon" :iconColor="ContractMode.Transfers.details.color" :tooltipText="$t('transfers')" :small="false"/>
              <span class="ml-2">
                {{$t('transfers')}}
              </span>
              <v-spacer/>
            </v-layout>
            <template #actions>
              <v-container>
                <v-row justify="end">
                  {{formatMoney(transferTotals.totalJournalEntry)}}
                </v-row>
                <v-row justify="end">
                  {{tonStringFromPounds(transferTotals.totalNetWeight)}}
                </v-row>
                <v-row justify="end">
                  {{transferTotals.loadCount}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <JournalEntryTable
              :hideFooter="true"
              :dense="true"
              :journalEntries.sync="transferJournalEntries"
              :includeTract="false"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Log Yard Sale Journal Entries -->
        <v-expansion-panel
        v-if="logYardSaleJournalEntries.length > 0"
        key="lys-journal-entries">
          <v-expansion-panel-header>
            <v-layout>
              <Icon :icon="ContractMode.LogYardSale.details.icon" :iconColor="ContractMode.LogYardSale.details.color" :tooltipText="$t('logYardSales')" :small="false"/>
              <span class="ml-2">
                {{$t('logYardSales')}}
              </span>
              <v-spacer/>
            </v-layout>
            <template #actions>
              <v-container>
                <v-row justify="end">
                  {{formatMoney(logYardSaleTotals.totalJournalEntry)}}
                </v-row>
                <v-row justify="end">
                  {{tonStringFromPounds(logYardSaleTotals.totalNetWeight)}}
                </v-row>
                <v-row justify="end">
                  {{logYardSaleTotals.loadCount}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <JournalEntryTable
              :hideFooter="true"
              :dense="true"
              :journalEntries.sync="logYardSaleJournalEntries"
              :includeTract="false"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Logs Journal Entries -->
        <v-expansion-panel
        v-for="(tract, index) in tractJournalEntries"
        :key="index">
          <v-expansion-panel-header>
            <v-layout>
              {{$t("tractNamePlaceholder", { tractName: tract.tractName })}}
              <v-spacer/>
            </v-layout>
            <template #actions>
              <v-container>
                <v-row justify="end">
                  {{formatMoney(tract.totalJournalEntry)}}
                </v-row>
                <v-row justify="end">
                  {{tonStringFromPounds(tract.totalNetWeight)}}
                </v-row>
                <v-row justify="end">
                  {{tract.loadCount}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <JournalEntryTable
              :hideFooter="true"
              :dense="true"
              :journalEntries.sync="tract.journalEntries"
              includeTract
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-dialog v-model="viewingDetail" width="60%">
      <JournalEntryDetail
      @close="viewingDetail = false"
      v-if="viewingDetail"
      :journalEntry="focusedJournalEntry"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations'
import { ContractType, ContractMode } from '@/utils/Enumerations.js'
import { mapActions } from 'vuex'

export default {
  name: 'JournalEntryByTract',

  props: {
    accountJournalEntry: Object,
    account: {
      type: Object,
      default: undefined
    },
    isTDialog: Boolean
  },

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    JournalEntryTable: () => import('./JournalEntryTable.vue'),
    JournalEntryDetail: () => import('./JournalEntryDetail.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  computed: {
    totalJournalEntry () {
      return formatMoney(this.journalEntries.reduce((a, b) => a + b.amount, 0))
    },

    accountName () {
      if (this.account) {
        return this.account.name
      } else {
        return this.accountJournalEntry.accountName
      }
    },

    logYardSaleTotals () {
      const totals = this.logYardSaleJournalEntries.reduce((acc, je) => {
        acc.totalJournalEntry += je.amount
        acc.totalNetWeight += je.netWeight
        acc.uniqueTicketNumbers.add(je.ticketNumber)
        return acc
      }, { totalJournalEntry: 0, totalNetWeight: 0, uniqueTicketNumbers: new Set() })

      return {
        ...totals,
        totalLoads: Object.keys(totals.uniqueTicketNumbers).length
      }
    },

    transferTotals () {
      const totals = this.transferJournalEntries.reduce((acc, je) => {
        acc.totalJournalEntry += je.amount
        acc.totalNetWeight += je.netWeight
        acc.uniqueTicketNumbers.add(je.ticketNumber)
        return acc
      }, { totalJournalEntry: 0, totalNetWeight: 0, uniqueTicketNumbers: new Set() })

      return {
        ...totals,
        totalLoads: totals.uniqueTicketNumbers.size
      }
    }
  },

  async created () {
    if (this.account) {
      const journalEntries = await this.getJournalEntriesForAccount(this.account.accountId)
      this.journalEntries = journalEntries
      this.noJournalEntries = journalEntries.length === 0
      this.createTractJournalEntries(journalEntries)
    } else {
      this.createTractJournalEntries(this.accountJournalEntry.journalEntries)
    }
    this.initializeOpenPanels()
  },

  data: () => ({
    openPanels: [],
    tractJournalEntries: [],
    transferJournalEntries: [],
    logYardSaleJournalEntries: [],
    journalEntries: [],
    noJournalEntries: false,
    viewingDetail: false,
    focusedJournalEntry: undefined,
    ContractMode
  }),

  methods: {
    ...mapActions('journalEntry', ['getJournalEntriesForAccount']),
    formatMoney,
    tonStringFromPounds,

    viewJournalEntryDetail (journalEntry) {
      this.focusedJournalEntry = journalEntry
      this.viewingDetail = true
    },

    initializeOpenPanels () {
      const transferJournalEntries = this.transferJournalEntries.length > 0
      const logYardSaleJournalEntries = this.logYardSaleJournalEntries.length > 0
      const tractJournalEntries = this.tractJournalEntries.length > 0
      if ((transferJournalEntries && logYardSaleJournalEntries) ||
        (transferJournalEntries && tractJournalEntries) ||
        (logYardSaleJournalEntries && tractJournalEntries) ||
        this.tractJournalEntries.length > 1
      ) {
        this.openPanels = []
      } else {
        this.openPanels = [0]
      }
    },

    createTractJournalEntries (journalEntries) {
      const tractJournalEntries = journalEntries
        .filter(je => je.contractType === ContractType.Production.value || je.contractType === ContractType.WoodsSale.value)
        .reduce((acc, je) => {
          const tractName = je.tractName
          if (!acc[tractName]) {
            acc[tractName] = {
              tractName,
              totalNetWeight: 0,
              totalJournalEntry: 0,
              uniqueTicketNumbers: {},
              journalEntries: []
            }
          }
          acc[tractName].journalEntries.push(je)
          acc[tractName].totalJournalEntry += je.amount
          acc[tractName].totalNetWeight += je.netWeight
          acc[tractName].uniqueTicketNumbers[je.ticketNumber] = je.ticketNumber
          return acc
        }, {})

      this.transferJournalEntries = journalEntries.filter(je => je.contractType === ContractType.Transfer.value)
      this.logYardSaleJournalEntries = journalEntries.filter(je => je.contractType === ContractType.LogYardSale.value)
      this.journalEntries = journalEntries
      this.tractJournalEntries = Object.values(tractJournalEntries)
    }
  }
}
</script>
