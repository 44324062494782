<template>
  <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
  <v-card width="100%" v-else flat data-testid="setting-form">
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="settingForm"
        testId="save-setting"
        :buttonText="$t('createNewSetting')"
        @submit="postSetting"
      >
        <v-row>
          <v-col cols="12" sm="6" >
            <v-text-field
              data-testid="setting-name"
              v-model="newSetting.name"
              :label="$t('settingName')"
              :rules="[rules.required]"
              counter
              color="black"
              maxlength="40"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" >
            <v-text-field
              data-testid="setting-code"
              v-model="newSetting.code"
              color="black"
              :label="$t('settingCode')"
              counter
              maxlength="7"
            ></v-text-field>
          </v-col>
        </v-row>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import fieldRules from '@/utils/rules'

export default {
  name: 'SettingCreation',

  components: {
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  data: () => ({
    rules: fieldRules.rules,
    loading: false,
    newSetting: {
      name: '',
      code: ''
    }
  }),

  methods: {
    ...mapActions('setting', ['createSetting']),

    async postSetting () {
      if (!this.$refs.form.$refs.settingForm.validate()) {
        return
      }

      this.loading = true
      try {
        const response = await this.createSetting(this.newSetting)
        this.$emit('setting-id', response)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
