<template>
  <v-card>
    <v-card-title class="primary">
      <span class="white--text headline">
        {{ editing ? 'Edit Tract Note' : 'Create Tract Note' }}
      </span>
      <v-spacer/>
      <Icon
        icon="mdi-close"
        iconColor="white"
        dataTestId="tract-note-close"
        :tooltipText="$t('close')"
        :small="false"
        @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper :disabled="!note.content" @submit="saveChanges">
        <v-container grid-list-sm>
          <v-row>
            <v-col cols="12">
              <span class="title font-weight-bold">{{$t('note')}}</span>
              <v-textarea
                v-model="note.content"
                data-testid="tract-note-note"
                ref="noteTextField"
                outlined
                auto-grow
                counter
                maxlength="1024"
                clearable
                dense
                color="black"
                name="input-7-4"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import fieldRules from '@/utils/rules.js'
export default {
  name: 'TractNoteForm',

  components: {
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    tractId: {
      type: Number,
      required: true
    },
    propNote: {
      type: Object,
      default: undefined,
      required: false
    },
    editing: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    rules: fieldRules.rules,
    note: {}
  }),

  created () {
    if (this.propNote) {
      this.note = { ...this.propNote }
    }
    this.note.tractId = this.tractId
  },

  mounted () {
    setTimeout(() => {
      this.$refs.noteTextField.focus()
    }, 0)
  },

  methods: {
    ...mapActions('notes', ['createTractNote', 'updateTractNote']),
    async saveChanges () {
      if (this.note.content.trim().length === 0) {
        this.setSnackError(this.$t('noteContentCannotBeBlank'))
        return
      }

      if (!this.editing) {
        await this.createTractNote(this.note)
      } else {
        await this.updateTractNote(this.note)
      }
      this.$emit('tract-note-modified')
      this.$emit('close')
    }
  }
}
</script>
