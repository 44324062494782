import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchHarvestTypes (context) {
    const response = await ApiServer.get('harvestTypes')
    const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name))
    context.commit('setHarvestTypes', sortedData)
    return sortedData
  },
  async deleteHarvestType (context, harvestTypeId) {
    const response = await ApiServer.delete(`harvestTypes/${harvestTypeId}`)
    context.commit('removeHarvestType', harvestTypeId)
    return response.data
  },
  async createHarvestType (context, harvestType) {
    const response = await ApiServer.post('harvestTypes', harvestType)
    context.commit('addHarvestType', response.data)
    return response.data
  },
  async updateHarvestType (context, harvestType) {
    let basePath = `harvestTypes/${harvestType.harvestTypeId}`
    if (harvestType.businessEntityId) basePath += `?businessEntityId=${harvestType.businessEntityId}`
    const response = await ApiServer.put(basePath, harvestType)
    context.commit('updateHarvestType', response.data)
    return response.data
  }
}
